define('quizzes-addon/components/reports/assessment/qz-questions', ['exports', 'ember', 'quizzes-addon/models/taxonomy/taxonomy-tag', 'quizzes-addon/models/taxonomy/taxonomy-tag-data', 'quizzes-addon/config/quizzes-question', 'gooru-web/config/config'], function (exports, _ember, _quizzesAddonModelsTaxonomyTaxonomyTag, _quizzesAddonModelsTaxonomyTaxonomyTagData, _quizzesAddonConfigQuizzesQuestion, _gooruWebConfigConfig) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @requires service:i18n
     */
    i18n: _ember['default'].inject.service(),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'qz-questions'],

    classNameBindings: ['isAnswerKeyHidden:key-hidden', 'showPerformance:performance-view'],

    actions: {
      onShowPullUp: function onShowPullUp(file) {
        this.set('activeFile', file);
        this.set('isShowFilePullUp', true);
      },

      onClosePullup: function onClosePullup() {
        this.set('activeFile', null);
        this.set('isShowFilePullUp', false);
      },

      onShowMore: function onShowMore() {
        this.toggleProperty('isExpanded');
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {boolean} isAnswerKeyHidden - Should the answer key be hidden?
     */
    isAnswerKeyHidden: false,

    /**
     * List of questions to be displayed by the component
     *
     * @constant {Array}
     */
    results: null,

    isShowComp: false,

    isShowFilePullUp: false,

    activeFile: _ember['default'].A(),

    isExpanded: false,

    hasMoreText: _ember['default'].computed('content.resource.description', 'content.resource.baseAnswers', 'isShowMore', function () {
      var questionText = this.get('content.resource.description');
      var answerText = '';
      var questionTypes = ['serp_phrase_cued_reading', 'serp_words_per_minute', 'serp_silent_reading'];
      var baseAnswerText = this.get('content.resource.baseAnswers');
      var type = this.get('content.resource.type');
      var baseAnswer = baseAnswerText ? baseAnswerText.get(0) : null;
      if (baseAnswer) {
        answerText = baseAnswer.answer_text;
      }
      return (questionText && questionText.length > 130 || answerText && answerText.length > 130 && questionTypes.includes(type)) && this.get('enableMorebtn') && this.get('isShowMore');
    }),

    answerHead: _ember['default'].computed('i18n', function () {
      var labelText = this.get('i18n').t(_gooruWebConfigConfig.ANSWER_HEAD[this.get('content.resource.type')]).string;
      return labelText ? labelText : this.get('i18n').t('report.answer-label').string;
    }),

    /**
     * Indicate if the table show the performance columns
     *
     * @property {Boolean}
     */
    showPerformance: _ember['default'].computed('isSerp', function () {
      return !this.get('isSerp');
    }),

    /**
     * Indicate if the table show the score column
     *
     * @property {Boolean}
     */
    showScore: _ember['default'].computed('isSerp', function () {
      return (!this.get('isSerp') || _quizzesAddonConfigQuizzesQuestion.REPORT_SCORE_QUESTION_TYPES.indexOf(this.get('content.resource.type')) !== -1) && _quizzesAddonConfigQuizzesQuestion.EXCLUDE_SCORE_QUESTION_TYPES.indexOf(this.get('content.resource.type')) === -1;
    }),

    /**
     * The components title
     *
     * @property {String}
     */
    title: null,

    /**
     * Indicates if the view is open ended
     * @property {boolean}
     */
    isOpenEnded: _ember['default'].computed('viewMode', function () {
      return this.get('viewMode') === 'open-ended';
    }),

    isSerp: _ember['default'].computed('content.resource.type', function () {
      return !!_quizzesAddonConfigQuizzesQuestion.SERP_QUESTION_TYPES[this.get('content.resource.type')];
    }),

    isHideDescription: _ember['default'].computed('content.resource.type', function () {
      return !(this.get('content.resource.type') === 'serp_classic' || this.get('content.resource.type') === 'serp_sorting');
    }),

    isChooseOne: _ember['default'].computed('content.resource.type', function () {
      return this.get('content.resource.type') === _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.serpChooseOne;
    }),

    isWPM: _ember['default'].computed('content.resource.type', function () {
      return this.get('content.resource.type') === _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.serpWordsPerMinute;
    }),

    isH5PContent: _ember['default'].computed('content.resource.type', function () {
      return this.get('content.resource.type') === 'h5p_drag_and_drop_question';
    }),

    isShowMore: false,

    /**
     * @property {TaxonomyTag[]} List of taxonomy tags
     */
    taxonomyTags: _ember['default'].computed('content.resource.standards.[]', function () {
      var standards = this.get('content.resource.standards');
      if (standards) {
        standards = standards.filter(function (standard) {
          // Filter out learning targets (they're too long for the card)
          return !_quizzesAddonModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));
        });
      }
      return _quizzesAddonModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);
    }),

    wpmCount: 0,

    // ------------------------------------------------------------------
    // Hooks

    didInsertElement: function didInsertElement() {
      var component = this;
      this.$('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
      if (this.get('isWPM')) {
        var answer = this.get('content.answer.firstObject');
        var ansParse = JSON.parse(answer.value);
        var textCount = ansParse.selectedText.wpmCount || 0;
        this.set('wpmCount', textCount);
      }
      _ember['default'].run.scheduleOnce('afterRender', function () {
        _ember['default'].run.later(function () {
          component.set('isShowMore', component.$('.show-more-container-panel').height() > 30);
        }, 100);
      });
    }
  });
});