define('quizzes-addon/components/charts/gru-bubble-chart', ['exports', 'ember'], function (exports, _ember) {
  /**
   *Bubble Chart
   *
   * Component responsible for showing the bubble chart.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['charts', 'gru-bubble-chart'],

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {String} content - chart content
     */
    content: null,

    /**
     * @property {String} color - chart color
     */
    color: null,

    /**
     * @property {String} style - style safe string for template
     */
    style: _ember['default'].computed('color', function () {
      return _ember['default'].String.htmlSafe('background-color: ' + this.get('color'));
    })
  });
});