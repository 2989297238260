define('quizzes-addon/services/quizzes/api-sdk/parse-event', ['exports', 'ember', 'quizzes-addon/config/parse-event', 'quizzes-addon/utils/device-info', 'quizzes-addon/adapters/parse-event/parse-event'], function (exports, _ember, _quizzesAddonConfigParseEvent, _quizzesAddonUtilsDeviceInfo, _quizzesAddonAdaptersParseEventParseEvent) {
  exports['default'] = _ember['default'].Service.extend({
    /**
     * @property {Service} Session service
     */
    session: _ember['default'].inject.service('session'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('parseEventsAdapter', _quizzesAddonAdaptersParseEventParseEvent['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    postParseEvent: function postParseEvent(eventName, eventItem) {
      var service = this;
      var eventData = {
        sessionId: window.localStorage.getItem('parse_event_session'),
        appId: _quizzesAddonConfigParseEvent.APP_ID,
        eventName: eventName,
        appVersion: _quizzesAddonConfigParseEvent.APP_VERSION,
        deviceInfo: (0, _quizzesAddonUtilsDeviceInfo.getDeviceInfo)(),
        tenantId: service.get('session.tenantId'),
        userId: service.get('session.userId'),
        userName: service.get('session.userData.username'),
        timezone: moment.tz(moment.tz.guess()).zoneAbbr(),
        tenantShortName: service.get('session.tenantShortName') ? service.get('session.tenantShortName') : null
      };
      if (eventItem) {
        eventData.context = eventItem;
      }
      service.get('parseEventsAdapter').postParseEvent(eventData);
    },
    getCurrentLocation: function getCurrentLocation() {
      return this.get('parseEventsAdapter').getCurrentLocation();
    }
  });
});