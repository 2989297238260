define('quizzes-addon/components/player/questions/qz-comprehension', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion) {
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------
    // Attributes

    classNames: ['qz-comprehension'],

    /**
     * @requires service:quizzes/configuration
     */
    configurationService: _ember['default'].inject.service('quizzes/configuration'),

    /**
     * @type {ContextService} contextService
     * @property {Ember.Service} Service to send context related events
     */
    contextService: _ember['default'].inject.service('quizzes/context'),

    // -------------------------------------------------------------------
    // Properties

    questionList: _ember['default'].computed('question', function () {
      return this.get('question.subQuestions') || _ember['default'].A([]);
    }),

    instructionsActionTextKey: null,

    questionResult: null,

    cdnURL: _ember['default'].computed(function () {
      return this.get('configurationService.configuration.properties.cdnURL');
    }),

    contextResult: null,

    isFinished: _ember['default'].computed('questionList.@each.isCompleted', function () {
      var questions = this.get('questionList');
      return questions.filterBy('isCompleted', true).length === questions.length;
    }),

    baseContext: _ember['default'].computed(function () {
      return window.lastPlayedResource;
    }),

    resourceContext: _ember['default'].computed('baseContext', function () {
      var context = this.get('baseContext');
      return context.resourceResult.copy();
    }),

    baseResource: null,

    subQuestionResults: _ember['default'].computed('baseResource', function () {
      return this.get('baseResource.subQuestions');
    }),

    subStartTime: _ember['default'].computed('resourceContext', function () {
      return this.get('resourceContext.startTime');
    }),

    watchQuestions: _ember['default'].observer('questionList', 'questionList.@each', 'questionList.@each.isCompleted', function () {
      var questions = this.get('questionList');

      var pendingQuestion = _ember['default'].A([]);
      var pendingItem = questions.length - questions.filterBy('isCompleted', true).length;
      questions.map(function (question) {
        if (!(question.get('isCompleted') === true)) {
          pendingQuestion.push(question);
        }
      });

      if (pendingItem === 1 && (pendingQuestion[0].get('type') === 'text_entry' || pendingQuestion[0].get('type') === 'extended_text') || pendingItem === 0 && ['single_choice', 'likert_scale_question'].includes(questions[0].get('type'))) {
        this.notifyAnswerCompleted([]);
      }
    }),

    // -------------------------------------------------------------------
    // Hooks

    // -----------------------------------------------------------------
    // Actions

    actions: {
      completeAnswer: function completeAnswer(question, answer) {
        var isVisible = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

        var component = this;
        var isFinished = component.get('isFinished');
        var questions = component.get('questionList');
        if (!isVisible && !isFinished) {
          component.startEvent(question, answer);
        }

        if (questions.length === 1 || questions.length === 0 || isFinished) {
          component.notifyAnswerCompleted([]);
        }
      }
    },

    // ------------------------------------------------------------------
    // Methods

    startEvent: function startEvent(question, answer) {
      var component = this;
      var context = component.get('baseContext');
      var contextId = context.contextId;
      var resourceId = question.id;
      var eventContext = context.eventContext;
      var baseResource = {
        id: context.resourceId
      };
      component.get('contextService').startPlayResource(resourceId, contextId, eventContext, baseResource).then(function () {
        component.stopEvent(question, answer);
      });
    },

    stopEvent: function stopEvent(question, answer) {
      var component = this;
      var context = component.get('baseContext');
      var contextId = context.contextId;
      var resourceId = question.id;
      var eventContext = context.eventContext;
      var resourceResult = component.get('resourceContext');
      var subStartTime = component.get('subStartTime');
      context.resourceResult.set('isQuestion', false);
      resourceResult.setProperties({
        baseResourceId: context.resourceId,
        stopTime: new Date().getTime(),
        subStartTime: subStartTime,
        answer: answer,
        isQuestion: true,
        resourceId: question.id
      });

      component.get('contextService').stopPlayResource(resourceId, contextId, resourceResult, eventContext).then(function () {
        question.set('isCompleted', true);
        _ember['default'].run.later(function () {
          var questions = component.get('questionList');
          var isFinished = component.get('isFinished') ? component.get('isFinished') : questions.filterBy('isCompleted', true).length === questions.length;
          component.sendAction('onTriggerSubEvent', isFinished);
        }, 500);
      });
      component.set('subStartTime', new Date().getTime());
    }
  });
});