define('quizzes-addon/components/player/resources/qz-resource', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-resource'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('aspectRatio')) {
        var timer;

        (function () {
          var delay = 300; // milliseconds
          timer = null;

          // Get the component dimensions from the css
          _ember['default'].run.scheduleOnce('afterRender', _this, 'updateHeight');

          $(window).resize(function () {
            clearTimeout(timer);
            // The resize callback won't be processed until the resizing has stopped
            timer = setTimeout(_this.updateHeight.bind(_this), delay);
          });
        })();
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {Resource} the resource
     */
    resource: null,

    /**
     * @property {Number} height - Component height
     * This value will be read from the css and will be updated on any window.resize events
     */
    height: 0,

    /**
     * @property {Number} width - Component width
     * This value will be read from the css and will be updated on any window.resize events
     */
    width: 0,

    /**
     * @property {string} bind the height css style for the component
     */
    resourceSize: _ember['default'].computed('width', 'height', function () {
      var width = this.get('width');
      var widthString = width > 0 ? width + 'px' : '100%';
      var height = this.get('height');
      var heightString = height > 0 ? height + 'px' : '100%';
      return _ember['default'].String.htmlSafe('width: ' + widthString + '; height: ' + heightString + ';');
    }),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Update the width value of the component per the css width value
     */
    updateHeight: function updateHeight() {
      var component = this;
      var aspectRatio = component.get('aspectRatio');
      var $element = _ember['default'].$(component.element);
      var height = $element.css('height') ? parseInt($element.css('height').split('px')[0]) : 0;
      var width = $element.css('width') ? parseInt($element.css('width').split('px')[0]) : 0;
      var newWidth = height * aspectRatio.width / aspectRatio.height;
      var newHeight = 0;
      if (newWidth > width) {
        newWidth = 0;
        newHeight = width * aspectRatio.height / aspectRatio.width;
      }
      if (!component.isDestroyed) {
        component.set('width', newWidth);
        component.set('height', newHeight);
      }
    }
  });
});