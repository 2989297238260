define('quizzes-addon/components/reports/qz-class-assessment-report', ['exports', 'ember', 'quizzes-addon/mixins/modal', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/models/result/context', 'gooru-web/mixins/tenant-settings-mixin'], function (exports, _ember, _quizzesAddonMixinsModal, _quizzesAddonConfigQuizzesConfig, _quizzesAddonModelsResultContext, _gooruWebMixinsTenantSettingsMixin) {
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsModal['default'], _gooruWebMixinsTenantSettingsMixin['default'], {
    // -------------------------------------------------------------------------
    // Dependencies
    /**
     * @type {CollectionService} collectionService
     * @property {Ember.Service} Service to retrieve a collection
     */
    quizzesCollectionService: _ember['default'].inject.service('quizzes/collection'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'qz-class-assessment-report'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Set a new emotion as selected and update the component appearance accordingly
       *
       * @function actions:changeView
       * @param {string} layout type @see gru-view-layout-picker
       * @returns {undefined}
       */
      changeView: function changeView(layout) {
        var thumbnails = layout === _quizzesAddonConfigQuizzesConfig.VIEW_LAYOUT_PICKER_OPTIONS.LIST;
        this.set('isTableView', !thumbnails);
      },

      /**
       * When showing the student details
       * @param {string} studentId
       */
      viewAssessmentReport: function viewAssessmentReport(studentId) {
        var _this = this;

        _ember['default'].Logger.debug('Class assessment report: student with ID ' + studentId + ' was selected');

        var reportData = this.get('reportData');
        var assessment = this.get('assessment');
        var reportEvent = reportData.findByProfileId(studentId)[0];
        var resourceResults = reportEvent.get('resourceResults');
        var classFramework = this.get('classFramework');
        var isDefaultShowFW = this.get('isDefaultShowFW');
        resourceResults.forEach(function (resourceResult) {
          var resource = assessment.get('resources').findBy('id', resourceResult.get('resourceId'));
          resourceResult.set('resource', resource);
        });
        var contextResult = _quizzesAddonModelsResultContext['default'].create({
          reportEvent: reportEvent,
          averageReaction: _ember['default'].computed.alias('reportEvent.averageReaction'),
          correctPercentage: _ember['default'].computed.alias('reportEvent.averageScore'),
          correctAnswers: _ember['default'].computed.alias('reportEvent.totalCorrect'),
          currentResourceId: _ember['default'].computed.alias('reportEvent.currentResourceId'),
          totalTimeSpent: _ember['default'].computed.alias('reportEvent.totalTimeSpent'),
          totalAttempts: 1,
          selectedAttempt: 1,
          resourceResults: _ember['default'].computed.alias('reportEvent.resourceResults'),
          collection: assessment,
          isRealTime: this.get('isRealTime'),
          showAttempts: this.get('showAttempts')
        });

        var isCollection = contextResult.reportEvent.collection.isCollection;

        var profile = _ember['default'].Object.create({
          username: contextResult.reportEvent.profileName
        });

        if (isCollection) {
          this.get('quizzesCollectionService').getCollection(contextResult.reportEvent.collectionId, classFramework, isDefaultShowFW).then(function (collectionData) {
            _ember['default'].set(contextResult.collection, 'thumbnailUrl', collectionData.thumbnailUrl);
            _ember['default'].set(contextResult.collection, 'standards', collectionData.taxonomy);

            var modalModel = {
              contextResult: contextResult,
              width: '80%',
              profile: profile
            };

            _this.actions.showModal.call(_this, 'reports.qz-assessment-report', modalModel, null, 'qz-assessment-report-modal', true);
          });
        } else {
          this.get('quizzesCollectionService').getAssessment(contextResult.reportEvent.collectionId, classFramework, isDefaultShowFW).then(function (collectionData) {
            _ember['default'].set(contextResult.collection, 'thumbnailUrl', collectionData.thumbnailUrl);
            _ember['default'].set(contextResult.collection, 'standards', collectionData.taxonomy);

            var modalModel = {
              contextResult: contextResult,
              width: '80%',
              profile: profile
            };

            _this.actions.showModal.call(_this, 'reports.qz-assessment-report', modalModel, null, 'qz-assessment-report-modal', true);
          });
        }
      },

      /**
       * When showing the question details
       * @param {string} questionId
       */
      viewQuestionDetail: function viewQuestionDetail(questionId) {
        _ember['default'].Logger.debug('Assessment report: question with ID ' + questionId + ' was selected');

        var question = this.get('assessment.resources').findBy('id', questionId);
        var modalModel = {
          anonymous: this.get('anonymous'),
          selectedQuestion: question,
          reportData: this.get('reportData'),
          width: '75%'
        };
        this.actions.showModal.call(this, 'reports.class-assessment.qz-questions-detail', modalModel, null, 'qz-questions-detail-modal', true);
      }
    },

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Indicates if the report is displayed in anonymous mode
     * @property {boolean} anonymous
     */
    anonymous: false,

    /**
     * @prop { Collection } assessment - Assessment taken by a group of students
     */
    assessment: _ember['default'].computed.alias('reportData.collection'),

    /**
     * @prop { boolean } isTableView - is the table view currently selected?
     */
    isTableView: true,

    /**
     * @property { ReportData } report data
     */
    reportData: null,

    /**
     * @prop { boolean } isRealTime - if the report is a real time report
     */
    isRealTime: false,

    /**
     * @prop { boolean } isRealTime - if the report is a real time report
     */
    showAttempts: false,

    isCollectionType: _ember['default'].computed('', function () {
      var reportData = this.get('reportData');
      var reportEvent = reportData.reportEvents && reportData.reportEvents.length > 0 ? reportData.reportEvents[0] : reportData;
      var isCollection = reportEvent && reportEvent.collection && reportEvent.collection.isCollection ? reportEvent.collection.isCollection : false;
      return isCollection;
    }),

    classFramework: _ember['default'].computed('class', function () {
      return this.get('class.preference') && this.get('class.preference.framework') ? this.get('class.preference.framework') : null;
    })
  });
});