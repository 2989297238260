define('quizzes-addon/serializers/class/class', ['exports', 'ember', 'quizzes-addon/serializers/profile/profile'], function (exports, _ember, _quizzesAddonSerializersProfileProfile) {

  /**
   * Serializer to support the Class CRUD operations for API 3.0
   *
   * @typedef {Object} ClassSerializer
   */
  exports['default'] = _ember['default'].Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('profileSerializer', _quizzesAddonSerializersProfileProfile['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    /**
     * Normalize the response from class members endpoint
     * @param payload is the endpoint response in JSON format
     * @returns {ClassMembersModel} a class members model object
     */
    normalizeReadClassMembers: function normalizeReadClassMembers(payload) {
      var serializer = this;
      return _ember['default'].Object.create({
        owner: this.get('profileSerializer').normalizeProfile(payload.details.findBy('id', payload.owner[0])),
        collaborators: serializer.filterCollaborators(payload),
        members: serializer.filterMembers(payload)
      });
    },

    filterCollaborators: function filterCollaborators(payload) {
      return this.filterElements(payload, 'collaborator');
    },

    filterMembers: function filterMembers(payload) {
      return this.filterElements(payload, 'member');
    },

    filterElements: function filterElements(payload, property) {
      var serializer = this;
      var elements = payload[property];
      if (_ember['default'].isArray(elements) && elements.length > 0) {
        return elements.map(function (elementId) {
          return serializer.get('profileSerializer').normalizeProfile(payload.details.findBy('id', elementId));
        }).compact();
      } else {
        return [];
      }
    }
  });
});