define('quizzes-addon/serializers/collection/collection', ['exports', 'ember', 'quizzes-addon/serializers/resource/resource', 'quizzes-addon/models/collection/collection', 'quizzes-addon/serializers/taxonomy/taxonomy', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonSerializersResourceResource, _quizzesAddonModelsCollectionCollection, _quizzesAddonSerializersTaxonomyTaxonomy, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Serializer for Collection
   *
   * @typedef {Object} CollectionSerializer
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {ResourceSerializer} resourceSerializer
     */
    resourceSerializer: null,

    session: _ember['default'].inject.service('session'),

    taxonomySerializer: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('resourceSerializer', _quizzesAddonSerializersResourceResource['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('taxonomySerializer', _quizzesAddonSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    /**
     * Normalize the Collection data into a Collection object
     * @param payload
     * @returns {Collection}
     */
    normalizeReadCollection: function normalizeReadCollection(payload) {
      var serializer = this;
      var taxonomySerializer = serializer.get('taxonomySerializer');
      return _quizzesAddonModelsCollectionCollection['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        id: payload.id,
        ownerId: payload.ownerId,
        isCollection: payload.isCollection,
        resources: serializer.normalizeResources(payload.resources),
        settings: !payload.isCollection && payload.metadata ? serializer.normalizeSettings(payload.metadata.setting || {}) : null,
        title: payload.metadata ? payload.metadata.title : '',
        standards: payload.metadata && payload.metadata.taxonomy ? taxonomySerializer.normalizeTaxonomyObject(payload.metadata.taxonomy) : []
      });
    },

    /**
     * Normalize the Collection data into a Collection object
     * @param payload
     * @returns {Collection}
     */
    normalizeGetCollection: function normalizeGetCollection(payload) {
      var serializer = this;
      var taxonomySerializer = serializer.get('taxonomySerializer');
      var basePath = serializer.get('session.cdnUrls.content');
      var thumbnailUrl = payload.thumbnail ? basePath + payload.thumbnail : _quizzesAddonConfigQuizzesConfig.DEFAULT_IMAGES.COLLECTION;
      var metadata = payload.metadata || {};
      return _quizzesAddonModelsCollectionCollection['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        id: payload.target_collection_id || payload.id,
        pathId: payload.id,
        title: payload.title,
        learningObjectives: payload.learning_objective,
        isVisibleOnProfile: typeof payload.visible_on_profile !== 'undefined' ? payload.visible_on_profile : true,
        courseId: payload.target_course_id || payload.course_id,
        unitId: payload.target_unit_id || payload.unit_id,
        lessonId: payload.target_lesson_id || payload.lesson_id,
        creatorId: payload.creator_id,
        ownerId: payload.owner_id,
        collectionSubType: payload.target_content_subtype,
        metadata: metadata,
        centurySkills: metadata['21_century_skills'] && metadata['21_century_skills'].length ? metadata['21_century_skills'] : [],
        format: payload.format || payload.target_content_type,
        taxonomy: payload.taxonomy ? taxonomySerializer.normalizeTaxonomyObject(payload.taxonomy) : [],
        thumbnailUrl: thumbnailUrl,
        content: payload.content
      });
    },

    /**
     * Normalize the Assesment data into Assesment object
     * @param payload
     * @returns {Assesment}
     */
    normalizeGetAssessment: function normalizeGetAssessment(payload) {
      var serializer = this;
      var taxonomySerializer = serializer.get('taxonomySerializer');
      var basePath = serializer.get('session.cdnUrls.content');
      var thumbnailUrl = payload.thumbnail ? basePath + payload.thumbnail : _quizzesAddonConfigQuizzesConfig.DEFAULT_IMAGES.ASSESSMENT;
      var metadata = payload.metadata || {};
      return _quizzesAddonModelsCollectionCollection['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        id: payload.target_collection_id || payload.id,
        pathId: payload.id,
        title: payload.title,
        learningObjectives: payload.learning_objective,
        isVisibleOnProfile: typeof payload.visible_on_profile !== 'undefined' ? payload.visible_on_profile : true,
        courseId: payload.target_course_id || payload.course_id,
        unitId: payload.target_unit_id || payload.unit_id,
        lessonId: payload.target_lesson_id || payload.lesson_id,
        creatorId: payload.creator_id,
        ownerId: payload.owner_id,
        collectionSubType: payload.target_content_subtype,
        metadata: metadata,
        centurySkills: metadata['21_century_skills'] && metadata['21_century_skills'].length ? metadata['21_century_skills'] : [],
        format: payload.format || payload.target_content_type,
        taxonomy: payload.taxonomy ? taxonomySerializer.normalizeTaxonomyObject(payload.taxonomy) : [],
        questionCount: payload.question ? payload.question.length : 0,
        thumbnailUrl: thumbnailUrl,
        content: payload.question
      });
    },

    /**
     * Normalize the resources from a collection
     * @param payload
     * @returns {Resource}
     */
    normalizeResources: function normalizeResources(payload) {
      var _this = this;

      var resources = [];
      if (_ember['default'].isArray(payload)) {
        resources = payload.map(function (resource) {
          return _this.get('resourceSerializer').normalizeReadResource(resource);
        });
        // Fix sequence value
        resources.sort(function (a, b) {
          return a.get('sequence') - b.get('sequence');
        }).forEach(function (resource, i) {
          return resource.set('sequence', i + 1);
        });
      }
      return resources;
    },

    /**
     * Normalize the settings from a collection
     * @param setting
     * @returns {Object}
     */
    normalizeSettings: function normalizeSettings(setting) {
      return {
        attempts: setting.attempts_allowed || -1,
        bidirectional: setting.bidirectional_play || false,
        showFeedback: setting.show_feedback || _quizzesAddonConfigQuizzesConfig.ASSESSMENT_SHOW_VALUES.SUMMARY,
        showKey: setting.show_key === _quizzesAddonConfigQuizzesConfig.ASSESSMENT_SHOW_VALUES.SUMMARY
      };
    }
  });
});