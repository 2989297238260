define('quizzes-addon/serializers/rubric/rubric', ['exports', 'ember', 'quizzes-addon/serializers/taxonomy/taxonomy', 'quizzes-addon/models/rubric/rubric', 'quizzes-addon/models/rubric/rubric-category'], function (exports, _ember, _quizzesAddonSerializersTaxonomyTaxonomy, _quizzesAddonModelsRubricRubric, _quizzesAddonModelsRubricRubricCategory) {

  /**
   * Serializer to support the Rubric CRUD operations
   *
   * @typedef {Object} RubricSerializer
   */
  exports['default'] = _ember['default'].Object.extend({
    session: _ember['default'].inject.service('session'),

    /**
     * @property {TaxonomySerializer} taxonomySerializer
     */
    taxonomySerializer: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('taxonomySerializer', _quizzesAddonSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },
    /**
     * Normalizes a rubric
     * @param {*} data
     * @return {Rubric}
     */
    normalizeRubric: function normalizeRubric(data, owners) {
      var serializer = this;
      var metadata = data.metadata || {};
      var ownerId = data.creator_id;
      var filteredOwners = _ember['default'].A(owners).filterBy('id', ownerId);
      var categories = data.categories;
      var basePath = serializer.get('session.cdnUrls.content');
      var thumbnail = data.thumbnail ? basePath + data.thumbnail : null;
      var url = data.url && !data.isRemote ? basePath + data.url : data.url || null;

      return _quizzesAddonModelsRubricRubric['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        id: data.id,
        title: data.title,
        description: data.description,
        thumbnail: thumbnail,
        standards: serializer.get('taxonomySerializer').normalizeTaxonomyObject(data.taxonomy),
        audience: metadata.audience,
        url: url,
        isPublished: data.publishStatus === 'published',
        publishDate: data.publishDate,
        rubricOn: data.isRubric,
        uploaded: !data.isRemote,
        feedback: data.feedback,
        requiresFeedback: data.overallFeedbackRequired,
        categories: categories ? categories.map(function (category) {
          return serializer.normalizeRubricCategory(category);
        }) : null,
        owner: filteredOwners.get('length') ? filteredOwners.get('firstObject') : ownerId,
        createdDate: data.createdAt,
        updatedDate: data.updatedAt,
        tenant: data.tenant,
        grader: data.gradingType
      });
    },

    /**
     * Normalizes a rubric category
     * @param {*} data
     * @return {RubricCategory}
     *
     */
    normalizeRubricCategory: function normalizeRubricCategory(data) {
      var levels = data.levels || [];
      return _quizzesAddonModelsRubricRubricCategory['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        title: data.title,
        feedbackGuidance: data.feedback,
        requiresFeedback: data.requiredFeedback,
        allowsLevels: data.level,
        allowsScoring: data.scoring,
        levels: levels.map(function (level) {
          return { name: level.name, score: level.score || null };
        })
      });
    }
  });
});