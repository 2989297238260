define('quizzes-addon/components/player/questions/qz-serp-words-per-minute', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP decoding question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    session: _ember['default'].inject.service('session'),

    /**
     * @type {CollectionService} mediaService
     * @property {Ember.Service} Service to work with media
     */
    mediaService: _ember['default'].inject.service('quizzes/api-sdk/media'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-words-per-minute'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var question = this.get('question');
      this.injectDecoding(question.baseAnswers);
      var timer = setInterval(function () {
        $('#wpm-timer-clk').text(_this.$('.record-actions .timer-count').text());
      }, 1000);
      this.set('intervalTimer', timer);
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    didDestroyElement: function didDestroyElement() {
      clearInterval(this.get('intervalTimer'));
    },

    // -------------------------------------------------------------------------
    // Properties

    userId: _ember['default'].computed.alias('session.userId'),

    isAudioUploaded: false,

    intervalTimer: null,

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    injectDecoding: function injectDecoding(baseAnswers) {
      var component = this,
          userAnswered = undefined;
      var user = {
        userId: this.get('userId')
      };
      var accessibilitySettings = JSON.parse(window.localStorage.getItem('accessibility_settings'));
      var userAnswerList = component.get('userAnswer');
      if (userAnswerList && userAnswerList.length) {
        userAnswered = userAnswerList.map(function (item) {
          var data = JSON.parse(item.value);
          data.audio = {
            url: data.value
          };
          return data;
        });
      }

      var content = {
        contentId: this.get('question.id'),
        contentTitle: this.get('question.title'),
        answers: baseAnswers,
        isHighContrast: accessibilitySettings && accessibilitySettings.is_high_contrast_enabled ? accessibilitySettings.is_high_contrast_enabled : false,
        userAnswer: userAnswered
      };
      window.serp.languageDecode().select('#serp-words-per-minute-container').dataIn(user, null, content).wordsPerMinute().render().listener(function (eventData) {
        component.handleDecodingSubmission(eventData);
      });
    },

    handleDecodingSubmission: function handleDecodingSubmission(eventData) {
      var component = this;
      component.set('isAudioUploaded', false);
      var decodingAns = $('.panel-body .decoding-answers');
      decodingAns.find('.loader-icons').show();
      var answers = [];
      var audioPromises = eventData.decoding_answers.map(function (answer) {
        return new Promise(function (resolve) {
          return component.uploadAudio(answer.audio.blob).then(function (fileUrl) {
            if (fileUrl) {
              setTimeout(function () {
                var answerData = {
                  value: fileUrl,
                  selectedText: answer.audio.selectedText,
                  speechText: {
                    audioText: '',
                    audioTime: 0,
                    avgWordCount: 0,
                    fullAudioText: ''
                  }
                };
                answers.push({
                  value: JSON.stringify(answerData)
                });
                return resolve();
              }, 1000);
            } else {
              component.set('isAudioUploaded', true);

              decodingAns.find('.audio-error').show();
              return;
            }
          });
        });
      });
      if (!component.set('isAudioUploaded')) {
        Promise.all(audioPromises).then(function () {
          if (answers && answers.length) {
            decodingAns.find('.audio-uploaded').show();
            decodingAns.find('.confirm-text').addClass('audio-saved');
            decodingAns.find('.loader-icons').hide();
            clearInterval(component.get('intervalTimer'));
            component.notifyAnswerCompleted(answers);
          }
        });
      }
    },
    uploadAudio: function uploadAudio(audioBlob) {
      return this.get('mediaService').uploadContentFile(audioBlob, true);
    }
  });
});