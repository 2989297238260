define('quizzes-addon/components/player/questions/qz-serp-syllables-division', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP decoding question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    session: _ember['default'].inject.service('session'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-syllables-division'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var question = this.get('question');
      var decodingExcercies = question.baseAnswers && question.baseAnswers.map(function (baseAnswer) {
        return {
          answer_text: baseAnswer.answer_text,
          correct_answer: baseAnswer.correct_answer.map(function (item) {
            return JSON.parse(item);
          })
        };
      });
      this.injectDecoding(decodingExcercies);
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    // -------------------------------------------------------------------------
    // Properties

    userId: _ember['default'].computed.alias('session.userId'),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    injectDecoding: function injectDecoding(baseAnswers) {
      var component = this,
          userAnswered = undefined;
      var user = {
        userId: this.get('userId')
      };
      var userAnswerList = component.get('userAnswer');
      if (userAnswerList && userAnswerList.length) {
        userAnswered = userAnswerList.map(function (item) {
          return JSON.parse(item.value);
        });
      }
      var content = {
        contentId: this.get('question.id'),
        contentTitle: this.get('question.title'),
        answers: baseAnswers,
        userAnswer: userAnswered
      };
      window.serp.languageDecode().select('#serp-syllables-division-questions-container').dataIn(user, null, content).dividingSyllables().render().listener(function (eventData) {
        var userAnswers = [];
        var question = component.get('question');
        if (question.baseAnswers.length === eventData.userAnswers.length) {
          eventData.userAnswers.forEach(function (item) {
            userAnswers.push({
              value: JSON.stringify(item)
            });
          });
          component.notifyAnswerCompleted(userAnswers);
        }
      });
    }
  });
});