define('quizzes-addon/components/reports/assessment/qz-questions-xs', ['exports', 'ember'], function (exports, _ember) {

  /**
   *Question-xs
   *
   * Component responsible for showing the question details for xsmall devices.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'qz-questions-xs'],
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Question to be displayed by the component
     *
     * @property {Ember.Object}
     */
    question: null,

    /**
     * Indicate if the table show the performance columns
     *
     * @property {Boolean}
     */
    showPerformance: true
  });
});