define('quizzes-addon/adapters/attempt/attempt', ['exports', 'quizzes-addon/mixins/token', 'quizzes-addon/adapters/application'], function (exports, _quizzesAddonMixinsToken, _quizzesAddonAdaptersApplication) {
  exports['default'] = _quizzesAddonAdaptersApplication['default'].extend(_quizzesAddonMixinsToken['default'], {
    /**
     * @property {Object} namespace base url for attempts endpoints
     */
    namespace: '/quizzes/api/v1/attempts',

    /**
     * Get data to show on the real time report
     * @param {String} contextId
     * @returns {Promise}
     */
    getReportData: function getReportData(contextId) {
      var namespace = this.get('namespace');
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        headers: this.get('headers')
      };
      var url = namespace + '/contexts/' + contextId;
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Get attempt data for report
     * @param {String} attemptId
     * @returns {Promise}
     */
    getAttemptData: function getAttemptData(attemptId) {
      var namespace = this.get('namespace');
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        headers: this.get('headers')
      };
      var url = namespace + '/' + attemptId;
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Get all attempt ids by context and profile id
     * @param {String} contextId
     * @param {String} profileId
     * @returns {Promise}
     */
    getAttemptIds: function getAttemptIds(contextId, profileId) {
      var namespace = this.get('namespace');
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        headers: this.get('headers')
      };
      var url = namespace + '/contexts/' + contextId + '/profiles/' + profileId;
      return this.sendAjaxRequest(url, options);
    }
  });
});