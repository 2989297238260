define('quizzes-addon/helpers/qz-reaction-icon', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {
  exports.reactionIcon = reactionIcon;

  /**
   * Get an icon depending on a emotion value (@see /app/config/config.js#L33)
   *
   * @param {Number} reactionValue
   * @returns {String} - html string
   */

  function reactionIcon(reactionValue) {
    return _ember['default'].String.htmlSafe((0, _quizzesAddonUtilsUtils.getReactionIcon)(reactionValue[0]));
  }

  exports['default'] = _ember['default'].Helper.helper(reactionIcon);
});