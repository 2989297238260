define('quizzes-addon/components/editor/assessment/qz-assessment', ['exports', 'ember', 'quizzes-addon/models/editor/assessment/category', 'quizzes-addon/models/editor/assessment/rubric'], function (exports, _ember, _quizzesAddonModelsEditorAssessmentCategory, _quizzesAddonModelsEditorAssessmentRubric) {
  /**
   *
   * Component for add/edit assessment
   *
   * @module
   * @augments ember/Route
   */
  exports['default'] = _ember['default'].Component.extend({
    /**
     * @property {Service} I18N service
     */
    i18n: _ember['default'].inject.service(),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['editor', 'assessment', 'qz-assessment'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Add new category
       */
      addNewCategory: function addNewCategory() {
        var newCategory = _quizzesAddonModelsEditorAssessmentCategory['default'].create({}).initLevels();
        var categories = this.get('categories');
        categories.addObject(newCategory);
      },
      /**
       *Set if feedback is required
       */
      setFeedBack: function setFeedBack() {
        this.set('assessment.requiredFeedback', !this.get('assessment.requiredFeedback'));
      },
      /**
       *Triggered by qz-category
       */
      deleteCategory: function deleteCategory(category) {
        var categories = this.get('categories');
        categories.removeObject(category);
      },
      /**
       *Copy category
       */
      copyCategory: function copyCategory(category, index) {
        var categories = this.get('categories');
        var newCategory = category.copy();
        categories.insertAt(index + 1, newCategory);
      }
    },

    // -------------------------------------------------------------------------
    // Properties
    assessment: _ember['default'].Object.create({
      title: '',
      learningObjective: '',
      rubric: _quizzesAddonModelsEditorAssessmentRubric['default'].create({}),
      totalPoints: 0,
      feedbackGuidance: '',
      requiredFeedback: false
    }),
    /**
     * @property {Category[]} Temporal categories array
     */
    categories: _ember['default'].computed('assessment.rubric.categories.[]', function () {
      var categories = _ember['default'].A([]);
      if (this.get('assessment.rubric.categories.length')) {
        //TODO return a copy of categories
        categories = this.get('assessment.rubric.categories');
      }
      return categories;
    }),

    /**
     * @property {Object[]} headerActions List of action buttons to show
     */
    headerActions: _ember['default'].computed(function () {
      return [{
        name: 'delete',
        text: this.get('i18n').t('common.delete'),
        icon: 'delete'
      }, {
        name: 'copy',
        text: this.get('i18n').t('common.copy'),
        icon: 'content_copy'
      }, {
        name: 'preview',
        text: this.get('i18n').t('common.preview'),
        icon: 'remove_red_eye'
      }, {
        name: 'settings',
        text: this.get('i18n').t('common.settings'),
        icon: 'settings'
      }, {
        name: 'assign',
        text: this.get('i18n').t('common.assign-to-class'),
        icon: 'person_add'
      }];
    }),
    /**
     * @property {Object[]} headerActions List of action buttons to show
     */
    footerActions: _ember['default'].computed(function () {
      return [{
        name: 'cancel',
        text: this.get('i18n').t('common.cancel'),
        'class': 'btn-default'
      }, {
        name: 'save',
        text: this.get('i18n').t('common.save'),
        'class': 'btn-primary'
      }];
    }),
    /**
     * @property {String} headerTitle
     */
    headerTitle: _ember['default'].computed(function () {
      return this.get('i18n').t('common.add-assessment');
    }),

    /**
     * @property {Object[]} options List of tab options to show
     */
    options: _ember['default'].computed(function () {
      return [{
        name: 'information',
        text: this.get('i18n').t('common.information')
      }, {
        name: 'prompt',
        text: this.get('i18n').t('common.prompt')
      }, {
        name: 'rubric',
        text: this.get('i18n').t('common.rubric')
      }];
    }),

    /**
     * @property {String} selected Current option selected
     */
    selected: 'prompt',
    /**
     * Indicate if is editing view
     */
    isEditing: true
  });
});