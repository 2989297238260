define('quizzes-addon/utils/gooru-legacy-url', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Utility methods to parse gooru legacy urls
   * @typedef {object} GooruLegacyUrl
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {string}
     */
    url: null,

    /**
     * Indicates if it is a gooru legacy url
     */
    isLegacyUrl: _ember['default'].computed('ur', function () {
      var url = this.get('url');
      return (/#([^&|^?]+)/gm.exec(url)
      );
    }),

    /**
     * Indicates if it is a collection play url
     */
    isCollectionPlayer: _ember['default'].computed.equal('module', 'collection-play'),

    /**
     * Indicates if it is a assessment play url
     */
    isAssessmentPlayer: _ember['default'].computed.equal('module', 'assessment-play'),

    /**
     * Indicates if it is a resource play url
     */
    isResourcePlayer: _ember['default'].computed.equal('module', 'resource-play'),

    /**
     * It contains the module name from the url
     * i.e #collection-play?id=1 would be collection-play
     * @property {string} application module
     */
    module: _ember['default'].computed('url', function () {
      var url = this.get('url');
      var match = /#([^&|^?]+)/gm.exec(url);
      return match ? match[1].trim() : false;
    }),

    /**
     * It contains the id parameter
     * @property {string} id
     */
    id: _ember['default'].computed('url', function () {
      var url = this.get('url');
      var match = /id=([^&]+)/gm.exec(url);
      return match ? match[1].trim() : false;
    }),

    /**
     * It contains the content id parameter
     * @property {string} id
     */
    contentId: _ember['default'].computed('url', function () {
      var url = this.get('url');
      var match = /cid=([^&]+)/gm.exec(url);
      return match ? match[1].trim() : false;
    }),

    /**
     * Gets the route information
     * @property
     */
    routeParams: _ember['default'].computed('url', function () {
      var legacyUrl = this;
      var id = legacyUrl.get('id');
      var params = null;
      if (this.get('isCollectionPlayer') || this.get('isAssessmentPlayer')) {
        var contentId = legacyUrl.get('contentId');
        var validContentId = contentId && contentId.indexOf('{') < 0; // sometimes it contains cid={1}
        var type = this.get('isCollectionPlayer') ? 'collection' : 'assessment';
        params = contentId && validContentId ? ['player', id, { queryParams: { resourceId: contentId, type: type } }] : ['player', id, { queryParams: { type: type } }];
      } else if (this.get('isResourcePlayer')) {
        params = ['content.resources.play', legacyUrl.get('id')];
      } else {
        /*
          if there is non of the above, we assume it is a profile url
          i.e /#perezedify
         */
        params = ['profile', legacyUrl.get('module')];
      }

      return params;
    })
  });
});