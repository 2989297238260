define('quizzes-addon/components/player/qz-question-viewer', ['exports', 'ember', 'quizzes-addon/config/quizzes-question', 'quizzes-addon/mixins/modal', 'quizzes-addon/config/quizzes-config', 'gooru-web/mixins/tenant-settings-mixin'], function (exports, _ember, _quizzesAddonConfigQuizzesQuestion, _quizzesAddonMixinsModal, _quizzesAddonConfigQuizzesConfig, _gooruWebMixinsTenantSettingsMixin) {
  /**
   * Player question viewer
   *
   * Component responsible for providing a frame where all question types
   * will be displayed i.e. it will be responsible for selecting any specific
   * question components per question type.
   *
   * @module
   * @see controllers/player.js
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsModal['default'], _gooruWebMixinsTenantSettingsMixin['default'], {
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @type {CollectionService} collectionService
     * @property {Ember.Service} Service to retrieve a collection|assessment
     */
    collectionService: _ember['default'].inject.service('quizzes/collection'),

    /**
     * @type {ClassService} Service to retrieve class information
     */
    classService: _ember['default'].inject.service('api-sdk/class'),

    session: _ember['default'].inject.service('session'),

    //Show next button and enable/disable it by checking answer text, once every question loaded
    didInsertElement: function didInsertElement() {
      var questionTitle = $('<h6/>').html(this.get('question.title')).text();
      this.set('question.title', questionTitle);
      this.enableNextButton();
      this.adjustFontSize();
      this.fontChanger();
      if (this.get('classId')) {
        this.getClassInfo();
      }
    },
    // Methods

    /**
     * @function parseRubricCategories
     * Method to parse rubric categories
     */
    parseRubricCategories: function parseRubricCategories(oaRubricCategories, categories) {
      if (categories) {
        categories.map(function (category) {
          var levels = category.levels;
          if (levels) {
            levels = levels.sortBy('level_score');
            category.title = category.category_title;
            category.allowsLevels = category.level;
            category.allowsScoring = category.scoring;
            category.feedbackGuidance = category.feedback_guidance;
            category.requiresFeedback = category.required_feedback;
            category.levels = levels.map(function (data) {
              var values = _ember['default'].Object.create({
                name: data.level_name,
                score: data.level_score
              });
              return values;
            });
          }
          oaRubricCategories.push(_ember['default'].Object.create(category));
        });
        return oaRubricCategories;
      }
    },
    /**
     * @requires service:i18n
     */
    i18n: _ember['default'].inject.service(),

    /**
     * @requires service:quizzes/configuration
     */
    configurationService: _ember['default'].inject.service('quizzes/configuration'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-question-viewer'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * When the question answer has been changed
       * @param {Resource} question the question
       */
      changeAnswer: function changeAnswer(question, answer) {
        if (!this.isDestroyed) {
          if (!this.get('submitted')) {
            //todo track analytics
            this.set('question', question);
            var questionResult = this.get('questionResult');
            questionResult.set('answer', answer);
            if (this.get('isComprehension')) {
              this.sendAction('completeAnswer', question, answer, true);
              return;
            }
            this.enableNextButton();
          }
        }
      },

      /**
       * When the question answer has been cleared
       * @param {Resource} question the question
       */
      clearAnswer: function clearAnswer(question) {
        if (!this.get('submitted')) {
          //todo track analytics
          this.set('question', question);
          this.set('answerCompleted', false);
          this.enableNextButton();
        }
      },

      /**
       * When the question answer has been completed
       * @param {Resource} question the question
       * @param { { answer: Object, correct: boolean } } stats
       */
      completeAnswer: function completeAnswer(question, answer) {
        if (!this.get('submitted')) {
          var questionResult = this.get('questionResult');
          questionResult.set('answer', answer);

          this.set('question', question);
          this.set('answerCompleted', true);
          if (this.get('isComprehension')) {
            this.sendAction('completeAnswer', question, answer);
            return;
          }
          this.enableNextButton();
        }
      },

      /**
       * When the question answer was loaded from BE
       * @param {Resource} question the question
       * @param { { answer: Object, correct: boolean } } stats
       */
      loadedAnswer: function loadedAnswer(question, answer) {
        if (!this.get('submitted')) {
          //Ember.run.later(() => {
          var questionResult = this.get('questionResult');
          questionResult.set('answer', answer);

          this.set('question', question);
          this.set('answerCompleted', false);
          this.enableNextButton();
          //});
        }
      },

      /**
       * Show explanation action triggered
       */
      showExplanation: function showExplanation() {
        this.set('isExplanationShown', true);
      },
      /**
       * Action triggered when the user see a hint
       */
      showHint: function showHint() {
        var actualHint = this.get('actualHint');

        this.get('hintsToDisplay').pushObject(this.get('question.hints').objectAt(actualHint));
        actualHint += 1;
        this.set('actualHint', actualHint);
      },

      /**
       * When the question is submitted
       */
      submitQuestion: function submitQuestion() {
        this.submitQuestion();
      },

      showImageModal: function showImageModal(thumbnail) {
        this.actions.showModal.call(this, 'player.qz-image-modal', {
          thumbnail: thumbnail,
          width: '90vw',
          height: '90vh'
        }, null, null, true);
      },
      //Aciton triggered when toggle teacher/student rubric categories container
      onToggleRubricContainer: function onToggleRubricContainer(containerSection) {
        var component = this;
        if (containerSection === _quizzesAddonConfigQuizzesConfig.ROLES.STUDENT) {
          component.toggleProperty('isStudentCategoriesExpanded');
        } else {
          component.toggleProperty('isTeacherCategoriesExpanded');
        }
        component.$('.' + containerSection + '.categories-container').slideToggle();
      },

      onTriggerSubEvent: function onTriggerSubEvent(eventStatus) {
        this.sendAction('onTriggerSubEvent', eventStatus);
      }
    },
    // -------------------------------------------------------------------------
    // Events

    /**
     * Removed keyup handler when the component will destroy
     */
    disableListenToEnter: _ember['default'].on('willDestroyElement', function () {
      $(document).off('keyup');
    }),

    /**
     * Listen to enter in order to submit the question when the user press enter
     */
    listenToEnter: _ember['default'].on('didInsertElement', function () {
      var component = this;
      $(document).on('keyup', function (e) {
        if (e.which === _quizzesAddonConfigQuizzesConfig.KEY_CODES.ENTER) {
          if (!component.get('isSubmitDisabled')) {
            if (!component.get('question.isOpenEnded') && component.get('question.type') !== 'serp_lang_activities_for_comprehension') {
              component.submitQuestion();
            }
          }
        }
      });
    }),

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Hits available for a question
     * @property {number} availableHints
     */
    actualHint: 0,

    /**
     * @property {boolean} indicates when the answer is completed
     */
    answerCompleted: false,

    /**
     * @property {string} Return the question body and modified the text if the question is
     * FIB to show the correct format.
     */
    questionBody: _ember['default'].computed('question.body', 'question.description', function () {
      var component = this;
      var text = this.get('question.body');

      if (component.get('question.isHotTextHighlight')) {
        text = this.get('question.description');
      }
      if (component.get('question.isFIB') || component.get('question.isscientificFIB')) {
        var regex = new RegExp(_quizzesAddonConfigQuizzesConfig.FIB_REGEX.source, 'g');
        text = component.get('question.body').replace(regex, '_______');
      }
      component.fetchContentInfo();
      return text;
    }),
    /**
     * Hits available for a question
     * @property {number} availableHints
     */
    availableHints: _ember['default'].computed('actualHint', 'question', function () {
      return this.get('question.hints.length') - this.get('actualHint');
    }),

    viewMetadataObserver: _ember['default'].observer('content', function () {
      var component = this;
      component.fetchContentInfo();
    }),

    /**
     * Default button text key
     * @property {string}
     */
    buttonTextKey: 'common.save',

    /**
     * The collection
     * @property {Collection}
     */
    collection: null,

    /**
     * Indicates when the question has explanation
     * @property {boolean}
     */
    doesNotHaveExplanation: _ember['default'].computed.not('question.explanation'),

    /**
     * Unicode value depending on the correctness of the question
     * @property {boolean}
     */
    feedbackUnicode: _ember['default'].computed('questionResult.correct', function () {
      return this.get('questionResult.correct') ? _quizzesAddonConfigQuizzesConfig.FEEDBACK_EMOTION_VALUES.CORRECT : _quizzesAddonConfigQuizzesConfig.FEEDBACK_EMOTION_VALUES.INCORRECT;
    }),
    /**
     * Indicate if the question is a free response question
     * @property {boolean}
     */
    freeResponse: _ember['default'].computed.equal('question.type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.openEnded),

    isSerpMultiChoice: _ember['default'].computed.equal('question.type', _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.serpMultiChoice),

    /**
     * Hints to display
     * @property {Array} hintsToDisplay
     */
    hintsToDisplay: _ember['default'].A(),

    /**
     * Default instructions action text key
     * @property {string}
     */
    instructionsActionTextKey: 'common.save',

    /**
     * Key to show the correct/incorrect message
     * @property {String} isCorrectMessageKey
     */
    isCorrectMessageKey: _ember['default'].computed('questionResult.correct', function () {
      return this.get('questionResult.correct') ? 'common.answer-correct' : 'common.answer-incorrect';
    }),

    /**
     * Is the explanation button disabled?
     * @property {boolean} disableHint
     */
    isExplanationButtonDisabled: _ember['default'].computed.or('isExplanationShown', 'doesNotHaveExplanation'),

    /**
     * Is the explanation shown?
     * @property {boolean} disableExplanation
     */
    isExplanationShown: false,

    /**
     * Is the hints button disabled?
     * @property {boolean} disableHint
     */
    isHintButtonDisabled: _ember['default'].computed.not('availableHints'),

    /**
     * @property {boolean} indicates when the inputs are enabled
     */
    isInputDisabled: _ember['default'].computed('questionResult.submitted', 'collection.showFeedback', function () {
      var showFeedback = this.get('collection.showFeedback') === _quizzesAddonConfigQuizzesConfig.ASSESSMENT_SHOW_VALUES.IMMEDIATE;
      return showFeedback && this.get('isStudent') && this.get('questionResult.submitted') || this.get('submitted');
    }),

    /**
     * Indicates if the student is playing the collection
     * @property {boolean}
     */
    isStudent: _ember['default'].computed.equal('role', 'student'),

    /**
     * @property {boolean} indicates when the submit functionality is enabled
     */
    isSubmitDisabled: _ember['default'].computed('answerCompleted', 'submitted', 'questionResult.submitted', 'collection.showFeedback', function () {
      var showFeedback = this.get('collection.showFeedback') === _quizzesAddonConfigQuizzesConfig.ASSESSMENT_SHOW_VALUES.IMMEDIATE;
      if (!showFeedback || this.get('isTeacher') || !this.get('questionResult.submitted')) {
        return this.get('submitted') || !this.get('answerCompleted');
      }
      return false;
    }),

    /**
     * Indicates if the teacher is playing this collection
     * @property {boolean}
     */
    isTeacher: _ember['default'].computed.not('isStudent'),

    /**
     * @property {string} on submit question action
     */
    onSubmitQuestion: 'submitQuestion',

    /**
     * The question
     * @property {Resource} question
     */
    question: null,

    /**
     * Question result, it is passed as a parameter for this component
     * @property {QuestionResult}
     */
    questionResult: null,

    /**
     * Indicates the user's role, could be 'student', 'teacher' or null
     * @property {string}
     */
    role: null,

    /**
     * Indicates if feedback should be shown
     * @property {boolean}
     */
    showFeedback: _ember['default'].computed('collection.showFeedback', 'questionResult.submitted', function () {
      var feedback = this.get('collection.showFeedback') === _quizzesAddonConfigQuizzesConfig.ASSESSMENT_SHOW_VALUES.IMMEDIATE;
      return feedback && this.get('isStudent') && this.get('questionResult.submitted');
    }),

    /**
     * Indicates when the collection is already submitted
     * @property {boolean}
     */
    submitted: false,

    /**
     * Returns the thumbnail url if it exists
     * @property {String}
     */
    thumbnail: _ember['default'].computed('question.thumbnail', function () {
      var cdnURL = this.get('configurationService.configuration.properties.cdnURL');
      return this.get('question.thumbnail') ? '' + cdnURL + this.get('question.thumbnail') : null;
    }),
    /**
     * @property {Array} teacherRubricCategories
     * Property for list of teacher rubric categories
     */
    teacherRubricCategories: _ember['default'].computed('questionResult.rubric', function () {
      var component = this;
      var rubric = component.get('questionResult.rubric');
      if (rubric) {
        var teacherRubric = rubric.teacherRubric;
        if (teacherRubric) {
          var oaRubricTeacherCategories = _ember['default'].A([]);
          var categories = teacherRubric.categories || _ember['default'].A([]);
          oaRubricTeacherCategories = component.parseRubricCategories(oaRubricTeacherCategories, categories);
          return oaRubricTeacherCategories;
        }
      }
    }),
    /***
     * @property {Boolean}
     * Property to check, whether SERP question is been played
     */
    isSerpQuestion: _ember['default'].computed('question.type', function () {
      this.set('uploadedFiles', _ember['default'].A());
      return !!_quizzesAddonConfigQuizzesQuestion.SERP_QUESTION_TYPES[this.get('question.type')];
    }),

    contextResult: null,

    isComprehension: false,

    comprehensionIndex: 0,

    isTouchQuestionType: _ember['default'].computed('question.type', function () {
      var isTouchDevice = typeof window.ontouchstart !== 'undefined';
      var type = this.get('question.type');
      return _quizzesAddonConfigQuizzesQuestion.TOUCH_QUESTION_TYPES.indexOf(type) !== -1 && isTouchDevice;
    }),

    showEvidenceKeyCheck: _ember['default'].computed('classData', function () {
      var classData = this.get('classData');
      return classData ? 'show.evidence' in classData.setting : false;
    }),

    showEvidence: _ember['default'].computed('classData', function () {
      var classData = this.get('classData');
      return classData ? classData.setting['show.evidence'] : false;
    }),

    isH5PContent: _ember['default'].computed('question', function () {
      return this.get('question.type') === 'h5p_drag_and_drop_question';
    }),

    /**
     * @property {String}
     */
    accessToken: _ember['default'].computed.alias('session.token-api3'),

    contentURL: _ember['default'].computed('isH5PContent', function () {
      if (this.get('isH5PContent')) {
        var accessToken = this.get('accessToken');
        var resourceId = this.get('question.id');
        var resourceType = this.get('question.type');
        var format = 'question';
        var contentURL = window.location.protocol + '//' + window.location.host + '/tools/h5p/play/' + resourceId + '?accessToken=' + accessToken + '&contentType=' + resourceType + '&format=' + format;
        return contentURL;
      }
    }),

    // -------------------------------------------------------------------------
    // Observers

    /**
     * Observes for the question itself
     * When it is changed some data should be reloaded
     */
    reloadQuestion: (function () {
      this.setProperties({
        actualHint: 0,
        answerCompleted: false,
        hintsToDisplay: _ember['default'].A(),
        isExplanationShown: false
      });
    }).observes('question'),

    // -------------------------------------------------------------------------
    // Methods

    getClassInfo: function getClassInfo() {
      var component = this;
      if (component.get('class')) {
        component.set('classData', component.get('class'));
      } else {
        if (component.get('classId') !== null && component.get('classId') !== undefined) {
          component.get('classService').readClassInfo(component.get('classId')).then(function (classData) {
            if (!component.isDestroyed) {
              component.set('classData', classData);
            }
          });
        }
      }
    },

    /**
     * @function fetchContentInfo
     * Method to fetch confirmation info data
     */
    fetchContentInfo: function fetchContentInfo() {
      var component = this;

      var content = component.get('content');
      var resourceId = component.get('questionResult.resourceId');

      if (content !== null && content !== undefined && resourceId) {
        var tempCont = content.findBy('id', resourceId);

        var playerMetadata = tempCont.player_metadata;
        var contentSubformat = tempCont.content_subformat;
        var questionEvidenceVisibility = component.get('questionEvidenceVisibility');

        if (questionEvidenceVisibility) {
          var questionEvidenceVisibilityKeyCheck = (contentSubformat in questionEvidenceVisibility);
          var defaultKeyCheck = ('default' in questionEvidenceVisibility);
          var contentSubformatCheck = questionEvidenceVisibility[contentSubformat];
          var defaultCheck = questionEvidenceVisibility['default'];

          component.set('questionEvidenceVisibilityKeyCheck', questionEvidenceVisibilityKeyCheck);

          component.set('contentSubformatCheck', contentSubformatCheck);
          component.set('defaultKeyCheck', defaultKeyCheck);
          component.set('defaultCheck', defaultCheck);
        }

        if (playerMetadata) {
          var tmpIsEvidenceEnabled = ('isEvidenceEnabled' in playerMetadata);
          component.set('playerMetadata', playerMetadata);
          component.set('tmpIsEvidenceEnabled', tmpIsEvidenceEnabled);
        }
      }
    },

    submitQuestion: function submitQuestion() {
      if (!this.get('submitted')) {
        var questionResult = this.get('questionResult');
        this.sendAction('onSubmitQuestion', this.get('question'), questionResult);
      }
    },

    enableNextButton: function enableNextButton() {
      var answer = this.get('questionResult.answer');
      var evidence = this.get('questionResult.evidence');
      var isH5PContent = this.get('isH5PContent');
      var isAnswerExist = isH5PContent || answer != null || this.get('freeResponse') && evidence && evidence.length;
      this.sendAction('isNextEnabled', isAnswerExist);
    },

    adjustFontSize: function adjustFontSize() {
      var component = this;
      component.$('.question-panel').on('input', '.font-size-loader .font-changer', function () {
        var el = component.$(this).closest('.question-panel');
        var el1 = component.$('.answers-panel');
        var fontChanger = el.find('.decoding-answers .decoding-answer .decoding-text');

        if (el.hasClass('serp_lang_activities_for_comprehension')) {
          var panelBody = el.find('.panel-default');
          panelBody.find('.panel-body .question .gru-math-text').css('zoom', '' + parseFloat($(this).val() / 10));

          var panelBody1 = el1.find('.comprehension-question-panel');
          panelBody1.find('.question .gru-math-text').css('zoom', '' + parseFloat($(this).val() / 10));
        } else {
          fontChanger.css('zoom', '' + parseFloat($(this).val() / 10));
        }
      });
    },
    fontChanger: function fontChanger() {
      var component = this;
      component.$('.panel-body h2').on('input', '.font-size-loader .font-changer', function () {
        var el = component.$('.panel-body');
        var fontChanger = el.find('.decoding-answers .decoding-answer .decoding-text');

        if (el.hasClass('serp_lang_activities_for_comprehension')) {
          var panelBody = el.find('.panel-body');
          panelBody.find('.question .gru-math-text').css('zoom', '' + parseFloat($(this).val() / 10));
        } else {
          fontChanger.css('zoom', '' + parseFloat($(this).val() / 10));
        }
      });
    }
  });
});