define('quizzes-addon/components/cards/resource-card', ['exports', 'ember', 'quizzes-addon/models/taxonomy/taxonomy-tag', 'quizzes-addon/models/taxonomy/taxonomy-tag-data'], function (exports, _ember, _quizzesAddonModelsTaxonomyTaxonomyTag, _quizzesAddonModelsTaxonomyTaxonomyTagData) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['resource-card'],

    // -------------------------------------------------------------------------
    // Events

    didRender: function didRender() {
      var component = this;
      component.$('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * resource object
     * @type {Object}
     */
    resource: null,

    /**
     * @property {TaxonomyTag[]} List of taxonomy tags
     */
    tags: _ember['default'].computed('resource.standards.[]', function () {
      var standards = this.get('resource.standards');
      if (standards) {
        standards = standards.filter(function (standard) {
          // Filter out learning targets (they're too long for the card)
          return !_quizzesAddonModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));
        });
        return _quizzesAddonModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);
      }
    }),

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Action triggered when the user play a resource
       */
      onPlayResource: function onPlayResource(resource) {
        this.sendAction('onPlayResource', resource);
      }
    }
  });
});