define('quizzes-addon/components/player/questions/qz-likert-scale', ['exports', 'ember', 'gooru-web/config/config', 'quizzes-addon/components/player/questions/qz-question'], function (exports, _ember, _gooruWebConfigConfig, _quizzesAddonComponentsPlayerQuestionsQzQuestion) {
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    classNames: ['qz-likert-scale'],

    answer: _ember['default'].computed('question', function () {
      var answers = this.get('question.baseAnswers');
      return answers && answers.length && answers[0] || {};
    }),

    likertItems: _ember['default'].computed('answer', function () {
      var answer = this.get('answer');
      return answer.items.map(function (item, index) {
        return {
          label: item,
          name: item,
          labelSeq: index + 1
        };
      });
    }),

    oneItemAtTime: _ember['default'].computed('answer', function () {
      var uiDisplay = this.get('answer.ui_display_guide') || {};
      var oneItemAtTime = uiDisplay.ui_presentation === 'one_item';
      return oneItemAtTime;
    }),

    likertPoints: _ember['default'].computed('answer', function () {
      var answer = this.get('answer');
      return answer.scale_point_labels.map(function (item) {
        return _ember['default'].Object.create({
          levelName: item.level_name,
          levelPoint: item.level_point
        });
      }).sortBy('levelPoint');
    }),

    activeComponent: _ember['default'].computed('answer', function () {
      var answer = this.get('answer');
      var activeType = _gooruWebConfigConfig.LIKERT_UI_TEMPLATES.find(function (item) {
        return item.ratingType === answer.ui_display_guide.rating_type;
      });
      return 'content/likert-scale/' + activeType.component;
    }),

    userAnswer: _ember['default'].computed('question', function () {
      return this.get('question.answer');
    }),

    actions: {
      onChangeOption: function onChangeOption(answers) {
        if (answers && answers.length) {
          var answerVal = JSON.parse(answers[0].value);
          var scaleSelectedItems = answerVal ? answerVal.liket_scale_selected_items : null;
          if (scaleSelectedItems) {
            var notAnswered = scaleSelectedItems.find(function (item) {
              return !item.scale_point;
            });
            if (!notAnswered) {
              this.notifyAnswerCompleted(answers);
            }
          }
        }
      }
    }
  });
});