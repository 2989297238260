define('quizzes-addon/services/quizzes/api-sdk/attempt', ['exports', 'ember', 'quizzes-addon/serializers/attempt/attempt', 'quizzes-addon/adapters/attempt/attempt'], function (exports, _ember, _quizzesAddonSerializersAttemptAttempt, _quizzesAddonAdaptersAttemptAttempt) {
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('attemptAdapter', _quizzesAddonAdaptersAttemptAttempt['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('attemptSerializer', _quizzesAddonSerializersAttemptAttempt['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {EventsAdapter} adapter
     */
    contextAdapter: null,

    /**
     * @property {EventsSerializer} serializer
     */
    contextSerializer: null,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Get data to show on the real time report
     * @param {String} contextId
     * @returns {Promise}
     */
    getReportData: function getReportData(contextId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('attemptAdapter').getReportData(contextId).then(function (response) {
          return service.get('attemptSerializer').normalizeReportData(response);
        }).then(resolve, reject);
      });
    },

    /**
     * Get attempt data for report
     * @param {String} attemptId
     * @returns {Promise}
     */
    getAttemptData: function getAttemptData(attemptId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('attemptAdapter').getAttemptData(attemptId).then(function (response) {
          return service.get('attemptSerializer').normalizeReportDataEvent(response);
        }).then(resolve, reject);
      });
    },

    /**
     * Get all attempt ids by context and profile id
     * @param {String} contextId
     * @param {String} profileId
     * @returns {Promise}
     */
    getAttemptIds: function getAttemptIds(contextIds, profileIds) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('attemptAdapter').getAttemptIds(contextIds, profileIds).then(function (response) {
          return service.get('attemptSerializer').normalizeAttemptIds(response);
        }).then(resolve, reject);
      });
    }
  });
});