define('quizzes-addon/helpers/safe-html', ['exports', 'ember'], function (exports, _ember) {
  exports.safeHtml = safeHtml;

  /**
   * Convert text to be html safe
   */

  function safeHtml(value) {
    return _ember['default'].String.htmlSafe(value);
  }

  exports['default'] = _ember['default'].Helper.helper(safeHtml);
});