define('quizzes-addon/components/reports/assessment/questions/qz-likert-scale', ['exports', 'ember', 'gooru-web/config/config'], function (exports, _ember, _gooruWebConfigConfig) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['qz-likert-scale'],

    answer: _ember['default'].computed('answers', function () {
      var answers = this.get('answers');
      return answers && answers.length ? answers[0] : null;
    }),

    userAnswer: _ember['default'].computed('question', function () {
      return this.get('question.answer');
    }),

    answers: _ember['default'].computed('question', function () {
      return this.get('question.resource.answerDetails');
    }),

    likertItems: _ember['default'].computed('answer', function () {
      var answer = this.get('answer');
      return answer.items.map(function (item) {
        return {
          label: item,
          name: item
        };
      });
    }),

    likertPoints: _ember['default'].computed('answer', function () {
      var answer = this.get('answer');
      return answer.scale_point_labels.map(function (item) {
        return _ember['default'].Object.create({
          levelName: item.level_name,
          levelPoint: item.level_point
        });
      }).sortBy('levelPoint');
    }),

    activeComponent: _ember['default'].computed('answer', function () {
      var answer = this.get('answer');
      var activeType = _gooruWebConfigConfig.LIKERT_UI_TEMPLATES.find(function (item) {
        return item.ratingType === answer.ui_display_guide.rating_type;
      });
      return 'content/likert-scale/' + activeType.component;
    })
  });
});