define('quizzes-addon/components/player/qz-assessment-confirmator', ['exports', 'ember', 'quizzes-addon/mixins/modal'], function (exports, _ember, _quizzesAddonMixinsModal) {
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsModal['default'], {
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-assessment-confirmator'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {number} Ammount of attempts left on the assessment
     */
    attempts: null,

    /**
     * @property {bool} Boolean used to determine whether you can navigate both ways on an assessment or only forward
     */
    bidirectional: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var component = this;
      var model = {
        bidirectional: component.get('bidirectional'),
        attempts: component.get('attempts'),
        title: component.get('title'),
        onStart: function onStart() {
          return component.sendAction('onStart');
        },
        onCancel: function onCancel() {
          return component.sendAction('onCancel');
        }
      };

      this.actions.showModal.call(this, 'content.modals.gru-assessment-confirmation', model);
    }
  });
});