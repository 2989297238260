define('quizzes-addon/components/player/questions/qz-question', ['exports', 'ember', 'quizzes-addon/config/quizzes-question'], function (exports, _ember, _quizzesAddonConfigQuizzesQuestion) {

  /**
   * Quizzes question base component
   *
   * This component contains convenience methods, properties and functionality to share across
   * all question type components
   *
   * @module
   * @see components/player/questions/*.js
   * @augments Ember/Component
   * @typedef {Object} QuestionComponent
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Indicate if the question has a user answer
     * @property {Boolean}
     */
    hasUserAnswer: _ember['default'].computed('userAnswer', function () {
      return this.get('userAnswer') && this.get('userAnswer.length');
    }),

    /**
     * Text for action in instructions
     * @property {string}
     */
    instructionsActionTextKey: 'common.save',

    /**
     * @property {String|Function} onAnswerChanged - event handler for when the question answer is changed
     */
    onAnswerChanged: null,

    /**
     * @property {String|Function} onAnswerCleared - event handler for when the question answer is cleared
     */
    onAnswerCleared: null,

    /**
     * @property {String|Function} onAnswerCompleted - event handler for when the question answer is completed
     */
    onAnswerCompleted: null,

    /**
     * @property {String|Function} onAnswerLoaded - event handler for when the question answer is loaded from BE
     */
    onAnswerLoaded: null,

    /**
     * Question information
     * @property {Resource} question
     */
    question: null,

    /**
     * Question Util based on the question type
     * @property {QuestionUtil}
     */
    questionUtil: _ember['default'].computed('question', function () {
      var question = this.get('question');
      var type = question.get('type');
      return (0, _quizzesAddonConfigQuizzesQuestion.getQuestionUtil)(type).create({ question: question });
    }),

    /**
     * Indicates if the question is readOnly
     * @property {boolean} readOnly
     */
    readOnly: null,

    /**
     * @property {string} user answer
     * @see quizzes/utils/question/multiplce-choice.js
     */
    userAnswer: null,

    // -------------------------------------------------------------------------
    // Observers
    // -------------------------------------------------------------------------
    // Observers

    /**
     * Refresh items when the question changes
     */

    refreshAnswers: _ember['default'].observer('question.id', function () {
      if (typeof this.setAnswers === 'function') {
        this.setAnswers();
      }
    }),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Notifies answer completion
     * @param {*} answer question answer
     * @param {boolean} correct
     */
    notifyAnswerChanged: function notifyAnswerChanged(answer) {
      var question = this.get('question');
      this.sendAction('onAnswerChanged', question, answer);
    },

    /**
     * Notifies answer completion
     * @param {*} answer question answer
     */
    notifyAnswerCleared: function notifyAnswerCleared(answer) {
      var question = this.get('question');
      this.sendAction('onAnswerCleared', question, answer);
    },

    /**
     * Notifies answer completion
     * @param {*} answer question answer
     * @param {boolean} correct
     */
    notifyAnswerCompleted: function notifyAnswerCompleted(answer) {
      var question = this.get('question');
      this.sendAction('onAnswerCompleted', question, answer);
    },

    /**
     * Notifies answer was loaded from BE
     */
    notifyAnswerLoaded: function notifyAnswerLoaded(answer) {
      var question = this.get('question');
      this.sendAction('onAnswerLoaded', question, answer);
    }
  });
});