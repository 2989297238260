define('quizzes-addon/routes/resource-player', ['exports', 'ember', 'quizzes-addon/models/context/event-context'], function (exports, _ember, _quizzesAddonModelsContextEventContext) {

  /**
   * @typedef { Ember.Route } ResourcePlayerRoute
   *
   * @module
   * @augments ember/Route
   */
  exports['default'] = _ember['default'].Route.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @type {resourceService} resourceService
     * @property {Ember.Service} Service to retrieve a collection
     */
    quizzesResourceService: _ember['default'].inject.service('quizzes/resource'),

    /**
     * @type {CollectionService} profileService
     * @property {Ember.Service} Service to retrieve a profile
     */
    profileService: _ember['default'].inject.service('quizzes/profile'),

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Action triggered when the user clicks on next
       */
      onNext: function onNext() {
        // Empty, it does nothing by default
      }
    },

    // -------------------------------------------------------------------------
    // Methods

    /**
     * @param {{ contextId: string }} params
     */
    model: function model(params) {
      return this.quizzesModel(params);
    },

    /**
     * @param {{ contextId: string }} params
     */
    quizzesModel: function quizzesModel(params) {
      var _this = this;

      var resourceId = params.resourceId;
      var pathId = params.pathId;
      var source = params.source;
      var sourceUrl = params.sourceUrl;
      var partnerId = params.partnerId;
      var tenantId = params.tenantId;
      var classId = params.classId;
      var courseId = params.courseId;
      var unitId = params.unitId;
      var lessonId = params.lessonId;
      var collectionId = params.collectionId;
      var pathType = params.pathType;
      var ctxPathId = params.ctxPathId;
      var ctxPathType = params.ctxPathType;

      return this.get('quizzesResourceService').readResource(resourceId).then(function (resource) {
        return _ember['default'].RSVP.hash({
          resource: resource,
          owner: _this.getOwnerProfile(resource),
          pathId: pathId,
          source: source,
          sourceUrl: sourceUrl,
          partnerId: partnerId,
          tenantId: tenantId,
          classId: classId,
          courseId: courseId,
          unitId: unitId,
          lessonId: lessonId,
          collectionId: collectionId,
          pathType: pathType,
          ctxPathId: ctxPathId,
          ctxPathType: ctxPathType
        });
      });
    },

    /**
     * Find owner profile if the resource has narration or is a link out resource
     */
    getOwnerProfile: function getOwnerProfile(resource) {
      var component = this;
      var promise = _ember['default'].RSVP.resolve();
      var ownerId = resource.ownerId;
      if (resource.get('narration') || resource.get('displayGuide')) {
        var profiles = [ownerId];
        promise = component.get('profileService').readProfiles(profiles).then(function (result) {
          return resource.set('owner', result[ownerId]);
        });
      }
      return promise;
    },

    setupController: function setupController(controller, model) {
      model.resource.set('owner', model.owner);
      controller.setProperties({
        resource: model.resource,
        classId: model.classId,
        courseId: model.courseId,
        unitId: model.unitId,
        lessonId: model.lessonId,
        collectionId: model.collectionId,
        eventContext: _quizzesAddonModelsContextEventContext['default'].create({
          classId: model.classId,
          courseId: model.courseId,
          unitId: model.unitId,
          lessonId: model.lessonId,
          collectionId: model.collectionId,
          pathId: model.pathId,
          partnerId: model.partnerId,
          source: model.source,
          sourceUrl: model.sourceUrl,
          tenantId: model.tenantId,
          pathType: model.pathType,
          ctxPathType: model.ctxPathType,
          ctxPathId: model.ctxPathId
        })
      });
    }
  });
});