define('quizzes-addon/components/gru-category-panel-textfield', ['exports', 'quizzes-addon/components/gru-category-panel'], function (exports, _quizzesAddonComponentsGruCategoryPanel) {
  exports['default'] = _quizzesAddonComponentsGruCategoryPanel['default'].extend({
    classNames: ['gru-category-panel-textfield'],

    /**
     * Panel placeholder text for input
     * @type {String}
     */
    'text-placeholder': ''
  });
});