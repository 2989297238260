define('quizzes-addon/components/editor/assessment/qz-scoring-levels', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['editor', 'assessment', 'qz-scoring-levels'],
    //// -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Delete Level
       */
      deleteLevel: function deleteLevel(level) {
        this.get('scoringLevels').removeObject(level);
      }
    },
    //// -------------------------------------------------------------------------
    // Properties

    /**
     * @property {Level[]} ScoringLevels
     */
    scoringLevels: null
  });
});