define('quizzes-addon/components/player/qz-feedback-form', ['exports', 'ember', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig, _quizzesAddonUtilsUtils) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Service

    /**
     * @requires service:session
     */
    session: _ember['default'].inject.service('session'),

    /**
     * @property {activityFeedbackService}
     */
    activityFeedbackService: _ember['default'].inject.service('quizzes/feedback'),

    /**
     * Observe the resource change
     */
    feedbackContentObserver: _ember['default'].observer('feedbackContent', function () {
      var component = this;
      component.fetchLearningActivityFeedback();
    }),

    // -------------------------------------------------------------------------
    // Events
    didInsertElement: function didInsertElement() {
      var component = this;
      component.fetchLearningActivityFeedback();
    },

    // -------------------------------------------------------------------------
    // Properties

    contentId: _ember['default'].computed.alias('feedbackContent.id'),

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      onToggleCategoryDetails: function onToggleCategoryDetails(category, index) {
        $('.feedback-container .feedback-body-panel .category-panel .category-info.category-' + index).slideToggle();
        category.set('isExpanded', !category.get('isExpanded'));
      },

      selectCategoryLevel: function selectCategoryLevel(category, ratingIndex) {
        var score = (ratingIndex + 1) * Math.floor(100 / category.maxScale.length);
        category.set('rating', ratingIndex + 1);
        category.set('scoreInPrecentage', score);
      },
      /**
       * Used for saving the user feedback from feedback modal
       */
      saveUserFeedback: function saveUserFeedback() {
        var feedbackData = this.getFeedbackObject();
        var component = this;
        component.get('activityFeedbackService').submitUserFeedback(feedbackData).then(function () {
          component.closeFeedbackModal();
        });
      },
      /*
       * Used for closing the feedback modal on clicking on Canel button
       */
      feedbackCancel: function feedbackCancel() {
        this.closeFeedbackModal();
      }
    },

    // -------------------------------------------------------------------------
    // Methods

    /**
     * @function fetchLearningActivityFeedback
     * Method to fetch learning activity feedback
     */

    fetchLearningActivityFeedback: function fetchLearningActivityFeedback() {
      var component = this;
      var userId = component.get('session.userId');
      var contentId = component.get('contentId');
      var listOfCategory = (0, _quizzesAddonUtilsUtils.getObjectsDeepCopy)(component.get('feedbackCategoryLists'));
      _ember['default'].RSVP.hash({
        categoryLists: listOfCategory,
        activityFeedback: component.get('activityFeedbackService').fetchActivityFeedback(contentId, userId)
      }).then(function (hash) {
        if (hash.activityFeedback.length) {
          hash.activityFeedback.map(function (feedback) {
            var category = hash.categoryLists.findBy('categoryId', feedback.categoryId);
            if (category) {
              if (category.feedbackTypeId === _quizzesAddonConfigQuizzesConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE) {
                category.set('rating', feedback.rating);
              } else if (category.feedbackTypeId === _quizzesAddonConfigQuizzesConfig.FEEDBACK_RATING_TYPE.QUALITATIVE) {
                category.set('quality', feedback.qualitative);
              } else {
                category.set('comments', feedback.qualitative);
              }
            }
          });
        }

        var categoryLists = _ember['default'].A([]);

        hash.categoryLists.map(function (category) {
          var score = category.rating * Math.floor(100 / category.maxScale);
          category.set('scoreInPrecentage', score);
          category.set('isExpanded', true);
          if (component.get('isShowReaction')) {
            if (category.get('feedbackTypeId') !== _quizzesAddonConfigQuizzesConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE) {
              categoryLists.push(category);
            }
          } else {
            categoryLists.push(category);
          }
        });
        component.set('categoryLists', categoryLists);
      });
    },
    getFeedbackObject: function getFeedbackObject() {
      var component = this;
      var userId = component.get('session.userId');
      var role = component.get('session.role') ? component.get('session.role') : _quizzesAddonConfigQuizzesConfig.ROLES.STUDENT;
      var userCategoryId = _quizzesAddonConfigQuizzesConfig.FEEDBACK_USER_CATEGORY['' + role];
      var userFeedback = _ember['default'].A([]);
      var categoryLists = component.get('categoryLists');
      var resource = component.get('feedbackContent');
      categoryLists.map(function (category) {
        var feedbackObj = {
          feeback_category_id: category.categoryId
        };
        if (category.feedbackTypeId === _quizzesAddonConfigQuizzesConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE) {
          feedbackObj.user_feedback_quantitative = category.rating;
        } else if (category.feedbackTypeId === _quizzesAddonConfigQuizzesConfig.FEEDBACK_RATING_TYPE.BOTH) {
          feedbackObj.user_feedback_qualitative = category.comments;
        } else if (category.feedbackTypeId === _quizzesAddonConfigQuizzesConfig.FEEDBACK_RATING_TYPE.QUALITATIVE) {
          feedbackObj.user_feedback_qualitative = category.quality;
        }
        userFeedback.pushObject(feedbackObj);
      });
      var userFeedbackObj = {
        content_id: resource.get('id'),
        content_type: resource.get('isResource') ? _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.RESOURCE : _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.QUESTION,
        user_category_id: userCategoryId,
        user_feedbacks: userFeedback,
        user_id: userId
      };
      return userFeedbackObj;
    },
    closeFeedbackModal: function closeFeedbackModal() {
      var component = this;
      var fn = component.get('feedbackCancel');
      fn && fn();
    }
  });
});