define('quizzes-addon/models/license', ['exports', 'ember'], function (exports, _ember) {

  /**
   * License model
   *
   * @typedef {Object} License
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {string}
     */
    id: null,

    /**
     * @property {string}
     */
    name: null,

    /**
     * @property {string}
     */
    description: null,

    /**
     * @property {string}
     */
    code: null
  });
});