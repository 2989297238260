define('quizzes-addon/components/reports/assessment/questions/qz-serp-phrase-reading', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion, _quizzesAddonUtilsUtils) {

  /**
   * SERP Encoding Assessment
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-serp-phrase-reading'],

    // -------------------------------------------------------------------------
    // Properties

    showCorrect: false,

    answers: _ember['default'].computed.alias('question.answer'),

    baseAnswers: _ember['default'].computed.alias('question.resource.baseAnswers'),

    exemplars: _ember['default'].computed('question.resource.data', function () {
      return this.get('question.resource.data') ? this.get('question.resource.data.exemplarDocs') : _ember['default'].A([]);
    }),

    audioList: _ember['default'].Object.create({}),

    activeIndex: null,

    isPlaying: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var component = this;
      this.get('answers').forEach(function (answer, i) {
        var _audio = new Audio(answer.value);
        _audio.onloadedmetadata = function () {
          if (this.duration === Infinity) {
            this.duration = 100000 * Math.random();
            var timerEl = component.$('.wpm-timer-section .timer-count');
            timerEl.find('.duration').html((0, _quizzesAddonUtilsUtils.sec2time)(this.duration || 0));
          }
        };
        _this.get('audioList').set('player-' + i, _audio);
      });
    },

    actions: {
      onPlayAudio: function onPlayAudio(container) {
        var component = this;
        var _audio = this.get('audioList').get(container);
        this.set('activeIndex', container);
        this.set('isPlaying', true);
        _audio.play();
        _audio.onended = function () {
          component.set('isPlaying', false);
        };
        _audio.ontimeupdate = function () {
          component.$('.answer-container .' + container + ' .audio-progress .progress-filling').css('width', _audio.currentTime / _audio.duration * 100 + '%');

          var timerEl = component.$('.' + container + ' .wpm-timer-section .timer-count');
          timerEl.find('.current-count').html((0, _quizzesAddonUtilsUtils.sec2time)(_audio.currentTime || 0));
          timerEl.find('.duration').html((0, _quizzesAddonUtilsUtils.sec2time)(_audio.duration || 0));
        };
      },
      onPauseAudio: function onPauseAudio(container) {
        this.set('isPlaying', false);
        var activeAudio = this.get('audioList.' + container);
        activeAudio.pause();
      }
    }
  });
});