define('quizzes-addon/serializers/lookup/lookup', ['exports', 'ember', 'quizzes-addon/models/country', 'quizzes-addon/models/state', 'quizzes-addon/models/district', 'quizzes-addon/models/audience', 'quizzes-addon/models/depth-of-knowledge', 'quizzes-addon/models/license'], function (exports, _ember, _quizzesAddonModelsCountry, _quizzesAddonModelsState, _quizzesAddonModelsDistrict, _quizzesAddonModelsAudience, _quizzesAddonModelsDepthOfKnowledge, _quizzesAddonModelsLicense) {

  /**
   * Serializer to support the Profile CRUD operations for API 3.0
   *
   * @typedef {Object} ProfileSerializer
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * Normalizes audiences
     * @param {} payload
     * @returns {Audience[]}
     */
    normalizeReadAudiences: function normalizeReadAudiences(payload) {
      var items = payload.audience || [];
      return items.map(function (audience) {
        return _quizzesAddonModelsAudience['default'].create({
          id: audience.id,
          name: audience.label,
          order: audience.sequence_id
        });
      });
    },

    /**
     * Normalizes depth of knowledge items
     * @param {} payload
     * @returns {DepthOfKnowledge[]}
     */
    normalizeReadDepthOfKnowledgeItems: function normalizeReadDepthOfKnowledgeItems(payload) {
      var items = payload.depth_of_knowledge || [];
      return items.map(function (dok) {
        return _quizzesAddonModelsDepthOfKnowledge['default'].create({
          id: dok.id,
          name: dok.label,
          order: dok.sequence_id
        });
      });
    },

    /**
     * Normalizes licenses
     * @param {} payload
     * @returns {License[]}
     */
    normalizeReadLicenses: function normalizeReadLicenses(payload) {
      var items = payload.license || [];
      return items.map(function (license) {
        return _quizzesAddonModelsLicense['default'].create({
          id: license.id,
          name: license.label,
          order: license.sequence_id
        });
      });
    },

    /**
     * Normalizes countries
     * @param {} payload
     * @returns {Country[]}
     */
    normalizeReadCountries: function normalizeReadCountries(payload) {
      var countries = payload.countries || [];
      return countries.map(function (country) {
        return _quizzesAddonModelsCountry['default'].create({
          id: country.id,
          name: country.name,
          code: country.code
        });
      });
    },

    /**
     * Normalizes states
     * @param {} payload
     * @returns {State[]}
     */
    normalizeReadStates: function normalizeReadStates(payload) {
      var states = payload.states || [];
      return states.map(function (state) {
        return _quizzesAddonModelsState['default'].create({
          id: state.id,
          name: state.name,
          code: state.code
        });
      });
    },

    /**
     * Normalizes district
     * @param {} payload
     * @returns {District[]}
     */
    normalizeReadDistricts: function normalizeReadDistricts(payload) {
      var districts = payload['school-districts'] || [];
      return districts.map(function (district) {
        return _quizzesAddonModelsDistrict['default'].create({
          id: district.id,
          name: district.name,
          code: district.code
        });
      });
    }
  });
});