define('quizzes-addon/components/reports/assessment/qz-resources', ['exports', 'ember', 'quizzes-addon/models/taxonomy/taxonomy-tag', 'quizzes-addon/models/taxonomy/taxonomy-tag-data'], function (exports, _ember, _quizzesAddonModelsTaxonomyTaxonomyTag, _quizzesAddonModelsTaxonomyTaxonomyTagData) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'qz-resources'],

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Indicates if the reaction bar is visible
     * @property {boolean}
     */
    showReactionBar: true,

    /**
     * @property {TaxonomyTag[]} List of taxonomy tags
     */
    taxonomyTags: _ember['default'].computed('content.resource.standards.[]', function () {
      var standards = this.get('content.resource.standards');
      if (standards) {
        standards = standards.filter(function (standard) {
          // Filter out learning targets (they're too long for the card)
          return !_quizzesAddonModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));
        });
      }
      return _quizzesAddonModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);
    })
  });
});