define('quizzes-addon/components/player/questions/qz-reorder', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion) {

  /**
   * Reorder Question
   *
   * Component responsible for controlling the logic and appearance of the answers for
   * a reorder question inside of the {@link player/qz-question-viewer.js}
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments player/questions/qz-question.js
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-reorder'],

    // -------------------------------------------------------------------------
    // Events

    initSortableList: _ember['default'].on('didInsertElement', function () {
      var component = this;
      component.setAnswers();
      if (!component.get('hasUserAnswer')) {
        component.shuffle();
      }
      this.set('areAnswersShuffled', true);
    }),

    removeSubscriptions: _ember['default'].on('willDestroyElement', function () {
      this.$('.sortable').off('sortupdate');
    }),

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Convenient structure to render the question answer choices
     * @property {*}
     */
    answers: _ember['default'].computed('question.answers.@each.value', function () {
      var answers = this.get('question.answers');
      if (this.get('hasUserAnswer')) {
        var userAnswer = this.get('userAnswer');
        answers = userAnswer.map(function (answer) {
          return answers.findBy('value', answer.value);
        });
      }
      return answers;
    }),

    /**
     * Return true if the answers list are shuffled
     * @property {Boolean}
     */
    areAnswersShuffled: false,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Disorder elements
     */
    disorder: function disorder(list) {
      var j,
          x,
          i = list.length;
      while (i) {
        j = parseInt(Math.random() * i);
        i -= 1;
        x = list[i];
        list[i] = list[j];
        list[j] = x;
      }
      return list;
    },

    /**
     * Notifies answer events
     * @param {boolean} onLoad if this was called when loading the component
     */
    notify: function notify(onLoad) {
      var component = this;
      var $items = component.$('.sortable').find('li');
      var answers = _ember['default'].A([]);

      $items.map(function (idx, item) {
        return answers.pushObject({ value: $(item).data('id') });
      });

      component.notifyAnswerChanged(answers);
      if (onLoad) {
        component.notifyAnswerLoaded(answers);
      } else {
        component.notifyAnswerCompleted(answers);
      }
    },

    /**
     * Set answers
     */
    setAnswers: function setAnswers() {
      var component = this;
      var sortable = this.$('.sortable');
      var readOnly = component.get('readOnly');

      sortable.sortable();
      if (readOnly) {
        sortable.sortable('disable');
      }

      if (component.get('hasUserAnswer')) {
        component.notify(true);
      }
      // Manually add subscriptions to sortable element -makes it easier to test
      sortable.on('sortupdate', function () {
        component.notify(false);
      });
    },

    /**
     * Take the list of items and shuffle all his members
     */
    shuffle: function shuffle() {
      var component = this;
      var $items = component.$('.sortable');
      $items.each(function () {
        var items = $items.children().clone(true);
        if (items.length) {
          while (!component.validateShuffle()) {
            $(this).html(component.disorder(items));
          }
        }
      });
    },
    /**
     * Validate shuffle doesn't be equal than the correct order
     */
    validateShuffle: function validateShuffle() {
      var component = this;
      var $items = component.$('.sortable li').toArray();
      var answers = component.get('answers');
      return $items.reduce(function (isValid, item, idx) {
        return isValid && answers[idx].get('value') !== $(item).data('id');
      }, true);
    }
  });
});