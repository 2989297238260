define('quizzes-addon/helpers/plus-one', ['exports', 'ember'], function (exports, _ember) {
  exports.plusOne = plusOne;

  /**
   * Add one to a number
   */

  function plusOne(value /*, options*/) {
    return parseInt(value) + 1;
  }

  exports['default'] = _ember['default'].Helper.helper(plusOne);
});