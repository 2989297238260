define('quizzes-addon/models/result/report-data', ['exports', 'ember', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/models/result/report-data-event', 'quizzes-addon/models/result/question'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig, _quizzesAddonModelsResultReportDataEvent, _quizzesAddonModelsResultQuestion) {

  /**
   * Report data model for context/assigment report
   *
   * @typedef {Object} ReportData
   *
   */
  exports['default'] = _ember['default'].Object.extend({
    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {Collection} collection
     */
    collection: null,

    /**
     * @property {string} collectionId
     */
    collectionId: null,

    /**
     * @property {string} contextId
     */
    contextId: null,

    /**
     * @property {ReportEvent[]} reportEvents
     */
    reportEvents: _ember['default'].A(),

    /**
     * @property {string[]} studentIds - List of student ids
     */
    resourceIds: _ember['default'].computed('collection.resources', function () {
      return this.get('collection.resources').map(function (resource) {
        return resource.get('id');
      });
    }),

    /**
     * @property {string[]} studentIds - List of student ids
     */
    students: _ember['default'].computed('reportEvents.@each.profileId', function () {
      return this.get('reportEvents').map(function (student) {
        return _ember['default'].Object.create({
          id: student.get('profileId'),
          code: student.get('profileCode'),
          fullName: student.get('profileName'),
          lastFirstName: student.get('lastFirstName')
        });
      });
    }),

    /**
     * @property {string[]} studentIds - List of student ids
     */
    studentIds: _ember['default'].computed('students.@each.id', function () {
      return this.get('students').map(function (student) {
        return student.get('id');
      });
    }),

    /**
     * @property {date} submittedAt Date of submission
     */
    submittedAt: 0,

    // -------------------------------------------------------------------------
    // Methods
    /**
     * Compares the answer values
     * @param {Answer} a
     * @param {Answer} b
     * @returns {Boolean}
     */
    compareAnswers: function compareAnswers(a, b) {
      var result = a && b && a.length === b.length;
      if (result) {
        a.forEach(function (answerA) {
          var filteredAnswers = b.find(function (answerB) {
            return answerB.value === answerA.value;
          });
          result &= !!filteredAnswers;
        });
      }
      return result;
    },

    /**
     * Find an event by a profile id
     * @returns {ReportDataEvent[]}
     */
    findByProfileId: function findByProfileId(profileId) {
      return this.get('reportEvents').filter(function (reportEvent) {
        return reportEvent.get('profileId') === profileId;
      });
    },

    /**
     * Find all results for the question id
     * @param {string} questionId
     * @returns {QuestionResult[]}
     */
    getResultsByQuestion: function getResultsByQuestion(questionId) {
      return this.get('reportEvents').map(function (reportEvent) {
        return reportEvent.get('resourceResults').find(function (result) {
          return result.get('resourceId') === questionId;
        });
      }).filter(function (result) {
        return result;
      });
    },

    /**
     * Find all results by answer
     * @param {string} questionId
     * @param {Answer} answer
     * @returns {QuestionResult[]}
     */
    getStudentsByQuestionAndAnswer: function getStudentsByQuestionAndAnswer(question, answer) {
      var _this = this;

      return this.get('reportEvents').filter(function (reportEvent) {
        var questionResult = reportEvent.get('resourceResults').find(function (result) {
          return result.get('resourceId') === question.get('id');
        });
        return questionResult ? _this.compareAnswers(questionResult.get('answer'), answer) : false;
      }).map(function (student) {
        return _ember['default'].Object.create({
          id: student.get('profileId'),
          code: student.get('profileCode'),
          fullName: student.get('profileName')
        });
      });
    },

    /**
     * Parse and add event data from web socket
     * @param {Object} eventData
     */
    parseEvent: function parseEvent(eventData) {
      if (eventData.eventName === _quizzesAddonConfigQuizzesConfig.CONTEXT_EVENT_TYPES.START) {
        this.parseStartEvent(eventData);
      } else if (eventData.eventName === _quizzesAddonConfigQuizzesConfig.CONTEXT_EVENT_TYPES.FINISH) {
        this.parseFinishEvent(eventData);
      } else if (eventData.eventName === _quizzesAddonConfigQuizzesConfig.CONTEXT_EVENT_TYPES.ON_RESOURCE) {
        this.parseOnResourceEvent(eventData);
      }
    },

    /**
     * Parse start event data from web socket
     * @param {Object} eventData
     */
    parseFinishEvent: function parseFinishEvent(eventData) {
      var oldReportEvents = this.findByProfileId(eventData.profileId);
      if (oldReportEvents.length) {
        oldReportEvents[0].setProfileSummary(eventData.eventBody.eventSummary, true);
        oldReportEvents[0].incrementProperty('updated');
      }
    },

    updatedProfileName: function updatedProfileName(profileId, profile) {
      var student = this.get('students').findBy('id', profileId);
      if (student) {
        student.set('lastFirstName', profile.get('lastFirstName'));
        student.set('fullName', profile.get('fullName'));
      }
      var oldReportEvents = this.findByProfileId(profileId);
      if (oldReportEvents.length) {
        oldReportEvents[0].set('lastFirstName', profile.get('lastFirstName'));
        oldReportEvents[0].set('profileName', profile.get('fullName'));
        oldReportEvents[0].set('profileCode', profile.get('profileCode'));
        oldReportEvents[0].incrementProperty('updated');
      }
    },

    /**
     * Parse on resource event data from web socket
     * @param {Object} eventData
     */
    parseOnResourceEvent: function parseOnResourceEvent(eventData) {
      var oldReportEvents = this.findByProfileId(eventData.profileId);
      if (oldReportEvents.length) {
        var profileEvent = oldReportEvents[0];
        var previousResource = eventData.eventBody.previousResource;
        profileEvent.set('isAttemptStarted', true);
        profileEvent.setProfileSummary(eventData.eventBody.eventSummary, false);
        profileEvent.set('currentResourceId', eventData.eventBody.currentResourceId);
        previousResource.skipped = previousResource.isSkipped;
        profileEvent.merge(previousResource.resourceId, previousResource);
        profileEvent.incrementProperty('updated');
      }
    },

    /**
     * Parse start event data from web socket
     * @param {Object} eventData
     */
    parseStartEvent: function parseStartEvent(eventData) {
      var _this2 = this;

      if (eventData.eventBody.isNewAttempt) {
        var oldReportEvents = this.findByProfileId(eventData.profileId);

        var properties = {
          currentResourceId: eventData.eventBody.currentResourceId,
          profileId: eventData.profileId,
          profileName: '',
          isAttemptStarted: true,
          resourceResults: this.get('collection.resources').map(function (res) {
            return _quizzesAddonModelsResultQuestion['default'].create(_ember['default'].getOwner(_this2).ownerInjection(), {
              resourceId: res.id,
              resource: res,
              savedTime: 0,
              reaction: 0,
              answer: null,
              score: 0,
              skipped: true
            });
          })
        };
        if (oldReportEvents.length) {
          var currentReportEvent = oldReportEvents[0];
          currentReportEvent.setProperties(properties);
          currentReportEvent.clearProfileSummary();
          currentReportEvent.incrementProperty('updated');
        } else {
          var newProfileEvent = _quizzesAddonModelsResultReportDataEvent['default'].create(_ember['default'].getOwner(this).ownerInjection(), properties);
          this.get('reportEvents').pushObject(newProfileEvent);
        }
      }
    },

    /**
     * Replace an event using a profile id
     * @param {String} profileId
     * @param {Object} profileEvent
     */
    replaceByProfileId: function replaceByProfileId(profileId, profileEvent) {
      this.set('reportEvents', this.get('reportEvents').map(function (reportEvent) {
        return reportEvent.get('profileId') === profileId ? profileEvent : reportEvent;
      }));
    },

    /**
     * Set the collection and update the events to have all resources
     * @param {Collection} collection
     */
    setCollection: function setCollection(collection) {
      this.set('collection', collection);
      var reportEvents = this.get('reportEvents');
      reportEvents.forEach(function (reportEvent) {
        reportEvent.setCollection(collection);
      });
    }
  });
});