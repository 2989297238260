define('quizzes-addon/components/assignment/qz-assessment-settings', ['exports', 'ember', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    /**
     * @property {Service} I18N service
     */
    i18n: _ember['default'].inject.service(),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-assessment-settings'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      onAnswerKeyChange: function onAnswerKeyChange(isChecked) {
        _ember['default'].Logger.log('onAnswerKeyChange', isChecked);
      },

      onAttemptsChange: function onAttemptsChange(newValue) {
        _ember['default'].Logger.log('onAttemptsChange', newValue);
      },
      onClassroomPlayEnabledChange: function onClassroomPlayEnabledChange() {
        _ember['default'].Logger.log('onClassroomPlayEnabledChange');
      },
      onBackwardsChange: function onBackwardsChange(isChecked) {
        // TODO
        _ember['default'].Logger.log('onBackwardsChange', isChecked);
      },
      onGenericChange: function onGenericChange() {
        _ember['default'].Logger.log('onGenericChange');
      }
    },

    // -------------------------------------------------------------------------
    // Properties
    /**
     * Options for attempts
     * @property {Array}
     */
    attemptsOptions: _ember['default'].computed(function () {
      var options = [{
        id: -1,
        name: this.get('i18n').t('gru-settings-edit.attempts-unlimited')
      }];
      for (var i = 1; i <= _quizzesAddonConfigQuizzesConfig.MAX_ATTEMPTS; i += 1) {
        options.push({
          id: i,
          name: i
        });
      }
      return options;
    }),

    /**
     * Options for feedback
     * @property {Map}
     */
    feedbackItems: _quizzesAddonConfigQuizzesConfig.ASSESSMENT_SHOW_VALUES,

    /**
     * Model to change settings to
     * @property {Object}
     */
    model: null,

    /**
     * Toggle Options
     * @property {Ember.Array}
     */
    switchOptions: _ember['default'].A([_ember['default'].Object.create({
      label: 'On',
      value: true
    }), _ember['default'].Object.create({
      label: 'Off',
      value: false
    })])
  });
});