define('quizzes-addon/components/reports/assessment/questions/qz-serp-classic', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * SERP Classic
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-serp-classic'],

    // -------------------------------------------------------------------------
    // Properties

    showCorrect: false,

    userAnswers: _ember['default'].computed('question.answer', function () {
      return this.get('question.answer').map(function (item) {
        return { correct_answer: item.value ? [item.value.slice(1, -1)] : [] };
      });
    }),

    baseAnswers: _ember['default'].computed.alias('question.resource.baseAnswers'),

    // ----------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this.defaultHightlight();
    },

    // ------------------------------------------------------------------
    // Methods

    /**
     * Help to highlight existing answer
     */

    defaultHightlight: function defaultHightlight() {
      var component = this;
      var answers = component.get('baseAnswers');
      var userAnswers = component.get('userAnswers');
      var looperObj = component.get('showCorrect') ? answers : userAnswers;
      looperObj.forEach(function (item, index) {
        var answerEl = component.$('.answer-edit-' + index + ' .answer-item-text');
        var answerText = item.correct_answer.length ? item.correct_answer[0] : item.text;
        var replacedText = answerText.replace(/(\[.?\])/gi, function (match) {
          return '<span class="' + (match.length > 2 ? 'active' : '') + '">' + (match.length > 2 ? match[1] : '_') + '</span>';
        });
        answerEl.html(component.wrapLetters(replacedText));
      });
    },

    /**
     * Help to wrap span tag for each letters
     */
    wrapLetters: function wrapLetters(text) {
      var letterGroups = '';
      var childEl = $('<p>' + text + '</p>')[0].childNodes;
      childEl.forEach(function (item) {
        if (item.nodeName === '#text') {
          for (var i = 0; i < item.data.length; i++) {
            letterGroups += '<span>' + item.data[i] + '</span>';
          }
          return;
        }
        letterGroups += item.outerHTML;
      });
      return letterGroups;
    }
  });
});