define('quizzes-addon/helpers/qz-question-config', ['exports', 'ember', 'quizzes-addon/config/quizzes-question'], function (exports, _ember, _quizzesAddonConfigQuizzesQuestion) {
  exports.questionConfig = questionConfig;

  /**
   * Get the question config
   */

  function questionConfig(value /*, options*/) {
    var questionType = value[0];
    var propertyPath = value.length > 1 ? value[1] : undefined;
    return (0, _quizzesAddonConfigQuizzesQuestion.getQuestionConfig)(questionType, propertyPath);
  }

  exports['default'] = _ember['default'].Helper.helper(questionConfig);
});