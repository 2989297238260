define('quizzes-addon/models/editor/assessment/rubric', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Rubric model
   * typedef {Object} Rubric
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {string} URL
     */
    url: '',
    /**
     * @property {string} mimeType
     */
    mimeType: 'application/pdf,image/*',
    /**
     * @property {Category[]} categories
     */
    categories: _ember['default'].A([])
  });
});