define('quizzes-addon/mixins/serializable', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Mixin to convert ember objects to JSON
   * Per: http://byronsalau.com/blog/convert-ember-objects-to-json/
   */
  exports['default'] = _ember['default'].Mixin.create({
    serialize: function serialize() {
      var result = {};
      var _this = $.extend(true, {}, this);

      for (var key in _this) {
        if (_this.hasOwnProperty(key)) {
          // Skip these
          if (key === 'isInstance' || key === 'isDestroyed' || key === 'isDestroying' || key === 'concatenatedProperties' || key === 'mergedProperties' || typeof this[key] === 'function') {
            continue;
          }
          result[key] = this[key];
        }
      }
      return result;
    }
  });
});