define('quizzes-addon/helpers/no-tags', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {
  exports.noTags = noTags;

  /**
   * Remove tags to html
   */

  function noTags(params, hash) {
    return (0, _quizzesAddonUtilsUtils.noTags)(hash.text);
  }

  exports['default'] = _ember['default'].Helper.helper(noTags);
});