define('quizzes-addon/components/gru-user-teaser', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['gru-user-teaser'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Properties

    /**
     * List of users to show
     * @property {Array}
     */
    users: null,

    /**
     * Get the first user from the users list
     * @property {Ember.Object}
     */
    firstUser: _ember['default'].computed('users', function () {
      return this.get('users.firstObject');
    }),

    /**
     * Get the first user from the users list
     * @property {Ember.Object}
     */
    usersLeft: _ember['default'].computed('users', function () {
      return this.get('users.length') - 1;
    })
  });
});