define('quizzes-addon/components/player/questions/qz-serp-baseword', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {
  /**
   * SERP baseword question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-base-word'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var question = this.get('question');
      var baseAnswers = question.get('baseAnswers') ? question.get('baseAnswers') : [];
      var decodingExcercies = baseAnswers.map(function (baseAnswer) {
        var normalizeAns = baseAnswer.correct_answer.map(function (item) {
          return _ember['default'].typeOf(item) === 'string' ? JSON.parse(item) : item;
        });
        return {
          answer_text: baseAnswer.answer_text,
          answer_type: baseAnswer.answer_type,
          base_words: normalizeAns
        };
      });
      // let baseAnswers = userAnswer.baseAnswers;
      this.injectSerpBaseWord(decodingExcercies);
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    // -------------------------------------------------------------------------
    // Properties

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    injectSerpBaseWord: function injectSerpBaseWord(baseAnswers) {
      var component = this,
          userAnswered = undefined;
      var user = {
        userId: 'userId'
      };

      var userAnswerList = component.get('userAnswer');
      if (userAnswerList && userAnswerList.length) {
        userAnswered = userAnswerList.map(function (item) {
          return JSON.parse(item.value);
        });
      }
      baseAnswers.forEach(function (baseAnswer) {
        baseAnswer.correct_answer = baseAnswer.base_words;
      });

      var content = {
        contentId: 'contentId',
        contentTitle: 'contentTitle',
        answers: baseAnswers,
        userAnswer: userAnswered
      };
      window.serp.languageDecode().select('#serp-baseword-answer-container').dataIn(user, null, content).baseWord().render().listener(function (eventData) {
        eventData.selectedBaseWord.map(function (item, index) {
          if (item && item.length === 0) {
            eventData.selectedBaseWord.removeObject(eventData.selectedBaseWord[index]);
          }
        });
        if (baseAnswers.length === eventData.selectedBaseWord.length) {
          var answer = eventData.selectedBaseWord.map(function (item) {
            return {
              value: JSON.stringify(item)
            };
          });

          component.notifyAnswerCompleted(answer);
        }
      });
    }
  });
});