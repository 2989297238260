define('quizzes-addon/components/reports/class-assessment/qz-summary', ['exports', 'ember', 'quizzes-addon/utils/utils', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/utils/math'], function (exports, _ember, _quizzesAddonUtilsUtils, _quizzesAddonConfigQuizzesConfig, _quizzesAddonUtilsMath) {

  /**
   * Class assessment summary
   *
   * Component responsible for aggregating the class assessment data
   * and presenting it in a summarized manner to the user
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'class-assessment', 'qz-summary'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * @function actions:toggleView
       * @param {boolean} isQuestionView - Should all the questions be visible or not?
       */
      toggleView: function toggleView(isQuestionView) {
        this.set('isQuestionView', isQuestionView);
      },

      /**
       * @function actions:selectQuestion
       * @param {Number} questionId
       */
      selectQuestion: function selectQuestion(questionId) {
        this.get('onSelectQuestion')(questionId);
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @prop { Collection } assessment
     */
    assessment: _ember['default'].computed.alias('reportData.collection'),

    /**
     * @prop { String[] } assessmentQuestionsIds - An array with the ids of all the questions in the assessment
     * ordered in ascending order per each question's order value.
     */
    assessmentQuestionsIds: _ember['default'].computed('assessment.resources.@each.id', 'assessment.resources.@each.sequence', function () {
      var questions = this.get('assessment.resourcesSorted').map(function (question) {
        // Copy only the most important properties of the resources array
        return {
          id: question.id,
          order: question.sequence
        };
      });

      return questions.sort(function (a, b) {
        return a.order - b.order;
      }).map(function (question) {
        return question.id;
      });
    }),

    /**
     * @prop { Object[] } assignmentScores - Aggregate data of the scores in the assessment
     * (to be consumed by the pie chart component)
     *
     * Each object will consist of:
     * - color: color corresponding to a grade bracket in the grading scale (@see /app/config/config.js)
     * - value: percentage of students in the class with a score within said grade bracket
     */
    assignmentScores: _ember['default'].computed('scoresData.@each.score', 'scoresData.@each.completed', function () {
      var scoresData = this.get('scoresData');
      var scoresColors = scoresData.map(function (result) {
        return (0, _quizzesAddonUtilsUtils.getGradeColor)(result.score);
      });
      var colors = _quizzesAddonConfigQuizzesConfig.GRADING_SCALE.map(function (item) {
        return item.COLOR;
      });

      var results = [];
      if (scoresColors.length) {
        (function () {
          var scoreColorsLen = scoresColors.length;
          colors.forEach(function (color) {
            // Count the number of appearances of a certain color
            var numColor = scoresColors.filter(function (scoreColor) {
              return scoreColor === color;
            }).length;
            if (numColor) {
              results.push({
                color: color,
                value: Math.round(numColor / scoreColorsLen * 100)
              });
            }
          });
        })();
      }
      return results;
    }),

    /**
     * @prop { number } averageScore - average score in the assessment
     * for the entire group of students (per scoresData)
     */
    averageScore: _ember['default'].computed('scoresData.@each.score', function () {
      var scores = this.get('scoresData').map(function (result) {
        return result.score ? result.score : 0;
      });
      return scores.length ? Math.round((0, _quizzesAddonUtilsMath.average)(scores)) : 0;
    }),

    /**
     * @prop { number } averageScore - average score in the assessment
     * for the entire group of students (per scoresData)
     */
    averageTime: _ember['default'].computed('scoresData.@each.score', function () {
      var scores = this.get('scoresData').map(function (result) {
        return result.score;
      });
      var averageTime = scores.length ? Math.round((0, _quizzesAddonUtilsMath.average)(scores)) : 0;
      averageTime = (0, _quizzesAddonUtilsUtils.formatTime)(averageTime);
      return averageTime;
    }),
    /**
     * @prop { boolean } isFullScreen - Should the overview be visible or not?
     */
    isFullScreen: false,

    /**
     * @prop { boolean } isQuestionView - Should all the questions be visible or not?
     */
    isQuestionView: false,

    /**
     * @prop { Object[] } questionsData - Array that keeps track of all the correct/incorrect answers
     * for each question in the assessment
     *
     * For each question, there will be a counter object with the following properties:
     * - id: question id
     * - correct: number of students that have answered the question correctly
     * - incorrect: number of students that did not answer the question correctly
     * - total: total number of students
     */
    questionsData: _ember['default'].computed('reportData.reportEvents.@each.updated', function () {
      var studentsIds = this.get('studentsIds');
      var totalStudents = studentsIds.length;
      var questionsIds = this.get('assessmentQuestionsIds');
      var reportEvents = this.get('reportData.reportEvents');

      var questions = [];

      questionsIds.forEach(function (questionId) {
        var questionCounter = {
          id: questionId,
          correct: 0,
          incorrect: 0,
          openEnded: 0,
          total: totalStudents,
          title: ''
        };
        questions.push(questionCounter);

        reportEvents.forEach(function (student) {
          var resourceResults = student.get('resourceResults').filter(function (result) {
            return result.resourceId === questionId;
          });
          resourceResults.forEach(function (questionResult) {
            questionCounter.title = questionResult.resource ? questionResult.resource.get('title') : '';
            if (questionResult.get('started')) {
              if (questionResult.get('isOpenEnded')) {
                questionCounter.openEnded += 1;
              } else {
                questionCounter.correct += questionResult.get('correct') ? 1 : 0;
                questionCounter.incorrect += questionResult.get('incorrect') ? 1 : 0;
              }
            } else if (questionResult.get('resource').isResource && questionResult.get('skipped') === false) {
              questionCounter.correct += 1; //each attempted is marked as correct for collection resource
            }
          });
        });
      });
      return questions;
    }),

    /**
     * @prop { ReportData } reportData
     */
    reportData: null,

    /**
     * @prop { Object[] } scoresData - Array with all the scores in the assessment
     *
     * Each object corresponds to an assessment result by a student and will consist of:
     * - score: number of questions answered correctly vs. total number of questions
     * - completed: have all the questions in the assessment been answered?
     */
    scoresData: _ember['default'].computed('reportData.reportEvents.@each.updated', function () {
      var _this = this;

      var reportEvents = this.get('reportData.reportEvents');

      var results = [];
      reportEvents.forEach(function (reportEvent) {
        if (_this.get('isCollection')) {
          if (reportEvent.get('totalTimeSpent') > 0) {
            results.push({
              score: reportEvent.get('totalTimeSpent'),
              completed: reportEvent.get('isAttemptFinished')
            });
          }
        } else {
          if (reportEvent.get('totalAnswered') > 0) {
            results.push({
              score: reportEvent.get('averageScore'),
              completed: reportEvent.get('isAttemptFinished')
            });
          }
        }
      });
      return results;
    }),

    /**
     * @prop { String[] } studentsIds - An array with the ids of all the students taking the assessment
     */
    studentsIds: _ember['default'].computed.alias('reportData.studentIds'),

    /**
     * @prop { Number } totalCompleted - Number of students that have completed the assessment
     */
    totalCompleted: _ember['default'].computed('scoresData.@each.completed', function () {
      var scoresData = this.get('scoresData');
      return !scoresData.length ? 0 : scoresData.map(function (result) {
        return result.completed ? 1 : 0;
      }).reduce(function (a, b) {
        return a + b;
      });
    })
  });
});