define('quizzes-addon/services/quizzes/api-sdk/collection', ['exports', 'ember', 'quizzes-addon/serializers/collection/collection', 'quizzes-addon/adapters/collection/collection'], function (exports, _ember, _quizzesAddonSerializersCollectionCollection, _quizzesAddonAdaptersCollectionCollection) {

  /**
   * @typedef {Object} CollectionService
   */
  exports['default'] = _ember['default'].Service.extend({
    /**
     * @property {Profile} Profile service
     */
    profileService: _ember['default'].inject.service('quizzes/profile'),

    /**
     * @property {Profile} Profile service
     */
    taxonomyService: _ember['default'].inject.service('quizzes/api-sdk/taxonomy'),

    /**
     * @property {CollectionSerializer} collectionSerializer
     */
    collectionSerializer: null,

    /**
     * @property {CollectionAdapter} collectionAdapter
     */
    collectionAdapter: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('collectionSerializer', _quizzesAddonSerializersCollectionCollection['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('collectionAdapter', _quizzesAddonAdaptersCollectionCollection['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    /**
     * Gets a Collection by id
     * @param {string} collectionId
     * @param {string} type collection|assessment
     * @param {boolean} refresh
     * @returns {Promise.<Collection>}
     */
    readCollection: function readCollection(collectionId, type, refresh, isDefaultShowFW, classFramework) {
      if (refresh === undefined) refresh = false;

      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('collectionAdapter').readCollection(collectionId, type, refresh).then(function (responseData) {
          var collection = service.get('collectionSerializer').normalizeReadCollection(responseData);
          if (isDefaultShowFW) {
            var taxonomyIds = [];
            var taxonomyId = collection.standards.map(function (data) {
              return data.taxonomyId;
            });
            taxonomyIds = taxonomyIds.concat(taxonomyId);
            if (taxonomyIds.length) {
              service.getcrosswalkCompetency(collection, classFramework, taxonomyIds).then(function () {
                resolve(collection);
              });
            } else {
              resolve(collection);
            }
          } else {
            resolve(collection);
          }
        }, reject);
      });
    },

    findResourcesByCollectionforDCA: function findResourcesByCollectionforDCA(sessionId, collectionId, classId, userId, collectionType, date) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('collectionAdapter').queryRecordForDCA({
          sessionId: sessionId,
          collectionId: collectionId,
          classId: classId,
          userId: userId,
          collectionType: collectionType,
          date: date,
          startDate: date,
          endDate: date
        }).then(function (payload) {
          resolve(payload);
        }, function (error) {
          reject(error);
        });
      });
    },

    findAssessmentResultByCollectionAndStudent: function findAssessmentResultByCollectionAndStudent(collectionType, collectionId, userId, sessionId) {
      var service = this;
      var params = {
        collectionType: collectionType,
        contentId: collectionId,
        userId: userId,
        sessionId: sessionId
      };
      return new _ember['default'].RSVP.Promise(function (resolve) {
        return service.get('collectionAdapter').queryRecord(params).then(function (payload) {
          resolve(payload);
        }, function () {
          resolve(undefined);
        });
      });
    },

    /**
     * Gets a Collection by id
     * @param {string} collectionId
     * @returns {Promise.<Collection>}
     */
    getCollection: function getCollection(collectionId, classFramework, isDefaultShowFW) {
      var service = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('collectionAdapter').getCollection(collectionId).then(function (responseData) {
          var collection = service.get('collectionSerializer').normalizeGetCollection(responseData);
          var profileService = service.get('profileService');
          if (window && window.sourceDetailsNoteTool) {
            window.sourceDetailsNoteTool.unit_id = collection.get('unitId');
            window.sourceDetailsNoteTool.lesson_id = collection.get('lessonId');
          }
          var taxonomyIds = [];
          var taxonomyId = collection.taxonomy.map(function (data) {
            return data.taxonomyId;
          });
          taxonomyIds = taxonomyIds.concat(taxonomyId);
          _ember['default'].RSVP.hash({
            getcrosswalkCompetency: isDefaultShowFW && taxonomyIds.length ? service.getcrosswalkCompetency(collection, classFramework, taxonomyIds) : [],
            profile: profileService.readUserProfile(collection.get('ownerId'))
          }).then(function (hash) {
            collection.set('owner', hash.profile);
            resolve(collection);
          });
        }, reject);
      });
    },

    /**
     * Gets a Assesment by id
     * @param {string} collectionId
     * @returns {Promise.<Collection>}
     */

    getAssessment: function getAssessment(collectionId, classFramework, isDefaultShowFW) {
      var service = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('collectionAdapter').getAssessment(collectionId).then(function (responseData) {
          var assessment = service.get('collectionSerializer').normalizeGetAssessment(responseData);
          var profileService = service.get('profileService');
          if (window && window.sourceDetailsNoteTool) {
            window.sourceDetailsNoteTool.unit_id = assessment.get('unitId');
            window.sourceDetailsNoteTool.lesson_id = assessment.get('lessonId');
          }
          var taxonomyIds = [];
          var taxonomyId = assessment.taxonomy.map(function (data) {
            return data.taxonomyId;
          });
          taxonomyIds = taxonomyIds.concat(taxonomyId);
          _ember['default'].RSVP.hash({
            getcrosswalkCompetency: isDefaultShowFW && taxonomyIds.length ? service.getcrosswalkCompetency(assessment, classFramework, taxonomyIds) : [],
            profile: profileService.readUserProfile(assessment.get('ownerId'))
          }).then(function (hash) {
            assessment.set('owner', hash.profile);
            resolve(assessment);
          });
        }, reject);
      });
    },
    /**
     * Notifies a collection change
     * @param {string} collectionId
     * @param {string} type collection|assessment
     * @returns {Promise.<boolean>}
     */
    notifyCollectionChange: function notifyCollectionChange(collectionId, type) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve) {
        service.readCollection(collectionId, type, true).then(function () {
          resolve();
        }, function () {
          resolve();
        });
      });
    },

    getcrosswalkCompetency: function getcrosswalkCompetency(collection, classFramework, taxonomyIds) {
      var service = this;
      var taxonomyService = service.get('taxonomyService');
      return taxonomyService.crosswalkCompetency(classFramework, taxonomyIds).then(function (crosswalkResponse) {
        var frameworkCrossWalkComp = crosswalkResponse;
        var collectionStandrs = collection.taxonomy || collection.standards;
        collectionStandrs.map(function (standard) {
          var taxonomyData = frameworkCrossWalkComp.findBy('sourceDisplayCode', standard.code);
          if (taxonomyData) {
            standard.code = taxonomyData.targetDisplayCode;
            standard.frameworkCode = taxonomyData.targetFrameworkId;
          }
        });
      });
    },
    getAttempt: function getAttempt(classId, memberId) {
      var service = this;
      return service.get('collectionAdapter').getAttempt(classId, memberId).then(function (data) {
        return data;
      });
    }
  });
});