define('quizzes-addon/adapters/profile/profile', ['exports', 'quizzes-addon/mixins/token', 'quizzes-addon/adapters/application'], function (exports, _quizzesAddonMixinsToken, _quizzesAddonAdaptersApplication) {
  exports['default'] = _quizzesAddonAdaptersApplication['default'].extend(_quizzesAddonMixinsToken['default'], {
    /**
     * @property {string} namespace End-point URI
     */
    namespace: '/api/nucleus/v2/profiles/search',

    /**
     * Reads a profile by id
     * @param {String} profileId
     * @returns {Promise}
     */
    readProfiles: function readProfiles(profileIds) {
      var namespace = this.get('namespace');
      var url = namespace + '?userids=' + profileIds.join();
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    }
  });
});