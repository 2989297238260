define('quizzes-addon/components/reports/assessment/questions/qz-multiple-answer', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * Multiple answer
   *
   * Component responsible for show the multiple answer, which option is selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-multiple-answer'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Convenient structure to render options
     * @property {[]}
     */
    answers: _ember['default'].computed('question', 'userAnswer', 'question.answers.@each.text', 'question.answers.@each.value', function () {
      var component = this;
      var question = component.get('question');
      var userAnswers = component.get('userAnswer') || [];
      var correctAnswers = question.get('question.correctAnswer');
      if (component.get('showCorrect')) {
        userAnswers = correctAnswers;
      }

      var answers = question.get('question.answers');
      return answers.map(function (answer) {
        var userAnswer = userAnswers.filterBy('value', answer.value) || [];
        var correctAnswer = correctAnswers.filterBy('value', answer.value);
        var correct = userAnswer.length === correctAnswer.length;
        return {
          text: answer.text,
          selected: !!userAnswer.length,
          correct: correct
        };
      });
    })

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods
  });
});