define('quizzes-addon/components/player/questions/qz-multiple-answer', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion) {

  /**
   * Multiple Answer Question
   *
   * Component responsible for controlling the logic and appearance of a multiple
   * answer question inside of the {@link player/qz-question-viewer.js}
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments Ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-multiple-answer'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * When the user changes the answer choice selection
       * @param {string} answerId
       * @param {boolean} onLoad if this was called when loading the component
       */
      selectAnswerChoice: function selectAnswerChoice(answerId) {
        var component = this;
        component.setUserAnswerChoice(answerId);
        component.notify(false);
      }
    },

    // -------------------------------------------------------------------------
    // Events

    init: function init() {
      this._super.apply(this, arguments);
      var component = this;
      _ember['default'].run.scheduleOnce('afterRender', component, function () {
        var userAnswers = component.get('userAnswer');
        var answers = component.get('question.answers');
        var userSelection = userAnswers ? answers.map(function (answer) {
          return {
            value: answer.value,
            selection: !!userAnswers.findBy('value', answer.value)
          };
        }) : _ember['default'].A();
        component.set('userSelection', userSelection);
        if (component.get('hasUserAnswer')) {
          component.notify(true);
        }
      });
    },

    // -------------------------------------------------------------------------
    // Properties
    /**
     * Convenient structure to render options
     * @property {[]}
     */
    answers: _ember['default'].computed('question.answers', 'userSelection', function () {
      var component = this;
      var answers = this.get('question.answers');
      var userSelection = this.get('userSelection');
      return answers.map(function (answer) {
        var answerId = answer.get('value');
        var filteredUserAnswer = userSelection ? userSelection.findBy('value', answerId) : null;
        return {
          value: answerId,
          text: answer.get('text'),
          groupValue: filteredUserAnswer ? component.userSelectionItemToChoice(filteredUserAnswer) : null
        };
      });
    }),

    // -------------------------------------------------------------------------
    // Observers

    resetUserSelection: _ember['default'].observer('question', function () {
      this.set('userSelection', _ember['default'].A());
    }),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Converts the answer choice string to a  user selection item
     * @param {string} answerChoice  in the format value|id, i.e yes|answer_1
     * @returns {{id: *, selection: boolean}}
     */
    choiceToUserSelectionItem: function choiceToUserSelectionItem(answerChoice) {
      var values = answerChoice.split('|');
      var value = values[1];
      var selection = values[0] === 'yes';
      return { value: value, selection: selection };
    },

    /**
     * Indicates when the answer is completed
     * @return {boolean}
     */
    isAnswerCompleted: function isAnswerCompleted() {
      var component = this,
          userSelection = component.get('userSelection'),
          totalAnswerChoices = component.get('question.answers.length');
      return userSelection.get('length') === totalAnswerChoices;
    },

    /**
     * Notifies answer events
     * @param {boolean} onLoad if this was called when loading the component
     */
    notify: function notify(onLoad) {
      var component = this;
      var userSelection = component.get('userSelection').filter(function (answer) {
        return answer.selection;
      }).map(function (answer) {
        return { value: answer.value };
      });
      component.notifyAnswerChanged(userSelection);
      if (component.isAnswerCompleted()) {
        if (onLoad) {
          component.notifyAnswerLoaded(userSelection);
        } else {
          component.notifyAnswerCompleted(userSelection);
        }
      }
    },

    /**
     * Sets the user answer choice
     * @param {string} answerChoice containing the user selection yes|120202 or no|20200392
     */
    setUserAnswerChoice: function setUserAnswerChoice(answerChoice) {
      var userSelection = this.get('userSelection');
      var userSelectionItem = this.choiceToUserSelectionItem(answerChoice);
      var value = userSelectionItem.value;
      var selection = userSelectionItem.selection;
      var found = userSelection.findBy('value', value);
      if (found) {
        found.selection = selection;
      } else {
        userSelection.addObject(userSelectionItem);
      }
    },

    /**
     * Converts user selection item to answer choice
     * @param {{value: *, selection: boolean}} userSelectionItem
     *
     * @return {string} in the format selection|value, i.e yes|answer_1
     */
    userSelectionItemToChoice: function userSelectionItemToChoice(answer) {
      return (answer.selection ? 'yes' : 'no') + '|' + answer.value;
    },
    didInsertElement: function didInsertElement() {
      var $fileInput = this.$('input[type="radio"]');
      if ($fileInput) {
        for (var i = 0; i < $fileInput.length; i++) {
          $fileInput[i].title = $fileInput[i].id;
        }
      }
    }
  });
});