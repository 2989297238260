define('quizzes-addon/components/reports/class-assessment/qz-table-view', ['exports', 'ember', 'quizzes-addon/utils/utils', 'quizzes-addon/utils/math', 'quizzes-addon/config/quizzes-question'], function (exports, _ember, _quizzesAddonUtilsUtils, _quizzesAddonUtilsMath, _quizzesAddonConfigQuizzesQuestion) {

  /**
   * Class assessment table view
   *
   * Component responsible for filtering and transforming the class assessment data
   * into a format readily consumable by the gru-two-tier-header-table component.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @requires service:i18n
     */
    i18n: _ember['default'].inject.service(),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'class-assessment', 'qz-table-view'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * @function actions:selectQuestion
       * @param {Number} questionId
       */
      selectQuestion: function selectQuestion(questionId) {
        this.get('onSelectQuestion')(questionId);
      },

      /**
       * @function actions:selectStudent
       * @param {string} studentId
       */
      selectStudent: function selectStudent(studentId) {
        this.get('onSelectStudent')(studentId);
      }
    },

    // -------------------------------------------------------------------------
    // Events

    init: function init() {
      this._super.apply(this, arguments);
      this.set('questionProperties', this.initQuestionProperties());
      this.set('studentsHeader', this.initStudentsHeader());
    },

    willDestroyElement: function willDestroyElement() {
      this.set('questionProperties', null);
      this.set('studentsHeader', null);
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Indicates if the report is displayed in anonymous mode
     * @property {boolean} anonymous
     */
    anonymous: false,

    /**
     * @prop { Collection } assessment
     */
    assessment: _ember['default'].computed.alias('reportData.collection'),

    /**
     * @prop { Object[] } assessmentQuestions - An array made up of all the questions in the assessment
     *
     * Each question object will consist of:
     * - label: visual representation of the header
     * - value: internal header identifier
     *- type : type represented by in column
     * - title : title of the resource or question to display on list header
     * The questions will be ordered in the array in ascending order per the order value
     */
    assessmentQuestions: _ember['default'].computed('assessment.resources.@each.id', function () {
      var labelPrefix = this.get('i18n').t('reports.qz-table-view.first-tier-header-prefix').string;

      var questions = this.get('assessment.resources').sortBy('sequence').map(function (question, index) {
        return {
          value: question.get('id'),
          label: labelPrefix + (index + 1),
          type: question.get('type'),
          title: question.get('title')
        };
      });

      // Add column used for showing totals at the beginning of the array
      questions.unshift({
        value: -1,
        label: this.get('i18n').t('reports.qz-table-view.totals').string
      });

      return questions;
    }),

    /**
     * @prop { String[] } assessmentQuestionsIds - An array with the ids of all the questions in the assessment
     */
    assessmentQuestionsIds: _ember['default'].computed('assessmentQuestions.@each.value', 'assessmentQuestions.@each.label', function () {
      return this.get('assessmentQuestions').map(function (question) {
        return question.value;
      });
    }),

    /**
     * @prop { Object[] } questionProperties - An array made up of question properties
     *
     * Each property object will consist of:
     * - filter: information to use for the corresponding filter checkbox
     * - label: visual representation of the header
     * - value: internal header identifier
     * - visible: should the property be visible or not?
     * - renderFunction: function to process values of this property for output
     * - aggregateFunction: if there's an aggregate column, this function will be
     *   used to aggregate all the values for this property that are in the same row
     * - aggregateRenderFunction: if there's an aggregate column, this function will
     *   take the result of the aggregateFunction and process it for output
     * - sortFunction: sort function for values of this property
     */
    questionProperties: null,

    /**
     * @prop { String[] } questionPropertiesIds - An array with the ids of all the question properties
     */
    questionPropertiesIds: _ember['default'].computed('questionProperties.@each.value', function () {
      return this.get('questionProperties').map(function (questionProperty) {
        return questionProperty.value;
      });
    }),

    /**
     * @prop { Object[] } scoresData - Array with all the scores in the assessment
     *
     * Each object corresponds to an assessment result by a student and will consist of:
     * - score: number of questions answered correctly vs. total number of questions
     * - completed: have all the questions in the assessment been answered?
     */
    scoresData: _ember['default'].computed('reportData.reportEvents.@each.updated', function () {
      var reportEvents = this.get('reportData.reportEvents');

      var results = [];
      reportEvents.forEach(function (reportEvent) {
        if (reportEvent.get('totalAnswered') > 0) {
          results.push({
            score: reportEvent.get('averageScore'),
            completed: reportEvent.get('isAttemptFinished')
          });
        }
      });
      return results;
    }),

    /**
     * @prop { number } averageScore - average score in the assessment
     * for the entire group of students (per scoresData)
     */
    averageScore: _ember['default'].computed('scoresData.@each.score', function () {
      var scores = this.get('scoresData').map(function (result) {
        return result.score ? result.score : 0;
      });
      return scores.length ? Math.round((0, _quizzesAddonUtilsMath.average)(scores)) : 0;
    }),

    /**
     * @prop { ReportData } reportData - Unordered 3D matrix of data to use as content for the table component
     */
    reportData: null,

    /**
     * @prop { User[] } students - Students taking the assessment
     */
    students: _ember['default'].computed.alias('reportData.students'),

    /**
     * @prop { String? } studentsHeader - Header for the students names
     */
    studentsHeader: null,

    /**
     * @prop { String[] } studentsIds - An array with the ids of all the students taking the assessment
     */
    studentsIds: _ember['default'].computed.alias('reportData.studentIds'),

    /**
     * @prop { Object[] } tableData - Ordered data to use as content for the table component
     * It merges the existing table frame with any updated table data.
     *
     * Each object in the array will consist of:
     * - id: row id
     * - header: row header
     * - content: an array of objects making up the row content where each object is made up of:
     *   - value: table cell un-formatted content
     *   - output: table cell content formatted for output (the formatting is done by
     *             the question property's render function)
     */
    tableData: _ember['default'].computed('anonymous', 'tableFrame', 'reportData.reportEvents.@each.updated', function () {
      var questionProperties = this.get('questionProperties');
      var questionPropertiesIds = this.get('questionPropertiesIds');
      var questionPropertiesIdsLen = questionPropertiesIds.length;
      var reportDataEvents = this.get('reportData.reportEvents');
      var assessmentQuestions = this.get('assessment.resources').sortBy('sequence');

      var component = this;
      // Copy the table frame contents
      var data = this.get('tableFrame').slice(0);
      var propertyValues = undefined;
      reportDataEvents.forEach(function (reportEvent, i) {
        propertyValues = [];
        for (var k = 0; k < questionPropertiesIdsLen; k++) {
          // Put all values for the same property into an array
          propertyValues[k] = [];
        }

        reportEvent.get('resourceResults').map(function (questionResult) {
          var question = assessmentQuestions.findBy('id', questionResult.get('resourceId'));
          questionResult.set('resource', question);
          return questionResult;
        }).sortBy('resource.sequence').forEach(function (questionResult, l) {
          var j = l + 1;
          for (var k = 0; k < questionPropertiesIdsLen; k++) {
            var renderFunction = questionProperties[k].renderFunction;
            var value = questionResult.get(questionPropertiesIds[k]);
            var _status = 'not-started';
            if (questionResult.get('skipped')) {
              _status = 'skipped';
            } else if (questionResult.get('resource.type') === 'extended_text') {
              _status = 'extended_text';
            } else if (questionResult.get('answer')) {
              _status = value ? 'correct' : 'incorrect';
            }
            if (k === 0) {
              value = _status;
            }
            var renderOut = !renderFunction ? value : renderFunction(value);
            if (renderFunction.name === 'formatTime' && value === 0) {
              renderOut = '<span class="ad score answer-not-started">' + j + '</span>';
            }
            var questionRenderOut = component.getOutPutForCollectionQuestion(questionResult, j);
            renderOut = questionRenderOut ? questionRenderOut : renderOut;

            data[i].content[j * questionPropertiesIdsLen + k] = {
              value: value,
              output: renderOut,
              quesId: questionResult.get('resourceId')
            };
            propertyValues[k].push(questionResult);
          }
        });

        // Compute the aggregate values
        for (var k = 0; k < questionPropertiesIdsLen; k++) {
          // Set the value in the aggregate (totals) column;
          var value = reportEvent.get(questionProperties[k].aggregateValue);
          var aggregateRenderFunction = questionProperties[k].aggregateRenderFunction;

          // For displaying the aggregate value, use the question property's aggregateRenderFunction.
          // If there's no aggregateRenderFunction, use the property's renderFunction by default.
          var aggregateOutput = aggregateRenderFunction ? aggregateRenderFunction(value) : questionProperties[k].renderFunction(value);
          aggregateOutput = aggregateOutput && aggregateOutput !== '' ? aggregateOutput : '-';

          data[i].content[k] = {
            value: value,
            output: aggregateOutput
          };
        }
      });

      return data;
    }),

    getOutPutForCollectionQuestion: function getOutPutForCollectionQuestion(questionResult, idx) {
      var component = this;
      var questionPropertiesIds = this.get('questionPropertiesIds');

      var renderOut = undefined;
      var value = questionResult.get(questionPropertiesIds[0]);
      var status = 'not-started';
      if (questionResult.get('skipped')) {
        status = 'skipped';
      } else if (questionResult.get('resource.type') === 'extended_text') {
        status = 'extended_text';
      } else if (questionResult.get('answer')) {
        status = value ? 'correct' : 'incorrect';
      }
      var isQuestion = Object.values(_quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES).find(function (x) {
        return x === questionResult.get('resource.type');
      });
      if (component.isCollectionType && isQuestion) {
        renderOut = (0, _quizzesAddonUtilsUtils.getAnswerResultIconWithValue)(status, idx);
      }
      return renderOut;
    },
    /**
     * @prop {Object[]} tableFrame - The table frame that encloses the table data
     * @return {Object[]}
     */
    tableFrame: _ember['default'].computed('anonymous', 'students.@each.lastFirstName', 'students.@each.id', function () {
      var anonymous = this.get('anonymous');
      var reportDataEvents = this.get('reportData.reportEvents');
      return this.get('students').map(function (student) {
        var eventsStudent = reportDataEvents.findBy('profileId', student.get('id'));
        return {
          id: student.get('id'),
          header: anonymous ? student.get('code') : student.get('lastFirstName'),
          lastFirstName: anonymous ? student.get('code') : student.get('lastFirstName'),
          avatarUrl: eventsStudent.avatarUrl,
          content: []
        };
      });
    }),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initialize the question properties array with values -including i18n labels
     * @return {Object[]}
     */
    initQuestionProperties: function initQuestionProperties() {
      return [_ember['default'].Object.create({
        filter: {
          label: this.get('i18n').t('reports.qz-table-view.scores').string,
          disabled: true
        },
        label: this.get('i18n').t('reports.qz-table-view.score').string,
        value: 'correct',
        visible: true,
        renderFunction: _quizzesAddonUtilsUtils.getAnswerResultIcon,
        aggregateValue: 'averageScore',
        aggregateRenderFunction: _quizzesAddonUtilsUtils.getScoreString
      }), _ember['default'].Object.create({
        filter: {
          label: this.get('i18n').t('reports.qz-table-view.study-time').string
        },
        label: this.get('i18n').t('reports.qz-table-view.study-time').string,
        value: 'timeSpent',
        renderFunction: _quizzesAddonUtilsUtils.formatTime,
        aggregateValue: 'totalTimeSpent'
      }), _ember['default'].Object.create({
        filter: {
          label: this.get('i18n').t('reports.qz-table-view.reactions').string
        },
        label: this.get('i18n').t('reports.qz-table-view.reaction').string,
        value: 'reaction',
        renderFunction: _quizzesAddonUtilsUtils.getReactionIcon,
        aggregateValue: 'averageReaction'
      })];
    },

    /**
     * Initialize the students header object with values including an i18n label
     * @return {Object[]}
     */
    initStudentsHeader: function initStudentsHeader() {
      return {
        label: this.get('i18n').t('reports.qz-table-view.student').string,
        value: 'lastFirstName',
        sortFunction: _quizzesAddonUtilsUtils.alphabeticalStringSort
      };
    }
  });
});