define('quizzes-addon/components/reports/assessment/questions/qz-serp-sorting', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * Hot spot text
   *
   * Component responsible for show the hot spot text, which option is selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-serp-sorting'],

    // -------------------------------------------------------------------------
    // Properties

    showCorrect: false,

    baseAnswers: _ember['default'].computed.alias('question.resource.baseAnswers'),

    userAnswers: _ember['default'].computed('question.answer', function () {
      return this.get('question.answer').map(function (item) {
        return JSON.parse(item.value)[0] || '';
      });
    }),

    answerDetails: _ember['default'].computed('baseAnswers', 'userAnswers', function () {
      var answerDetails = this.get('showCorrect') ? this.get('baseAnswers') : this.get('userAnswers');
      return answerDetails.sortBy('sequence');
    })
  });
});