define('quizzes-addon/components/editor/assessment/qz-category', ['exports', 'ember', 'quizzes-addon/models/editor/assessment/category'], function (exports, _ember, _quizzesAddonModelsEditorAssessmentCategory) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['editor', 'assessment', 'qz-category'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       *Set if feedback is required
       */
      setFeedBack: function setFeedBack() {
        this.set('category.requiredFeedback', !this.get('category.requiredFeedback'));
      },
      /**
       *Triggered when scoring switch change
       */
      onScoringChange: function onScoringChange(isChecked) {
        this.set('showScore', isChecked);
      },
      /**
       *Delete a category
       */
      deleteCategory: function deleteCategory(category) {
        this.sendAction('onDeleteCategory', category);
      },
      /**
       *Copy category
       */
      copyCategory: function copyCategory(category, index) {
        this.sendAction('onCopyCategory', category, index);
      }
    },

    // -------------------------------------------------------------------------
    // Properties
    /**
     * @property {Category} Category
     */
    category: _quizzesAddonModelsEditorAssessmentCategory['default'].create({}),
    /**
     * @property {int} Category index number
     */
    number: 1,
    /**
     *Show the score scale
     *
     * @property {Boolean}
     */
    showScore: false,
    /**
     * List of options to show in the switch
     *
     * @property {Array}
     */
    switchOptions: _ember['default'].A([_ember['default'].Object.create({
      label: 'On',
      value: true
    }), _ember['default'].Object.create({
      label: 'Off',
      value: false
    })])
  });
});