define('quizzes-addon/components/file-picker', ['exports', 'ember', 'ember-cli-file-picker/components/file-picker', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/mixins/endpoint-config'], function (exports, _ember, _emberCliFilePickerComponentsFilePicker, _quizzesAddonConfigQuizzesConfig, _quizzesAddonMixinsEndpointConfig) {
  exports['default'] = _emberCliFilePickerComponentsFilePicker['default'].extend(_quizzesAddonMixinsEndpointConfig['default'], {

    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @requires service:i18n
     */
    i18n: _ember['default'].inject.service(),

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Default handler when a file is removed
     * @type {Function}
     */
    onRemoveFile: null,

    // -------------------------------------------------------------------------
    // Methods

    /*
     * Validate the files per:
     * https://github.com/funkensturm/ember-cli-file-picker#validations
     */
    filesAreValid: function filesAreValid(files) {
      var file = files[0];
      var valid = true;

      // Clear any previous error messages
      this.get('errors').clear();

      //File size will be read from env config, if not default from config
      var fileMaxSize = this.getFileMaxSizeInMB() || _quizzesAddonConfigQuizzesConfig.FILE_MAX_SIZE_IN_MB;
      var fileMaxSizeInBytes = parseInt(fileMaxSize) * 1024 * 1024;
      if (file.size > fileMaxSizeInBytes) {
        var errorMessage = this.get('i18n').t('common.errors.file-max-size', {
          fileMaxSize: fileMaxSize
        }).string;
        this.get('errors').addObject(errorMessage);
        valid = false;
      }

      if (!valid) {
        // Remove the image preview and run the handler for removing the image
        this.clearPreview();
        this.get('onRemoveFile')();
      }

      return valid;
    },

    /**
     * When the file input changed (a file got selected)
     * Override original method to restore preview after selecting 'cancel' from the browser file dialog
     * @see https://github.com/funkensturm/ember-cli-file-picker/pull/18
     * @param  {Event} event The file change event
     */
    filesSelected: function filesSelected(event) {
      var files = event.target.files;
      if (files.length) {
        this.handleFiles(files);
      } else {
        // The user chose to cancel the image selection from the browser file window
        // so preview will be cleared along with any error messages there might have been
        this.clearPreview();
        this.get('errors').clear();
        this.get('onRemoveFile')();
      }
    }
  });
});