define('quizzes-addon/serializers/profile/profile', ['exports', 'ember', 'quizzes-addon/models/profile/profile', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonModelsProfileProfile, _quizzesAddonConfigQuizzesConfig) {
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {Ember.Service} Service to session
     */
    session: _ember['default'].inject.service('session'),

    /**
     * @property {Ember.Service} Service to configuration properties
     */
    configurationService: _ember['default'].inject.service('configuration'),

    /**
     * Normalizes a list of profiles
     * @param {Object[]} payload
     * @returns {Profile[]}
     */
    normalizeProfiles: function normalizeProfiles(profiles) {
      var serializer = this;
      return profiles.users.reduce(function (profilesById, profile) {
        profilesById[profile.id] = serializer.normalizeProfile(profile);
        return profilesById;
      }, {});
    },

    /**
     * Normalize a profile
     * @param {Object} payload
     * @returns {Profile}
     */
    normalizeProfile: function normalizeProfile(profile) {
      var serializer = this;
      var basePath = serializer.get('session.cdnUrls.user');
      var appRootPath = serializer.get('configurationService.configuration.appRootPath'); //configuration appRootPath
      var thumbnailUrl = profile.thumbnail ? basePath + profile.thumbnail : appRootPath + _quizzesAddonConfigQuizzesConfig.DEFAULT_IMAGES.USER_PROFILE;

      return _quizzesAddonModelsProfileProfile['default'].create({
        id: profile.id,
        email: profile.email,
        firstName: profile.first_name,
        lastName: profile.last_name,
        username: profile.username,
        avatarUrl: thumbnailUrl,
        studentId: profile.roster_global_userid
      });
    }
  });
});