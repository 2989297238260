define('quizzes-addon/services/quizzes/api-sdk/taxonomy', ['exports', 'ember', 'quizzes-addon/serializers/taxonomy/taxonomy', 'quizzes-addon/adapters/taxonomy/taxonomy'], function (exports, _ember, _quizzesAddonSerializersTaxonomyTaxonomy, _quizzesAddonAdaptersTaxonomyTaxonomy) {

  /**
   * API-SDK Service for the Taxonomies back-end endpoints
   *
   * @typedef {Object} APITaxonomyService
   */
  exports['default'] = _ember['default'].Service.extend({
    taxonomySerializer: null,

    taxonomyAdapter: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('taxonomySerializer', _quizzesAddonSerializersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('taxonomyAdapter', _quizzesAddonAdaptersTaxonomyTaxonomy['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    /**
     * Fetches the Taxonomy Subjects
     *
     * @param category - The classification type
     * @returns {Promise}
     */
    fetchSubjects: function fetchSubjects(category) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('taxonomyAdapter').fetchSubjects(category).then(function (response) {
          resolve(service.get('taxonomySerializer').normalizeFetchSubjects(response));
        }, function (error) {
          reject(error);
        });
      });
    },

    /**
     * Fetches the Taxonomy Courses
     *
     * @param frameworkId - the framework ID
     * @param subjectId - the taxonomy subject ID
     * @returns {Promise}
     */
    fetchCourses: function fetchCourses(frameworkId, subjectId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('taxonomyAdapter').fetchCourses(frameworkId, subjectId).then(function (response) {
          resolve(service.get('taxonomySerializer').normalizeFetchCourses(response));
        }, function (error) {
          reject(error);
        });
      });
    },

    /**
     * Fetches the Taxonomy Domains
     *
     * @param frameworkId - the framework ID
     * @param subjectId - the taxonomy subject ID
     * @param courseId - the taxonomy course ID
     * @returns {Promise}
     */
    fetchDomains: function fetchDomains(frameworkId, subjectId, courseId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('taxonomyAdapter').fetchDomains(frameworkId, subjectId, courseId).then(function (response) {
          resolve(service.get('taxonomySerializer').normalizeFetchDomains(response));
        }, function (error) {
          reject(error);
        });
      });
    },

    /**
     * Fetches the Taxonomy Codes
     *
     * @param frameworkId - the framework ID
     * @param subjectId - the taxonomy subject ID
     * @param courseId - the taxonomy course ID
     * @param domainId - the taxonomy domain ID
     * @returns {Promise}
     */
    fetchCodes: function fetchCodes(frameworkId, subjectId, courseId, domainId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('taxonomyAdapter').fetchCodes(frameworkId, subjectId, courseId, domainId).then(function (response) {
          resolve(service.get('taxonomySerializer').normalizeFetchCodes(response));
        }, function (error) {
          reject(error);
        });
      });
    },

    /**
     * Fetches the Taxonomy Codes by IDs
     *
     * @param codesIds - the list of codes IDs
     * @returns {Promise}
     */
    fetchCodesByIds: function fetchCodesByIds(codesIds) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('taxonomyAdapter').fetchCodesByIds(codesIds).then(function (response) {
          resolve(service.get('taxonomySerializer').normalizeFetchCodes(response));
        }, function (error) {
          reject(error);
        });
      });
    },

    crosswalkCompetency: function crosswalkCompetency(classFramework, taxonomyIds) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('taxonomyAdapter').crosswalkCompetency(classFramework, taxonomyIds).then(function (response) {
          resolve(service.get('taxonomySerializer').normalizeCrosswalkCompetency(response));
        }, function (error) {
          reject(error);
        });
      });
    }
  });
});