define('quizzes-addon/components/player/resources/qz-html-resource', ['exports', 'quizzes-addon/components/player/resources/qz-resource'], function (exports, _quizzesAddonComponentsPlayerResourcesQzResource) {

  /**
   * Youtube resource component
   *
   * Component responsible for controlling the logic and appearance of the youtube resource type
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-viewer.js
   * @augments Ember/Component
   **/

  exports['default'] = _quizzesAddonComponentsPlayerResourcesQzResource['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-html-resource']
  });
});