define('quizzes-addon/adapters/taxonomy/taxonomy', ['exports', 'quizzes-addon/mixins/token', 'ember'], function (exports, _quizzesAddonMixinsToken, _ember) {

  /**
   * Adapter for Taxonomy endpoints
   *
   * @typedef {Object} TaxonomyAdapter
   */
  exports['default'] = _ember['default'].Object.extend(_quizzesAddonMixinsToken['default'], {
    namespace: '/api/nucleus/v1/taxonomy',

    /**
     * Fetches the Taxonomy Subjects for the specific type
     *
     * @param category - The classification type
     * @returns {Promise}
     */
    fetchSubjects: function fetchSubjects(category) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/subjects';
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: adapter.defineHeaders(),
        data: {
          classification_type: category
        }
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Fetches the Taxonomy Courses
     *
     * @param frameworkId - the framework ID
     * @param subjectId - the taxonomy subject ID
     * @returns {Promise}
     */
    fetchCourses: function fetchCourses(frameworkId, subjectId) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/frameworks/' + frameworkId + '/subjects/' + subjectId + '/courses';
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: adapter.defineHeaders(),
        data: {}
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Fetches the Taxonomy Domains
     *
     * @param frameworkId - the framework ID
     * @param subjectId - the taxonomy subject ID
     * @param courseId - the taxonomy course ID
     * @returns {Promise}
     */
    fetchDomains: function fetchDomains(frameworkId, subjectId, courseId) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/frameworks/' + frameworkId + '/subjects/' + subjectId + '/courses/' + courseId + '/domains';
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: adapter.defineHeaders(),
        data: {}
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Fetches the Taxonomy Codes
     *
     * @param frameworkId - the framework ID
     * @param subjectId - the taxonomy subject ID
     * @param courseId - the taxonomy course ID
     * @param domainId - the taxonomy domain ID
     * @returns {Promise}
     */
    fetchCodes: function fetchCodes(frameworkId, subjectId, courseId, domainId) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/frameworks/' + frameworkId + '/subjects/' + subjectId + '/courses/' + courseId + '/domains/' + domainId + '/codes';
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: adapter.defineHeaders(),
        data: {}
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Fetches the Taxonomy Codes by a provided list of IDs
     *
     * @param codesIds - the list of codes IDs
     * @returns {Promise}
     */
    fetchCodesByIds: function fetchCodesByIds(codesIds) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/codes';
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: adapter.defineHeaders(),
        data: {
          idList: codesIds.join(',')
        }
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Fetches the crosswalk competency based on the class framework
     *
     * @param framework- The class framework
     * @param competency- competency array
     * @returns {Promise}
     */
    crosswalkCompetency: function crosswalkCompetency(framework, competencies) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/framework/crosswalk/competency/' + framework;
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers'),
        data: JSON.stringify({
          codes: competencies
        })
      };
      // options.data = Object.assign(options.data, filter);
      return _ember['default'].$.ajax(url, options);
    },

    defineHeaders: function defineHeaders() {
      return {
        Authorization: 'Token '
      };
    }
  });
});