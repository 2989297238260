define('quizzes-addon/components/player/questions/qz-serp-sorting', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP sorting
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-sorting'],

    // -------------------------------------------------------------------------
    // Properties

    answers: _ember['default'].computed('question.baseAnswers', function () {
      var answers = this.get('question.baseAnswers');
      return answers.map(function (item) {
        return {
          answer_text: item.answer_text,
          answer_type: null,
          is_correct: true
        };
      });
    }),

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initialize();
    },

    // -------------------------------------------------------------------------
    // Methods

    initialize: function initialize() {
      var component = this;
      var userAnswered = undefined;
      var answers = component.get('answers');
      var userAnswerList = component.get('userAnswer');
      if (userAnswerList && userAnswerList.length) {
        userAnswered = userAnswerList.map(function (item) {
          return JSON.parse(item.value);
        });
      }
      if (userAnswered && userAnswered.length) {
        userAnswered.forEach(function (items, index) {
          items.forEach(function (item) {
            var answer = answers.get(index);
            _ember['default'].set(answer, 'answer_type', item.answer_type);
            var tag = '<li data-answer-index=' + index + '> ' + item.answer_text + '</li>';
            if (item.answer_type === 'soft') {
              component.$('.qz-column-soft-blk').append(tag);
            } else if (item.answer_type === 'hard') {
              component.$('.qz-column-hard-blk').append(tag);
            }
          });
        });
      }

      component.$(function () {
        component.$('.qz-sorting-blk li, .qz-sorting-list-item li').draggable({
          helper: 'clone',
          revert: 'invalid'
        });
        component.$('.qz-sorting-blk').droppable({
          tolerance: 'intersect',
          drop: function drop(event, ui) {
            $(this).append($(ui.draggable));
            var answerIndex = ui.helper[0].dataset.answerIndex;
            var type = event.target.classList.contains('qz-column-soft-blk') ? 'soft' : 'hard';
            var answer = answers.get(answerIndex);
            _ember['default'].set(answer, 'answer_type', type);
            component.getSequenceItem(component.$('.qz-column-soft-blk'), component);
            component.getSequenceItem(component.$('.qz-column-hard-blk'), component);
            component.parseUserAnswer(component);
          }
        });
      });
    },

    /**
     * @function parseUserAnswer Help to order user answers list
     */
    parseUserAnswer: function parseUserAnswer(component) {
      var answers = component.get('answers');
      var userAnswers = [];
      answers = answers.sortBy('sequence');
      answers.forEach(function (item) {
        item.sequence = 0;
        userAnswers.push({
          value: '[' + JSON.stringify(item) + ']'
        });
      });
      var allAnswered = answers.findBy('answer_type', null);
      if (!allAnswered) {
        component.notifyAnswerCompleted(userAnswers);
      }
    },

    getSequenceItem: function getSequenceItem(_this, component) {
      var answers = component.get('answers');
      var elementList = _this.children();
      elementList.each(function (index, el) {
        var answerIndex = el.dataset.answerIndex;
        var answer = answers.get(answerIndex);
        _ember['default'].set(answer, 'sequence', index);
      });
    }
  });
});