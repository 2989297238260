define('quizzes-addon/components/reports/assessment/questions/qz-scientific-free-response', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * Fill in the blank
   *
   * Component responsible for controlling the logic and appearance of a fill in the blank
   * question inside of the assessment report.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-scientific-free-response'],

    // -------------------------------------------------------------------------
    // Properties

    userAnswers: _ember['default'].computed('userAnswer', function () {
      var component = this;
      var userAnswers = _ember['default'].A([]);
      var userAnswer = component.get('userAnswer');
      userAnswer.map(function (answer) {
        var answerValue = answer.value.split(':');
        userAnswers.pushObject({
          category: answerValue[0],
          value: answerValue[1]
        });
      });
      return userAnswers;
    }),

    /**
     * Return an array with every sentence and user answer, and indicate if is correct or incorrect
     * @return {Array}
     */
    answer: _ember['default'].computed('question', 'userAnswers', function () {
      var component = this;
      var question = component.get('question');
      var questionText = question.get('question.answerDetails');
      var userAnswers = component.get('userAnswers');
      var answers = userAnswers.map(function (answer) {
        var userAnswer = answer.value.trim().toLowerCase();
        return {
          text: userAnswer,
          'class': 'answer'
        };
      });

      var sentences = questionText.map(function (questionTextPart) {
        return {
          category: questionTextPart.answer_category,
          'class': 'sentence'
        };
      });
      sentences = userAnswers && userAnswers.length ? sentences : [];

      return this.mergeArrays(sentences, answers);
    }),

    // -------------------------------------------------------------------------
    // Methods
    /**
     * Merge sentences and answers arrays
     * @return {Array}
     */
    mergeArrays: function mergeArrays(sentences, answers) {
      var mergeArrays = _ember['default'].A();
      answers.forEach(function (item, index) {
        mergeArrays.pushObject(sentences.get(index));
        mergeArrays.pushObject(item);
      });
      mergeArrays.pushObject(answers);
      return mergeArrays;
    }
  });
});