define('quizzes-addon/components/editor/assessment/qz-submission-format', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    /**
     * @property {Service} I18N service
     */
    i18n: _ember['default'].inject.service(),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-submission-format'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Selected format type
       */
      selectType: function selectType(type) {
        this.set('selectedType', type);
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @type {String} selectedType
     */
    selectedType: null,

    /**
     * @type {Array[]} questionTypes
     */
    submissionFormats: _ember['default'].A([_ember['default'].Object.create({
      format: 'textbox'
    }), _ember['default'].Object.create({
      format: 'upload'
    })])
  });
});