define('quizzes-addon/helpers/grading-scale-legend', ['exports', 'ember', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig) {
  exports.gradingScaleLegend = gradingScaleLegend;

  /**
   * Generate the legend to show the grading scale used throughout the app
   * Additional options may be displayed with the grading scale
   *
   * @example
   *
   * {{grading-scale-legend}}
   * {{grading-scale-legend notStarted="not started"}}
   *
   * @param hash {String} - Object with named arguments:
   *   * notStarted {String} - string for the legend option 'not started'
   * @returns {String}
   */

  function gradingScaleLegend(params, hash) {
    var notStarted = hash && hash.notStarted;
    var notScored = hash && hash.notScored;

    var $el = $('<div><ul class="grading-scale-legend"></ul></div>');
    var $legend = $el.find('.grading-scale-legend');

    var gradingScaleLen = _quizzesAddonConfigQuizzesConfig.GRADING_SCALE.length;

    if (notStarted) {
      $legend.append('<li class="not-started">\n                      <i></i>\n                      <span>' + notStarted + '</span>\n                   </li>');
    }

    for (var i = 0; i < gradingScaleLen; i++) {
      var bracket = _quizzesAddonConfigQuizzesConfig.GRADING_SCALE[i];

      // The upper limit of the grading scale will be 100
      var upperLimit = _quizzesAddonConfigQuizzesConfig.GRADING_SCALE[i + 1] ? _quizzesAddonConfigQuizzesConfig.GRADING_SCALE[i + 1].LOWER_LIMIT - 1 : 100;

      $legend.append('<li>\n                      <i style="background-color: ' + bracket.COLOR + ';"></i>\n                      <span>' + bracket.LOWER_LIMIT + ' - ' + upperLimit + '%</span>\n                   </li>');
    }

    if (notScored) {
      $legend.append('<li class="not-scored">                       <i style="background-color: ' + _quizzesAddonConfigQuizzesConfig.OPEN_ENDED_COLOR + ';"></i>                       <span>' + notScored + '</span>                   </li>');
    }

    return _ember['default'].String.htmlSafe($el.html());
  }

  exports['default'] = _ember['default'].Helper.helper(gradingScaleLegend);
});