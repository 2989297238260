define('quizzes-addon/adapters/class/class', ['exports', 'quizzes-addon/mixins/token', 'quizzes-addon/adapters/application'], function (exports, _quizzesAddonMixinsToken, _quizzesAddonAdaptersApplication) {
  exports['default'] = _quizzesAddonAdaptersApplication['default'].extend(_quizzesAddonMixinsToken['default'], {
    /**
     * @property {string} End-point URI
     */
    namespace: '/api/nucleus/v1/classes',

    /**
     * Get the list of members, invitees, collaborators and owner of the class
     * @param classId the class ID to be read
     * @returns {Promise}
     */
    readClassMembers: function readClassMembers(classId) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/' + classId + '/members';
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    }
  });
});