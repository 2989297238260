define('quizzes-addon/components/gru-standards-popup', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['gru-standards-popup'],

    classNameBindings: ['component-class'],

    /**
     * @type {?String} specific class
     */
    'component-class': null,

    /**
     * List of standards
     * @property {array}
     */
    model: []
  });
});