define('quizzes-addon/helpers/item-initial', ['exports', 'ember', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig) {
  exports.itemInitial = itemInitial;

  /**
   * get initial text
   */

  function itemInitial(value /*, options*/) {
    if (value[0] === _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.ASSESSMENT) {
      return 'common.assessmentInitial';
    }
    return 'common.collectionInitial';
  }

  exports['default'] = _ember['default'].Helper.helper(itemInitial);
});

// constants