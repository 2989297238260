define('quizzes-addon/components/taxonomy/gru-taxonomy-tag-list', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Taxonomy tag list
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['taxonomy', 'gru-taxonomy-tag-list'],

    classNameBindings: ['isInCard:in-card'],

    // --------------------------------------------
    // Actions
    actions: {
      removeTag: function removeTag(tag) {
        if (this.get('onRemove')) {
          this.get('onRemove')(tag);
        }
      }
    },

    // -------------------------------------------------------------------------
    // Events
    setup: _ember['default'].on('didInsertElement', function () {
      if (this.get('nonVisibleTags')) {
        this.setupTooltip();
      }
    }),

    cleanUp: _ember['default'].on('willDestroyElement', function () {
      var $anchor = this.$('button.non-visible-tags');
      $anchor.off('click');

      // In case a popover was open, it will need to be destroyed
      $anchor.popover('destroy');
    }),

    // --------------------------------------------
    // Properties

    /**
     * @property {boolean} Should the taxonomy tags in the list display a tooltip with more details
     */
    hasTooltips: true,

    /**
     * @property {boolean} Is the taxonomy tag list contained within a content card
     */
    isInCard: false,

    /**
     * @property {boolean} Is the taxonomy tag list called from search
     */
    isInSearch: false,

    /**
     * @property {TaxonomyTag[]} taxonomy tag
     */
    tags: null,

    /**
     * Indicates the total of tags visible
     * @property {number}
     */
    tagsVisible: null,

    /**
     * @property {TaxonomyTag[]} taxonomy tag
     */
    visibleTags: _ember['default'].computed('tags.[]', function () {
      var tagsVisible = this.get('tagsVisible') || 999999; //long number so it show all when no provided
      return this.get('tags').filter(function (tag, index) {
        return index < tagsVisible;
      });
    }),

    /**
     * @property {number}
     */
    totalTags: _ember['default'].computed.alias('tags.length'),

    /**
     * Indicates how many tags are not visible
     * @property {number}
     */
    nonVisibleTags: _ember['default'].computed('totalTags', function () {
      var totalTags = this.get('totalTags');
      var tagsVisible = this.get('tagsVisible') || totalTags;
      var nonVisibleTags = totalTags - tagsVisible;
      return nonVisibleTags > 0 ? nonVisibleTags : 0;
    }),

    /**
     * @property {string}
     */
    onRemove: null,

    // -------------------------------------------------------------------------
    // Methods

    setupTooltip: function setupTooltip() {
      var _this = this;

      var $anchor = this.$('button.non-visible-tags');

      if ($anchor.length) {
        (function () {
          var component = _this;
          var placement = _this.get('isInCard') || _this.get('isInSearch') ? 'bottom' : 'auto right';

          $anchor.addClass('clickable');
          $anchor.attr('data-html', 'true');
          $anchor.popover({
            placement: placement,
            content: function content() {
              return component.$('.all-tags').html();
            },
            trigger: 'manual'
          });

          $anchor.click(function () {
            var $this = $(this);
            if (!$this.hasClass('list-open')) {
              // Close all tag-list popovers by simulating a click on them
              $('.non-visible-tags.list-open').click();
              $this.addClass('list-open').popover('show');
            } else {
              $this.removeClass('list-open').popover('hide');
            }
          });
        })();
      }
    }
  });
});