define('quizzes-addon/components/reports/assessment/questions/qz-true-false', ['exports', 'quizzes-addon/components/reports/assessment/questions/qz-single-choice'], function (exports, _quizzesAddonComponentsReportsAssessmentQuestionsQzSingleChoice) {

  /**
   * True/False
   *
   * Component responsible for show the true/false answer, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsReportsAssessmentQuestionsQzSingleChoice['default'].extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-true-false']

    // -------------------------------------------------------------------------
    // Properties

    // -------------------------------------------------------------------------
    // Methods
  });
});