define('quizzes-addon/validators/presence-html', ['exports', 'ember-cp-validations/validators/base', 'ember'], function (exports, _emberCpValidationsValidatorsBase, _ember) {
  exports['default'] = _emberCpValidationsValidatorsBase['default'].extend({
    /**
     * @property {Service} I18N service
     */
    i18n: _ember['default'].inject.service(),

    /**
     * Validates string by removing all html tags
     * @param {String} value The string to validate
     * @param {Object} options Validation options with messageKey for i18n
     * @returns {Boolean|String} Validity of answer or error message
     */
    validate: function validate(value, options) {
      var validator = this;
      var cleanValue = _ember['default'].$('<div>').html(value).text().trim();
      return cleanValue ? true : validator.get('i18n').t(options.messageKey).string;
    }
  });
});