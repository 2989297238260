define('quizzes-addon/utils/taxonomy', ['exports', 'quizzes-addon/models/taxonomy/taxonomy-item', 'quizzes-addon/models/taxonomy/browse-item', 'quizzes-addon/config/quizzes-config'], function (exports, _quizzesAddonModelsTaxonomyTaxonomyItem, _quizzesAddonModelsTaxonomyBrowseItem, _quizzesAddonConfigQuizzesConfig) {
  exports.generateTaxonomyTestTree = generateTaxonomyTestTree;
  exports.generateBrowseTestTree = generateBrowseTestTree;
  exports.getCategoryFromSubjectId = getCategoryFromSubjectId;

  /**
   * Generates a taxonomy tree data structure for testing
   * @param {Number} levels - total number of parent/children levels in the tree
   * @param {TaxonomyItem} parent - parent item for all the items created in the current level
   * @param {Number} inc - number by which the number of items in each level will increase
   * @param {Number} currentLevel - current tree level being built (starts at 1)
   * @return {TaxonomyItem[][] ...} - the list of taxonomy items in the first level
   */

  function generateTaxonomyTestTree() {
    var levels = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];
    var parent = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];
    var inc = arguments.length <= 2 || arguments[2] === undefined ? 1 : arguments[2];
    var currentLevel = arguments.length <= 3 || arguments[3] === undefined ? 1 : arguments[3];

    var totalItems = currentLevel * inc;
    var items = [];

    if (currentLevel <= levels) {
      for (var i = 0; i < totalItems; i++) {
        var parentId = parent ? parent.get('id') : '0';
        var parentIdNum = parentId.charAt(parentId.length - 1);
        var itemId = currentLevel + parentIdNum + i;

        var taxonomyItem = _quizzesAddonModelsTaxonomyTaxonomyItem['default'].create({
          id: parentId + '-' + itemId,
          code: 'Code : ' + currentLevel + ' : ' + parentIdNum + ' : ' + i,
          title: 'Item : ' + currentLevel + ' : ' + parentIdNum + ' : ' + i,
          level: currentLevel,
          parent: parent
        });

        generateTaxonomyTestTree(levels, taxonomyItem, inc, currentLevel + 1);
        items.push(taxonomyItem);
      }

      if (parent) {
        // Link all items to parent
        parent.set('children', items);
      }

      return items;
    }
  }

  /**
   * Generates a tree data structure for testing the browse selector (@see gru-browse-selector)
   * @param {Number} levels - total number of parent/children levels in the tree
   * @param {Number} lastLevels - number of sub-levels in the last level of the tree
   * @param {Number} inc - number by which the number of items in each level will increase
   * @return {BrowseItem[][] ...} - the list of browse items in the first level
   */

  function generateBrowseTestTree() {
    var levels = arguments.length <= 0 || arguments[0] === undefined ? 1 : arguments[0];
    var lastLevels = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];
    var inc = arguments.length <= 2 || arguments[2] === undefined ? 1 : arguments[2];

    var startLevel = 1;
    var browseItems = [];

    var taxonomyItems = generateTaxonomyTestTree(levels + lastLevels, null, inc, startLevel);

    taxonomyItems.forEach(function (rootTaxonomyItem) {
      var item = _quizzesAddonModelsTaxonomyBrowseItem['default'].createFromTaxonomyItem(rootTaxonomyItem, levels + lastLevels);
      browseItems.push(item);
    });

    return browseItems;
  }

  /**
   * Gets a category object from a subjectId
   * @param {String} subjectId - The subject id with the format 'CCSS.K12.Math'
   * @return {Object} - An object with the category information
   */

  function getCategoryFromSubjectId(subjectId) {
    var result = _quizzesAddonConfigQuizzesConfig.TAXONOMY_CATEGORIES[0].value; // Default to K12 category
    if (subjectId) {
      var keys = subjectId.split('.');
      if (keys.length > 1) {
        var categoryCode = keys[1];
        for (var i = _quizzesAddonConfigQuizzesConfig.TAXONOMY_CATEGORIES.length - 1; i >= 0; i--) {
          // The second part of the subjectId represents the category
          if (categoryCode === _quizzesAddonConfigQuizzesConfig.TAXONOMY_CATEGORIES[i].apiCode) {
            result = _quizzesAddonConfigQuizzesConfig.TAXONOMY_CATEGORIES[i].value;
            break;
          }
        }
      }
    }
    return result;
  }
});