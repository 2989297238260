define('quizzes-addon/components/player/qz-player', ['exports', 'ember', 'quizzes-addon/mixins/modal', 'quizzes-addon/config/quizzes-config', 'gooru-web/mixins/tenant-settings-mixin', 'quizzes-addon/config/parse-event', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonMixinsModal, _quizzesAddonConfigQuizzesConfig, _gooruWebMixinsTenantSettingsMixin, _quizzesAddonConfigParseEvent, _quizzesAddonUtilsUtils) {
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsModal['default'], _gooruWebMixinsTenantSettingsMixin['default'], {
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @type {CollectionService} collectionService
     * @property {Ember.Service} Service to retrieve a collection
     */
    collectionService: _ember['default'].inject.service('quizzes/collection'),

    /**
     * @type {CollectionService} profileService
     * @property {Ember.Service} Service to retrieve a profile
     */
    profileService: _ember['default'].inject.service('quizzes/profile'),

    /**
     * @type {ContextService} contextService
     * @property {Ember.Service} Service to send context related events
     */
    contextService: _ember['default'].inject.service('quizzes/context'),

    /**
     * @requires service:notifications
     */
    quizzesNotifications: _ember['default'].inject.service('quizzes/notifications'),

    /**
     * @property {activityFeedbackService}
     */
    activityFeedbackService: _ember['default'].inject.service('quizzes/feedback'),

    /**
     * @requires service:i18n
     */
    i18n: _ember['default'].inject.service(),

    /**
     * @requires service:session
     */
    session: _ember['default'].inject.service('session'),

    /**
     * @property {Service} parseEvent service
     */
    parseEventService: _ember['default'].inject.service('quizzes/api-sdk/parse-event'),

    /**
     * @type {AttemptService} attemptService
     * @property {Ember.Service} Service to send context related events
     */
    quizzesAttemptService: _ember['default'].inject.service('quizzes/attempt'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-player'],
    attributeBindings: ['bgStyle:style'],
    bgStyle: _ember['default'].computed('tenantSettingBg', function () {
      return 'background-image: url(' + this.get('tenantSettingBg') + ')';
    }),

    classNameBindings: ['showConfirmation:confirmation'],

    /**
     * @property {Boolean} isShowPullUp
     */
    isShowPullUp: false,

    content: null,

    attemptCountData: null,

    /**
     * @property {Boolean}
     */
    isPublicClass: _ember['default'].computed('class.isPublic', function () {
      return this.get('class.isPublic');
    }),

    collectionObserver: _ember['default'].observer('context', function () {
      var component = this;
      component.fetchAttemptData();
    }),

    resourceObserver: _ember['default'].observer('resource', 'narrationValue', function () {
      this.set('isShowNarrationContainer', this.get('resource.isResource') && this.get('narrationValue.narration'));
    }),

    /**
     * @property {Boolean} isEnableFullScreen
     * Property to enable/disable fullscreen mode
     */
    isEnableFullScreen: _ember['default'].computed('tenantSettingsObj', function () {
      var tenantSetting = this.get('tenantSettingsObj');
      return tenantSetting && tenantSetting.ui_element_visibility_settings && tenantSetting.ui_element_visibility_settings.enable_study_player_fullscreen_mode === true ? tenantSetting.ui_element_visibility_settings.enable_study_player_fullscreen_mode : false;
    }),

    /**
     * Help to trace the comprehension sub question actions
     */
    subEventInprogress: false,

    nextActionResource: null,

    queuedAction: false,

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Action triggered when the user completed a answer
       */
      isNextEnabled: function isNextEnabled(isAnswerCompleted) {
        this.set('isNextEnabled', isAnswerCompleted);
      },
      /**
       * Action triggered when the user closes the content player
       */
      closePlayer: function closePlayer(transitionTo) {
        var component = this;
        var courseId = component.get('course.id') || null;
        component.sendAction('onClosePlayer', transitionTo, courseId);
      },

      /**
       * Action triggered to remix the collection
       * @param content
       */
      remixCollection: function remixCollection() {
        this.sendAction('onRemixCollection');
      },

      /**
       * Triggered when an resource emotion is selected
       * @param {string} emotionScore
       */
      changeEmotion: function changeEmotion(emotionScore) {
        var resourceResult = this.get('resourceResult');
        resourceResult.set('reaction', emotionScore);
      },

      /**
       * Finish from the confirmation
       */
      finishCollection: function finishCollection() {
        this.finishCollection();
      },

      resumeCollection: function resumeCollection() {
        this.set('showFinishConfirmation', false);
      },

      /**
       * When clicking at submit all or end
       */
      submitAll: function submitAll() {
        var component = this;
        component.set('queuedAction', false);
        var subEventInprogress = component.get('subEventInprogress');
        if (subEventInprogress) {
          component.set('queuedAction', true);
          return;
        }
        var collection = component.get('collection');
        var contextResult = component.get('contextResult');
        var resourceResult = component.get('resourceResult');
        var resourcesPlayer = this.get('resourcesPlayer');
        if (!resourceResult.get('resource.isResource')) {
          component.checkPartialScore(resourcesPlayer, resourceResult);
        }
        var userFeedback = component.get('userCategoryFeedback');
        if (userFeedback && userFeedback.length) {
          var learningFeedback = component.getFeedbackObject();
          component.get('activityFeedbackService').submitUserFeedback(learningFeedback);
        }
        component.saveResourceResult(null, contextResult, resourceResult);
        if (collection.get('isAssessment') && !component.get('diagnosticActive')) {
          //open confirmation modal
          component.finishConfirm();
        } else {
          //finishes the last resource
          component.finishCollection();
        }
        var timespent = 0;
        contextResult.resourceResults.forEach(function (item) {
          timespent += item.savedTime;
        });
        var context = {
          resourceId: resourceResult.resource.id,
          ownerId: resourceResult.resource.ownerId,
          title: resourceResult.resource.title,
          type: resourceResult.resource.type,
          timespent: timespent
        };
        component.get('parseEventService').postParseEvent(_quizzesAddonConfigParseEvent.PARSE_EVENTS.CLICK_USAGE_REPORT, context);
      },

      /**
       * Action triggered when the user open the player
       */
      openPlayer: function openPlayer() {
        this.firstResourceOpen();
      },

      onPlayNext: function onPlayNext() {
        this.resetProperty();
        this.sendAction('onPlayNext');
      },

      onEmptyNextPlay: function onEmptyNextPlay() {
        this.sendAction('onEmptyNextPlay');
      },

      onAcceptSuggestion: function onAcceptSuggestion() {
        this.sendAction('onAcceptSuggestion');
      },

      onIgnoreSuggestion: function onIgnoreSuggestion() {
        this.sendAction('onIgnoreSuggestion');
      },

      showTimer: function showTimer() {
        this.toggleProperty('isShowStudyTimer');
      },

      showRelatedContentContainer: function showRelatedContentContainer() {
        this.toggleProperty('isShowRelatedContentContainer');
      },

      showNarrationContainer: function showNarrationContainer() {
        this.toggleProperty('isShowNarrationContainer');
      },

      showFeedbackContainer: function showFeedbackContainer() {
        this.toggleProperty('isShowFeedbackContainer');
      },

      /**
       * Handle onPreviousResource event from qz-player-footer
       * @see components/player/qz-player-footer.js
       * @param {Resource} question
       */
      previousResource: function previousResource(resource) {
        var component = this;
        var next = component.get('collection').prevResource(resource);
        if (next) {
          this.set('isShowPullUp', false);
          _ember['default'].$(window).scrollTop(0);
          component.stopPlayResource(next);
        }
      },

      /**
       * Handle onNextResource event from qz-player-footer
       * @see components/player/qz-player-footer.js
       * @param {Resource} question
       */
      nextResource: function nextResource(resource) {
        var component = this;
        component.set('queuedAction', false);
        var subEventInprogress = component.get('subEventInprogress');
        if (subEventInprogress) {
          component.set('queuedAction', true);
          component.set('nextActionResource', resource);
          return;
        }
        var resourcesPlayer = this.get('resourcesPlayer');
        var resourceResult = this.get('resourceResult');
        if (!resourceResult.get('resource.isResource')) {
          component.checkPartialScore(resourcesPlayer, resourceResult);
        }
        var next = component.get('collection').nextResource(resource);
        component.resetProperty();
        if (next) {
          this.set('isShowPullUp', false);
          _ember['default'].$(window).scrollTop(0);
          component.stopPlayResource(next);
        }
      },

      /**
       * Triggered when a navigator resource is selected
       * @param {Resource} resource
       */
      selectNavigatorItem: function selectNavigatorItem(resource) {
        var component = this;
        component.set('showFinishConfirmation', false);
        component.stopPlayResource(resource);
      },

      /**
       * Handle onSubmitQuestion event from qz-question-viewer
       * @see components/player/qz-question-viewer.js
       * @param {Resource} question
       * @param {QuestionResult} questionResult
       */
      submitQuestion: function submitQuestion(question) {
        var component = this;
        component.moveOrFinish(question);
        component.saveNoteData();
      },
      showPullUp: function showPullUp() {
        var controller = this;
        var currentResource = controller.get('resource');
        if (currentResource.isResource) {
          controller.toggleProperty('isShowPullUp');
          $('.qz-main').removeClass('intial-narration-slide');
          var isShowPullUp = controller.get('isShowPullUp');
          controller.$('.narration').addClass('narration-slide');
          controller.$('.player-narration').css('height', '' + (isShowPullUp ? 'auto' : ''));
          controller.$('.player-narration').css('left', '2%');
          if ($('body .player-narration').hasClass('naration-panel')) {
            $('.player-narration').removeClass('naration-panel');
            controller.set('isShowPullUp', true);
          }
        }
        controller.set('narrationValue', currentResource);
        if (controller.get('isShowPullUp')) {
          var context = {
            title: currentResource.title,
            type: currentResource.type,
            ownerId: currentResource.ownerId,
            narration: currentResource.narration,
            resourceId: currentResource.id
          };
          controller.get('parseEventService').postParseEvent(_quizzesAddonConfigParseEvent.PARSE_EVENTS.CLICK_NARRATION, context);
        }
      },
      onPullUpClose: function onPullUpClose() {
        var controller = this;
        controller.set('isShowPullUp', false);
        controller.$('.player-narration').css('height', 'auto');
        if ($('body').hasClass('narration-slide')) {
          controller.$('.narration').removeClass('narration-slide');
        }
      },

      onCloseResouresContent: function onCloseResouresContent() {
        this.resetProperty();
      },

      onDiagnosticNext: function onDiagnosticNext() {
        var component = this;
        $(document).off('visibilitychange');
        if (component.get('isDiagnosticEnd')) {
          component.set('resource', null);
          component.set('collection.hasResources', false);
          component.sendAction('onDiagnosticNext');
          component.set('isDoneInitiate', false);
          component.set('sendContextFinish', false);
          component.set('categoryLists', null);
          component.set('attemptData', null);
        } else {
          component.send('submitAll');
        }
      },

      actionContent: function actionContent(metadata) {
        var component = this;
        component.set('content', metadata);
      },

      /**
       * Action triggered when toggle screen mode
       */
      onToggleScreen: function onToggleScreen() {
        var component = this;
        component.get('parseEventService').postParseEvent(_quizzesAddonConfigParseEvent.PARSE_EVENTS.CLICK_STUDY_PLAYER_FULLSCREEN);
        component.toggleScreenMode();
      },

      /**
       * Action help to track the sub event for comprehension questions
       */
      onTriggerSubEvent: function onTriggerSubEvent(isFinished) {
        var component = this;
        var resource = component.get('nextActionResource');

        component.set('subEventInprogress', !isFinished);
        if (isFinished && component.get('queuedAction')) {
          if (resource) {
            component.send('nextResource', resource);
          } else {
            component.send('submitAll');
          }
        }
      }
    },

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var classId = this.get('class.id');
      var memberId = this.get('session.userId');
      if (this.get('isContentAttemptSetting') && classId && memberId) {
        this.getAttempt().then(function (attempt) {
          if (attempt && attempt.contentAttempts) {
            _this.set('attemptCountData', attempt.contentAttempts);
          }
          _this.initiateComponent();
        });
      } else {
        this.initiateComponent();
      }
    },

    didDestroyElement: function didDestroyElement() {
      $(document).off('visibilitychange');
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * The attempts played in a context
     * @property {Collection} attempts
     */
    attempts: _ember['default'].computed('contextResult.context.attempts', function () {
      return this.get('contextResult.context.attempts');
    }),

    /**
     * @property {ContextResult} contextResult
     */
    contextResult: null,

    /**
     * The collection presented in this player
     * @property {Collection} collection
     */
    collection: _ember['default'].computed('contextResult.collection', function () {
      return this.get('contextResult.collection');
    }),

    /**
     * The context presented in this player
     * @property {Context} context
     */
    context: _ember['default'].computed('contextResult.context', function () {
      return this.get('contextResult.context');
    }),

    sessionId: _ember['default'].computed('contextResult', function () {
      return this.get('contextResult.sessionId');
    }),

    /**
     * @property {EventContext} eventContext
     */
    eventContext: null,

    /**
     * @property {Boolean} sendContextFinish
     */
    sendContextFinish: false,

    /**
     * Indicates if the player needs to check the attempts
     * @property {boolean}
     */
    notCheckAttempts: true,

    /**
     * Is Assessment
     * @property {boolean}
     */
    isAssessment: _ember['default'].computed('collection.isAssessment', function () {
      return this.get('collection.isAssessment');
    }),

    /**
     * Should resource navigation in the player be disabled?
     * @property {Boolean}
     */
    isNavigationDisabled: _ember['default'].computed('collection', function () {
      return this.get('isAssessment') && !this.get('collection.bidirectional');
    }),

    /**
     * Indicates if the current resource type is resource
     * @property {boolean}
     */
    isResource: _ember['default'].computed('resource', function () {
      var resource = this.get('resource');
      return resource && !resource.get('isQuestion');
    }),

    /**
     * Indicates if the student is playing the collection
     * @property {boolean}
     */
    isStudent: _ember['default'].computed.equal('role', 'student'),

    /**
     * Indicates if the teacher is playing this collection
     * @property {boolean}
     */
    isTeacher: _ember['default'].computed.equal('role', 'teacher'),

    /**
     * Indicates if the current resource type is resource
     * @property {boolean}
     */
    isNotIframeUrl: _ember['default'].computed('resource', function () {
      var resource = this.get('resource');
      return resource && resource.displayGuide;
    }),

    /**
     * URL to redirect to student report
     * @property {String} reportURL
     */
    reportURL: null,

    /**
     * The resource playing
     * @property {Resource} resource
     */
    resource: null,

    /**
     * Query param
     * @property {string} resourceId
     */
    resourceId: null,

    /**
     * Number of events currently running
     * @property {Number} resourceEventCount
     */
    resourceEventCount: 0,

    /**
     * Return the list of resources available to show on the player
     * @property {ResourceResult[]}
     */
    resourcesPlayer: _ember['default'].computed('mapLocation.context', 'collection.resourcesSorted', 'contextResult.sortedResourceResults', function () {
      var availableResources = this.get('collection.resourcesSorted').mapBy('id');
      return this.get('contextResult.sortedResourceResults').filter(function (item) {
        return item.resourceId && availableResources.includes(item.resourceId);
      });
    }),

    /**
     * The resource result playing
     * @property {ResourceResult}
     */
    resourceResult: null,

    /**
     * Indicates the user's role, could be 'student', 'teacher' or null
     * This property is not used for the context-player
     * @property {string}
     */
    role: null,

    /**
     * @property {boolean} indicates if the answer should be saved
     */
    saveEnabled: true, // save only when logged in

    /**
     * Indicates if it should show the back button
     * @property {boolean}
     */
    showBackButton: true,

    /**
     * Indicates if content should be displayed
     * @property {boolean} showContent
     */
    showContent: false,

    /**
     * Indicates if show the assessment confirmation
     * @property {boolean} showConfirmation
     */
    showConfirmation: false,

    /**
     * Indicates if show immediate feedback
     * @property {boolean} showFeedback
     */
    showFeedback: _ember['default'].computed('collection', function () {
      if (this.get('collection.isCollection')) {
        return this.get('isStudyPlayerCollectionShowCorrectAnswer') ? this.get('isStudyPlayerCollectionShowCorrectAnswer') === 'true' : true;
      } else {
        return this.get('collection.immediateFeedback');
      }
    }),

    /**
     * @property {String} It decide to show the back to collection or not.
     */
    showBackToCollection: true,

    /**
     * @property {String} It decide to show the back to course map or not.
     */
    showBackToCourseMap: true,

    /**
     * If the previous button should be shown
     * @property {boolean}
     */
    showPrevious: _ember['default'].computed('resource', 'isNavigationDisabled', function () {
      var resource = this.get('resource');
      return !!this.get('collection').prevResource(resource) && !this.get('isNavigationDisabled');
    }),

    /**
     * If the next button should be shown
     * @property {boolean}
     */
    showNext: _ember['default'].computed('resource', function () {
      var resource = this.get('resource');
      return this.get('collection').nextResource(resource);
    }),

    /**
     * Indicates if the report should be displayed
     * @property {boolean} showReport
     */
    showReport: false,

    /**
     * Query param indicating if it is a collection or assessment
     * @property {string}
     */
    type: null,

    /**
     * If there is a back event to perform
     * @property {function}
     */
    onClosePlayer: null,

    /**
     * Check whether next button is enabled or not
     */
    isNextEnabled: true,

    /**
     * @property {Boolean} isShowActivityFeedback
     * Property to evaluate whether the feedback tab should shown
     */
    isShowActivityFeedback: false,

    /**
     * @property {array[]} feedbackCategory
     * store feedback category list
     */
    feedbackCategory: null,

    /**
     * @property {boolean} isShowFeedback
     * Property to show/hide feedback component
     */
    isShowFeedback: false,

    isCollection: _ember['default'].computed('collection', function () {
      return this.get('collection.isCollection');
    }),

    resourceContentOrder: 0,
    /**
     * @property {boolean} isShowStudyTimer
     * Property to show/hide study timer component
     */
    isShowStudyTimer: false,

    /**
     * @property {boolean} isShowRelatedContentContainer
     * Property to show/hide Related content component
     */
    isShowRelatedContentContainer: false,

    /**
     * @property {boolean} isShowFeedbackContainer
     * Property to show/hide feedback component
     */
    isShowFeedbackContainer: false,

    /**
     * @property {boolean} isShowNarrationContainer
     * Property to show/hide narration component
     */
    isShowNarrationContainer: false,

    isOpenLeftPanal: true,

    /**
     * @property {Boolean} Indicate if the context has more attempts available
     */
    noMoreAttempts: _ember['default'].computed('collection.isAssessment', 'collection.attempts', 'attempts', function () {
      var component = this;
      if (component.get('isContentAttemptSetting')) {
        if (component.get('collection.isAssessment') && component.get('collection.attempts') > 0 && component.get('attempts') && component.get('attempts') >= component.get('collection.attempts')) {
          var isNoMoreAttempt = true;
          if (this.get('attemptCountData') && this.get('attemptCountData.length') && component.get('collection.settings') && component.get('collection.settings.attempts')) {
            var attemptCount = this.get('attemptCountData').findBy('content_id', component.get('collection.id'));
            if (attemptCount && attemptCount.attempt && attemptCount.attempt > component.get('attempts')) {
              isNoMoreAttempt = false;
            }
          } else {
            isNoMoreAttempt = true;
          }
          return isNoMoreAttempt;
        } else {
          return false;
        }
      } else {
        return component.get('collection.isAssessment') && component.get('collection.attempts') > 0 && component.get('attempts') && component.get('attempts') >= component.get('collection.attempts');
      }
    }),

    /**
     * @property {Boolean}
     * Is suggested content
     */
    isSuggestedContent: _ember['default'].computed('pathType', function () {
      var component = this;
      var pathType = component.get('pathType');
      return pathType === 'teacher' || pathType === 'system';
    }),

    diagnosticActive: _ember['default'].computed('mapLocation.context.itemSubType', function () {
      return this.get('mapLocation.context.itemSubType') === 'diagnostic';
    }),

    isDiagnosticEnd: _ember['default'].computed('mapLocation', 'mapLocation.context.status', function () {
      return this.get('mapLocation.context.status') === 'diagnostic-end';
    }),

    isInitiated: false,

    isDoneInitiate: false,

    diagnosticObserver: _ember['default'].observer('isInitiated', function () {
      if (this.get('isInitiated') && !this.get('isDoneInitiate')) {
        this.set('isDoneInitiate', true);
        if (!this.get('isDiagnosticEnd')) {
          this.initiateComponent();
        }
      }
    }),

    // -------------------------------------------------------------------------
    // Methods

    checkPartialScore: function checkPartialScore(resourcesPlayer, resourceResult) {
      var component = this;
      var resourceUseAns = (0, _quizzesAddonUtilsUtils.getObjectsDeepCopy)(resourceResult.get('answer'));
      var resourceCorrectAns = (0, _quizzesAddonUtilsUtils.getObjectsDeepCopy)(resourceResult.get('resource.correctAnswer'));
      var resourceType = resourceResult.get('resource.type');
      var isCorrect = JSON.stringify(resourceUseAns).toLowerCase() === JSON.stringify(resourceCorrectAns).toLowerCase();
      var isPartialCorrect = false;
      if (!resourceCorrectAns) {
        isCorrect = true;
      }
      if (!isCorrect) {
        if (resourceType === 'serp_lang_identify_base_word' || resourceType === 'serp_lang_vowel_teams' || resourceType === 'serp_lang_counting_syllables' || resourceType === 'serp_sorting' || resourceType === 'serp_identify_vowel_sound_activity_question') {
          resourceUseAns = component.convertToObject(resourceUseAns);
          resourceCorrectAns = component.convertToObject(resourceCorrectAns);
        }
        for (var i = 0; i < resourceUseAns.length; i++) {
          if (resourceType === 'text_entry' || resourceType === 'drag_and_drop' || resourceType === 'match_the_following_question') {
            if (resourceUseAns[i] && resourceUseAns[i].value && resourceCorrectAns[i] && resourceCorrectAns[i].value) {
              resourceUseAns[i].isCorrect = resourceUseAns[i].value === resourceCorrectAns[i].value;
            } else {
              resourceUseAns[i].isCorrect = false;
            }
          } else if (resourceType === 'serp_lang_identify_base_word' || resourceType === 'serp_lang_vowel_teams' || resourceType === 'serp_lang_counting_syllables' || resourceType === 'serp_identify_vowel_sound_activity_question') {
            var ItemIndex;

            (function () {
              var userArray = resourceUseAns[i].value;
              var correctArray = resourceCorrectAns[i].value;

              userArray.forEach(function (item) {
                if (resourceType === 'serp_lang_counting_syllables') {
                  ItemIndex = correctArray.findIndex(function (b) {
                    return b.text.toLowerCase() === item.text.toLowerCase() && b.crossposition === item.crossPosition && b.inputvalue === item.inputValue;
                  });
                } else if (resourceType === 'serp_lang_identify_base_word') {
                  ItemIndex = correctArray.findIndex(function (b) {
                    return b.word_text.toLowerCase() === item.word_text.toLowerCase() && b.start === item.start && b.end === item.end;
                  });
                } else if (resourceType === 'serp_lang_vowel_teams') {
                  ItemIndex = correctArray.findIndex(function (b) {
                    return b.text.toLowerCase() === item.text.toLowerCase() && b.start === item.start && b.end === item.end && b.crosspositions === item.crossPositions;
                  });
                } else if (resourceType === 'serp_identify_vowel_sound_activity_question') {
                  ItemIndex = correctArray.findIndex(function (b) {
                    return b.text.toLowerCase() === item.text.toLowerCase() && b.crossposition === item.crossPosition && b.iscross === item.isCross && b.isshort === item.isShort;
                  });
                }
                if (ItemIndex !== -1) {
                  item.isCorrect = true;
                } else {
                  item.isCorrect = false;
                }
              });
              var userCorrectAnw = userArray.filterBy('isCorrect');
              if (correctArray.length === userCorrectAnw.length) {
                resourceUseAns[i].isCorrect = true;
              } else if (userCorrectAnw.length !== 0 && correctArray.length > userCorrectAnw.length) {
                resourceUseAns[i].isPartialCorrect = true;
              } else {
                resourceUseAns[i].isCorrect = false;
              }
            })();
          } else if (resourceType === 'serp_sorting') {
            resourceUseAns[i].isCorrect = resourceUseAns[i].value[0].answer_text.toLowerCase() === resourceCorrectAns[i].value[0].answer_text.toLowerCase() && resourceUseAns[i].value[0].answer_type.toLowerCase() === resourceCorrectAns[i].value[0].answer_type.toLowerCase();
          } else {
            var correctAnswer = resourceCorrectAns.filterBy('value', resourceUseAns[i].value);
            if (correctAnswer.length) {
              resourceUseAns[i].isCorrect = true;
            } else {
              resourceUseAns[i].isCorrect = false;
            }
          }
        }
        var userCorrectAnw = resourceUseAns.filterBy('isCorrect');
        var userPartialAnw = resourceUseAns.filterBy('isPartialCorrect');
        if (userCorrectAnw.length === resourceCorrectAns.length) {
          if (resourceCorrectAns.length === resourceUseAns.length) {
            isCorrect = true;
          } else {
            isPartialCorrect = true;
          }
        } else if (userCorrectAnw.length > 0 || userPartialAnw.length) {
          isPartialCorrect = true;
        } else {
          if (resourceType === 'multiple_choice' && resourceUseAns.length === 0) {
            isPartialCorrect = true;
          } else {
            isCorrect = false;
          }
        }
      }
      var lastPlayResource = resourcesPlayer.findBy('resourceId', resourceResult.get('resourceId'));
      lastPlayResource.set('isCorrect', isCorrect);
      lastPlayResource.set('isPartialCorrect', isPartialCorrect);
    },

    convertToObject: function convertToObject(array) {
      return array.map(function (item) {
        item.value = JSON.parse(item.value);
        return item;
      });
    },

    fetchAttemptData: function fetchAttemptData() {
      var component = this;
      var contextId = component.get('context') ? component.get('context.id') : component.get('contextResult.contextId');
      var profileId = component.get('session.userData.gooruUId');
      component.get('quizzesAttemptService').getAttemptIds(contextId, profileId).then(function (attemptIds) {
        return !attemptIds || !attemptIds.length ? {} : component.get('quizzesAttemptService').getAttemptData(attemptIds[attemptIds.length - 1]);
      }).then(function (attemptData) {
        component.set('attemptData', attemptData);
      });
    },
    getAttempt: function getAttempt() {
      var component = this;
      var classId = component.get('class.id');
      var memberId = component.get('session.userId');
      if (classId && memberId) {
        return component.get('collectionService').getAttempt(classId, memberId);
      }
    },

    /**
     * Saves an assessment result
     */
    finishCollection: function finishCollection() {
      // Disable navigation so resource events are not called after finishing
      this.set('isNavigationDisabled', true);
      this.set('sendContextFinish', true);
    },

    /**
     * Opens the confirmation dialog to finish the assessment
     */
    finishConfirm: function finishConfirm() {
      this.resetProperty();
      this.set('showFinishConfirmation', true);
    },

    /**
     * Moves to the next resource or finishes the collection
     * @param {Resource} resource
     */
    moveOrFinish: function moveOrFinish(resource) {
      var component = this;
      var resourcesPlayer = this.get('resourcesPlayer');
      var resourceResult = this.get('resourceResult');
      if (!resourceResult.get('resource.isResource')) {
        component.checkPartialScore(resourcesPlayer, resourceResult);
      }
      var next = component.get('collection').nextResource(resource);
      if (next) {
        _ember['default'].$(window).scrollTop(0);
        component.stopPlayResource(next);
      } else {
        if (component.get('diagnosticActive')) {
          $(document).off('visibilitychange');
          if (component.get('isDiagnosticEnd')) {
            component.set('resource', null);
            component.sendAction('onDiagnosticNext');
            component.set('isDoneInitiate', false);
            component.set('sendContextFinish', false);
            component.set('categoryLists', null);
            component.set('attemptData', null);
          } else {
            component.send('submitAll');
          }
        } else {
          var contextResult = component.get('contextResult');
          var _resourceResult = component.get('resourceResult');
          return component.saveResourceResult(null, contextResult, _resourceResult).then(function () {
            return component.finishConfirm();
          });
        }
      }
    },

    /**
     * Moves to resource
     * @param {Resource} resource
     */
    stopPlayResource: function stopPlayResource(resource, firstTime) {
      var component = this;
      var contextResult = component.get('contextResult');
      var resourceResult = component.get('resourceResult');
      var resourceId = resource.get('id');
      var collection = component.get('collection');
      var categoryLists = component.get('categoryLists');
      var type = resource.get('isResource') ? _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.RESOURCE : _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.QUESTION;
      var resourceCategory = categoryLists.get(type + 's');
      component.set('isShowActivityFeedback', true);
      if (resourceCategory && resourceCategory.length) {
        component.set('feedbackCategory', resourceCategory.sortBy('feedbackTypeId'));
        component.set('format', type);
      } else {
        component.set('feedbackCategory', null);
      }
      if (resource.get('isResource')) {
        component.set('isNextEnabled', true);
      }
      component.getOwnerProfile(resource, collection).then(function () {
        if (resourceResult) {
          resourceResult.set('skipped', false);
        }
        return component.saveResourceResult(resourceId, contextResult, resourceResult, firstTime).then(function () {
          _ember['default'].run(function () {
            return component.set('resource', null);
          });
          resourceResult = contextResult.getResultByResourceId(resourceId);
          resourceResult.set('startTime', new Date().getTime());
          component.setProperties({
            showReport: false,
            resourceId: resourceId,
            resource: resource,
            resourceResult: resourceResult
          }); //saves the resource status
        }, function () {
          var message = component.get('i18n').t('common.errors.getting-next-resource').string;
          component.get('quizzesNotifications').error(message);
        });
      });
      if (resource.isResource && resource.narration) {
        _ember['default'].run.later(function () {
          $('.qz-main').addClass('intial-narration-slide');
          var narrationDiscription = resource.narration;
          var snippet = document.createElement('div');
          snippet.innerHTML = narrationDiscription;
          var links = snippet.getElementsByTagName('a');
          for (var i = 0; i < links.length; i++) {
            links[i].setAttribute('target', '_blank');
          }
          resource.set('narration', snippet.innerHTML);
          component.set('isShowPullUp', true);
        });
      }
      component.set('narrationValue', resource);
    },

    /**
     * When the submission is complete
     */
    onFinish: null,

    /**
     * Saves the resource result and moves to the next
     * @param resourceId
     * @param contextResult
     * @param resourceResult
     * @returns {Promise.<boolean>}
     */
    saveResourceResult: function saveResourceResult(resourceId, contextResult, resourceResult, firstTime) {
      var component = this;
      var promise = _ember['default'].RSVP.resolve();
      var save = component.get('saveEnabled');
      if (save) {
        (function () {
          var contextId = contextResult.get('contextId');
          var eventContext = component.get('eventContext');
          component.incrementProperty('resourceEventCount');
          if (!resourceResult) {
            resourceResult = contextResult.getResultByResourceId(resourceId);

            resourceResult.set('startTime', new Date().getTime());
          }
          if (firstTime && !contextResult.hasStarted) {
            component.setLastPlayedResource(resourceId, contextId, eventContext, contextResult);
            component.get('contextService').startPlayResource(resourceId, contextId, eventContext).then();
          } else if (firstTime && contextResult.hasStarted) {
            component.setLastPlayedResource(resourceId, contextId, eventContext, contextResult);
            component.get('contextService').resumePlayResource(resourceId, contextId, eventContext).then();
          } else {
            resourceResult.set('stopTime', new Date().getTime());
          }
          promise = firstTime ? _ember['default'].RSVP.resolve() : component.get('contextService').stopPlayResource(resourceResult.resourceId, contextId, resourceResult, eventContext)['catch'](function () {
            return component.get('contextService').stopPlayResource(resourceResult.resourceId, contextId, resourceResult, eventContext);
          }).then(function (result) {
            if (resourceId) {
              component.setLastPlayedResource(resourceId, contextId, eventContext, contextResult);
              component.get('contextService').startPlayResource(resourceId, contextId, eventContext).then();
            }
            resourceResult.set('score', result.score);
          });
          promise = promise.then(function () {
            return component.decrementProperty('resourceEventCount');
          }, function () {
            component.decrementProperty('resourceEventCount');
            component.saveNoteData();
            return _ember['default'].RSVP.reject();
          });
        })();
      }
      return promise;
    },

    /**
     * Starts the assessment or collection
     */
    startAssessment: function startAssessment() {
      var component = this;
      component.saveNoteData();
      var collection = component.get('collection');
      var contextResult = component.get('contextResult');
      var hasResources = collection.get('hasResources');
      var resource = null;

      component.set('showContent', true);
      var resourceResults = component.get('contextResult.resourceResults');
      if (hasResources) {
        resource = contextResult.get('currentResource');
        if (component.get('resourceId')) {
          //if has a resource id as query param
          resource = collection.getResourceById(component.get('resourceId'));
        }
        if (!resource) {
          resource = collection.get('resources').objectAt(0);
        }
      }
      if (resource && resource.get('isScientificFIB') || resource && resource.get('isScientificFreeResponse')) {
        if (!collection.get('isCollection')) {
          component.get('collectionService').getAssessment(collection.get('id')).then(function (rubricResult) {
            var assessmentInfo = rubricResult.get('content');
            var result = resourceResults.map(function (resource) {
              var activeResource = assessmentInfo.findBy('id', resource.get('resourceId'));
              resource.set('rubric', activeResource);
              return resource;
            });
            component.set('contextResult.resourceResults', result);
          });
        } else {
          component.get('collectionService').getCollection(collection.get('id')).then(function (rubricResult) {
            var assessmentInfo = rubricResult.get('content');
            var result = resourceResults.map(function (resource) {
              var activeResource = assessmentInfo.findBy('id', resource.get('resourceId'));
              resource.set('rubric', activeResource);
              return resource;
            });
            component.set('contextResult.resourceResults', result);
          });
        }
      }
      component.saveNoteData();
      if (resource) {
        component.stopPlayResource(resource, true);
      }
    },
    /**
     * Find owner profile if the resource has narration or is a link out resource
     */
    getOwnerProfile: function getOwnerProfile(resource, collection) {
      var component = this;
      var promise = _ember['default'].RSVP.resolve();
      var resourceId = resource.ownerId;
      var collectionId = collection.ownerId;
      if (resource.get('narration') || resource.get('displayGuide')) {
        var profiles = [resourceId, collectionId];
        promise = component.get('profileService').readProfiles(profiles).then(function (result) {
          resource.set('owner', result[resourceId]);
          collection.set('avatarUrl', result[collectionId] ? result[collectionId].avatarUrl : null);
          collection.set('author', result[collectionId] ? result[collectionId].username : null);
        });
      }
      return promise;
    },

    /**
     * @function fetchActivityFeedbackCateory
     * Method to fetch learning activity feedback
     */

    fetchActivityFeedbackCateory: function fetchActivityFeedbackCateory() {
      var component = this;
      var role = component.get('session.role') ? component.get('session.role') : _quizzesAddonConfigQuizzesConfig.ROLES.STUDENT;
      var userCategoryId = _quizzesAddonConfigQuizzesConfig.FEEDBACK_USER_CATEGORY['' + role];
      return component.get('activityFeedbackService').getFeedbackCategory(userCategoryId).then(function (categoryLists) {
        component.set('categoryLists', categoryLists);
      });
    },

    /**
     * @function getFeedbackObject
     * Method to return feedback objective
     */

    getFeedbackObject: function getFeedbackObject() {
      var component = this;
      var userId = component.get('session.userId');
      var role = component.get('session.role') ? component.get('session.role') : _quizzesAddonConfigQuizzesConfig.ROLES.STUDENT;
      var userCategoryId = _quizzesAddonConfigQuizzesConfig.FEEDBACK_USER_CATEGORY['' + role];
      var userFeedback = _ember['default'].A([]);
      var categoryLists = component.get('userCategoryFeedback');
      var resource = component.get('resource');
      categoryLists.map(function (category) {
        var feedbackObj = {
          feeback_category_id: category.categoryId
        };
        if (category.feedbackTypeId === _quizzesAddonConfigQuizzesConfig.FEEDBACK_RATING_TYPE.QUANTITATIVE) {
          feedbackObj.user_feedback_quantitative = category.rating;
        } else if (category.feedbackTypeId === _quizzesAddonConfigQuizzesConfig.FEEDBACK_RATING_TYPE.BOTH) {
          feedbackObj.user_feedback_qualitative = category.comments;
        } else if (category.feedbackTypeId === _quizzesAddonConfigQuizzesConfig.FEEDBACK_RATING_TYPE.QUALITATIVE) {
          feedbackObj.user_feedback_qualitative = category.quality;
        }
        userFeedback.pushObject(feedbackObj);
      });
      var userFeedbackObj = {
        content_id: resource.get('id'),
        content_type: resource.get('isResource') ? _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.RESOURCE : _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.QUESTION,
        user_category_id: userCategoryId,
        user_feedbacks: userFeedback,
        user_id: userId
      };
      return userFeedbackObj;
    },

    setLastPlayedResource: function setLastPlayedResource(resourceId, contextId, eventContext, contextResult) {
      var resourceResult = contextResult.getResultByResourceId(resourceId);
      var lastPlayedResource = {
        resourceId: resourceId,
        contextId: contextId,
        eventContext: eventContext,
        resourceResult: resourceResult
      };
      resourceResult.set('startTime', new Date().getTime());
      window.lastPlayedResource = lastPlayedResource;
    },

    // -------------------------------------------------------------------------
    // Observers
    /**
     * Observes to send the finish event when the event count reaches zero
     */
    onEventCountChange: (function () {
      var component = this;
      if (component.get('resourceEventCount') === 0 && component.get('sendContextFinish')) {
        var contextResult = component.get('contextResult');
        var contextId = contextResult.get('contextId');
        var eventContext = component.get('eventContext');
        var promise = !component.get('saveEnabled') ? _ember['default'].RSVP.resolve() : component.get('contextService').finishContext(contextId, eventContext);
        promise.then(function () {
          if (component.get('diagnosticActive')) {
            component.set('resource', null);
            component.sendAction('onDiagnosticNext');
            component.set('isDoneInitiate', false);
            component.set('sendContextFinish', false);
            component.set('categoryLists', null);
            component.set('attemptData', null);
          } else {
            component.get('onFinish') && component.sendAction('onFinish');
          }
          window.lastPlayedResource = null;
        });
      }
    }).observes('resourceEventCount', 'sendContextFinish'),

    // -------------------------------------------------------------------------
    // Handle the custom events.

    intialize: function intialize() {
      window.lastPlayedResource = null;
      var component = this;

      var showStudyPlayerLeftPanelOnInitialLoad = component.get('showStudyPlayerLeftPanelOnInitialLoad');
      if (showStudyPlayerLeftPanelOnInitialLoad !== undefined) {
        component.set('isOpenLeftPanal', showStudyPlayerLeftPanelOnInitialLoad);
      }

      $(document).on('visibilitychange', function () {
        component.visibilityChange(component);
      });
    },

    visibilityChange: function visibilityChange(component) {
      if (window.lastPlayedResource && window.lastPlayedResource.resourceId) {
        var context = window.lastPlayedResource;
        var contextResult = component.get('contextResult');
        var resourceResult = contextResult.getResultByResourceId(context.resourceId);
        if (document.hidden) {
          resourceResult.set('stopTime', new Date().getTime());
          window.lastPlayedResource.resourceResult = resourceResult;
          component.get('contextService').pausePlayResource(context.resourceId, context.contextId, resourceResult, context.eventContext).then();
        } else {
          resourceResult.set('startTime', new Date().getTime());
          window.lastPlayedResource.resourceResult = resourceResult;
          component.get('contextService').resumePlayResource(context.resourceId, context.contextId, context.eventContext).then();
        }
      }
    },

    resetProperty: function resetProperty() {
      this.set('isShowFeedbackContainer', false);
      this.set('isShowNarrationContainer', false);
      this.set('isShowRelatedContentContainer', false);
      this.set('isShowStudyTimer', this.get('isShowStudyTimer'));
      this.saveNoteData();
    },

    firstResourceOpen: function firstResourceOpen() {
      var component = this;
      var startContext = component.get('startContextFunction');
      startContext().then(function (contextResult) {
        contextResult.merge(component.get('collection'));
        component.set('contextResult', contextResult);
        component.set('showConfirmation', false);
        component.startAssessment();
        var context = {
          collectionId: contextResult.collectionId,
          contextId: contextResult.contextId,
          currentResourceId: contextResult.currentResourceId
        };
        component.get('parseEventService').postParseEvent(_quizzesAddonConfigParseEvent.PARSE_EVENTS.START, context);
      });
    },

    initiateComponent: function initiateComponent() {
      var _this2 = this;

      var component = this;
      this.intialize();
      this.fetchAttemptData();
      this.set('isInitiated', false);
      this.fetchActivityFeedbackCateory().then(function () {
        component.saveNoteData();
        if (_this2.get('isAnonymous') || _this2.get('isTeacher') || !_this2.get('isStudyPlayer')) {
          _this2.set('showConfirmation', false);
          _this2.startAssessment();
        } else {
          if (!_this2.get('noMoreAttempts')) {
            _this2.set('showConfirmation', false);
            _this2.firstResourceOpen();
          }
        }
      });
    },

    /**
     * @function toggleScreenMode
     * Method to toggle fullscreen mode
     */
    toggleScreenMode: function toggleScreenMode() {
      var component = this;
      _ember['default'].$('body').toggleClass('fullscreen');
      component.toggleProperty('isFullScreen');
      component.set('isOpenLeftPanal', !component.get('isFullScreen'));
    },

    saveNoteData: function saveNoteData() {
      var contextResult = this.get('contextResult');
      var eventContext = this.get('eventContext');
      var collection = this.get('collection');
      var classDetail = this.get('class');
      window.sourceDetailsNoteTool = {};
      window.sourceDetailsNoteTool.class_id = classDetail ? classDetail.get('id') : null;
      window.sourceDetailsNoteTool.course_id = classDetail ? classDetail.get('courseId') : null;
      window.sourceDetailsNoteTool.collection_id = contextResult ? contextResult.get('collectionId') : null;
      window.sourceDetailsNoteTool.resource_id = contextResult ? contextResult.get('currentResourceId') : null;
      window.sourceDetailsNoteTool.session_id = contextResult ? contextResult.get('sessionId') : null;
      window.sourceDetailsNoteTool.source = eventContext ? eventContext.get('source') : null;
      window.sourceDetailsNoteTool.tx_comp_code = collection.standards ? this.getTxCode(collection.standards) : [];
    },

    getTxCode: function getTxCode(list) {
      var txArray = [];
      if (list && list.length > 0) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = list[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var iterator = _step.value;

            txArray.push(iterator.id);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }
      return txArray;
    }
  });
});