define('quizzes-addon/components/gru-assignments-detail', ['exports', 'ember', 'quizzes-addon/mixins/modal', 'quizzes-addon/models/profile/profile', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonMixinsModal, _quizzesAddonModelsProfileProfile, _quizzesAddonUtilsUtils) {
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsModal['default'], {
    // -------------------------------------------------------------------------
    // Dependencies
    /**
     * @property {Service} I18N service
     */
    i18n: _ember['default'].inject.service(),

    /**
     * @property {Service} Context service
     */
    contextService: _ember['default'].inject.service('quizzes/context'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['gru-assignments-detail'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Open add student modal
       */
      addStudent: function addStudent() {
        var assigned = this.get('assignment.assignees');
        var assignedStudents = [];
        var students = [];
        if (assigned) {
          assignedStudents = assigned.getEach('id');
          if (this.get('studentList')) {
            students = this.get('studentList').map(function (student) {
              var studentObject = _quizzesAddonModelsProfileProfile['default'].create(student);
              studentObject.set('isAssigned', assignedStudents.includes(student.id));
              return studentObject;
            });
          }
        }
        this.set('students', students);

        var model = {
          students: this.get('students'),
          assignment: this.get('assignment'),
          width: '95%'
        };
        this.actions.showModal.call(this, 'gru-assign-student-modal', model, null, null, null, false);
      },

      /**
       * Redirect to real time
       */
      openRealTime: function openRealTime() {
        if (this.get('realTimeURL')) {
          var url = this.get('realTimeURL').replace('{context-id}', this.get('assignment.id'));
          window.location.href = url;
        } else {
          this.get('router').transitionTo('reports.context', this.get('assignment.id'));
        }
      },
      /**
       * Preview player
       */
      previewPlayer: function previewPlayer(assignment) {
        var _this = this;

        var playerURL = this.get('playerURL');
        assignment.set('classId', null);
        this.get('contextService').createContext(assignment).then(function (_ref) {
          var id = _ref.id;

          if (playerURL) {
            var url = playerURL.replace('{context-id}', id);
            window.location.href = url;
          } else {
            _this.get('router').transitionTo('player', id);
          }
        });
      },
      /**
       * Open player
       */
      openPlayer: function openPlayer(assignment) {
        if (this.get('playerURL')) {
          var url = this.get('playerURL').replace('{context-id}', assignment.get('id'));
          window.location.href = url;
        } else {
          this.get('router').transitionTo('player', assignment.get('id'));
        }
      },
      /**
       * View Report
       */
      viewReport: function viewReport(assignment) {
        _ember['default'].Logger.log('View Report:', assignment);
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Assignment to show
     */
    assignment: null,

    /**
     *Indicate if the assignment has attempts left
     */
    hasAttempts: _ember['default'].computed('assignment.attempts', 'assignment.totalAttempts', function () {
      return this.get('assignment.totalAttempts') - this.get('assignment.attempts') > 0;
    }),

    /**
     * @property {Boolean} Indicate if is a teacher view
     * @see gru-assignments-list and assignments.js route
     */
    isTeacher: false,

    /**
     * @property {String} scoreStyle style safe string for the score span
     */
    scoreStyle: _ember['default'].computed('assignment.score', function () {
      var color = (0, _quizzesAddonUtilsUtils.getGradeColor)(this.get('assignment.score') || 'NA');
      return _ember['default'].String.htmlSafe('background-color: ' + color);
    }),

    /**
     * @property {Array} Students list
     */
    students: null
  });
});