define('quizzes-addon/utils/question-result', ['exports', 'ember', 'quizzes-addon/utils/math'], function (exports, _ember, _quizzesAddonUtilsMath) {
  exports.stats = stats;
  exports.averageReaction = averageReaction;
  exports.correctAnswers = correctAnswers;
  exports.correctPercentage = correctPercentage;
  exports.totalTimeSpent = totalTimeSpent;
  exports.totalCompleted = totalCompleted;
  exports.totalNotStarted = totalNotStarted;
  exports.completedResults = completedResults;
  exports.answeredResults = answeredResults;
  exports.sortResults = sortResults;
  exports.userAnswers = userAnswers;

  /**
   * Utility methods to handle stats for QuestionResult instances
   */

  /**
   * Returns stats for a set of question results
   * @param {QuestionResult[]} questionResults
   * @returns {{ total: number, correct: number, incorrect: number, skipped: number, notStarted: number}}
   */

  function stats(questionResults) {
    var total = questionResults.length;
    var correct = 0;
    var incorrect = 0;
    var skipped = 0;
    var started = 0;
    var timeSpent = 0;
    var reactions = [];
    var isOpenEnded = false;
    var openEndedPercentageTotal = 0;
    questionResults.forEach(function (item) {
      if (_ember['default'].get(item, 'correct') && _ember['default'].get(item, 'correct') !== 'incorrect') {
        correct += _ember['default'].get(item, 'correct') ? 1 : 0;
      } else {
        incorrect += _ember['default'].get(item, 'incorrect') ? 1 : 0;
      }

      skipped += _ember['default'].get(item, 'skipped') ? 1 : 0;
      started += _ember['default'].get(item, 'started') ? 1 : 0;
      timeSpent += _ember['default'].get(item, 'timeSpent');
      isOpenEnded = _ember['default'].get(item, 'resource.type') === 'extended_text';
      if (_ember['default'].get(item, 'reaction')) {
        reactions.push(_ember['default'].get(item, 'reaction'));
      }
    });

    var notStarted = total - started;
    var completed = correct + incorrect; //incorrect should include skipped ones
    //OE question should not be calculated
    if (isOpenEnded) {
      correct = incorrect = 0;
      //show full blue panel bar
      openEndedPercentageTotal = 100;
    }

    return _ember['default'].Object.create({
      total: total,
      totalCorrect: correct,
      correctPercentage: completed ? (0, _quizzesAddonUtilsMath.roundFloat)(correct / completed * 100) : null,
      correctPercentageFromTotal: (0, _quizzesAddonUtilsMath.roundFloat)(correct / total * 100, 1), //percentage including not started
      totalIncorrect: incorrect,
      incorrectPercentage: completed ? (0, _quizzesAddonUtilsMath.roundFloat)(incorrect / completed * 100) : null,
      incorrectPercentageFromTotal: (0, _quizzesAddonUtilsMath.roundFloat)(incorrect / total * 100, 1), //percentage including not started
      totalSkipped: skipped,
      skippedPercentage: (0, _quizzesAddonUtilsMath.roundFloat)(skipped / total * 100),
      totalNotStarted: notStarted,
      notStartedPercentage: (0, _quizzesAddonUtilsMath.roundFloat)(notStarted / total * 100),
      totalCompleted: completed,
      completedPercentage: (0, _quizzesAddonUtilsMath.roundFloat)(completed / total * 100),
      averageReaction: reactions.length ? (0, _quizzesAddonUtilsMath.roundFloat)((0, _quizzesAddonUtilsMath.average)(reactions)) : null,
      totalTimeSpent: timeSpent,
      isOpenEnded: isOpenEnded,
      openEndedPercentageTotal: openEndedPercentageTotal
    });
  }

  /**
   * Average user reaction to the questions in the assessment
   * @param {QuestionResult[]} questionsResults
   * @prop {Number} averageReaction
   */

  function averageReaction(questionsResults) {
    var totals = stats(questionsResults);
    return totals.get('averageReaction');
  }

  /**
   * Number of questions answered correctly in this attempt
   * @param {QuestionResult[]} questionsResults
   * @prop {Number}
   */

  function correctAnswers(questionsResults) {
    var totals = stats(questionsResults);
    return totals.get('totalCorrect');
  }

  /**
   * Percentage of correct answers vs. the total number of questions
   * @param {QuestionResult[]} questionsResults
   * @param {boolean} includeAll, when true it calculates the percentage based on all questions, not only the answered
   * @prop {Number}
   */

  function correctPercentage(questionsResults) {
    var includeAll = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

    var totals = stats(questionsResults);
    return includeAll ? totals.get('correctPercentageFromTotal') : totals.get('correctPercentage');
  }

  /**
   * Total number of seconds spent completing the current attempt
   * @param {QuestionResult[]} questionsResults
   * @prop {Number}
   */

  function totalTimeSpent(questionsResults) {
    var totals = stats(questionsResults);
    return totals.get('totalTimeSpent');
  }

  /**
   * Total number of results completed
   * @param {QuestionResult[]} questionsResults
   * @prop {number}
   */

  function totalCompleted(questionsResults) {
    var totals = stats(questionsResults);
    return totals.get('totalCompleted');
  }

  /**
   * Total number of results completed
   * @param {QuestionResult[]} questionsResults
   * @prop {number}
   */

  function totalNotStarted(questionsResults) {
    var totals = stats(questionsResults);
    return totals.get('totalNotStarted');
  }

  /**
   * Returns only completed results
   * @param {QuestionResult[]} questionsResults
   * @prop {QuestionResult[]}
   */

  function completedResults(questionsResults) {
    return questionsResults.filter(function (questionResult) {
      return questionResult.get('completed');
    });
  }

  /**
   * Returns only answered results
   * @param {QuestionResult[]} questionsResults
   * @prop {QuestionResult[]}
   */

  function answeredResults(questionsResults) {
    return questionsResults.filter(function (questionResult) {
      return questionResult.get('started');
    });
  }

  /**
   * Sort results by submittedAt field, ascending
   * @param {QuestionResult[]} questionsResults
   * @prop {QuestionResult[]}
   */

  function sortResults(questionsResults) {
    return questionsResults.sortBy('submittedAt');
  }

  /**
   * Returns valid user answers
   * @param {QuestionResult[]} questionResults
   * @return {*} user answers
   */

  function userAnswers(questionResults) {
    return questionResults.map(function (questionResult) {
      return questionResult.get('answer');
    });
  }
});