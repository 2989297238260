define('quizzes-addon/components/gru-slide-up-menu', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['gru-slide-up-menu'],

    actions: {
      /**
       * Close mobile menu
       */
      closeMenu: function closeMenu() {
        this.set('visible', false);
      },
      /**
       * Send option action to his parent
       */
      sendOptionAction: function sendOptionAction(option) {
        this.sendAction(option.action, option.object);
      }
    },
    // -------------------------------------------------------------------------
    // Events
    /**
     * DidInsertElement ember event
     */
    didInsertElement: function didInsertElement() {
      !this.get('visible') ? $('.gru-slide-up-menu').addClass('hide') : '';
    },

    setVisible: _ember['default'].observer('visible', function () {
      var $menu = $('.gru-slide-up-menu .slide-menu');
      var component = this;
      if (component.get('visible')) {
        component.toggleVisibility();
        component.set('menuHeight', '-' + $menu.innerHeight() + 'px');
        $menu.css('bottom', component.get('menuHeight')).animate({
          bottom: '0'
        }, 600);
      } else {
        $menu.animate({
          bottom: component.get('menuHeight')
        }, 600, function () {
          component.toggleVisibility();
        });
      }
    }),
    // -------------------------------------------------------------------------
    // Properties
    /**
     * Menu height on pixels
     */
    menuHeight: 0,

    /**
     * Indicate if the menu is visible
     */
    visible: false,

    // -------------------------------------------------------------------------
    // Methods
    toggleVisibility: function toggleVisibility() {
      $('.gru-slide-up-menu').toggleClass('hide');
      $('html,body').toggleClass('disabled-screen');
    }
  });
});