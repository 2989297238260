define('quizzes-addon/helpers/course-sections-prefix', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {

  /**Show the Unit, Lesson, Assessment and Collection label correctly,
   *  this is showing the letter and number prior the name.
   *
   * @example
   *
   * <span>{{course-sections-prefix title="My Unit" type="unit" index=1 complete=false}}</span>
   * @see /app/templates/components
   *
   * @param title {String}
   * @param index {Number}
   * @param trype {String} "unit","lesson","collection" and "assessment"
   * @returns {String}
   */
  exports['default'] = _ember['default'].Helper.extend({
    i18n: _ember['default'].inject.service('i18n'),

    compute: function compute(params, _ref) {
      var index = _ref.index;
      var type = _ref.type;
      var longName = _ref.longName;

      var i18n = this.get('i18n');
      return (0, _quizzesAddonUtilsUtils.courseSectionsPrefix)(index, type, i18n, longName);
    }
  });
});