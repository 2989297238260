define('quizzes-addon/components/player/resources/qz-url-resource', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Url resource component
   *
   * Component responsible for controlling the logic and appearance of the url resource type
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-viewer.js
   * @augments Ember/Component
   **/
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-url-resource']

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods
  });
});