define('quizzes-addon/mixins/token', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @type {ConfigurationService} configurationService
     * @property {Ember.Service} Service to retrieve configuration
     */
    quizzesConfigurationService: _ember['default'].inject.service('quizzes/configuration'),

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {Object} headers Token header for API calls
     */
    headers: _ember['default'].computed('token', function () {
      return {
        Authorization: 'Token ' + this.get('token')
      };
    }),

    /**
     * @property {String} token access token used for authorization on ajax requests
     */
    token: _ember['default'].computed.alias('quizzesConfigurationService.configuration.properties.token')
  });
});