define('quizzes-addon/components/gru-view-layout-picker', ['exports', 'ember', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig) {

  /**
   * View Layout Picker
   *
   * Component responsible for letting the user change the profile visualization
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['gru-view-layout-picker'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Set a new visualization as selected and update the component appearance
       * @function actions:setLayout
       * @param {string} newLayout
       */
      setLayout: function setLayout(newLayout) {
        if (!newLayout.get('isActive')) {
          this.cleanup();
          this.selectLayout(newLayout);
        }
        this.sendAction('onViewLayoutChange', this.get('selectedView'));
      }
    },
    // -------------------------------------------------------------------------
    // Properties
    /**
     * List of layouts to be displayed by the component
     *
     * @constant {Array}
     */
    viewLayouts: function viewLayouts() {
      return this.setLayoutFilter();
    },

    /**
     * Selected view layout
     *
     * @property {string}
     */
    selectedView: null,

    // -------------------------------------------------------------------------
    // Events

    /**
     * DidInsertElement ember event
     */
    didInsertElement: function didInsertElement() {
      this.set('viewLayouts', this.setLayoutFilter());
    },

    // -------------------------------------------------------------------------
    // Methods

    /**
     * layouts to be displayed by the component
     */
    cleanup: function cleanup() {
      var component = this;
      component.get('viewLayouts').forEach(function (option) {
        option.set('isActive', false);
        component.set('selectedView', null);
      });
    },

    /**
     * set layouts to be displayed by the component
     * @return {Array}
     */
    setLayoutFilter: function setLayoutFilter() {
      return _ember['default'].A([_ember['default'].Object.create({
        view: _quizzesAddonConfigQuizzesConfig.VIEW_LAYOUT_PICKER_OPTIONS.THUMBNAILS,
        isActive: true,
        icon: 'view_module'
      }), _ember['default'].Object.create({
        view: _quizzesAddonConfigQuizzesConfig.VIEW_LAYOUT_PICKER_OPTIONS.LIST,
        isActive: false,
        icon: 'view_list'
      })]);
    },

    /**
     * Set a new visualization
     */
    selectLayout: function selectLayout(layout) {
      layout.set('isActive', true);
      this.set('selectedView', layout.view);
    }
  });
});