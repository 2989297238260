define('quizzes-addon/components/player/qz-resource-viewer', ['exports', 'ember', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/models/result/resource', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig, _quizzesAddonModelsResultResource, _quizzesAddonUtilsUtils) {

  /**
   * Player question viewer
   *
   * Component responsible for providing a frame where all question types
   * will be displayed i.e. it will be responsible for selecting any specific
   * question components per question type.
   *
   * @module
   * @see controllers/player.js
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-resource-viewer'],

    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @type {resourceService} resourceService
     * @property {Ember.Service} Service to send resource events
     */
    quizzesResourceService: _ember['default'].inject.service('quizzes/resource'),

    session: _ember['default'].inject.service('session'),

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Action triggered when the next button is clicked
       */
      next: function next() {
        this.set('isNextDisabled', true);
        var resourceResult = this.get('resourceResult');
        var eventContext = this.get('eventContext');
        resourceResult.set('stopTime', new Date().getTime());
        this.get('quizzesResourceService').sendFinishResource(this.get('resource.id'), resourceResult, eventContext);
        this.sendAction('onNext');
      }
    },

    // -------------------------------------------------------------------------
    // Events

    initializers: function initializers() {
      var _this = this;

      var resource = this.get('resource');
      if (resource) {
        var resourceResult = _quizzesAddonModelsResultResource['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
          resourceId: resource.resourceId,
          reaction: 0,
          startTime: new Date().getTime()
        });
        this.set('resourceResult', resourceResult);
      }
      var learningToolId = this.get('resource.learningToolId') ? this.get('resource.learningToolId') : null;
      return _ember['default'].RSVP.hash({
        toolDetails: learningToolId ? this.get('quizzesResourceService').getLearningToolInformation(learningToolId) : null
      }).then(function (_ref) {
        var toolDetails = _ref.toolDetails;

        _this.set('toolDetails', toolDetails);
        var userData = _this.get('session.userData');
        var toolConfig = _this.get('toolDetails.toolConfig');
        var additionalParams = toolConfig.additional_query_params;
        var paramsUsername = additionalParams ? additionalParams.username : '';
        var username = paramsUsername === '[username]' ? userData.username : paramsUsername;
        var clientCode = additionalParams ? additionalParams.client_code : '';
        var contentURL = resource.get('body');
        var addtionalParams = '?username=' + username + '&clientCode=' + clientCode;
        var url = contentURL;
        if (username !== '' && clientCode !== '') {
          url = url + addtionalParams;
        }
        resource.set('body', url);
      });
    },

    /**
     * DidInsertElement ember event
     */
    didInsertElement: function didInsertElement() {
      this.initializers();
      this.setNarrationEffect();
      this.calculateResourceContentHeight();
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var component = this;
      /**
       * method used to listen the events from iframe.
       **/
      function receiveMessage(event) {
        var eventContent = _ember['default'].A([]);
        if (event.data.message === 'xAPI_event') {
          var eventData = event.data.eventData;
          eventData.id = (0, _quizzesAddonUtilsUtils.generateUUID)();
          eventData.resourceId = component.get('resource.id');
          eventData.timestamp = new Date();
          eventData.userId = component.get('session.userId'), eventData.source = component.get('source');
          eventData.collectionId = component.get('collection.id');
          eventData.tenantId = component.get('session.tenantId');
          eventData.classId = component.get('classId');
          eventData.collectionType = component.get('collection.isCollection') ? _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.COLLECTION : _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.ASSESSMENT;
          eventData.sessionId = component.get('sessionId');
          if (component.get('isStudyPlayer')) {
            eventData.courseId = component.get('courseId');
            eventData.unitId = component.get('unit.id');
            eventData.lessonId = component.get('lesson.id');
          } else {
            eventData.dcaId = component.get('dcaId');
          }
          eventContent.push(eventData);
          component.loopFun(eventContent);
          component.get('quizzesResourceService').createxAPIEvent(eventContent);
        }
      }

      if (component.get('isStudent') && component.get('isStudyPlayer')) {
        window.addEventListener('message', receiveMessage, false);
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {EventContext} eventContext
     */
    eventContext: null,

    /**
     * Disable next button
     * @property {Boolean} sendEvents
     */
    isNextDisabled: false,

    /**
     * Indicates if the current resource type is a link out
     * @property {boolean}
     */
    isNotIframeUrl: _ember['default'].computed('resource', function () {
      var resource = this.get('resource');
      return resource && resource.displayGuide;
    }),

    /**
     * The resource playing
     * @property {Resource} resource
     */
    resource: null,

    mapLocation: null,

    /**
     * The resource component selected
     * @property {string}
     */
    resourceComponentSelected: _ember['default'].computed('resource.id', function () {
      var resourceType = this.get('resource.isImageResource') ? 'image' : this.get('resource.resourceType');
      var component = _quizzesAddonConfigQuizzesConfig.RESOURCE_COMPONENT_MAP[resourceType];

      if (!component) {
        _ember['default'].Logger.error('Resources of type ' + resourceType + ' are currently not supported');
      } else {
        _ember['default'].Logger.debug('Resources component selected: ', component);
        return component;
      }
    }),

    computedResource: _ember['default'].computed('resource.id', function () {
      var _this2 = this;

      var resource = this.get('resource');
      var isSecured = resource.get('isSecured');

      if (isSecured) {
        (function () {
          var url = resource.get('url');
          var productId = resource.get('product_id');
          var publisherTenantId = resource.get('publisherTenantId');

          resource.set('url', 'https://');
          resource.set('body', 'https://');

          _this2.get('quizzesResourceService').generateSecureToken(url, productId, publisherTenantId).then(function (token) {
            token = token && token.access_token;
            var params = { token: token };
            var securedUrl = (0, _quizzesAddonUtilsUtils.addParamsToUrl)(url, params);
            resource.set('url', securedUrl);
            resource.set('body', securedUrl);
          });
        })();
      }

      return resource;
    }),

    /**
     * @property {ResourceResult} resourceResult
     */
    resourceResult: null,

    /**
     * Show the next button and send events
     * @property {Boolean} sendEvents
     */
    sendEvents: false,

    /**
     * Show the narration section
     * @property {Boolean} showNarration
     */
    showNarration: true,

    isH5PContent: _ember['default'].computed('resource', function () {
      return this.get('resource.format') === 'h5p_interactive_video' || this.get('resource.format') === 'h5p_interactive_slide' || this.get('resource.format') === 'h5p_interactive_personality_quiz' || this.get('resource.format') === 'h5p_drag_and_drop_resource';
    }),

    /**
     * @property {String}
     */
    accessToken: _ember['default'].computed.alias('session.token-api3'),

    contentURL: _ember['default'].computed('isH5PContent', function () {
      if (this.get('isH5PContent')) {
        var accessToken = this.get('accessToken');
        var resourceId = this.get('resource.id');
        var resourceType = this.get('resource.format');
        var resourceScore = this.get('resource.showScore');
        var format = 'resource';
        var contentURL = window.location.protocol + '//' + window.location.host + '/tools/h5p/play/' + resourceId + '?accessToken=' + accessToken + '&contentType=' + resourceType + '&format=' + format + '&showScore=' + resourceScore;
        return contentURL;
      }
    }),

    /**
     * @property {Boolean} isStudent
     */
    isStudent: _ember['default'].computed.equal('session.role', _quizzesAddonConfigQuizzesConfig.ROLES.STUDENT),

    isLTILaunch: _ember['default'].computed('resource.learningToolId', function () {
      return !!this.get('resource.learningToolId');
    }),

    // -------------------------------------------------------------------------
    // Observers

    /**
     * Observes for the resource change
     */
    resourceObserver: (function () {
      this.calculateResourceContentHeight();
    }).observes('resource.id'),

    // -------------------------------------------------------------------------
    // Methods

    loopFun: function loopFun(arryList) {
      var loopFun = function loopFun(arryList) {
        arryList.forEach(function (item) {
          if (item instanceof Object) {
            Object.keys(item).map(function (key) {
              var value = item[key];
              if (key.indexOf('.') !== -1) {
                var keyValue = key.replace(/\./g, '~dot~');
                item[keyValue] = value;
                delete item[key];
              }
            });
            loopFun(Object.values(item));
          }
        });
      };
      loopFun(arryList);
    },

    /**
     * Calculates the height of the content area (it will change depending on height
     * of the narration -if there is one)
     */
    calculateResourceContentHeight: function calculateResourceContentHeight() {
      if (this.get('resource.isUrlResource') || this.get('resource.isPDFResource') || this.get('resource.isImageResource') && this.get('isNotIframeUrl') === false) {
        var narrationHeight = 0;
        if (this.get('showNarration') === true) {
          narrationHeight = this.$('.narration').innerHeight();
        }

        var contentHeight = $('.qz-content').height();

        // The 4 pixels subtracted are to make sure no scroll bar will appear for the content
        // (Users should rely on the iframe scroll bar instead)
        this.set('calculatedResourceContentHeight', contentHeight - narrationHeight - 4);
      }
    },
    /**
     * Set jquery effect to narration
     * */
    setNarrationEffect: function setNarrationEffect() {
      $('.narration').effect('highlight', { color: '#84B7DD' }, 2000);
    },

    /**
     * The protocol the user is using to access the page (http or https)
     * @property {String}
     */
    currentProtocol: window.location.protocol,

    /**
     * The protocol for the resource url
     * @property {String}
     */
    resourceProtocol: _ember['default'].computed('resource.url', function () {
      var httpsPattern = /^(https:\/\/)/;
      var cdnPattern = /^(\/\/cdn.gooru.org\/)/;
      var httpsResult = httpsPattern.test(this.get('resource.body'));
      var cdnResult = cdnPattern.test(this.get('resource.body'));
      var resultProtocol = httpsResult === true || cdnResult === true ? 'https:' : 'http:';
      return resultProtocol;
    }),

    /**
     * Check it can be render inside player or not
     * @property {boolean}
     */

    isLinkOut: _ember['default'].computed('resource', function () {
      var currentProtocol = this.get('currentProtocol');
      var resourceProtocol = this.get('resourceProtocol');
      if (currentProtocol === 'https:' && resourceProtocol === 'http:' && this.get('resource').type !== 'html_resource') {
        return true;
      }
      return false;
    }),

    /**
     * @property {boolean} isNextEnabled make ture by default for resource types
     */
    isNextEnabled: true
  });
});