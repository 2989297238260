define('quizzes-addon/helpers/is-teacher', ['exports', 'ember'], function (exports, _ember) {
  exports.isTeacher = isTeacher;

  /**
   * Check if a user is teacher
   */

  function isTeacher(params) {
    /**
     * @property {Class}
     */
    var aClass = params[0];
    var userId = params[1];
    return aClass.isTeacher(userId);
  }

  exports['default'] = _ember['default'].Helper.helper(isTeacher);
});