define('quizzes-addon/adapters/parse-event/parse-event', ['exports', 'ember', 'quizzes-addon/config/parse-event'], function (exports, _ember, _quizzesAddonConfigParseEvent) {

  /**
   * Adapter to post event to parse server
   *
   * @typedef {Object} ParseEventsAdapter
   */
  exports['default'] = _ember['default'].Object.extend({
    parseEventApiUrl: '/web/parse/event',
    /**
     * Post parse events to parse server
     *
     * @param {object} eventData
     * @returns {Promise}
     */
    postParseEvent: function postParseEvent(eventData) {
      var adapter = this;
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: adapter.defineHeaders(),
        data: JSON.stringify(eventData)
      };
      return _ember['default'].$.ajax(adapter.get('parseEventApiUrl'), options);
    },

    defineHeaders: function defineHeaders() {
      return {
        'x-parse-application-id': _quizzesAddonConfigParseEvent.APP_ID
      };
    },

    getCurrentLocation: function getCurrentLocation() {
      var options = {
        type: 'GET'
      };
      return _ember['default'].$.ajax('http://ip-api.com/json', options);
    }
  });
});