define('quizzes-addon/models/result/question', ['exports', 'ember', 'quizzes-addon/models/result/resource'], function (exports, _ember, _quizzesAddonModelsResultResource) {

  /**
   * Model for a brief summary of the status of a question after it was answered by a user.
   *
   * @typedef {Object} QuestionResult
   *
   */
  exports['default'] = _quizzesAddonModelsResultResource['default'].extend({
    /**
     * @property {Object} answer - Answer provided by the user
     */
    answer: null,

    /**
     * If user did not answer the question or did not view the resource, then status will be skipped.
     * Values: correct / incorrect / skipped
     *
     * This value can be null for “start” event. Required for “stop” event.
     *
     * @property {String}
     */
    attemptStatus: _ember['default'].computed('correct', 'skipped', 'isResource', 'isOpenEnded', 'isScientific', function () {
      var correct = this.get('correct');
      var skipped = this.get('skipped');
      var isResource = this.get('isResource');
      var isOpenEnded = this.get('isOpenEnded');
      var isScientific = this.get('isScientific');
      var status = 'started';
      if (isResource || isOpenEnded || isScientific) {
        status = skipped ? 'skipped' : 'started';
      } else {
        status = correct ? 'correct' : skipped ? 'skipped' : 'incorrect';
      }
      return status;
    }),

    /**
     * @property {boolean} correct - Was the answer provided for this question correct?
     */
    correct: _ember['default'].computed.equal('score', 100),

    /**
     * @property {boolean} incorrect - Was the answer provided for this question incorrect?
     */
    incorrect: _ember['default'].computed.not('correct'),

    /**
     * @property {boolean} isOpenEnded - If the question is openEnded
     */
    isOpenEnded: _ember['default'].computed.alias('question.isOpenEnded'),

    /**
     * @property {boolean} isScientific - If the question is scientific
     */
    isScientific: _ember['default'].computed('question', function () {
      var question = this.get('question');
      return question.get('isScientificFreeResponse') || question.get('isScientificFIB');
    }),

    /**
     * @property {number} resource - the question
     */
    question: _ember['default'].computed.alias('resource'),

    /**
     * @property {number} questionId - ID of the question
     */
    questionId: _ember['default'].computed.alias('resourceId'),

    /**
     * @property {number} reaction - user reaction to the resource
     */
    reaction: 0,

    /**
     * @property {number} score - Resource score
     */
    score: 0,

    /**
     * @property {boolean} started
     */
    skipped: true,

    /**
     * @property {boolean} started
     */
    started: _ember['default'].computed.bool('answer'),

    /**
     * @property {boolean} submitted - if the question is already submitted
     */
    submitted: false,

    baseResourceId: null,

    subResources: _ember['default'].A([]),

    // -------------------------------------------------------------------------
    // Methods

    clear: function clear() {
      this._super.apply(this, arguments);
      this.set('answer', null);
      this.set('score', 0);
    }
  });
});