define('quizzes-addon/components/reports/assessment/questions/qz-serp-words-per-minute', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion, _quizzesAddonUtilsUtils) {

  /**
   * SERP Encoding Assessment
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-serp-words-per-minute'],

    // -------------------------------------------------------------------------
    // Properties

    showCorrect: false,

    answers: _ember['default'].computed.alias('question.answer'),

    baseAnswers: _ember['default'].computed.alias('question.resource.baseAnswers'),

    answer: _ember['default'].computed('answers', function () {
      return this.get('answers.firstObject');
    }),

    exemplars: _ember['default'].computed('question.resource.data', function () {
      return this.get('question.resource.data') ? this.get('question.resource.data.exemplarDocs') : _ember['default'].A([]);
    }),

    isPlaying: false,

    audio: null,

    wordsPerMinute: null,

    selectedText: null,

    wordsPerMinuteCount: null,

    // -------------------------------------------------------------------------
    // Event
    didInsertElement: function didInsertElement() {
      var component = this;
      var answer = this.get('answer');
      var audioObj = answer.value ? JSON.parse(answer.value) : null;
      this.set('selectedText', audioObj.selectedText);

      if (audioObj) {
        var speechText = audioObj.speechText.fullAudioText.split(' ').length;
        var selectedText = audioObj.selectedText;
        var wpm = selectedText.wpmCount || 0;
        var wpmcount = audioObj.speechText.avgWordCount ? audioObj.speechText.avgWordCount : 0;
        wpmcount = wpmcount < speechText ? wpmcount : speechText;
        this.setProperties({
          wordsPerMinute: wpm
        });
        component.set('audioDuration', (0, _quizzesAddonUtilsUtils.sec2time)(component.get('selectedText').audioLength / 1000 || 0));
        this.set('wordsPerMinuteCount', wpmcount);
        var audioUrl = audioObj.value;
        this.set('audio', new Audio(audioUrl));
      }
    },

    actions: {
      onPlayAudio: function onPlayAudio() {
        var component = this;
        this.set('isPlaying', true);
        var _audio = component.get('audio');
        _audio.play();
        _audio.onended = function () {
          component.set('isPlaying', false);
        };
        _audio.ontimeupdate = function () {
          component.$('.answer-container .audio-progress .progress-filling').css('width', _audio.currentTime / _audio.duration * 100 + '%');

          var timerEl = component.$('.wpm-timer-section .timer-count');
          timerEl.find('.current-count').html((0, _quizzesAddonUtilsUtils.sec2time)(_audio.currentTime || 0));
          timerEl.find('.duration').html((0, _quizzesAddonUtilsUtils.sec2time)(component.get('selectedText').audioLength / 1000 || 0));
        };
      },
      onPauseAudio: function onPauseAudio() {
        this.set('isPlaying', false);
        var activeAudio = this.get('audio');
        activeAudio.pause();
      }
    }
  });
});