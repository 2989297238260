define('quizzes-addon/services/quizzes/api-sdk/class', ['exports', 'ember', 'quizzes-addon/serializers/class/class', 'quizzes-addon/adapters/class/class'], function (exports, _ember, _quizzesAddonSerializersClassClass, _quizzesAddonAdaptersClassClass) {

  /**
   * @typedef {Object} ClassService
   */
  exports['default'] = _ember['default'].Service.extend({
    classSerializer: null,

    classAdapter: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('classSerializer', _quizzesAddonSerializersClassClass['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('classAdapter', _quizzesAddonAdaptersClassClass['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    /**
     * Gets the members, collaborators, invitees and owner for a specified class ID
     * @param classId the class id to read
     * @returns {Promise}
     */
    readClassMembers: function readClassMembers(classId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('classAdapter').readClassMembers(classId).then(function (response) {
          resolve(service.get('classSerializer').normalizeReadClassMembers(response));
        }, function (error) {
          reject(error);
        });
      });
    }
  });
});