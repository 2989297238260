define('quizzes-addon/services/quizzes/api-sdk/media', ['exports', 'ember', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/adapters/media/media'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig, _quizzesAddonAdaptersMediaMedia) {
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('mediaAdapter', _quizzesAddonAdaptersMediaMedia['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    session: _ember['default'].inject.service('session'),

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {mediaAdapter} adapter
     */
    mediaAdapter: null,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Uploads a file to the content cdn
     *
     * @param fileData object with the data
     * @returns {Promise}
     */
    uploadContentFile: function uploadContentFile(fileData) {
      var isAudio = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('mediaAdapter').uploadFile(fileData, _quizzesAddonConfigQuizzesConfig.ENTITY_TYPE.CONTENT, isAudio).then(function (response) {
          resolve(service.get('session.cdnUrls.content') + response.filename);
        }, function (error) {
          reject(error);
        });
      });
    },

    /**
     * Help to split words per minutes from the audio
     */
    wordsPerMinuteTextUpdate: function wordsPerMinuteTextUpdate(params) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve) {
        service.get('mediaAdapter').wordsPerMinuteTextUpdate(params).then(function (text) {
          return resolve(text);
        }, function () {
          return resolve({
            audioText: '',
            audioTime: 0,
            avgWordCount: 0,
            fullAudioText: ''
          });
        });
      });
    }
  });
});