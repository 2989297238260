define('ember-cli-font-awesome/components/fa-stack', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',

    classNames: 'fa-stack',

    classNameBindings: ['sizeCssClass'],

    sizeCssClass: computed('size', function () {
      var size = this.get('size');
      if (size) {
        if (size.match(/^fa-/)) {
          return size;
        } else {
          return size.match(/(?:lg|x)$/) ? 'fa-' + size : 'fa-' + size + 'x';
        }
      }
    })
  });
});