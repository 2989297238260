define('quizzes-addon/helpers/format-date', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {
  exports.formatDate = formatDate;

  /**
   * Format date
   */

  function formatDate(value /*, hash*/) {
    var date = value[0];
    var format = value.length > 1 ? value[1] : undefined;
    return (0, _quizzesAddonUtilsUtils.formatDate)(date, format);
  }

  exports['default'] = _ember['default'].Helper.helper(formatDate);
});