define('quizzes-addon/utils/sort-featured-courses', ['exports', 'ember'], function (exports, _ember) {
  exports.getSubjects = getSubjects;
  exports.sortFeaturedCourses = sortFeaturedCourses;
  exports.sortCoursesBySubject = sortCoursesBySubject;

  /**
   * Utility methods to handle course ordering for Featured Courses
   */

  /**
    * Ordered and unique subjects for an array of Courses
    * @param {Course[]} featured courses
    * @prop {Course[]} ordered featured course subjects
    */

  function getSubjects(courses) {
    var subjects = _ember['default'].A([]);
    courses.map(function (course) {
      return _ember['default'].Object.create({
        subject: course.subject,
        subjectSequence: course.subjectSequence,
        taxonomySubject: course.subjectName
      });
    }).filter(function (course) {
      return !subjects.findBy('taxonomySubject', course.get('taxonomySubject'));
    }).forEach(function (course) {
      if (!subjects.findBy('taxonomySubject', course.get('taxonomySubject'))) {
        subjects.push(course);
      }
    });
    subjects = subjects.sortBy('subjectSequence');
    // Create an additional bucket for courses that don't have taxonomy data
    subjects.unshift(_ember['default'].Object.create({
      subject: '',
      subjectSequence: 0,
      taxonomySubject: null
    }));
    return subjects;
  }

  /**
   * @param {Course[]} featured courses
   * @return {Course[]} featured courses ordered by subjects
   */

  function sortFeaturedCourses(courses) {
    var result = getSubjects(courses).map(function (subjectBucket) {
      return courses.filter(function (course) {
        return course.subjectName === subjectBucket.taxonomySubject;
      });
    });
    return result.map(function (arrayOfCourses) {
      return arrayOfCourses.sort(function (a, b) {
        return a.sequence - b.sequence;
      });
    });
  }

  /**
   * @param {Course[]} list of courses
   * @return {Course[]} list of courses ordered by subjects
   */

  function sortCoursesBySubject(courses) {
    /**
     * Ordered and unique subjects for an array of Courses
     * @param {Course[]} featured courses
     * @prop {Course[]} ordered featured course subjects
     */
    function getSubjectBuckets(courses) {
      var result = courses.map(function (course) {
        return _ember['default'].Object.create({
          subject: course.subject,
          subjectSequence: course.subjectSequence
        });
      }).filter(function (elem, pos, list) {
        return list.reduce(function (result, e, i) {
          return result < 0 && e.subject === elem.subject ? i : result;
        }, -1) === pos;
      });
      return result.sort(function (a, b) {
        return a.subjectSequence - b.subjectSequence;
      });
    }

    var result = getSubjectBuckets(courses).map(function (subjectBucket) {
      return courses.filter(function (course) {
        return course.subject === subjectBucket.subject;
      });
    });
    return result.map(function (arrayOfCourses) {
      return arrayOfCourses.sort(function (a, b) {
        return a.sequence - b.sequence;
      });
    });
  }
});