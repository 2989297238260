define('quizzes-addon/components/player/questions/qz-serp-choose-one', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // --------------------------------------------------------------
    // Attributes

    // -----------------------------------------------------------------
    // Properties

    // -------------------------------------------------------------------
    // Actions
    actions: {
      onAnswerChanged: function onAnswerChanged(question, answer) {
        this.sendAction('onAnswerChanged', question, answer);
      },

      onAnswerCleared: function onAnswerCleared(question, answer) {
        this.sendAction('onAnswerCleared', question, answer);
      },

      onAnswerCompleted: function onAnswerCompleted(question, answer) {
        this.sendAction('onAnswerCompleted', question, answer);
      },

      onAnswerLoaded: function onAnswerLoaded(question, answer) {
        this.sendAction('onAnswerLoaded', question, answer);
      }
    }
  });
});