define('quizzes-addon/models/editor/assessment/level', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Level model
   * typedef {Object} Level
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {string} id
     */
    id: '',
    /**
     * @property {string} Description
     */
    description: '',
    /**
     * @property {Integer} Points
     */
    points: 0,

    /**
     * Return a copy of the level
     *
     * @function
     * @return {Level}
     */
    copy: function copy() {
      var properties = this.getProperties(this.modelProperties());
      return this.get('constructor').create(properties);
    },

    /**
     * Copy a list of property values from another model to override the current ones
     *
     * @function
     * @param {Level} model
     * @param {String[]} propertyList
     * @return {null}
     */
    merge: function merge(model) {
      var propertyList = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

      var properties = model.getProperties(propertyList);
      this.setProperties(properties);
    },
    /**
     * Return a list of properties
     *
     * @function
     * @return {Array}
     */
    modelProperties: function modelProperties() {
      var properties = [];
      var enumerableKeys = Object.keys(this);
      for (var i = 0; i < enumerableKeys.length; i++) {
        var key = enumerableKeys[i];
        var value = _ember['default'].typeOf(this.get(key));
        if (value === 'string' || value === 'number' || value === 'boolean') {
          properties.push(key);
        }
      }
      return properties;
    }
  });
});