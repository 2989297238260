define('quizzes-addon/components/player/questions/qz-hs-image', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-hs-text', 'quizzes-addon/mixins/modal'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzHsText, _quizzesAddonMixinsModal) {

  /**
   * Hot Spot Image
   *
   * Component responsible for controlling the logic and appearance of a hot spot
   * image question inside of the {@link player/qz-question-viewer.js}
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments components/player/questions/qz-hs-text.js
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzHsText['default'].extend(_quizzesAddonMixinsModal['default'], {
    // -------------------------------------------------------------------------
    // Dependencies
    i18n: _ember['default'].inject.service(),

    /**
     * @property {Service} Configuration service
     */
    configurationService: _ember['default'].inject.service('quizzes/configuration'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-hs-image'],

    // -------------------------------------------------------------------------
    // Properties

    isMobile: (function () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    }).property(),

    longPressed: function longPressed(imgEl) {
      var parentEl = $(imgEl.target).parent(),
          parentEl$ = $(parentEl[0]),
          hoverCtx = parentEl$.find('.img-hover');

      hoverCtx.toggleClass('img-hover-none');
      hoverCtx.toggleClass('img-hover-flexed');
    },

    mouseDown: function mouseDown(el) {
      var runOnce = _ember['default'].run.later(this, this.get('longPressed'), el, 1000);
      this.set('pressed', runOnce);
    },

    mouseUp: function mouseUp() {
      _ember['default'].run.cancel(this.get('pressed'));
    },

    contextMenu: function contextMenu(evt) {
      var parentEl = $(evt.target).parent(),
          parentEl$ = $(parentEl[0]),
          hoverCtx = parentEl$.find('.img-hover');
      hoverCtx.toggleClass('img-hover-none');
      hoverCtx.toggleClass('img-hover-flexed');
      evt.preventDefault();
      evt.stopPropagation();
      return false;
    },
    /**
     * @typedef answers
     * @property {String} value - answer value
     * @property {String} text - url string for an image
     */
    answers: _ember['default'].computed.map('question.answers', function (answer) {
      var cdnURL = this.get('configurationService.configuration.properties.cdnURL');
      return {
        value: answer.get('value'),
        text: cdnURL + answer.get('text')
      };
    }),

    /**
     * @property {String} instructions - Question instructions
     */
    instructions: _ember['default'].computed(function () {
      var action = this.get('i18n').t(this.get('instructionsActionTextKey')).string;
      return this.get('i18n').t('qz-hs-image.instructions', { action: action });
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      showImageModal: function showImageModal(thumbnail) {
        this.actions.showModal.call(this, 'player.qz-image-modal', {
          thumbnail: thumbnail,
          width: '90vw',
          height: '90vh',
          overflow: 'auto'
        }, null, null, true);
      },
      selectAnswerImage: function selectAnswerImage() {
        console.log('li.selectAnswerImage', 'click'); //eslint-disable-line
      }
    },
    // -------------------------------------------------------------------------
    // Actions
    /**
     * Set answers and set click events on every answer to selected and unselected answers
     */
    setupSubscriptions: _ember['default'].on('didInsertElement', function () {
      var component = this;
      var readOnly = component.get('readOnly');

      component.setUserAnswer();

      if (!readOnly) {
        if (component.get('userAnswer')) {
          component.notify(true);
        }
        this.$('li.answer').on('click', function (el) {
          var $this = $(this);
          var answerId = $this.data('id');
          if (el.target.className === 'bookmark' || el.target.className === 'img-thumbnail' || el.target.className === 'gru-icon check_circle material-icons ember-view') {
            var selected = component.get('selectedAnswers');
            var answer = selected.findBy('value', answerId);

            $this.toggleClass('selected');

            if (!answer) {
              selected.push({ value: answerId });
            } else {
              var idx = selected.indexOf(answer);
              selected.splice(idx, 1);
            }

            component.notify(false);
          }
        });
      }
    })
  });
});