define('quizzes-addon/components/reports/assessment/questions/qz-hs-text', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * Hot spot text
   *
   * Component responsible for show the hot spot text, which option is selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-hs-text'],

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Return the hot spot answers to show on the component, indicating if the user select the answer and
     * if is correct or not.
     */
    answers: _ember['default'].computed('question', 'anonymous', function () {
      var component = this;
      var question = component.get('question');
      var userAnswers = component.get('userAnswer');
      var correctAnswers = question.get('question.correctAnswer');
      var anonymous = component.get('anonymous');
      var answers = question.get('question.answers');
      return answers.map(function (answer) {
        var userAnswerCorrect = false;
        var selected = false;
        if (component.get('showCorrect')) {
          userAnswers = question.get('question.correctAnswer');
          if (userAnswers.findBy('value', answer.value)) {
            userAnswerCorrect = correctAnswers.findBy('value', answer.value);
            selected = true;
          }
        }
        if (userAnswers.findBy('value', answer.value)) {
          userAnswerCorrect = correctAnswers.findBy('value', answer.value);
          selected = true;
        }
        var elementClass = anonymous ? 'anonymous' : userAnswerCorrect ? 'correct' : 'incorrect';
        return {
          text: answer.get('text'),
          selected: selected,
          'class': elementClass
        };
      });
    })
  });
});