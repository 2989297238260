define('quizzes-addon/components/reports/assessment/questions/qz-match-the-following', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['qz-match-the-following'],

    // -------------------------------------------------------------------------
    // Properties

    /**
     *
     * @property {Array} userAnswer has Array of user answered objects
     */
    userAnswers: _ember['default'].computed('question', function () {
      var answers = this.get('question.answer') || [];
      return answers.map(function (item) {
        return item.value;
      });
    }),

    showCorrect: false,

    /**
     * Convenient structure to render the question answer choices
     * @property {*}
     */
    answers: _ember['default'].computed('question', function () {
      var answers = this.get('question.resource.answerDetails');
      return answers;
    }),

    leftArray: _ember['default'].computed('answers', function () {
      var left = _ember['default'].A();
      this.get('answers').forEach(function (answer) {
        var answerObject = _ember['default'].Object.create({
          sequence: answer.sequence,
          leftValue: answer.left_value,
          leftValueFormat: answer.left_value_format
        });
        left.pushObject(answerObject);
      });
      return left;
    }),

    rightArray: _ember['default'].computed('answers', function () {
      var right = _ember['default'].A();
      var userAnsObj = _ember['default'].A();
      var showCorrect = this.get('showCorrect');
      var userAnswers = this.get('userAnswers');
      this.get('answers').forEach(function (answer) {
        var answerObject = _ember['default'].Object.create({
          sequence: answer.sequence,
          rightValue: answer.right_value,
          leftValue: answer.left_value,
          rightValueFormat: answer.right_value_format,
          rightValShuffleOrder: answer.right_val_shuffle_order
        });
        right.pushObject(answerObject);
      });

      userAnswers.forEach(function (userSeq) {
        var ansObj = right.find(function (item) {
          return item.sequence + ',' + item.leftValue + ',' + item.rightValue + ',' + item.rightValShuffleOrder === userSeq;
        });
        userAnsObj.pushObject(ansObj);
      });
      return showCorrect ? right : userAnsObj;
    })
  });
});