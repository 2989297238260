define('quizzes-addon/components/player/qz-player-confirmation', ['exports', 'ember', 'quizzes-addon/models/taxonomy/taxonomy-tag', 'quizzes-addon/models/taxonomy/taxonomy-tag-data', 'quizzes-addon/config/quizzes-config', 'gooru-web/mixins/tenant-settings-mixin'], function (exports, _ember, _quizzesAddonModelsTaxonomyTaxonomyTag, _quizzesAddonModelsTaxonomyTaxonomyTagData, _quizzesAddonConfigQuizzesConfig, _gooruWebMixinsTenantSettingsMixin) {
  exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-player-confirmation'],
    classNameBindings: ['isShowSuggestion:show-suggestion'],

    // -------------------------------------------------------------------------
    // Service

    /**
     * @type {CollectionService} collectionService
     * @property {Ember.Service} Service to retrieve a collection|assessment
     */
    collectionService: _ember['default'].inject.service('quizzes/collection'),

    session: _ember['default'].inject.service('session'),

    // /**
    //  * @property {AssessmentService} Service to retrieve an assessment
    //  */
    // assessmentService: Ember.inject.service('api-sdk/assessment'),
    //
    // /**
    //  * @property {CollectionService} Service to retrieve a collection
    //  */
    // collectionService: Ember.inject.service('api-sdk/collection'),

    // collectionObserver: Ember.observer('context', function() {
    //   const component = this;
    //   component.fetchConfirmationInfo();
    // }),

    // -------------------------------------------------------------------------
    // Events
    // didInsertElement() {
    //   const component = this;
    //   // component.fetchConfirmationInfo();
    // },

    didRender: function didRender() {
      var component = this;
      component.$('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });
    },

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      // //Action triggered when click on the start
      // start() {
      //   this.sendAction('onStartPlayer');
      // },
      //
      // playNext() {
      //   this.sendAction('onPlayNext');
      // },
      // emptyNextPlay() {
      //   this.sendAction('onEmptyNextPlay');
      // },
      // onExit() {
      //   window.parent.postMessage(PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE, '*');
      // },

      //Action triggered when click on the cancel
      cancel: function cancel() {
        var component = this;
        var transitionTo = this.get('source');
        var isIframeMode = component.get('isIframeMode');
        if (isIframeMode) {
          window.parent.postMessage(_quizzesAddonConfigQuizzesConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE, '*');
        } else {
          component.sendAction('onClosePlayer', transitionTo);
        }
      },

      showTimer: function showTimer() {
        this.sendAction('showTimer');
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {number} currentAttempts
     */
    attempts: null,

    attemptsLeft: _ember['default'].computed('attempts', 'collection.attempts', function () {
      return this.get('collection.attempts') - this.get('attempts');
    }),

    /**
     * @property {Collection} collection
     */
    collection: null,

    /**
     * @property {Context} context
     */
    context: null,

    /**
     * @property {boolean} flag for determining button behaviour
     */
    disableStart: _ember['default'].computed('unlimited', 'noMoreAttempts', function () {
      return !this.get('unlimited') && this.get('noMoreAttempts');
    }),

    /**
     * @property {Boolean} Indicate if the context has more attempts available
     */
    noMoreAttempts: _ember['default'].computed('collection.isAssessment', 'collection.attempts', 'attempts', function () {
      var component = this;
      if (component.get('isContentAttemptSetting')) {
        if (component.get('collection.isAssessment') && component.get('collection.attempts') > 0 && component.get('attempts') && component.get('attempts') >= component.get('collection.attempts')) {
          var isNoMoreAttempt = true;
          if (this.get('attemptCountData') && this.get('attemptCountData.length') && component.get('collection.settings') && component.get('collection.settings.attempts')) {
            var attemptCount = this.get('attemptCountData').findBy('content_id', component.get('collection.id'));
            if (attemptCount && attemptCount.attempt && attemptCount.attempt > component.get('attempts')) {
              isNoMoreAttempt = false;
            }
          } else {
            isNoMoreAttempt = true;
          }
          return isNoMoreAttempt;
        } else {
          return false;
        }
      } else {
        return component.get('collection.isAssessment') && component.get('collection.attempts') > 0 && component.get('attempts') && component.get('attempts') >= component.get('collection.attempts');
      }
    }),
    contentType: _ember['default'].computed('collection', function () {
      var collection = this.get('collection');
      var contentType = collection.get('isCollection') ? _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.COLLECTION : _quizzesAddonConfigQuizzesConfig.CONTENT_TYPES.ASSESSMENT;
      return contentType;
    }),
    playAttempts: _ember['default'].computed('collection', 'lesson', 'lessonList', 'unitList', function () {
      var component = this;
      var collection = component.get('collection');
      var lesson = component.get('lesson');
      var lessonDetails = component.get('lessonList');
      var unitList = component.get('unitList');
      var lessonLength = lessonDetails.length;
      var activeLesson = lessonDetails.findBy('id', lesson.get('id'));
      var lIndex = lessonDetails.findIndex(function (item) {
        return item.id === lesson && lesson.get('id');
      });
      var lessonIndex = lIndex + 1;
      var unitLength = unitList.length;
      var uIndex = unitList.findIndex(function (item) {
        return item.id === activeLesson && activeLesson.get('unit_id');
      });
      var unitIndex = uIndex + 1;
      var lessonData = lesson.get('children');
      var collectionLength = lessonData.length;
      var activeCollection = lessonData.findBy('id', collection.get('id'));
      var cIndex = lessonData.findIndex(function (item) {
        return item.id === collection && collection.get('id');
      });
      var collectionIndex = cIndex + 1;
      var isMilestoneLesson = lesson && lesson.get('isMilestoneLesson');
      return isMilestoneLesson ? true : activeCollection && activeCollection.get('resourceCount') === 0 && activeCollection && activeCollection.get('questionCount') === 0 && unitIndex === unitLength && lessonLength === lessonIndex && collectionLength === collectionIndex;
    }),
    /**
     * @property {boolean} flag for determining unlimited behaviour
     */
    unlimited: _ember['default'].computed.equal('collection.attempts', -1),

    isCollectionConfirmation: _ember['default'].computed('collection', function () {
      var component = this;
      return component.get('collection.isCollection');
    }),

    /**
     * @property {Boolean}
     * Is suggested content
     */
    isSuggestedContent: _ember['default'].computed('pathType', function () {
      var component = this;
      var pathType = component.get('pathType');
      return pathType === 'teacher' || pathType === 'system';
    }),

    /**
     * @property {TaxonomyTag[]} List of taxonomy tags
     */
    tags: _ember['default'].computed('confirmationInfo', function () {
      var standards = this.get('confirmationInfo.taxonomy');
      if (standards) {
        standards = standards.filter(function (standard) {
          // Filter out learning targets (they're too long for the card)
          return !_quizzesAddonModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));
        });
        return _quizzesAddonModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);
      }
    }),

    // /**
    //  * Property used to  identify whether collection object has items to play.
    //  */
    // hasCollectionItems: Ember.computed('confirmationInfo', function() {
    //   let resourceCount = this.get('confirmationInfo.resourceCount');
    //   let questionCount = this.get('confirmationInfo.questionCount');
    //   let hasCollectionItems = false;
    //   let isCollection = this.get('isCollectionConfirmation');
    //   if (isCollection && (resourceCount > 0 || questionCount > 0)) {
    //     hasCollectionItems = true;
    //   } else if (questionCount > 0) {
    //     hasCollectionItems = true;
    //   }
    //   return hasCollectionItems;
    // }),

    /**
     * Maintains the state of data loading
     * @type {Boolean}
     */
    isLoading: false

    // // -------------------------------------------------------------------------
    // // Methods
    //
    // /**
    //  * @function fetchConfirmationInfo
    //  * Method to fetch confirmation info data
    //  */
    // fetchConfirmationInfo() {
    //   let component = this;
    //   let collection = component.get('collection');
    //   let isCollectionConfirmation = component.get('isCollectionConfirmation');
    //   component.set('isLoading', true);
    //   if (isCollectionConfirmation) {
    //     component.getCollection(collection.id).then(function(collectionInfo) {
    //       console.log(collectionInfo,'collectionInfo');
    //       if (!component.get('isDestroyed')) {
    //         let contentCount = component.getResourceQuestionCount(
    //           collection.resources
    //         );
    //         collection.resources.forEach(r => {
    //           let taxonomy = collectionInfo.content.findBy('id', r.id);
    //           if (taxonomy) {
    //             r.standards = component.tempSerializeResourceTaxonomy(
    //               taxonomy.taxonomy
    //             );
    //           }
    //         });
    //         collectionInfo.questionCount = contentCount.questionCount;
    //         collectionInfo.resourceCount = contentCount.resourceCount;
    //         component.set('confirmationInfo', collectionInfo);
    //         component.set('isLoading', false);
    //       }
    //     });
    //   } else {
    //     component.getAssessment(collection.id).then(function(assessmentInfo) {
    //       if (!component.get('isDestroyed')) {
    //         component.set('confirmationInfo', assessmentInfo);
    //         component.set('isLoading', false);
    //       }
    //     });
    //   }
    // },
    //
    // tempSerializeResourceTaxonomy(taxonomy) {
    //   if (taxonomy) {
    //     return Array.from(Object.keys(taxonomy), function(k) {
    //       var taxonomyObject = taxonomy[k];
    //       taxonomyObject.id = k;
    //       taxonomyObject.title = taxonomyObject.code;
    //       taxonomyObject.caption = taxonomyObject.code;
    //       taxonomyObject.data = taxonomyObject;
    //       return Ember.Object.create(taxonomyObject);
    //     });
    //   }
    // },
    //
    // /**
    //  * @function getCollection
    //  * Get a collection by Id
    //  */
    // getCollection(collectionId) {
    //   const component = this;
    //   // component.get('collectionService').readCollection(collectionId)
    //   const collectionPromise = Ember.RSVP.resolve(
    //     component.get('collectionService').getCollection(collectionId)
    //   );
    //   return Ember.RSVP.hash({
    //     collection: collectionPromise
    //   }).then(function(hash) {
    //     return hash.collection;
    //   });
    // },
    //
    // /**
    //  * @function getAssessment
    //  * Get an assessment by Id
    //  */
    // getAssessment(assessmentId) {
    //   const component = this;
    //   // component.get('assessmentService').readAssessment(assessmentId)
    //
    //   const assessmentPromise = Ember.RSVP.resolve(
    //       component.get('collectionService').getAssessment(assessmentId)
    //   );
    //   return Ember.RSVP.hash({
    //     assessment: assessmentPromise
    //   }).then(function(hash) {
    //     return hash.assessment;
    //   });
    // },
    //
    // /**
    //  * @function getResourceQuestionCount
    //  * Method to get resource and question count from the collection
    //  */
    // getResourceQuestionCount(resources) {
    //   let questionCount = 0;
    //   let resourceCount = 0;
    //   if (Ember.isArray(resources)) {
    //     resources.map(resource => {
    //       if (resource.isResource) {
    //         resourceCount++;
    //       } else {
    //         questionCount++;
    //       }
    //     });
    //   }
    //   return {
    //     questionCount,
    //     resourceCount
    //   };
    // }
  });
});