define('quizzes-addon/validators/number-compare', ['exports', 'ember-cp-validations/validators/base', 'ember'], function (exports, _emberCpValidationsValidatorsBase, _ember) {
  exports['default'] = _emberCpValidationsValidatorsBase['default'].extend({
    /**
     * @property {Service} I18N service
     */
    i18n: _ember['default'].inject.service(),

    /**
     * Validate the value be greater than a specific model property
     */
    validate: function validate(value, options, model) {
      return value > model.get(options.property) || this.get('i18n').t('common.errors.due-date-error').string;
    }
  });
});