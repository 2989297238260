define('quizzes-addon/components/player/questions/qz-fib', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Fill in the blank
   *
   * Component responsible for controlling the logic and appearance of a fill in the blank
   * question inside of the {@link player/qz-question-viewer.js}
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments Ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-fib'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events
    initInputEvents: (function () {
      var component = this;
      component.setAnswersEvents();
    }).on('didInsertElement'),

    // -------------------------------------------------------------------------
    // Properties
    /**
     * Replace '[]' to an input, but not []{
     * @param question.body
     */
    answers: _ember['default'].computed('question.body', function () {
      var component = this;
      var answers = component.get('question.body');
      var readOnly = component.get('readOnly');
      var disabled = readOnly ? 'disabled' : '';
      // matches [] but not []{, which indicates a malformed sqrt
      var regex = _quizzesAddonConfigQuizzesConfig.FIB_REGEX;

      if (component.get('hasUserAnswer')) {
        var userAnswer = component.get('userAnswer');
        userAnswer.forEach(function (choice) {
          var input = '<input type=\'text\' value=\'' + choice.value + '\' ' + disabled + '/>';
          answers = answers.replace(regex, input);
        });

        return answers;
      } else {
        var input = '<input type=\'text\' value=\'\' ' + disabled + '/>';
        return answers.replace(new RegExp(regex.source, 'g'), input);
      }
    }),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods
    /**
     * Notify input answers
     * @param {boolean} onLoad if this was called when loading the component
     */
    notifyInputAnswers: function notifyInputAnswers(onLoad) {
      var component = this,
          inputs = component.$('.fib-answers input[type=text]'),
          answers = inputs.map(function (index, input) {
        var answer = _ember['default'].$(input).val();
        return { value: _ember['default'].$.trim(answer) };
      }).toArray();

      var answerCompleted = answers.join('').length > 0; //to check that at least 1 answer has text
      component.notifyAnswerChanged(answers);
      if (answerCompleted) {
        if (onLoad) {
          component.notifyAnswerLoaded(answers);
        } else {
          component.notifyAnswerCompleted(answers);
        }
      } else {
        component.notifyAnswerCleared(answers);
      }
    },

    /**
     * Set answers
     */
    setAnswersEvents: function setAnswersEvents() {
      var component = this;
      var inputs = component.$('.fib-answers');
      if (component.get('hasUserAnswer')) {
        component.notifyInputAnswers(true);
      }
      inputs.off('focusout');
      inputs.on('keyup', 'input[type=text]', function () {
        if (!component.get('isComprehension')) {
          component.notifyInputAnswers(false);
        }
      });
      if (component.get('isComprehension')) {
        inputs.on('focusout', 'input[type=text]', function () {
          component.notifyInputAnswers(false);
        });
      }
    }
  });
});