define('quizzes-addon/helpers/clean-file-name', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {
  exports.cleanFileName = cleanFileName;

  /**
   * Clean a file name
   */

  function cleanFileName(value /*, hash*/) {
    return (0, _quizzesAddonUtilsUtils.getFileNameFromInvalidUrl)(value);
  }

  exports['default'] = _ember['default'].Helper.helper(cleanFileName);
});