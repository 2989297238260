define('quizzes-addon/helpers/three-bounce-spinner', ['exports', 'ember'], function (exports, _ember) {
  exports.threeBounceSpinner = threeBounceSpinner;

  /**
   * CSS loading spinner by Tobias Ahlin
   * http://tobiasahlin.com/spinkit/
   */

  function threeBounceSpinner() {
    var html = '<div class="three-bounce-spinner"> \
                  <div class="bounce1"></div> \
                  <div class="bounce2"></div> \
                  <div class="bounce3"></div> \
                </div>';
    return _ember['default'].String.htmlSafe(html);
  }

  exports['default'] = _ember['default'].Helper.helper(threeBounceSpinner);
});
/*jshint multistr: true */