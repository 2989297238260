define('quizzes-addon/components/cards/gru-resource-result-card', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['cards', 'gru-resource-result-card'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {ResourceResult | QuestionResult} item information to be used in the card
     */
    item: null
  });
});
/**
 * Resource card
 *
 * Component responsible for show the  resource information in cards, so that most useful information is summarized there.
 * @module
 *
 */