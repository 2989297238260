define('quizzes-addon/utils/gru-theme', ['exports', 'ember'], function (exports, _ember) {

  /**
   * This util class holds the information for a Gooru Theme.
   * Gooru themes are useful to setup the application branding, messages and locale
   *
   * @typedef {object} GruTheme
   */
  exports['default'] = _ember['default'].Object.extend({
    // -------------------------------------------------------------------------
    // Properties
    /**
     * @property {string} theme id
     */
    id: null,

    /**
     * Loaded styles
     * @property {{url: string}} styles
     */
    styles: {
      url: null
    },

    /**
     * @property {{ url: string, locale: string }} path to a javascript translation file complaint with ember-i18n
     */
    translations: {
      url: null,
      locale: 'en'
    },

    // -------------------------------------------------------------------------
    // Methods
    /**
     * Returns theme translations
     *
     * {
     *   "en" : {
     *      "common" : {
     *        "search": "Search"
     *      }
     *    }
     * }
     *
     */
    loadTranslations: function loadTranslations() {
      var theme = this;
      var url = theme.get('translations.url');
      return url ? theme._loadTranslations(url) : _ember['default'].A();
    },

    /**
     * Loads translations from a url
     * @param {string} url
     * @returns {Promise}
     * @private
     */
    _loadTranslations: function _loadTranslations(url) {
      return new _ember['default'].RSVP.Promise(function (resolve) {
        _ember['default'].$.get(url, null, resolve);
      });
    }
  });
});