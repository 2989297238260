define('quizzes-addon/components/player/qz-activity-feedback', ['exports', 'ember', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/config/parse-event', 'gooru-web/mixins/tenant-settings-mixin'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig, _quizzesAddonConfigParseEvent, _gooruWebMixinsTenantSettingsMixin) {
  exports['default'] = _ember['default'].Component.extend(_gooruWebMixinsTenantSettingsMixin['default'], {
    // -------------------------------------------------------------------------
    // Service

    /**
     * @requires service:session
     */
    session: _ember['default'].inject.service('session'),

    /**
     * @property {Service} parseEvent service
     */
    parseEventService: _ember['default'].inject.service('quizzes/api-sdk/parse-event'),

    // -------------------------------------------------------------------------
    // Observe

    /**
     * Observe the user feedback category
     */
    feedbackObserver: _ember['default'].observer('isShowFeedback', function () {
      if (this.get('isShowFeedback')) {
        this.$('.feedback-content-body').slideDown();
        this.set('isShowBackdrop', true);
      }
    }),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-activity-feedback resource-child'],

    // -------------------------------------------------------------------------
    // Events
    didInsertElement: function didInsertElement() {
      var component = this;
      component.$('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });
      if (component.get('attemptData') && component.get('attemptData.resourceResults') && component.get('feedbackContent')) {
        var currentResourceId = component.get('feedbackContent.id');
        var resourceResults = component.get('attemptData.resourceResults');
        var currentResource = resourceResults.findBy('resourceId', currentResourceId);
        if (currentResource) {
          component.set('ratingScore', currentResource.get('reaction'));
        }
      }
    },

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Action triggered when the user change the emotion
       * @see qz-emotion-picker
       */
      changeEmotion: function changeEmotion(emotionScore) {
        var component = this;
        var unicode = component.selectedEmotionUnicode(emotionScore);
        component.$('#emotion-' + emotionScore).find('svg use').attr('xlink:href', _quizzesAddonConfigQuizzesConfig.EMOTION_IMAGE + '#' + unicode);
        this.sendAction('onChangeEmotion', emotionScore);
      },

      /**
       * Action triggered when the user click on feedback tab and hover the next button
       */
      onToggleFeedbackContent: function onToggleFeedbackContent() {
        var component = this;
        component.$('.feedback-content-body').slideToggle();
        component.toggleProperty('isShowBackdrop');
        if (component.get('isShowBackdrop')) {
          component.get('parseEventService').postParseEvent(_quizzesAddonConfigParseEvent.PARSE_EVENTS.CLICK_FEEDBACK);
        }
      },

      onCloseFeedback: function onCloseFeedback() {
        this.sendAction('showFeedbackContainer');
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Indicates when the collection is already submitted
     * @property {boolean}
     */
    submitted: false,

    /**
     * Indicates when the back drop is shown
     * @property {boolean}
     */
    isShowBackdrop: false,

    /**
     * @property {string} on change emotion action
     */
    onChangeEmotion: 'onChangeEmotion',

    /**
     * @property {number} The rating score for the current resource
     */
    ratingScore: 0,

    /**
     * Indicates if changes can be made
     * @property {boolean} readOnly
     */
    readOnly: false,

    isShowReaction: _ember['default'].computed('tenantSettingsObj', function () {
      var tenantSettings = this.get('tenantSettingsObj');
      return tenantSettings && tenantSettings.ui_element_visibility_settings && tenantSettings.ui_element_visibility_settings.show_reaction_only ? tenantSettings.ui_element_visibility_settings.show_reaction_only : false;
    }),

    // -------------------------------------------------------------------------
    //  Methods

    /**
     * Find selected emotion unicode from rating score
     * @type {{String}}
     */
    selectedEmotionUnicode: function selectedEmotionUnicode(ratingScore) {
      if (ratingScore) {
        var selectedEmotion = _quizzesAddonConfigQuizzesConfig.EMOTION_VALUES.findBy('value', ratingScore);
        return selectedEmotion.unicode;
      }
      return _quizzesAddonConfigQuizzesConfig.NO_REACTION;
    }
  });
});