define('quizzes-addon/adapters/player/activity-feedback', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Adapter to support the capture activity feedback operations
   *
   * @typedef {Object} ActivityFeedbackAdapter
   */
  exports['default'] = _ember['default'].Object.extend({
    session: _ember['default'].inject.service('session'),

    activityFeedbackNamespace: '/api/ds/users/v2/activity',

    feedbackCategoryNamespace: '/api/nucleus/v1/lookups',

    /**
     * Fetch feedback category
     * @returns {Promise.<[]>}
     */
    getFeedbackCategories: function getFeedbackCategories(userCategoryId, language) {
      var adapter = this;
      var namespace = adapter.get('feedbackCategoryNamespace');
      var url = namespace + '/feedback-categories?user_category_id=' + userCategoryId + '&language=' + language;
      var options = {
        type: 'GET',
        headers: adapter.defineHeaders(),
        contentType: 'application/json; charset=utf-8'
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Fetch user activity feedback
     */
    fetchActivityFeedback: function fetchActivityFeedback(contentId, userId) {
      var adapter = this;
      var namespace = adapter.get('activityFeedbackNamespace');
      var url = namespace + '/feedbacks?content_id=' + contentId + '&user_id=' + userId;
      var options = {
        type: 'GET',
        headers: adapter.defineHeaders(),
        contentType: 'application/json; charset=utf-8'
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * submit user feedback for every activity
     * @returns {Promise.<[]>}
     */
    submitUserFeedback: function submitUserFeedback(feedbackData) {
      var adapter = this;
      var namespace = adapter.get('activityFeedbackNamespace');
      var url = namespace + '/feedbacks';
      var options = {
        type: 'POST',
        headers: adapter.defineHeaders(),
        contentType: 'application/json; charset=utf-8',
        dataType: 'text',
        processData: false,
        data: JSON.stringify(feedbackData)
      };
      return _ember['default'].$.ajax(url, options);
    },

    defineHeaders: function defineHeaders() {
      return {
        Authorization: 'Token ' + this.get('session.token-api3')
      };
    }
  });
});