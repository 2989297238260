define('quizzes-addon/components/editor/assessment/qz-header', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['header', 'assessment', 'edit'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      select: function select(value) {
        this.set('selected', value);
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {Object[]} headerActions List of action buttons to show
     */
    headerActions: [],

    /**
     * @property {Object[]} options List of tab options to show
     */
    options: [],

    /**
     * @property {String} selected Current option selected
     */
    selected: '',

    /**
     * @property {String} title Header title
     */
    title: ''
  });
});