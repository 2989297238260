define('quizzes-addon/models/result/context', ['exports', 'ember', 'quizzes-addon/models/result/question', 'quizzes-addon/models/result/resource'], function (exports, _ember, _quizzesAddonModelsResultQuestion, _quizzesAddonModelsResultResource) {

  /**
   * Model for a group of resources that were answered by a user during one attempt to complete an assessment.
   *
   * @typedef {Object} ContextResult
   *
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {number} averageReaction sumarization of all reactions
     */
    averageReaction: 0,

    /**
     * @property {Collection} collection
     */
    collection: null,

    /**
     * @property {string} collectionId
     */
    collectionId: null,

    /**
     * @property {Context} context
     */
    context: null,

    /**
     * @property {string} contextId
     */
    contextId: null,

    /**
     * @property {number} correctAnswers number of correct answers
     */
    correctAnswers: 0,

    /**
     * @property {number} correctPercentage percentage of correct answers
     */
    correctPercentage: 0,

    /**
     * @property {string} currentResourceId
     */
    currentResourceId: null,

    /**
     * @property {ResourceResult} currentResult
     */
    currentResource: _ember['default'].computed('currentResult', function () {
      return this.get('currentResult.resource');
    }),

    /**
     * @property {ResourceResult} currentResult
     */
    currentResult: _ember['default'].computed('currentResourceId', function () {
      return this.getResultByResourceId(this.get('currentResourceId'));
    }),

    /**
     * Indicates if it has mastery
     * @property {boolean} hasMastery
     */
    hasMastery: _ember['default'].computed.bool('mastery.length'),

    /**
     * @property {boolean} isRealTime
     */
    isRealTime: false,

    /**
     * TODO: TBD
     * @property {Object[]} mastery - An array of learning target objects
     */
    mastery: [],

    /**
     * @property {QuestionResult[]} questionResults
     */
    questionResults: _ember['default'].computed('resourceResults.@each.updated', function () {
      return this.get('resourceResults').filter(function (resourceResult) {
        return resourceResult instanceof _quizzesAddonModelsResultQuestion['default'];
      });
    }),

    /**
     * @property {QuestionResult[]} nonOpenEndedQuestionResults
     */
    nonOpenEndedQuestionResults: _ember['default'].computed('questionResults.@each.updated', function () {
      return this.get('questionResults').filter(function (questionResult) {
        return !questionResult.get('question.isOpenEnded');
      });
    }),

    /**
     * @property {ResourceResult[]} resourceResults
     */
    resourceResults: _ember['default'].A([]),

    /**
     * @property {QuestionResult[]} questionResults
     */
    sortedResourceResults: _ember['default'].computed('resourceResults.@each.updated', function () {
      return this.get('resourceResults').sortBy('resource.sequence');
    }),

    /**
     * @property {boolean} showAttempts
     */
    showAttempts: false,

    /**
     * @property {boolean} submitted if the context has already been submitted
     */
    submitted: false,

    /**
     * @property {date} submittedAt Date of submission
     */
    submittedAt: 0,

    /**
     * @property {string} title - Title of the assessment
     */
    title: _ember['default'].computed.alias('collection.title'),

    /**
     * @property {number} totalAttempts number of attempts made
     */
    totalAttempts: 0,

    /**
     * @property {number} totalResources
     */
    totalResources: _ember['default'].computed.alias('resourceResults.length'),

    /**
     * @property {number} totalQuestions that aren't open ended
     */
    totalNonOpenEndedQuestions: _ember['default'].computed.alias('nonOpenEndedQuestionResults.length'),

    /**
     * @property {number} totalTimeSpent sum of all the time spent
     */
    totalTimeSpent: 0,

    /**
     * @property {Array} children of collection
     */
    children: _ember['default'].A([]),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Gets the result by resource id
     * @param {string} resourceId
     * @returns {ResourceResult}
     */
    getResultByResourceId: function getResultByResourceId(resourceId) {
      return this.get('resourceResults').findBy('resourceId', resourceId);
    },

    /**
     * Initializes the assessment results
     * @param {Collection} collection
     */
    // TODO change may be needed when quizzes endpoint to retrieve resources is ready
    merge: function merge(collection) {
      this.set('collection', collection);
      var resourceResults = this.get('resourceResults');
      var resources = collection.get('resources');

      if (resources.get('length')) {
        resources.forEach(function (resource) {
          var resourceId = resource.get('id');
          var found = resourceResults.findBy('resourceId', resourceId);
          if (!found) {
            var result = resource.get('isQuestion') ? _quizzesAddonModelsResultQuestion['default'].create({ resourceId: resourceId, resource: resource }) : _quizzesAddonModelsResultResource['default'].create({ resourceId: resourceId, resource: resource });
            resourceResults.pushObject(result);
          } else {
            if (resource.get('isResource')) {
              var result = _quizzesAddonModelsResultResource['default'].create({
                resourceId: resourceId,
                resource: resource,
                savedTime: found.savedTime,
                reaction: found.reaction,
                answer: found.answer || null,
                score: found.score,
                skipped: found.skipped
              });
              resourceResults.removeObject(found);
              resourceResults.pushObject(result);
            }
            found.set('resource', resource);
          }
        });

        var currentResourceId = this.get('currentResourceId');
        if (!currentResourceId || resourceResults.findBy('id', currentResourceId)) {
          this.set('currentResourceId', resources[0].get('id'));
        }
      } else {
        _ember['default'].Logger.error('Collection with ID: ' + collection.get('id') + ' does not have any resources. No resource results were set.');
      }
    }
  });
});