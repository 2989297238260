define('quizzes-addon/adapters/application', ['exports', 'ember', 'quizzes-addon/mixins/endpoint-config'], function (exports, _ember, _quizzesAddonMixinsEndpointConfig) {

  /**
   * Adapter to support the Lookup API 3.0 integration
   *
   * @typedef {Object} LookupAdapter
   */
  exports['default'] = _ember['default'].Object.extend(_quizzesAddonMixinsEndpointConfig['default'], {
    namespace: '/config',

    /**
     * Gets custom configuration
     * @returns {Promise.<[]>}
     */
    sendAjaxRequest: function sendAjaxRequest(url, options) {
      var _this = this;

      options.beforeSend = function (jqXHR, settings) {
        var url = settings.url;
        if (url.startsWith('/')) {
          if (url.startsWith(_this.getRealTimeWebServiceUri()) || url.startsWith(_this.getRealTimeWebSocketUri())) {
            var realTimeUrl = _this.getRealTimeWebServiceUrl();
            settings.url = '' + realTimeUrl + url;
          }
          if (url.startsWith('/quizzes')) {
            var endpointUrl = _this.getEndpointUrl();
            settings.url = '' + endpointUrl + url;
          } else {
            var endpointUrl = _this.getEndpointProviderUrl();
            settings.url = '' + endpointUrl + url;
          }
        }
      };
      return _ember['default'].$.ajax(url, options);
    }
  });
});