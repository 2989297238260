define('quizzes-addon/adapters/lookup/lookup', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Adapter to support the Lookup API 3.0 integration
   *
   * @typedef {Object} LookupAdapter
   */
  exports['default'] = _ember['default'].Object.extend({
    namespace: '/api/nucleus/v1/lookups',

    /**
     * Gets the audience list information
     * @returns {Promise.<[]>}
     */
    readAudiences: function readAudiences() {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/audience';
      var options = {
        type: 'GET',
        headers: adapter.defineHeaders()
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Gets the depth of knowledge list information
     * @returns {Promise.<[]>}
     */
    readDepthOfKnowledgeItems: function readDepthOfKnowledgeItems() {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/dok';
      var options = {
        type: 'GET',
        headers: adapter.defineHeaders()
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Gets the depth of knowledge list information
     * @returns {Promise.<[]>}
     */
    readLicenses: function readLicenses() {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/licenses';
      var options = {
        type: 'GET',
        headers: adapter.defineHeaders()
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Gets the countries information
     *
     * @param {string} keyword optional keyword
     * @returns {Promise}
     */
    readCountries: function readCountries(keyword) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/countries';
      var data = keyword ? { keyword: keyword } : {};

      var options = {
        type: 'GET',
        //using default content type so this is serialized as query params
        headers: adapter.defineHeaders(),
        data: data
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Gets the states information
     *
     * @param {string} country id required
     * @param {string} keyword optional keyword
     * @returns {Promise}
     */
    readStates: function readStates(countryId, keyword) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/countries/' + countryId + '/states';
      var data = keyword ? { keyword: keyword } : {};

      var options = {
        type: 'GET',
        //using default content type so this is serialized as query params
        headers: adapter.defineHeaders(),
        data: data
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     * Gets the districts information
     *
     * @param {string} state id optional
     * @param {string} keyword optional keyword
     * @returns {Promise}
     */
    readDistricts: function readDistricts(stateId, keyword) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/school-districts';
      var data = {};

      if (keyword) {
        data.keyword = keyword;
      }

      if (stateId) {
        data.state_id = stateId;
      }

      var options = {
        type: 'GET',
        //using default content type so this is serialized as query params
        headers: adapter.defineHeaders(),
        data: data
      };
      return _ember['default'].$.ajax(url, options);
    },

    /**
     *
     * @returns {{Authorization: string}}
     */
    defineHeaders: function defineHeaders() {
      return {
        Authorization: 'Token '
      };
    }
  });
});