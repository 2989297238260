define('quizzes-addon/components/qz-preview-url', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-preview-url'],

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {Number} the calculated resource content height
     */
    calculatedResourceContentHeight: null,

    /**
     * @property {Service} Configuration service
     */
    quizzesConfigurationService: _ember['default'].inject.service('quizzes/configuration'),

    /**
     * @property {string} bind the height css style for the component
     */
    resourceHeight: _ember['default'].computed('calculatedResourceContentHeight', function () {
      var height = this.get('calculatedResourceContentHeight');
      var heightString = height > 0 ? height + 'px' : '100%';
      return _ember['default'].String.htmlSafe('height: ' + heightString);
    }),

    /**
     * @property {string} Resource URL
     */
    url: _ember['default'].computed('resource.body', function () {
      var component = this;
      var resourceUrl = component.get('resource.body');
      var cdnUrl = component.get('quizzesConfigurationService.configuration.properties.cdnURL');
      return (0, _quizzesAddonUtilsUtils.toAbsolutePath)(resourceUrl, cdnUrl);
    }),

    resource: null
  });
});