define('quizzes-addon/serializers/resource/resource', ['exports', 'ember', 'quizzes-addon/models/resource/resource', 'quizzes-addon/models/resource/answer', 'quizzes-addon/config/quizzes-question', 'quizzes-addon/serializers/rubric/rubric'], function (exports, _ember, _quizzesAddonModelsResourceResource, _quizzesAddonModelsResourceAnswer, _quizzesAddonConfigQuizzesQuestion, _quizzesAddonSerializersRubricRubric) {

  /**
   * Serializer for Resource
   *
   * @typedef {Object} ResourceSerializer
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {rubricSerializer} taxonomySerializer
     */
    rubricSerializer: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('rubricSerializer', _quizzesAddonSerializersRubricRubric['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },
    /**
     * @property {Ember.Service} Service to session
     */
    session: _ember['default'].inject.service('session'),

    /**
     * Assign the content CDN url from authenticated session object
     * @type {String}
     */
    contentCDNUrl: _ember['default'].computed.alias('session.cdnUrls.content'),

    /**
     * Normalize the resource data into a Resource object
     * @param resourceData
     * @returns {Resource}
     */
    normalizeReadResource: function normalizeReadResource(resourceData) {
      var serializer = this;
      var questionData = resourceData.title || !resourceData.metadata ? resourceData : resourceData.metadata;
      var interaction = questionData ? questionData.interaction : null;
      var playerMetadata = resourceData.playerMetadata ? resourceData.playerMetadata : null;
      var additionalAttributes = playerMetadata && playerMetadata.additional_attributes ? playerMetadata.additional_attributes : null;
      var questionMetadata = questionData.questionMetadata || null;
      if (!questionData.learningToolId && questionData.product) {
        questionData.learningToolId = questionData.product.learing_tool_id;
      }

      var resource = _quizzesAddonModelsResourceResource['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        id: resourceData.id,
        isResource: resourceData.isResource,
        sequence: resourceData.sequence,
        answerDetails: questionData.answers,
        hint: questionData.hintExplanationDetail,
        body: questionData.url || questionData.body,
        description: questionData.description,
        correctAnswer: questionData.correctAnswer,
        narration: questionData.narration,
        ownerId: questionData.creator_id || questionData.ownerId,
        title: questionData.title,
        thumbnail: questionData.thumbnail,
        learningToolId: questionData.learningToolId,
        product: questionData.product,
        displayGuide: questionData.display_guide && (questionData.display_guide.is_broken === 1 || questionData.display_guide.is_frame_breaker === 1),
        type: questionData.content_subformat || questionData.type,
        mediaUrl: questionData.thumbnail ? serializer.get('contentCDNUrl') + questionData.thumbnail : null,
        baseAnswers: questionData.answers,
        playerMetadata: resourceData.playerMetadata ? resourceData.playerMetadata.video_timeline_by_competencies : [],
        metadata: questionMetadata,
        htmlContent: questionData.html_content ? decodeURIComponent(escape(atob(questionData.html_content))) : null,
        showScore: !(resourceData && resourceData.display_guide && resourceData.display_guide.show_scores === false)
      });

      if (resourceData.SubResource && resourceData.SubResource.length) {
        resource.set('subQuestions', _ember['default'].A(resourceData.SubResource.map(function (item) {
          return serializer.normalizeReadResource(item);
        })));
      }

      resource.set('displayGuide', resource.get('displayGuide') || this.checkURLProtocol(resource.body));

      if (interaction) {
        resource.setProperties({
          answers: serializer.normalizeAnswers(interaction.choices),
          maxChoices: interaction.maxChoices,
          prompt: interaction.prompt,
          shuffle: interaction.isShuffle
        });
      }
      if (resource.get('type') === _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.openEnded && resourceData.rubric) {
        resource.set('rubric', this.get('rubricSerializer').normalizeRubric(resourceData.rubric));
      }
      if (resource.get('type') === _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.serpChooseOne && additionalAttributes) {
        resource.set('questionText', additionalAttributes.chooseone_question_text || null);
      }
      if (resource.get('type') === _quizzesAddonConfigQuizzesQuestion.QUESTION_TYPES.serpSorting && additionalAttributes) {
        resource.setProperties({
          softText: additionalAttributes.soft_text || '',
          hardText: additionalAttributes.hard_text || ''
        });
      }
      return resource;
    },

    /**
     * Normalize the choices data into answers array
     * @param choices array
     * @returns {Answer[]}
     */
    normalizeAnswers: function normalizeAnswers(choices) {
      var _this = this;

      return _ember['default'].isArray(choices) ? choices.map(function (answer) {
        return _this.normalizeAnswer(answer);
      }) : [];
    },

    /**
     * Normalize the choice data into an answer object
     * @param choice object
     * @returns {Answer}
     */
    normalizeAnswer: function normalizeAnswer(choice) {
      return _quizzesAddonModelsResourceAnswer['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        isFixed: choice.isFixed,
        text: choice.text,
        value: choice.value,
        struggles: choice.struggles
      });
    },

    /**
     * Check if the current location protocol matches
     * @param url
     * @returns {Boolean}
     */
    checkURLProtocol: function checkURLProtocol(url) {
      return window.location.protocol === 'https:' && /^((http):\/\/)/.test(url);
    }
  });
});