define('quizzes-addon/models/result/resource', ['exports', 'ember', 'quizzes-addon/mixins/serializable'], function (exports, _ember, _quizzesAddonMixinsSerializable) {

  /**
   * Model for the status of a resource after it has been viewed by a user.
   *
   * @typedef {Object} ResourceResult
   *
   */
  exports['default'] = _ember['default'].Object.extend(_quizzesAddonMixinsSerializable['default'], {
    /**
     * Values: started / skipped
     *
     * @property {String}
     */
    attemptStatus: _ember['default'].computed('correct', 'skipped', function () {
      var skipped = this.get('skipped');
      return skipped ? 'skipped' : 'started';
    }),

    /**
     * @property {boolean} isCorrect
     */
    isCorrect: _ember['default'].computed('score', function () {
      return this.get('score') === 100;
    }),

    /**
     * @property {boolean} isQuestion
     */
    isQuestion: _ember['default'].computed.not('isResource'),

    /**
     * @property {boolean} isQuestion
     */
    isResource: _ember['default'].computed.bool('resource.isResource'),

    /**
     * @property {boolean} isQuestion
     */
    isComprehension: _ember['default'].computed.bool('resource.subQuestions.length'),

    /**
     * @property {number} reaction - user reaction to the resource
     */
    reaction: 0,

    /**
     * @property {number} resource - the resource
     */
    resource: null,

    /**
     * @property {number} resourceId - ID of the resource
     */
    resourceId: null,

    /**
     * @property {number} savedTime - Current loaded time for the resource
     */
    savedTime: 0,

    /**
     * @property {number} skipped - if the resource was skipped
     */
    skipped: true,

    /**
     * @property {number} started - if the resource was started
     */
    started: _ember['default'].computed.not('skipped'),

    /**
     * @property {number} startTime - Current start time for the resource
     */
    startTime: 0,

    /**
     * @property {number} subStartTime - help to maintain the sub question
     */
    subStartTime: 0,

    /**
     * @property {number} stopTime - Current stop time for the resource
     */
    stopTime: 0,

    /**
     * @property {number} timeSpent - Time spent in this resource
     */
    timeSpentToSave: _ember['default'].computed('startTime', 'stopTime', 'savedTime', 'subStartTime', function () {
      var subStartTime = this.get('subStartTime');
      var startTime = this.get('startTime') || 0;
      startTime = subStartTime ? subStartTime : startTime;
      var stopTime = this.get('stopTime') || startTime;
      return this.roundMilliseconds(stopTime - startTime);
    }),

    /**
     * @property {number} timeSpent - Time spent in this resource
     */
    timeSpent: _ember['default'].computed('startTime', 'stopTime', 'savedTime', 'subStartTime', function () {
      var subStartTime = this.get('subStartTime');
      var startTime = this.get('startTime') || 0;
      startTime = subStartTime ? subStartTime : startTime;
      var stopTime = this.get('stopTime') || startTime;
      return this.roundMilliseconds(stopTime - startTime);
    }),

    /**
     * @property {Number} updated keep track of updated to redraw realtime dashboard
     */
    updated: 0,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Clear properties
     */
    clear: function clear() {
      this.set('reaction', 0);
      this.set('savedTime', 0);
      this.set('startTime', 0);
      this.set('stopTime', 0);
    },

    /**
     * Round milliseconds
     */
    roundMilliseconds: function roundMilliseconds(milliseconds) {
      return milliseconds - milliseconds % 1000;
    },

    /**
     * Return a copy of the level
     *
     * @function
     * @return {Level}
     */
    copy: function copy() {
      var properties = this.getProperties(this.modelProperties());
      return this.get('constructor').create(properties);
    },
    /**
     * Return a list of properties
     *
     * @function
     * @return {Array}
     */
    modelProperties: function modelProperties() {
      var properties = [];
      var enumerableKeys = Object.keys(this);
      for (var i = 0; i < enumerableKeys.length; i++) {
        var key = enumerableKeys[i];
        var value = _ember['default'].typeOf(this.get(key));
        if (value === 'string' || value === 'number' || value === 'boolean') {
          properties.push(key);
        }
      }
      return properties;
    }
  });
});