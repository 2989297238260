define('quizzes-addon/components/assignment/qz-assign', ['exports', 'ember', 'quizzes-addon/models/profile/profile'], function (exports, _ember, _quizzesAddonModelsProfileProfile) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Events
    init: function init() {
      this._super.apply(this, arguments);
      this.set('teacher', _quizzesAddonModelsProfileProfile['default'].create({
        id: this.get('teacherConfig.id'),
        firstName: this.get('teacherConfig.firstName'),
        lastName: this.get('teacherConfig.lastName'),
        username: this.get('teacherConfig.username'),
        email: this.get('teacherConfig.email')
      }));
    },
    // -------------------------------------------------------------------------
    // Properties
    /**
     * Owner of the assignment
     */
    teacher: null,
    /**
     * Student list
     */
    students: null
  });
});