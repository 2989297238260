define('quizzes-addon/components/reports/assessment/questions/qz-serp-pick-n-choose', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * SERP Pick N Choose
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-serp-pick-n-choose'],

    // -------------------------------------------------------------------------
    // Properties

    showCorrect: false,

    userAnswers: _ember['default'].computed.alias('question.answer'),

    baseAnswers: _ember['default'].computed('question.resource', function () {
      var baseAnswers = this.get('question.resource.baseAnswers');
      var answers = this.get('question.resource.answers');
      baseAnswers = baseAnswers.map(function (item, index) {
        item.id = answers[index].value;
        return item;
      });
      return baseAnswers;
    })
  });
});