define("quizzes-addon/utils/encode-term", ["exports"], function (exports) {
  exports.encodeTerm = encodeTerm;
  /**
   * This function encodes special characters.
   */

  function encodeTerm(term) {
    return encodeURIComponent(term).replace(/[!'()*]/g, function (c) {
      return "%" + c.charCodeAt(0).toString(16);
    });
  }
});