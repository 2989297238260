define('quizzes-addon/components/reports/assessment/questions/qz-serp-multi-choice', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -----------------------------------------------
    // Attributes
    classNames: ['reports', 'assessment', 'questions', 'qz-serp-choose-one'],

    // -----------------------------------------------------------
    // Properties

    showCorrect: false
  });
});