define('quizzes-addon/components/reports/assessment/questions/qz-comprehension', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------
    // Attributes
    classNames: ['reports', 'assessment', 'questions', 'qz-comprehension'],

    // -------------------------------------------------------------
    // Properties

    showCorrect: false,

    showPerformance: true,

    question: null,

    subQuestions: _ember['default'].computed('question', function () {
      return this.get('question.resource.subResource') || _ember['default'].A([]);
    })

    // --------------------------------------------------------------
    // Hooks

    // -------------------------------------------------------------
    // Methods
  });
});