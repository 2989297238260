define('quizzes-addon/serializers/context/context', ['exports', 'ember', 'quizzes-addon/models/result/context', 'quizzes-addon/models/result/question', 'quizzes-addon/models/context/context'], function (exports, _ember, _quizzesAddonModelsResultContext, _quizzesAddonModelsResultQuestion, _quizzesAddonModelsContextContext) {
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @requires service:session
     */
    session: _ember['default'].inject.service('session'),

    /**
     * Normalizes a ContextResult
     * @param {ContextResult} contextResult
     * @returns {*[]}
     */
    normalizeContextResult: function normalizeContextResult(payload) {
      var serializer = this;
      return _quizzesAddonModelsResultContext['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        contextId: payload.contextId,
        currentResourceId: payload.currentResourceId,
        resourceResults: serializer.normalizeResourceResults(payload.events),
        collectionId: payload.collectionId,
        hasStarted: !!(payload.events && payload.events.length > 0),
        sessionId: payload.sessionId
      });
    },

    /**
     * Serializes read assignment
     ** @param {*[]} payload
     */
    normalizeReadContext: function normalizeReadContext(payload) {
      var contextData = payload.contextData;
      var metadata = contextData ? contextData.metadata : {};
      return _quizzesAddonModelsContextContext['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        id: payload.contextId,
        title: metadata.title,
        description: metadata.description,
        classId: payload.classId,
        collectionId: payload.collectionId,
        isCollection: payload.isCollection,
        profileId: payload.profileId,
        hasStarted: payload.hasStarted
      });
    },

    /**
     * Serializes read assignments
     ** @param {*[]} payload
     */
    normalizeReadContexts: function normalizeReadContexts(payload) {
      var _this = this;

      payload = payload || [];
      return payload.map(function (assignment) {
        return _this.normalizeReadContext(assignment);
      });
    },

    /**
     * Serializes normalize Evidence
     ** @param {*[]} payload
     */
    normalizeEvidence: function normalizeEvidence(payload) {
      var _this2 = this;

      if (payload) {
        var _ret = (function () {
          var evidenceList = _ember['default'].A([]);
          payload.map(function (evidence) {
            var cdnURL = _this2.get('session.cdnUrls.content');
            var originalFileName = {
              fileName: cdnURL + evidence.fileName,
              originalFileName: evidence.originalFileName
            };
            evidenceList.pushObject(originalFileName);
          });
          return {
            v: evidenceList
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      } else {
        return null;
      }
    },

    /**
     * Normalizes list of resource results
     * @param {*[]} payload
     * @returns {ResourceResult[]}
     */
    normalizeResourceResults: function normalizeResourceResults(payload) {
      var _this3 = this;

      var serializer = this;
      payload = payload || [];
      return payload.map(function (resourceResult) {
        return _quizzesAddonModelsResultQuestion['default'].create(_ember['default'].getOwner(serializer).ownerInjection(), {
          resourceId: resourceResult.resourceId,
          savedTime: resourceResult.timeSpent,
          reaction: resourceResult.reaction,
          answer: resourceResult.answer || null,
          score: resourceResult.score,
          skipped: resourceResult.isSkipped,
          subResources: resourceResult.subResource || null,
          evidence: _this3.normalizeEvidence(resourceResult.evidence)
        });
      });
    },

    /**
     * Serializes an assignment
     * @param {Assignment} assignment
     ** @return {*[]} payload
     */
    serializeContext: function serializeContext(assignment) {
      var serializedAssignment = this.serializeUpdateContext(assignment);
      serializedAssignment.collectionId = assignment.get('collectionId');
      serializedAssignment.classId = assignment.get('classId');
      serializedAssignment.isCollection = assignment.get('isCollection');
      serializedAssignment.hasStarted = assignment.get('hasStarted');
      return serializedAssignment;
    },

    /**
     * Serializes a ResourceResult
     * @param {ResourceResult} resourceResult
     * @returns {*}
     */
    serializeResourceResult: function serializeResourceResult(resourceResult) {
      var sendResourceId = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

      var serialized = {
        reaction: resourceResult.get('reaction'),
        timeSpent: resourceResult.get('timeSpentToSave')
      };
      if (resourceResult.evidence && resourceResult.evidence.length) {
        serialized.evidence = resourceResult.evidence;
      }
      if (sendResourceId) {
        serialized.resourceId = resourceResult.get('resourceId');
      }
      if (resourceResult.baseResourceId) {
        serialized.baseResourceId = resourceResult.baseResourceId;
      }

      if (resourceResult.get('isQuestion')) {
        serialized.answer = resourceResult.get('answer') ? resourceResult.get('answer').map(function (_ref) {
          var value = _ref.value;
          return {
            value: value
          };
        }) : null;
      }
      return serialized;
    },

    /**
     * Serializes an assignment to update
     * @param {Assignment} assignment
     ** @return {*[]} payload
     */
    serializeUpdateContext: function serializeUpdateContext(assignment) {
      return {
        contextData: {
          contextMap: assignment.get('contextMapping'),
          metadata: {
            title: assignment.get('title'),
            description: assignment.get('description')
          }
        }
      };
    },

    /**
     * Serializes the context params for analytics
     * @param {String} eventSource
     * @param {String} pathId
     * @param {String} collectionSubType
     * @param {Object} cul { classId, courseId, unitId, lessonId, collectionId }
     ** @return {*} payload
     */
    serializeEventContext: function serializeEventContext(context) {
      var source = context.source;
      var sourceUrl = context.sourceUrl;
      var sourceId = context.sourceId;
      var tenantId = context.tenantId;
      var partnerId = context.partnerId;
      var pathId = context.pathId;
      var timezone = context.timezone;
      var classId = context.classId;
      var courseId = context.courseId;
      var unitId = context.unitId;
      var lessonId = context.lessonId;
      var collectionId = context.collectionId;
      var collectionSubType = context.collectionSubType;
      var pathType = context.pathType;
      var ctxPathType = context.ctxPathType;
      var ctxPathId = context.ctxPathId;

      var eventContext = {
        eventSource: source,
        sourceUrl: sourceUrl,
        sourceId: sourceId,
        tenantId: tenantId,
        partnerId: partnerId,
        pathId: pathId ? +pathId : 0,
        timezone: timezone,
        collectionSubType: collectionSubType,
        pathType: pathType,
        ctxPathType: ctxPathType,
        ctxPathId: ctxPathId
      };
      if (classId) {
        eventContext = Object.assign(eventContext, {
          classId: classId,
          courseId: courseId,
          unitId: unitId,
          lessonId: lessonId,
          collectionId: collectionId
        });
      }
      return eventContext;
    }
  });
});