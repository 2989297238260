define('quizzes-addon/services/quizzes/api-sdk/lookup', ['exports', 'ember', 'quizzes-addon/serializers/lookup/lookup', 'quizzes-addon/adapters/lookup/lookup'], function (exports, _ember, _quizzesAddonSerializersLookupLookup, _quizzesAddonAdaptersLookupLookup) {

  /**
   * Service to support the Lookup entities
   *
   * Country, State, District
   *
   * @typedef {Object} LookupService
   */
  exports['default'] = _ember['default'].Service.extend({
    lookupSerializer: null,

    lookupAdapter: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('lookupSerializer', _quizzesAddonSerializersLookupLookup['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('lookupAdapter', _quizzesAddonAdaptersLookupLookup['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    /**
     * Gets the audience information
     * @returns {Promise.<Audience[]>}
     */
    readAudiences: function readAudiences() {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('lookupAdapter').readAudiences().then(function (response) {
          resolve(service.get('lookupSerializer').normalizeReadAudiences(response));
        }, reject);
      });
    },

    /**
     * Gets the depth of knowlege information
     * @returns {Promise.<DepthOfKnowledge[]>}
     */
    readDepthOfKnowledgeItems: function readDepthOfKnowledgeItems() {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('lookupAdapter').readDepthOfKnowledgeItems().then(function (response) {
          resolve(service.get('lookupSerializer').normalizeReadDepthOfKnowledgeItems(response));
        }, reject);
      });
    },

    /**
     * Gets the license information
     * @returns {Promise.<License[]>}
     */
    readLicenses: function readLicenses() {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('lookupAdapter').readLicenses().then(function (response) {
          resolve(service.get('lookupSerializer').normalizeReadLicenses(response));
        }, reject);
      });
    },

    /**
     * Gets the audience information
     * @returns {Promise.<Audience[]>}
     */
    readAudienceItems: function readAudienceItems() {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('lookupAdapter').readAudienceItems().then(function (response) {
          resolve(response);
          //resolve(service.get('lookupSerializer').normalizeReadCountries(response));
        }, reject);
      });
    },

    /**
     * Gets the countries information
     * @param {string} keyword optional
     *
     * @returns {Promise}
     */
    readCountries: function readCountries(keyword) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('lookupAdapter').readCountries(keyword).then(function (response) {
          resolve(service.get('lookupSerializer').normalizeReadCountries(response));
        }, reject);
      });
    },

    /**
     * Gets the states information
     * @param {string} country id
     * @param {string} keyword optional
     *
     * @returns {Promise}
     */
    readStates: function readStates(countryId, keyword) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('lookupAdapter').readStates(countryId, keyword).then(function (response) {
          resolve(service.get('lookupSerializer').normalizeReadStates(response));
        }, reject);
      });
    },

    /**
     * Gets the districts information
     * @param {string} stateId
     * @param {string} keyword optional
     *
     * @returns {Promise}
     */
    readDistricts: function readDistricts(stateId, keyword) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('lookupAdapter').readDistricts(stateId, keyword).then(function (response) {
          resolve(service.get('lookupSerializer').normalizeReadDistricts(response));
        }, reject);
      });
    }
  });
});