define('quizzes-addon/components/charts/gru-x-bar-chart', ['exports', 'ember'], function (exports, _ember) {
  /**
   * Stacked Horizontal Bar Chart
   *
   * Component responsible for showing the stacked horizontal bar chart.
   * This component takes the dimensions of height and width from the parent element.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['charts', 'gru-x-bar-chart'],

    // -------------------------------------------------------------------------
    // Properties

    /**
     *
     * Sample
     * [
     *    {
     *      color: failColor,
     *      percentage: Math.round(dataObj.incorrect / dataObj.total * 100)
     *    },
     *    {
     *      color: correctColor,
     *      percentage: Math.round(dataObj.correct / dataObj.total * 100)
     *    },
     *    ...
     *  ]
     * @property {Array} options data
     */
    data: null,

    isFull: _ember['default'].computed('data.@each.color', 'data.@each.percentage', function () {
      var sum = this.get('data').reduce(function (previousValue, value) {
        return previousValue + value.percentage;
      }, 0);
      return sum >= 100;
    }),

    styles: _ember['default'].computed('data', function () {
      return this.get('data').map(function (questionData) {
        return _ember['default'].String.htmlSafe('background-color: ' + questionData.color + '; width: ' + questionData.percentage + '%;');
      });
    }),

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      if (!this.validValues()) {
        _ember['default'].Logger.warn('Graph values sum more than 100');
      }
    },

    /*
     * Check if the values are up 100%
     */
    validValues: function validValues() {
      var sum = this.get('data').reduce(function (previousValue, value) {
        return previousValue + parseInt(value.percentage);
      }, 0);
      return sum <= 100;
    }
  });
});