define('quizzes-addon/components/player/qz-free-response-viewer', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question', 'gooru-web/mixins/tenant-settings-mixin'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _gooruWebMixinsTenantSettingsMixin) {
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend(_gooruWebMixinsTenantSettingsMixin['default'], {
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @requires service:quizzes/configuration
     */
    configurationService: _ember['default'].inject.service('quizzes/configuration'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['player', 'qz-free-response-viewer'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Show full rubric
       */
      showFullRubric: function showFullRubric() {
        this.set('showFullRubric', !this.get('showFullRubric'));
      },
      /**
       * Submit Question
       */
      submitQuestion: function submitQuestion() {
        this.sendAction('onSubmitQuestion');
      },

      showFullImage: function showFullImage() {
        this.set('isShowFullImage', true);
      },

      onCloseFullImage: function onCloseFullImage() {
        this.set('isShowFullImage', false);
      },

      onFocusOut: function onFocusOut() {
        var component = this;
        if (component.get('isComprehension') && component.get('isAnswerCompleted')) {
          var answer = [{
            value: _ember['default'].$.trim(component.get('answer'))
          }];
          component.notifyAnswerCompleted(answer);
        }
      }
    },

    // -------------------------------------------------------------------------
    // Events

    /**
     * When loading the user answer
     */
    updateUserAnswer: _ember['default'].on('init', function () {
      var component = this;
      component.setAnswers();
    }),

    // -------------------------------------------------------------------------
    // Properties
    /**
     * @property {string} the user answer
     */
    answer: '',

    /**
     * @property {Array[RubricCategory]} categories
     */
    categories: _ember['default'].computed('hasRubric', function () {
      return this.get('question.rubric').get('categories');
    }),

    viewMetadataObserver: _ember['default'].observer('content', function () {
      var component = this;
      component.fetchContentInfo();
    }),

    /**
     * Indicates if the question has a rubric assigned
     * @return {bool}
     */
    hasRubric: _ember['default'].computed.bool('question.rubric.rubricOn'),

    /**
     * Indicates if rubric contains a url
     * @return {bool}
     */
    hasUrl: _ember['default'].computed.bool('question.rubric.url'),

    /**
     * Indicates when the answer is completed
     * @return {bool}
     */
    isAnswerCompleted: _ember['default'].computed.bool('answer.length'),

    /**
     * Free Response Question
     * @property {Question} question
     */
    question: null,

    /**
     * Parsed Question Text
     * @property {String} questionText
     */
    questionText: null,

    isShowFullImage: false,

    /**
     * @property {Boolean} showFullRubric
     */
    showFullRubric: false,

    /**
     * Indicates when the answer is completed
     * @return {bool}
     */
    showPanel: _ember['default'].computed('hasRubric', 'hasUrl', 'categories', function () {
      return this.get('hasRubric') && (this.get('categories.length') || this.get('hasUrl'));
    }),

    /**
     * @property {Number} totalPoints
     */
    totalPoints: _ember['default'].computed('hasRubric', function () {
      return this.get('question.rubric').get('totalPoints');
    }),

    /**
     * Returns the thumbnail url if it exists
     * @property {String}
     */
    thumbnail: _ember['default'].computed('question.thumbnail', function () {
      var cdnURL = this.get('configurationService.configuration.properties.cdnURL');
      return this.get('question.thumbnail') ? '' + cdnURL + this.get('question.thumbnail') : null;
    }),

    isComprehension: false,

    comprehensionIndex: 0,

    // -------------------------------------------------------------------------
    // Observers

    /**
     * When the user changes the response
     */
    updateAnswerObserver: function updateAnswerObserver() {
      this.notify(false);
    },

    watchObserver: _ember['default'].observer('questionResult.evidence', function () {
      var component = this;
      component.notifyAnswerCompleted(null);
    }),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * @function fetchContentInfo
     * Method to fetch confirmation info data
     */
    fetchContentInfo: function fetchContentInfo() {
      var component = this;
      var playerMetadata = component.get('content.player_metadata');
      var contentSubformat = component.get('content.content_subformat');
      var questionEvidenceVisibility = component.get('questionEvidenceVisibility');

      if (questionEvidenceVisibility) {
        var questionEvidenceVisibilityKeyCheck = (contentSubformat in questionEvidenceVisibility);
        var defaultKeyCheck = ('default' in questionEvidenceVisibility);
        var contentSubformatCheck = questionEvidenceVisibility[contentSubformat];
        var defaultCheck = questionEvidenceVisibility['default'];

        component.set('questionEvidenceVisibilityKeyCheck', questionEvidenceVisibilityKeyCheck);
        component.set('contentSubformatCheck', contentSubformatCheck);
        component.set('defaultKeyCheck', defaultKeyCheck);
        component.set('defaultCheck', defaultCheck);
      }

      if (playerMetadata) {
        var tmpIsEvidenceEnabled = ('isEvidenceEnabled' in playerMetadata);
        component.set('playerMetadata', playerMetadata);
        component.set('tmpIsEvidenceEnabled', tmpIsEvidenceEnabled);
      }
    },

    /**
     * Notifies answer events
     * @param {boolean} onLoad if this was called when loading the component
     */
    notify: function notify(onLoad) {
      var component = this,
          answer = [{
        value: _ember['default'].$.trim(component.get('answer'))
      }];
      component.notifyAnswerChanged(answer);
      var evidence = component.get('questionResult.evidence');
      if (component.get('isAnswerCompleted') || evidence) {
        if (onLoad) {
          component.notifyAnswerLoaded(answer);
        } else {
          if (!component.get('isComprehension')) {
            component.notifyAnswerCompleted(answer);
          }
        }
      } else {
        component.notifyAnswerCleared(answer);
      }
    },

    /**
     * Set answer
     * */
    setAnswers: function setAnswers() {
      if (this.get('hasUserAnswer')) {
        var userAnswer = this.get('userAnswer.firstObject.value');
        this.set('answer', userAnswer);
        this.notify(true);
      }
      // set observer for answer update
      this.addObserver('answer', this.updateAnswerObserver);
    }
  });
});