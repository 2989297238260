define('quizzes-addon/components/reports/assessment/questions/qz-open-ended', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * Open Ended Question
   *
   * Component responsible for controlling the logic and appearance of an open
   * ended question inside of the assessment report
   *
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-open-ended'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    answer: _ember['default'].computed('question', 'showCorrect', function () {
      var correctAnswer = this.get('question.question.correctAnswer.firstObject.value') || 'N/A';
      return this.get('showCorrect') ? correctAnswer : this.get('userAnswer.firstObject.value');
    })

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods
  });
});