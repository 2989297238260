define('quizzes-addon/components/player/questions/qz-hot-text-highlight', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question', 'quizzes-addon/utils/question/hot-text-highlight'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _quizzesAddonUtilsQuestionHotTextHighlight) {

  /**
   * Hot Text Highlight
   *
   * Component responsible for controlling the logic and appearance of a hot
   * text question inside of the {@link player/qz-question-viewer.js}
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments Ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-hot-text-highlight'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Select or unselect an item
       * @param {{index: number, text: string, selected: boolean}} item
       */
      markItem: function markItem(item) {
        var component = this;
        if (!component.get('readOnly')) {
          item.set('selected', !item.get('selected'));
          component.notifyEvents(component.getSelectedItems(), false);
        }
      }
    },

    // -------------------------------------------------------------------------
    // Events
    /**
     * Generate items from question answer choices
     */
    initItems: (function () {
      var component = this;
      if (component.get('hasUserAnswer')) {
        component.notifyEvents(component.getSelectedItems(), true);
      }
    }).on('didInsertElement'),

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property items possible answers, it handles word and sentence variants
     */
    items: _ember['default'].computed('question.body', function () {
      var component = this;
      var items = _quizzesAddonUtilsQuestionHotTextHighlight['default'].getItems(this.get('question'));
      if (component.get('hasUserAnswer')) {
        (function () {
          var userAnswer = component.get('userAnswer');
          items.forEach(function (item) {
            return item.set('selected', !!userAnswer.findBy('value', item.get('text') + ',' + item.get('index')));
          });
        })();
      }
      return items;
    }),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Returns those items selected by the user
     * @returns {{index: number, text: string, selected: boolean}[]} selected items
     */
    getSelectedItems: function getSelectedItems() {
      return this.get('items').filterBy('selected', true);
    },

    /**
     * Notifies events based on selected items
     * @param {{index: number, text: string, selected: boolean}} selectedItems
     * @param {boolean} onLoad if this was called when loading the component
     */
    notifyEvents: function notifyEvents(selectedItems, onLoad) {
      var component = this;
      selectedItems.forEach(function (item) {
        item.set('text', item.get('text'));
      });
      var userAnswer = selectedItems.map(function (item) {
        return {
          value: item.get('text').trim() + ',' + item.get('index')
        };
      });
      component.notifyAnswerChanged(userAnswer);
      if (selectedItems.get('length')) {
        if (onLoad) {
          component.notifyAnswerLoaded(userAnswer);
        } else {
          component.notifyAnswerCompleted(userAnswer);
        }
      } else {
        component.notifyAnswerCleared(userAnswer);
      }
    }
  });
});