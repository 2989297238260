define('quizzes-addon/config/env/test', ['exports'], function (exports) {
  /*
    Test Environment configuration properties
   */
  exports['default'] = {
    endpoint: {
      url: 'http://localhost:7357',
      secureUrl: 'http://localhost:7357',
      providerUrl: 'http://localhost:7357'
    },

    realTime: {
      webServiceUrl: 'https://localhost:7357',
      webServiceUri: '/nucleus/realtime',
      webSocketUrl: 'realtimeURL',
      webSocketUri: '/realtimeURI'
    }
  };
});