define('ember-cli-embedded/helpers/registry', ['exports'], function (exports) {
  exports.resolve = resolve;
  exports.resolveFactory = resolveFactory;

  function resolve(registry, application, name) {
    if (registry.container) {
      // pre Ember-2.2.0
      return registry.container().lookup(name);
    } else {
      return application.__container__.lookup('config:embedded');
    }
  }

  function resolveFactory(registry, application, name) {
    if (registry.container) {
      // pre Ember-2.2.0
      return registry.container().lookupFactory(name);
    } else {
      return application.__container__.lookupFactory(name);
    }
  }
});