define('quizzes-addon/adapters/collection/collection', ['exports', 'quizzes-addon/mixins/token', 'quizzes-addon/adapters/application'], function (exports, _quizzesAddonMixinsToken, _quizzesAddonAdaptersApplication) {
  exports['default'] = _quizzesAddonAdaptersApplication['default'].extend(_quizzesAddonMixinsToken['default'], {
    /**
     * @property {string} End-point URI
     */
    namespace: '/quizzes/api/v1/collections',

    collectionNameSpace: '/api/nucleus/v1/collections',

    assessmentNameSpace: '/api/nucleus/v1/assessments',

    dcanamespace: '/api/nucleus-insights/v2',

    attemptCountNamespace: '/api/nucleus/v1/classes',

    /**
     * Reads a Collection by id
     *
     * @param {string} collectionId
     * @returns {Promise}
     */
    readCollection: function readCollection(collectionId, type) {
      var refresh = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      var adapter = this;
      var namespace = adapter.get('namespace');
      refresh = true;
      var url = namespace + '/' + collectionId + '?type=' + type + '&refresh=' + refresh;
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    },

    queryRecordForDCA: function queryRecordForDCA(query) {
      var namespace = this.get('dcanamespace');
      var sessionParam = 'sessionId=' + query.sessionId;
      if (query.sessionId === 'NA' || query.sessionId === undefined) {
        sessionParam = '';
      }
      var classParam = 'classId=' + query.classId;
      var date = 'date=' + query.date;
      var startDate = 'startDate=' + query.startDate;
      var endDate = 'endDate=' + query.endDate;
      var collectionId = query.collectionId;
      var userId = query.userId;
      var collectionType = query.collectionType;
      var url = namespace + '/dca/' + collectionType + '/' + collectionId + '/user/' + userId + '?' + sessionParam + '&' + classParam + '&' + date + '&' + startDate + '&' + endDate;
      var options = {
        type: 'GET',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers'),
        data: {}
      };
      return this.sendAjaxRequest(url, options);
    },

    queryRecord: function queryRecord(query) {
      var namespace = this.get('dcanamespace');
      var contentId = query.contentId;
      var collectionType = query.collectionType;
      var userId = query.userId;
      var sessionId = query.sessionId;
      var queryParams = 'sessionId=' + sessionId;
      var url = namespace + '/' + collectionType + '/' + contentId + '/user/' + userId + '?' + queryParams;
      var options = {
        type: 'GET',
        dataType: 'json',
        headers: this.get('headers'),
        data: {}
      };
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Reads a Collection by id
     *
     * @param {string} collectionId
     * @returns {Promise}
     */
    getCollection: function getCollection(collectionId) {
      var adapter = this;
      var namespace = adapter.get('collectionNameSpace');
      var url = namespace + '/' + collectionId;
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Reads a Assesment by id
     *
     * @param {string} collectionId
     * @param {string} type assessment
     * @param {boolean} refresh indicates if the data should be refreshed from the repository
     * @returns {Promise}
     */
    getAssessment: function getAssessment(collectionId) {
      var adapter = this;
      var namespace = adapter.get('assessmentNameSpace');
      var url = namespace + '/' + collectionId;
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    },
    getAttempt: function getAttempt(classId, memberId) {
      var namespace = this.get('attemptCountNamespace');
      var url = namespace + '/' + classId + '/members/' + memberId + '/contents/attempts';
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    }
  });
});