define('quizzes-addon/routes/reports/context', ['exports', 'ember', 'quizzes-addon/models/result/report-data-event'], function (exports, _ember, _quizzesAddonModelsResultReportDataEvent) {

  /**
   * Route for collection/assessment report
   *
   * Gathers and passes initialization data for context events
   * from BE to the controller
   *
   * @module
   * @augments ember/Route
   */
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      anonymous: {}
    },

    /**
     * @type {AttemptService} attemptService
     * @property {Ember.Service} Service to send context related events
     */
    quizzesAttemptService: _ember['default'].inject.service('quizzes/attempt'),

    /**
     * @type {CollectionService} collectionService
     * @property {Ember.Service} Service to retrieve a collection
     */
    quizzesCollectionService: _ember['default'].inject.service('quizzes/collection'),

    /**
     * @property {Service} Configuration service
     */
    quizzesConfigurationService: _ember['default'].inject.service('quizzes/configuration'),

    /**
     * @type {ProfileService} profileService
     * @property {Ember.Service} Service to send profile related events
     */
    quizzesProfileService: _ember['default'].inject.service('quizzes/profile'),

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Navigate to the previous page
       */
      navigateBack: function navigateBack() {
        // Empty, it does nothing by default
      }
    },

    // -------------------------------------------------------------------------
    // Methods

    /**
     * @param {{ contextId: string }} params
     */
    model: function model(params) {
      return this.quizzesModel(params);
    },

    /**
     * @param {{ contextId: string }} params
     */
    quizzesModel: function quizzesModel(params) {
      var _this = this;

      var route = this;
      var contextId = params.contextId;
      var anonymous = params.anonymous;
      var students = params.students || [];
      var currentClass = params.currentClass || null;
      var type = params.type || route.get('quizzesConfigurationService.configuration.properties.type');
      params.type = type;

      return route.get('quizzesAttemptService').getReportData(contextId).then(function (reportData) {
        var avgScoreData = params.avgScoreData ? JSON.parse(params.avgScoreData) : null;
        reportData.reportEvents.forEach(function (item) {
          var scoreValue = avgScoreData ? avgScoreData.findBy('userId', item.profileId) : null;
          item.averageScore = item.averageScore >= 0 ? item.averageScore : scoreValue && scoreValue.score ? scoreValue.score : 0;
        });

        /* Setting avatarUrl for students which have event data */
        students.filter(function (student) {
          var reportDataFilteredByProfilePresent = reportData.get('reportEvents').findBy('profileId', student.id);
          return reportDataFilteredByProfilePresent;
        }).forEach(function (student) {
          var reportDataWithStudentData = reportData.get('reportEvents').findBy('profileId', student.id);

          if (reportDataWithStudentData) {
            reportDataWithStudentData.avatarUrl = student.get('avatarUrl');
          }
        });

        students.filter(function (student) {
          return !reportData.get('reportEvents').findBy('profileId', student.id);
        }).forEach(function (student) {
          if (student.get('isActive')) {
            reportData.get('reportEvents').push(_quizzesAddonModelsResultReportDataEvent['default'].create(_ember['default'].getOwner(_this).ownerInjection(), {
              profileId: student.get('id'),
              profileName: student.get('fullName'),
              lastFirstName: student.get('lastFirstName'),
              avatarUrl: student.get('avatarUrl'),
              isAttemptStarted: false,
              isAttemptFinished: false
            }));
          }
        });
        return reportData;
      }).then(function (reportData) {
        return _ember['default'].RSVP.hash({
          anonymous: anonymous,
          reportData: reportData,
          currentClass: currentClass,
          collection: route.get('quizzesCollectionService').readCollection(reportData.collectionId, type),
          profiles: route.get('quizzesProfileService').readProfiles(reportData.get('reportEvents').map(function (_ref) {
            var profileId = _ref.profileId;
            return profileId;
          })),
          modelParams: params
        });
      });
    },

    setupController: function setupController(controller, model) {
      var anonymous = model.anonymous;
      var collection = model.collection;
      var reportData = model.reportData;
      var profiles = model.profiles;
      var currentClass = model.currentClass;
      reportData.get('reportEvents').forEach(function (reportEvent) {
        var profile = profiles[reportEvent.get('profileId')];
        reportEvent.setProfileProperties(profile);
      });
      controller.set('modelParams', model.modelParams);
      controller.set('collection', collection);
      reportData.setCollection(collection);
      controller.set('reportData', reportData);
      controller.set('anonymous', anonymous);
      controller.set('class', currentClass);
    },

    deactivate: function deactivate() {
      var webSocketClient = this.get('controller').get('webSocketClient');
      if (webSocketClient && webSocketClient.connected) {
        webSocketClient.disconnect();
      }
    }
  });
});