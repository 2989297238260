define('ember-cli-embedded/initializers/embedded', ['exports', 'ember', 'ember-cli-embedded/helpers/registry'], function (exports, _ember, _emberCliEmbeddedHelpersRegistry) {
  exports.initialize = initialize;
  var get = _ember['default'].get;

  function initialize() {
    var application = arguments[1] || arguments[0];
    var registry = application.registry;

    var env = (0, _emberCliEmbeddedHelpersRegistry.resolveFactory)(registry, application, 'config:environment');
    var isEmbedded = get(env, 'embedded');
    if (isEmbedded) {
      application.reopen({
        start: _ember['default'].run.bind(application, function emberCliEmbeddedStart(config) {
          var embeddedConfig = _ember['default'].$.extend(true, isEmbedded === true ? {} : isEmbedded, config || {});
          this.register('config:embedded', embeddedConfig);
          this.advanceReadiness();
        })
      });
      application.deferReadiness();
    }
  }

  exports['default'] = {
    name: 'ember-cli-embedded',
    after: 'export-application-global',
    initialize: initialize
  };
});