define('quizzes-addon/adapters/resource/resource', ['exports', 'quizzes-addon/mixins/token', 'quizzes-addon/adapters/application'], function (exports, _quizzesAddonMixinsToken, _quizzesAddonAdaptersApplication) {
  exports['default'] = _quizzesAddonAdaptersApplication['default'].extend(_quizzesAddonMixinsToken['default'], {
    /**
     * @property {string} namespace Nile End-point URI
     */
    namespace: '/api/nucleus/v1/resources',

    /**
     * @property {string} namespaceQuizzes End-point URI
     */
    namespaceQuizzes: '/quizzes/api/v1/resources',

    /**
     * @property {string} xAPINamespace Nile End-point URI
     */
    xAPINamespace: '/api/v1/xapi',

    /**
     * @property {string} learningRegistry End-point URI
     */
    learningRegistry: '/api/learning-registry/v1',

    /**
     * @property {string} contentSecurityNamespace End-point URI
     */
    contentSecurityNamespace: '/api/content-security',

    /**
     * Finish resource event
     *
     * @param {string} resourceId
     * @param {string} resourceEventData
     * @param {string} eventContext
     * @returns {Promise}
     */
    sendFinishResource: function sendFinishResource(resourceId, resourceEventData, eventContext) {
      var adapter = this;
      var namespace = adapter.get('namespaceQuizzes');
      var url = namespace + '/' + resourceId + '/finish';
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers'),
        data: JSON.stringify({ resourceEventData: resourceEventData, eventContext: eventContext })
      };
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Reads a resource by id
     *
     * @param {string} resourceId
     * @returns {Promise}
     */
    readResource: function readResource(resourceId) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/' + resourceId;
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    },

    /**
     *
     * @param {string} eventContent
     * @returns {Promise}
     */
    createxAPIEvent: function createxAPIEvent(eventContent) {
      var adapter = this;
      var namespace = adapter.get('xAPINamespace');
      var url = namespace + '/h5p';
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers'),
        data: JSON.stringify(eventContent)
      };
      return this.sendAjaxRequest(url, options);
    },
    /**
     * Fetch learning tool information
     * @returns {Promise.<[]>}
     */
    getLearningToolInformation: function getLearningToolInformation(toolId) {
      var adapter = this;
      var namespace = adapter.get('learningRegistry');
      var url = namespace + '/learning-tools/' + toolId;
      var options = {
        type: 'GET',
        headers: this.get('headers'),
        contentType: 'application/json; charset=utf-8'
      };
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Generate token for secure materials
     * @param {string} resourceUrl
     * @param {string} productId
     * @param {string} tenantId
     * 
     * @returns {Promise<string>} generated token
     **/
    generateSecureToken: function generateSecureToken(resourceUrl, productId, tenantId) {
      var adapter = this;
      var namespace = adapter.get('contentSecurityNamespace');
      var url = namespace + '/token/generate';
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers'),
        data: JSON.stringify({ resourceUrl: resourceUrl, productId: productId, tenantId: tenantId })
      };
      return this.sendAjaxRequest(url, options);
    }
  });
});