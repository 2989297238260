define('quizzes-addon/utils/question/question', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Base question util class
   * This utility class defines the basic behavior for all question types
   * it contains convenience methods to grade and retrieve useful information
   * from question types
   *
   * @typedef {Object} QuestionUtil
   */

  exports['default'] = _ember['default'].Object.extend({
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {Resource}
     */
    question: null,

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Indicates if the answer is correct
     * Default implementation, it check if all answer choices are correct
     *
     * @param {Array} answer user answer
     * @return {boolean}
     */
    isCorrect: function isCorrect(answer) {
      var utility = this;
      var correctAnswer = this.getCorrectAnswer();
      var correct = answer.get('length') === correctAnswer.get('length');
      answer.forEach(function (answerChoice, index) {
        correct = correct && utility.isAnswerChoiceCorrect(answerChoice, index);
      });

      return correct;
    },

    /**
     * Indicates if the answer choice is correct
     * @param { * } answerChoice
     */
    isAnswerChoiceCorrect: function isAnswerChoiceCorrect(answerChoice) {
      _ember['default'].Logger.warning('The method getCorrectAnswer is not implemented', answerChoice);
    },

    /**
     * This method should be implemented at different question types
     *
     * @return {*} the correct answer choice id
     */
    getCorrectAnswer: function getCorrectAnswer() {
      _ember['default'].Logger.warning('The method getCorrectAnswer is not implemented');
    },

    /**
     * This returns the answers distribution
     *
     * @param { [] } userAnswers, i.e [2,1,3,2,1]
     * @return { { answer: *, count: number, key: string }[] }
     */
    distribution: function distribution(resourceResults) {
      var util = this;
      var distributionMap = {};
      var distribution = _ember['default'].A([]);
      resourceResults.forEach(function (result) {
        var questionType = result.get('resource.type');
        var isOpenEndedQuestion = questionType === 'extended_text';
        var answer = result.get('answer');
        if (result.get('started')) {
          var answerKey = util.answerKey(answer);
          var answerDistribution = distributionMap[answerKey];
          var count = 0;
          var percentage = 0;
          if (!answerDistribution) {
            answerDistribution = _ember['default'].Object.create({
              answer: answer,
              correct: isOpenEndedQuestion ? true : result.get('correct'),
              count: count,
              percentage: percentage,
              key: answerKey,
              result: result,
              isOpenEndedQuestion: isOpenEndedQuestion
            });
            distribution.addObject(answerDistribution);
            distributionMap[answerKey] = answerDistribution;
          } else {
            count = answerDistribution.get('count');
          }
          count += 1;
          answerDistribution.set('count', count);
          answerDistribution.set('percentage', Math.round(result.get('score')));
        }
      });
      return distribution;
    },

    /**
     * Returns a unique key representing the answer
     * @param answer
     * @returns {{}}
     */
    answerKey: function answerKey(answer) {
      return _ember['default'].A(answer).sortBy('value').reduce(function (key, item) {
        return '' + key + item.value;
      }, '');
    },

    /**
     * Indicates if two answers are the same
     * @param answerA
     * @param answerB
     * @returns {boolean}
     */
    sameAnswer: function sameAnswer(answerA, answerB) {
      return this.answerKey(answerA) === this.answerKey(answerB);
    },

    /**
     * Converts the model user answer into an answerObject format
     * @param {*} userAnswer
     * @return {AnswerObject[]}
     */
    toAnswerObjects: function toAnswerObjects(userAnswer) {
      _ember['default'].Logger.warning('The method toAnswerObject is not implemented', userAnswer);
    },

    /**
     * Converts the model user answer into an answerObject format
     * @see quizzes/utils/question/*
     * @param {*} userAnswer
     * @return {*[]}
     */
    toJSONAnswerObjects: function toJSONAnswerObjects(userAnswer) {
      var json = [];
      if (userAnswer) {
        var answerObjects = this.toAnswerObjects(userAnswer);
        json = answerObjects.map(function (answerObject) {
          return {
            text: answerObject.get('text'),
            status: answerObject.get('status'),
            order: answerObject.get('order'),
            answerId: answerObject.get('answerId'),
            skip: answerObject.get('skip')
          };
        });
      }
      return json;
    },

    /**
     * Converts an answerObject format to model userAnswer
     * @param {AnswerObject[]} answerObjects
     */
    toUserAnswer: function toUserAnswer(answerObjects) {
      _ember['default'].Logger.warning('The method toUserAnswer is not implemented', answerObjects);
    },

    /**
     * Gets an Answer by id
     * @param {string} answerId
     * @returns {Answer}
     */
    getAnswerById: function getAnswerById(answerId) {
      return this.getQuestionAnswers().findBy('id', answerId);
    },

    /**
     * Gets an Answer by text
     * @param {string} text
     * @returns {Answer}
     */
    getAnswerByText: function getAnswerByText(text) {
      return this.getQuestionAnswers().findBy('text', text);
    },

    /**
     * Returns the question answers
     * @returns {Answer[]}
     */
    getQuestionAnswers: function getQuestionAnswers() {
      return this.get('question.answers');
    }
  });
});