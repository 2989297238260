define('quizzes-addon/components/player/questions/qz-serp-digraph', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP decoding question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    session: _ember['default'].inject.service('session'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-digraph'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var question = this.get('question');
      var exemplarAnswer = question.get('hint').exemplar_docs;
      for (var index = 0; index < question.baseAnswers.length; index++) {
        var element = question.baseAnswers[index];
        var data = exemplarAnswer.findBy('answer_text', element.answer_text);
        if (data) {
          element.exemplar_answer = data.correct_answer;
        }
      }
      var userAnswer = this.get('userAnswer');
      this.injectDigraph(question.baseAnswers, userAnswer);
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    // -------------------------------------------------------------------------
    // Properties

    userId: _ember['default'].computed.alias('session.userId'),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    /**
     * @function injectDigraph
     * @param {Array} baseAnswers
     */
    injectDigraph: function injectDigraph(baseAnswers, userAnswer) {
      var component = this;
      var user = {
        userId: this.get('userId')
      };
      var content = {
        contentId: this.get('question.id'),
        contentTitle: this.get('question.title'),
        answers: baseAnswers,
        userAnswer: userAnswer
      };
      window.serp.languageDecode().select('#serp-digraph-answer-container').dataIn(user, null, content).underline().render().listener(function (eventData) {
        component.handleDigraphAnswers(eventData.digraph_answers);
      });
    },

    /**
     * @function handleDigraphAnswers
     * @param {Array} digraphAnswers
     */
    handleDigraphAnswers: function handleDigraphAnswers(digraphAnswers) {
      if (digraphAnswers && digraphAnswers.length && digraphAnswers.length === this.get('question').baseAnswers.length) {
        var answers = digraphAnswers.map(function (answer) {
          return {
            value: answer.selected_text.join()
          };
        });
        this.notifyAnswerCompleted(answers);
      }
    }
  });
});