define('quizzes-addon/components/reports/assessment/questions/qz-serp-digraph', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * SERP Underline
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-serp-digraph'],

    audioRecorder: null,
    /**
     * @property {Boolean} isPause
     */
    isPause: false,
    answerIndex: null,
    playerIndex: null,
    // -------------------------------------------------------------------------
    // Properties
    actions: {
      onPlayAudio: function onPlayAudio(container, file, index, exemplarIndex) {
        var component = this;
        if (file) {
          (function () {
            var _audio = component.get('audioRecorder');
            if (!_audio || component.get('answerIndex') !== index || component.get('playerIndex') !== exemplarIndex) {
              _audio = new Audio(file);
              component.set('answerIndex', index);
              component.set('playerIndex', exemplarIndex);
            }
            component.set('audioRecorder', _audio);
            _audio.play();
            component.set('isPause', true);
            _audio.ontimeupdate = function () {
              component.$('.' + container + ' .audio-progress .progress-filling').css('width', _audio.currentTime / _audio.duration * 100 + '%');
            };
            _audio.addEventListener('ended', function () {
              component.set('isPause', false);
            });
          })();
        }
      },

      onPauseAudio: function onPauseAudio() {
        var component = this;
        var audio = component.get('audioRecorder');
        audio.pause();
        component.set('isPause', false);
      }
    },

    answers: _ember['default'].computed('showCorrect', function () {
      var component = this;
      var questionAnswers = JSON.parse(JSON.stringify(component.get('showCorrect') ? component.get('baseAnswers') : component.get('question.answer')));
      return questionAnswers.map(function (answer, index) {
        var answerInputs = component.get('showCorrect') ? answer.correct_answer : answer.value.split(',');
        var baseAnswer = component.get('baseAnswers').findBy('sequence', index + 1);
        var answerText = baseAnswer.answer_text;
        var value = component.get('question.resource.hint.exemplar_docs');
        answer.exemplarAnswer = value[index].correct_answer;
        answerInputs.map(function (answerInput) {
          answer.isCorrect = baseAnswer && baseAnswer.correct_answer.includes(answerInput);
          answerText = answerText.replace(answerInput, component.getHighlightedText(answer.isCorrect, answerInput));
          answer.text = answerText;
        });
        return answer;
      });
    }),

    baseAnswers: _ember['default'].computed.alias('question.resource.baseAnswers'),

    showCorrect: false,

    highlightAnswers: function highlightAnswers() {
      var component = this;
      var answers = _ember['default'].A([]);
      var questionAnswers = component.get('showCorrect') ? component.get('baseAnswers') : component.get('question.answer');
      questionAnswers.map(function (answer, index) {
        var answerInputs = component.get('showCorrect') ? answer.correct_answer : answer.value.split(',');
        var baseAnswer = component.get('baseAnswers').findBy('sequence', index + 1);
        var answerText = baseAnswer.answer_text;
        answerInputs.map(function (answerInput) {
          answer.isCorrect = baseAnswer && baseAnswer.correct_answer.includes(answerInput);
          answerText = answerText.replace(answer.value, component.getHighlightedText(answer));
          answer.text = answerText;
        });
        answers.pushObject(answer);
      });
      return answers;
    },

    getHighlightedText: function getHighlightedText(isCorrect, text) {
      return '<span class="' + (isCorrect ? 'correct' : 'wrong') + '">' + text + '</span>';
    }
  });
});