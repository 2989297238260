define('quizzes-addon/routes/assign', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    /**
     * @property {Service} Configuration service
     */
    configurationService: _ember['default'].inject.service('quizzes/configuration'),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Get model for the controller
     */
    model: function model() {
      var teacherConfig = this.get('configurationService.configuration.properties.teacher');
      var context = this.get('configurationService.configuration.properties.context');

      return _ember['default'].RSVP.hash({
        teacherConfig: teacherConfig,
        context: context
      });
    },

    /**
     * Set all controller properties from the model
     * @param controller
     * @param model
     */
    setupController: function setupController(controller, model) {
      controller.set('teacherConfig', model.teacherConfig);
      controller.set('context', model.context);
    }
  });
});