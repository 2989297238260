define('quizzes-addon/components/player/questions/qz-match-the-following', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion) {

  /**
   * Reorder Question
   *
   * Component responsible for controlling the logic and appearance of the answers for
   * a reorder question inside of the {@link player/qz-question-viewer.js}
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments player/questions/qz-question.js
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['gru-match-the-following'],

    // -------------------------------------------------------------------------
    // Events

    initSortableList: _ember['default'].on('didInsertElement', function () {
      var component = this;
      component.setAnswers();
      if (!component.get('hasUserAnswer')) {
        component.shuffle();
      }
      this.set('areAnswersShuffled', true);
    }),

    removeSubscriptions: _ember['default'].on('willDestroyElement', function () {
      this.$('.sortable').off('sortupdate');
    }),

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Convenient structure to render the question answer choices
     * @property {*}
     */
    /**
     * Convenient structure to render the question answer choices
     * @property {*}
     */
    answers: _ember['default'].computed('question.answerDetails.[]', function () {
      var answers = this.get('question.answerDetails').sortBy('order');
      return answers;
    }),

    /**
     *
     * @property {Array} userAnswer has Array of user answered objects
     */
    userAnswers: _ember['default'].computed('userAnswer', function () {
      var answers = this.get('userAnswer') || [];
      return answers.map(function (item) {
        return item.value;
      });
    }),

    leftArray: _ember['default'].computed('answers', function () {
      var left = _ember['default'].A();
      this.get('answers').map(function (answer) {
        var answerObject = _ember['default'].Object.create({
          sequence: answer.sequence,
          leftValue: answer.left_value,
          leftValueFormat: answer.left_value_format
        });
        left.pushObject(answerObject);
      });
      return left;
    }),

    rightArray: _ember['default'].computed('answers', function () {
      var userAnswers = this.get('userAnswers');
      var right = _ember['default'].A();
      var userAnsObj = _ember['default'].A();
      this.get('answers').map(function (answer) {
        var answerObject = _ember['default'].Object.create({
          sequence: answer.sequence,
          rightValue: answer.right_value,
          leftValue: answer.left_value,
          rightValueFormat: answer.right_value_format,
          rightValShuffleOrder: answer.right_val_shuffle_order
        });
        right.pushObject(answerObject);
      });
      userAnswers.forEach(function (userSeq) {
        var ansObj = right.find(function (item) {
          return item.sequence + ',' + item.leftValue + ',' + item.rightValue + ',' + item.rightValShuffleOrder === userSeq;
        });
        userAnsObj.pushObject(ansObj);
      });
      return userAnswers && userAnswers.length ? userAnsObj : right;
    }),

    /**
     * Return true if the answers list are shuffled
     * @property {Boolean}
     */
    areAnswersShuffled: false,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Disorder elements
     */
    disorder: function disorder(list) {
      var j,
          x,
          i = list.length;
      while (i) {
        j = parseInt(Math.random() * i);
        i -= 1;
        x = list[i];
        list[i] = list[j];
        list[j] = x;
      }
      return list;
    },

    /**
     * Notifies answer events
     * @param {boolean} onLoad if this was called when loading the component
     */
    notify: function notify(onLoad) {
      var component = this;
      var $items = component.$('.sortable').find('li');
      var answers = _ember['default'].A([]);

      $items.map(function (idx, item) {
        var questionAns = component.get('answers');
        var activeItem = questionAns.findBy('sequence', parseInt($(item).data('id'), 0));
        if (activeItem) {
          var answerObj = {
            value: activeItem.sequence + ',' + activeItem.left_value + ',' + activeItem.right_value + ',' + activeItem.right_val_shuffle_order
          };
          answers.pushObject(answerObj);
        }
      });

      component.notifyAnswerChanged(answers);
      if (onLoad) {
        component.notifyAnswerLoaded(answers);
      } else {
        component.notifyAnswerCompleted(answers);
      }
    },

    /**
     * Set answers
     */
    setAnswers: function setAnswers() {
      var component = this;
      var sortable = this.$('.sortable');
      var readOnly = component.get('readOnly');

      sortable.sortable({
        containment: 'parent'
      });
      if (readOnly) {
        sortable.sortable('disable');
      }

      if (component.get('hasUserAnswer')) {
        component.notify(true);
      }
      // Manually add subscriptions to sortable element -makes it easier to test
      sortable.on('sortupdate', function () {
        component.notify(false);
      });
    },

    /**
     * Take the list of items and shuffle all his members
     */
    shuffle: function shuffle() {
      var component = this;
      var $items = component.$('.sortable');
      $items.each(function () {
        var items = $items.children();
        if (items.length) {
          items = Array.from(items);
          items = component.disorder(items).reverse();
          $(this).html(items.length <= 2 ? items : component.disorder(items));
        }
      });
    }
  });
});