define('quizzes-addon/routes/reports/student-context', ['exports', 'ember', 'gooru-web/mixins/tenant-settings-mixin'], function (exports, _ember, _gooruWebMixinsTenantSettingsMixin) {

  /**
   * Route for student report
   *
   * Gathers and passes initialization data for attempt events
   * from BE to the controller
   *
   * @module
   * @augments ember/Route
   */
  exports['default'] = _ember['default'].Route.extend(_gooruWebMixinsTenantSettingsMixin['default'], {
    /**
     * @type {AttemptService} attemptService
     * @property {Ember.Service} Service to send context related events
     */
    quizzesAttemptService: _ember['default'].inject.service('quizzes/attempt'),

    /**
     * @type {CollectionService} collectionService
     * @property {Ember.Service} Service to retrieve a collection
     */
    quizzesCollectionService: _ember['default'].inject.service('quizzes/collection'),

    /**
     * @property {Service} Configuration service
     */
    quizzesConfigurationService: _ember['default'].inject.service('quizzes/configuration'),

    /**
     * @property {Service} profile service
     */
    quizzesProfileService: _ember['default'].inject.service('quizzes/profile'),

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Navigate to the previous page
       */
      navigateBack: function navigateBack() {
        window.history.back();
      }
    },

    classFramework: _ember['default'].computed('currentClass', function () {
      return this.get('currentClass.preference') && this.get('currentClass.preference.framework') ? this.get('currentClass.preference.framework') : null;
    }),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * @param {{ contextId: string }} params
     */
    model: function model(params) {
      return this.quizzesModel(params);
    },

    /**
     * @param {{ contextId: string }} params
     */
    quizzesModel: function quizzesModel(params) {
      var route = this;
      var contextId = params.contextId;
      var classFramework = route.get('classFramework');
      var isDefaultShowFW = route.get('isDefaultShowFW');
      var profileId = params.profileId || route.get('quizzesConfigurationService.configuration.properties.profileId');
      var type = params.type || route.get('quizzesConfigurationService.configuration.properties.type');

      return route.get('quizzesAttemptService').getAttemptIds(contextId, profileId).then(function (attemptIds) {
        return !attemptIds || !attemptIds.length ? {} : route.get('quizzesAttemptService').getAttemptData(attemptIds[attemptIds.length - 1]).then(function (attemptData) {
          return _ember['default'].RSVP.hash({
            attemptData: attemptData,
            collection: route.get('quizzesCollectionService').readCollection(attemptData.collectionId, type, false, isDefaultShowFW, classFramework),
            profile: profileId !== 'anonymous' ? route.get('quizzesProfileService').readUserProfile(profileId) : {},
            assessmentResult: params.type === 'assessment' ? params.source === 'coursemap' ? route.get('quizzesCollectionService').findAssessmentResultByCollectionAndStudent(params.type, params.collectionId, params.profileId, attemptData.attemptId) : route.get('quizzesCollectionService').findResourcesByCollectionforDCA(attemptData.attemptId, params.collectionId, params.classId, params.profileId, params.type, moment().format('YYYY-MM-DD')) : {}
          });
        });
      });
    },

    setupController: function setupController(controller, model) {
      if (model && model.attemptData) {
        model.attemptData.setCollection(model.collection);
        controller.set('attemptData', model.attemptData);
        controller.set('profile', model.profile);
        var found = model.assessmentResult && model.assessmentResult.content && model.assessmentResult.content.length;
        if (found) {
          var content = model.assessmentResult.content[0];
          controller.set('isGradedScore', content.assessment.score);
          var resources = content.resources || content.questions || content.usageData;
          if (resources) {
            var evidenceData = resources.map(function (result) {
              return result.isGraded === false;
            });
            controller.set('isGraded', evidenceData.contains(true));
          }
        }
        controller.set('currentClass', this.get('currentClass'));
      }
    }
  });
});