define('quizzes-addon/utils/performance-data', ['exports', 'ember', 'quizzes-addon/utils/math', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsMath, _quizzesAddonUtilsUtils) {
  exports.createDataMatrix = createDataMatrix;

  function createPerformanceObject(performance) {
    var score = performance.get('score');
    var timeSpent = performance.get('timeSpent');
    return _ember['default'].Object.create({
      score: score,
      timeSpent: (0, _quizzesAddonUtilsUtils.formatTime)(timeSpent),
      hasStarted: score > 0 || timeSpent > 0,
      completionDone: performance.get('completionDone'),
      completionTotal: performance.get('completionTotal')
    });
  }

  function createUserAverageObject(studentPerformance) {
    var score = studentPerformance.get('averageScore');
    var timeSpent = studentPerformance.get('averageTimeSpent');
    return _ember['default'].Object.create({
      score: (0, _quizzesAddonUtilsMath.roundFloat)(score),
      studyTime: timeSpent,
      timeSpent: (0, _quizzesAddonUtilsUtils.formatTime)((0, _quizzesAddonUtilsMath.roundFloat)(timeSpent)),
      hasStarted: score > 0 || timeSpent > 0,
      completionDone: studentPerformance.get('sumCompletionDone'),
      completionTotal: studentPerformance.get('sumCompletionTotal')
    });
  }

  function createItemAverageObject(classPerformanceData, itemId) {
    var score = classPerformanceData.calculateAverageScoreByItem(itemId);
    var timeSpent = classPerformanceData.calculateAverageTimeSpentByItem(itemId);

    return _ember['default'].Object.create({
      score: (0, _quizzesAddonUtilsMath.roundFloat)(score),
      timeSpent: (0, _quizzesAddonUtilsUtils.formatTime)((0, _quizzesAddonUtilsMath.roundFloat)(timeSpent)),
      hasStarted: score > 0 || timeSpent > 0,
      completionDone: classPerformanceData.calculateSumCompletionDoneByItem(itemId),
      completionTotal: classPerformanceData.calculateSumCompletionTotalByItem(itemId)
    });
  }

  function createClassAverageObject(classPerformanceData) {
    var score = classPerformanceData.get('classAverageScore');
    var timeSpent = classPerformanceData.get('classAverageTimeSpent');

    return _ember['default'].Object.create({
      score: (0, _quizzesAddonUtilsMath.roundFloat)(score),
      timeSpent: (0, _quizzesAddonUtilsUtils.formatTime)((0, _quizzesAddonUtilsMath.roundFloat)(timeSpent)),
      hasStarted: score > 0 || timeSpent > 0,
      completionDone: classPerformanceData.get('classSumCompletionDone'),
      completionTotal: classPerformanceData.get('classSumCompletionTotal')
    });
  }

  /**
   * Create a matrix with random data to be used to fill the teacher analytics data by student.
   * @param headers the table header
   * @param classPerformanceData the base performance data
   */

  function createDataMatrix(headers, classPerformanceData) {
    var studentPerformanceData = classPerformanceData.get('studentPerformanceData');
    var dataMatrix = _ember['default'].A([]);

    studentPerformanceData.forEach(function (studentPerformance) {
      var user = studentPerformance.get('user');
      var performanceData = studentPerformance.get('performanceData');
      var userData = _ember['default'].Object.create({
        user: user.get('fullName'),
        performanceData: _ember['default'].A([])
      });
      headers.forEach(function (headerItem) {
        var performance = performanceData.findBy('id', user.get('id') + '@' + headerItem.get('id'));
        if (performance) {
          userData.get('performanceData').push(createPerformanceObject(performance));
        } else {
          userData.get('performanceData').push(undefined);
        }
      });
      // Inserts User averages at position 0 of the current row of performance elements.
      userData.get('performanceData').insertAt(0, createUserAverageObject(studentPerformance));
      // Pushes User data in the matrix.
      dataMatrix.push(userData);
    });

    // Inserts the Header average for each item (unit|lesson|collection)
    var itemPerformanceAverageData = _ember['default'].Object.create({
      performanceData: _ember['default'].A([])
    });
    headers.forEach(function (headerItem) {
      var itemPerformanceAverage = createItemAverageObject(classPerformanceData, headerItem.get('id'));
      itemPerformanceAverageData.get('performanceData').push(itemPerformanceAverage);
    });
    itemPerformanceAverageData.get('performanceData').insertAt(0, createClassAverageObject(classPerformanceData));
    dataMatrix.insertAt(0, itemPerformanceAverageData);
    return dataMatrix;
  }
});
/* eslint require-jsdoc: 0 */