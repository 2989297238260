define('quizzes-addon/services/quizzes/api-sdk/resource', ['exports', 'ember', 'quizzes-addon/serializers/context/context', 'quizzes-addon/serializers/resource/resource', 'quizzes-addon/adapters/resource/resource'], function (exports, _ember, _quizzesAddonSerializersContextContext, _quizzesAddonSerializersResourceResource, _quizzesAddonAdaptersResourceResource) {
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('resourceAdapter', _quizzesAddonAdaptersResourceResource['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('resourceSerializer', _quizzesAddonSerializersResourceResource['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('contextSerializer', _quizzesAddonSerializersContextContext['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {ResourceAdapter} adapter
     */
    resourceAdapter: null,

    /**
     * @property {ResourceSerializer} serializer
     */
    resourceSerializer: null,

    /**
     * @property {ContextSerializer} contextSerializer
     */
    contextSerializer: null,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Reads a resource by id
     * @param {String} resourceId
     * @returns {Promise}
     */
    sendFinishResource: function sendFinishResource(resourceId, resourceResult, eventContext) {
      var service = this;
      var eventData = this.get('contextSerializer').serializeResourceResult(resourceResult, false);
      var context = this.get('contextSerializer').serializeEventContext(eventContext);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        return service.get('resourceAdapter').sendFinishResource(resourceId, eventData, context).then(resolve, reject);
      });
    },

    /**
     * Reads a resource by id
     * @param {String} resourceId
     * @returns {Promise}
     */
    readResource: function readResource(resourceId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('resourceAdapter').readResource(resourceId).then(function (response) {
          response.isResource = true;
          return service.get('resourceSerializer').normalizeReadResource(response);
        }).then(resolve, reject);
      });
    },

    /**
     * Reads a toolConfig by id
     * @param {String} toolId
     * @returns {Promise}
     */
    getLearningToolInformation: function getLearningToolInformation(toolId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('resourceAdapter').getLearningToolInformation(toolId).then(function (responseData) {
          resolve(responseData);
        }, reject);
      });
    },

    /**
     * Create XAPI event based on resource id
     * @param {String} eventData
     * @returns {Promise}
     */
    createxAPIEvent: function createxAPIEvent(eventData) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        return service.get('resourceAdapter').createxAPIEvent(eventData).then(resolve, reject);
      });
    },

    /**
     * Generate token for secure materials
     * @param {string} resourceUrl
     * @param {string} productId
     * @param {string} tenantId
     * 
     * @returns {Promise<string>} generated token
     **/
    generateSecureToken: function generateSecureToken(resourceUrl, productId, tenantId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('resourceAdapter').generateSecureToken(resourceUrl, productId, tenantId).then(resolve, reject);
      });
    }
  });
});