define('quizzes-addon/components/cards/qz-resource-card', ['exports', 'ember', 'quizzes-addon/mixins/modal', 'quizzes-addon/models/taxonomy/taxonomy-tag', 'quizzes-addon/models/taxonomy/taxonomy-tag-data', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonMixinsModal, _quizzesAddonModelsTaxonomyTaxonomyTag, _quizzesAddonModelsTaxonomyTaxonomyTagData, _quizzesAddonUtilsUtils) {

  /**
   * Resource and Question card
   *
   * Component responsible of showing the resource or question information in cards, so that most useful information is summarized there.
   * @module
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsModal['default'], {
    // Dependencies

    /**
     * @type {resourceService} resourceService
     * @property {Ember.Service} Service to send resource events
     */
    quizzesResourceService: _ember['default'].inject.service('quizzes/resource'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['cards', 'qz-resource-card'],

    startTimer: null,

    timer: null,

    timerValue: 0,

    timerChanged: _ember['default'].computed('startTimer', function () {
      var _this = this;

      if (this.startTimer === true) {
        this.timer = setInterval(function () {
          _this.timerValue += 1;
        }, 1000);
      }
    }),

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Action triggered to edit the resource/question
       */
      editResource: function editResource() {
        this.sendAction('onEditResource', this.get('resource'));
      },
      /**
       * Action triggered to play the resource/question
       */
      playResource: function playResource() {
        this.sendAction('onPlayResource', this.get('resource'));
      },
      handleImageError: function handleImageError() {
        event.target.src = '../../../assets/gooru/images/resource-gray.svg';
      },

      openLink: function openLink() {
        var resource = this.get('resource');
        var isSecured = resource.get('isSecured');

        var resourceUrl = this.get('resource.body');
        var cdnUrl = this.get('quizzesConfigurationService.configuration.properties.cdnURL');
        var url = (0, _quizzesAddonUtilsUtils.toAbsolutePath)(resourceUrl, cdnUrl);

        if (!isSecured) {
          window.open(url, '_blank');
        } else {
          var productId = resource.get('product_id');
          var publisherTenantId = resource.get('publisherTenantId');

          this.get('quizzesResourceService').generateSecureToken(url, productId, publisherTenantId).then(function (token) {
            token = token && token.access_token;
            var params = { token: token };
            var securedUrl = (0, _quizzesAddonUtilsUtils.addParamsToUrl)(url, params);
            window.open(securedUrl, '_blank');
          });
        }
      }
    },

    // -------------------------------------------------------------------------
    // Events

    didRender: function didRender() {
      var component = this;
      component.$('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    },

    // -------------------------------------------------------------------------
    // Properties
    /**
     * @property {Resource|Question} resource
     */
    resource: null,

    /**
     * Indicates if this resource is a question
     * @property {boolean}
     */
    isQuestion: _ember['default'].computed.equal('resource.format', 'question'),

    /**
     * Indicates if the edit functionality is enabled
     * @property {boolean}
     */
    editEnabled: false,

    /**
     * Indicates if the remix functionality is enabled
     * @property {boolean}
     */
    remixEnabled: _ember['default'].computed.not('editEnabled'),

    /**
     * @property {string} edit action
     */
    onEditResource: null,

    /**
     * @property {string} remix action
     */
    onRemixQuestion: null,

    /**
     * @property {Service} Configuration service
     */
    quizzesConfigurationService: _ember['default'].inject.service('quizzes/configuration'),

    /**
     * @property {TaxonomyTag[]} List of taxonomy tags
     */
    tags: _ember['default'].computed('resource.standards.[]', function () {
      var standards = this.get('resource.standards');
      if (standards) {
        standards = standards.filter(function (standard) {
          // Filter out learning targets (they're too long for the card)
          return !_quizzesAddonModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(standard.get('id'));
        });
      }
      return _quizzesAddonModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(standards);
    }),
    /**
     * Show the publisher if the resource has publisher and is publish
     * @property {boolean}
     */
    showPublisher: _ember['default'].computed('resource', function () {
      return this.get('resource').isPublished && this.get('resource').publisher;
    }),

    /**
     * @property {string} Resource URL
     */
    url: _ember['default'].computed('resource.body', function () {
      var component = this;
      var resourceUrl = component.get('resource.body');
      var cdnUrl = component.get('quizzesConfigurationService.configuration.properties.cdnURL');
      return (0, _quizzesAddonUtilsUtils.toAbsolutePath)(resourceUrl, cdnUrl);
    }),
    destroy: function destroy() {
      this._super.apply(this, arguments);
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    }
  });
});