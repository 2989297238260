define('quizzes-addon/config/quizzes-config', ['exports'], function (exports) {
  // File max size in MB
  var FILE_MAX_SIZE_IN_MB = 12;

  exports.FILE_MAX_SIZE_IN_MB = FILE_MAX_SIZE_IN_MB;
  var RESOURCE_COMPONENT_MAP = {
    'video/youtube': 'player.resources.qz-youtube-resource',
    'resource/url': 'qz-preview-url',
    handouts: 'qz-preview-url',
    image: 'qz-preview-url',
    'vimeo/video': 'player.resources.qz-vimeo-resource',
    'resource/html': 'player.resources.qz-html-resource'
  };

  exports.RESOURCE_COMPONENT_MAP = RESOURCE_COMPONENT_MAP;
  var UPLOADABLE_TYPES = [{
    value: 'image',
    validExtensions: '.jpg, .jpeg, .gif, .png',
    validType: 'image/*'
  }, {
    value: 'text',
    validExtensions: '.pdf',
    validType: 'application/pdf'
  }];

  exports.UPLOADABLE_TYPES = UPLOADABLE_TYPES;
  var RESOURCE_TYPES = {
    webpage: 'webpage_resource',
    video: 'video_resource',
    interactive: 'interactive_resource',
    audio: 'audio_resource',
    image: 'image_resource',
    text: 'text_resource',
    html: 'html_resource'
  };

  exports.RESOURCE_TYPES = RESOURCE_TYPES;
  var QUIZZES_RESOURCE_TYPES = {
    url: 'resource/url',
    youtube: 'video/youtube',
    vimeo: 'vimeo/video',
    pdf: 'handouts',
    image: 'image',
    html: 'resource/html'
  };

  exports.QUIZZES_RESOURCE_TYPES = QUIZZES_RESOURCE_TYPES;
  var DEFAULT_IMAGES = {
    USER_PROFILE: 'assets/quizzes-addon/quizzes/profile.png',
    COURSE: '/assets/quizzes/course-default.png',
    COLLECTION: '/assets/gooru/collection-default.png',
    ASSESSMENT: '/assets/gooru/assessment-default.png',
    QUESTION_PLACEHOLDER_IMAGE: '/assets/quizzes/question-placeholder-image.png'
  };

  exports.DEFAULT_IMAGES = DEFAULT_IMAGES;
  var K12_CATEGORY = {
    value: 'k_12',
    apiCode: 'K12',
    label: 'common.categoryOptions.k12'
  };

  exports.K12_CATEGORY = K12_CATEGORY;
  var TAXONOMY_CATEGORIES = [K12_CATEGORY, {
    value: 'higher_education',
    apiCode: 'HE',
    label: 'common.categoryOptions.higher-ed'
  }, {
    value: 'professional_learning',
    apiCode: 'PL',
    label: 'common.categoryOptions.professional-dev'
  }];

  exports.TAXONOMY_CATEGORIES = TAXONOMY_CATEGORIES;
  var CONTENT_TYPES = {
    COLLECTION: 'collection',
    ASSESSMENT: 'assessment',
    EXTERNAL_ASSESSMENT: 'assessment-external',
    COURSE: 'course',
    UNIT: 'unit',
    LESSON: 'lesson',
    RESOURCE: 'resource',
    QUESTION: 'question'
  };

  exports.CONTENT_TYPES = CONTENT_TYPES;
  var KEY_CODES = {
    DOWN: 40,
    ENTER: 13,
    ESCAPE: 27,
    LEFT: 37,
    RIGHT: 39,
    SPACE: 32,
    UP: 38
  };
  exports.KEY_CODES = KEY_CODES;
  var VIEW_LAYOUT_PICKER_OPTIONS = {
    LIST: 'list',
    THUMBNAILS: 'thumbnails'
  };

  exports.VIEW_LAYOUT_PICKER_OPTIONS = VIEW_LAYOUT_PICKER_OPTIONS;
  var EMOTION_VALUES = [{ value: 5, unicode: '1f601' }, { value: 4, unicode: '1f642' }, { value: 3, unicode: '1f610' }, { value: 2, unicode: '1f641' }, { value: 1, unicode: '1f625' }];

  exports.EMOTION_VALUES = EMOTION_VALUES;
  // unicode values for the correct and incorrect svg files
  var FEEDBACK_EMOTION_VALUES = {
    CORRECT: '1f44d',
    INCORRECT: '1f44e'
  };

  exports.FEEDBACK_EMOTION_VALUES = FEEDBACK_EMOTION_VALUES;
  var SCORES = {
    REGULAR: 60,
    GOOD: 70,
    VERY_GOOD: 80,
    EXCELLENT: 90
  };

  exports.SCORES = SCORES;
  var GRADING_SCALE = [{ LOWER_LIMIT: 0, COLOR: '#F46360' }, //red-400
  { LOWER_LIMIT: 60, COLOR: '#ED8E36' }, //orange-400
  { LOWER_LIMIT: 70, COLOR: '#F8BA41' }, //yellow-400
  { LOWER_LIMIT: 80, COLOR: '#A3CA9F' }, //green-200
  { LOWER_LIMIT: 90, COLOR: '#4B9741' } //green-400
  ];

  exports.GRADING_SCALE = GRADING_SCALE;
  var CONTEXT_EVENT_TYPES = {
    FINISH: 'finishContextEvent',
    ON_RESOURCE: 'onResourceEvent',
    START: 'startContextEvent'
  };

  exports.CONTEXT_EVENT_TYPES = CONTEXT_EVENT_TYPES;
  var CORRECT_COLOR = GRADING_SCALE[GRADING_SCALE.length - 1].COLOR;exports.CORRECT_COLOR = CORRECT_COLOR;
  //green-400

  var INCORRECT_COLOR = GRADING_SCALE[0].COLOR;exports.INCORRECT_COLOR = INCORRECT_COLOR;
  //red-400

  var ANONYMOUS_COLOR = '#0072BC';exports.ANONYMOUS_COLOR = ANONYMOUS_COLOR;
  //blue-400

  var OPEN_ENDED_COLOR = '#0072BC';exports.OPEN_ENDED_COLOR = OPEN_ENDED_COLOR;
  //blue-400

  var NO_ANSWER_COLOR = '#FFFFFF';exports.NO_ANSWER_COLOR = NO_ANSWER_COLOR;
  //white

  // Height of the application header in pixels
  var HEADER_HEIGHT = 50;

  exports.HEADER_HEIGHT = HEADER_HEIGHT;
  var REAL_TIME_CLIENT = {
    CONNECTION_ATTEMPT_DELAY: 3000,
    OUTGOING_HEARTBEAT: 5000,
    INCOMING_HEARTBEAT: 5000
  };

  exports.REAL_TIME_CLIENT = REAL_TIME_CLIENT;
  var ENTITY_TYPE = {
    CONTENT: 'content',
    USER: 'user'
  };

  exports.ENTITY_TYPE = ENTITY_TYPE;
  var NETWORK_TYPE = {
    FOLLOWING: 'followings',
    FOLLOWERS: 'followers'
  };

  exports.NETWORK_TYPE = NETWORK_TYPE;
  var COUNTRY_CODES = {
    US: 'US'
  };

  exports.COUNTRY_CODES = COUNTRY_CODES;
  var DEFAULT_PAGE_SIZE = 50;

  exports.DEFAULT_PAGE_SIZE = DEFAULT_PAGE_SIZE;
  var DEFAULT_SEARCH_PAGE_SIZE = 20;

  exports.DEFAULT_SEARCH_PAGE_SIZE = DEFAULT_SEARCH_PAGE_SIZE;
  var TAXONOMY_LEVELS = {
    COURSE: 'course',
    DOMAIN: 'domain',
    STANDARD: 'standard',
    MICRO: 'micro-standard'
  };

  exports.TAXONOMY_LEVELS = TAXONOMY_LEVELS;
  var CODE_TYPES = {
    STANDARD_CATEGORY: 'standard_level_0',
    STANDARD: 'standard_level_1',
    SUB_STANDARD: 'standard_level_2',
    LEARNING_TARGET_L0: 'learning_target_level_0',
    LEARNING_TARGET_L1: 'learning_target_level_1',
    LEARNING_TARGET_L2: 'learning_target_level_2'
  };

  exports.CODE_TYPES = CODE_TYPES;
  var GOORU_DEFAULT_STANDARD = 'GDF';

  exports.GOORU_DEFAULT_STANDARD = GOORU_DEFAULT_STANDARD;
  var ASSESSMENT_SHOW_VALUES = {
    IMMEDIATE: 'immediate',
    SUMMARY: 'summary',
    NEVER: 'never'
  };

  exports.ASSESSMENT_SHOW_VALUES = ASSESSMENT_SHOW_VALUES;
  var MAX_ATTEMPTS = 10;

  exports.MAX_ATTEMPTS = MAX_ATTEMPTS;
  /* token expiration time in milliseconds */
  var TOKEN_EXPIRATION_TIME = 180000;

  exports.TOKEN_EXPIRATION_TIME = TOKEN_EXPIRATION_TIME;
  var RESERVED_WORDS = ['account-settings', 'analytics', 'assessments', 'class', 'classes', 'collections', 'content', 'context-player', 'courses', 'featured', 'forgot-password', 'home', 'index', 'integration', 'logout', 'network', 'player', 'profile', 'questions', 'reports', 'reset-password', 'resources', 'search', 'sign-in', 'sign-up', 'sign-up-finish'];

  exports.RESERVED_WORDS = RESERVED_WORDS;
  var DEFAULT_AVAILABLE_TIME = '12:00 am';
  exports.DEFAULT_AVAILABLE_TIME = DEFAULT_AVAILABLE_TIME;
  var DEFAULT_DUE_TIME = '11:30 pm';

  exports.DEFAULT_DUE_TIME = DEFAULT_DUE_TIME;
  var FIB_REGEX = /\[](?!{)/;

  exports.FIB_REGEX = FIB_REGEX;
  var PLAYER_EVENT_SOURCE = {
    RGO: 'rgo'
  };

  exports.PLAYER_EVENT_SOURCE = PLAYER_EVENT_SOURCE;
  var PLAYER_EVENT_MESSAGE = {
    GRU_PUllUP_CLOSE: 'gru_pullup_close',
    GRU_LOADING_COMPLETED: 'gru_loading_completed'
  };

  exports.PLAYER_EVENT_MESSAGE = PLAYER_EVENT_MESSAGE;
  var ROLES = {
    STUDENT: 'student',
    TEACHER: 'teacher'
  };

  exports.ROLES = ROLES;
  var FEEDBACK_USER_CATEGORY = {
    teacher: 1,
    student: 2
  };

  exports.FEEDBACK_USER_CATEGORY = FEEDBACK_USER_CATEGORY;
  var FEEDBACK_RATING_TYPE = {
    QUANTITATIVE: 1,
    QUALITATIVE: 2,
    BOTH: 3
  };

  exports.FEEDBACK_RATING_TYPE = FEEDBACK_RATING_TYPE;
  var EMOTION_IMAGE = '/assets/quizzes-addon/emoji-one/emoji.svg';

  exports.EMOTION_IMAGE = EMOTION_IMAGE;
  var NO_REACTION = 'no-reaction';
  exports.NO_REACTION = NO_REACTION;
});