define('quizzes-addon/services/quizzes/api-sdk/feedback', ['exports', 'ember', 'quizzes-addon/adapters/player/activity-feedback', 'quizzes-addon/serializers/player/activity-feedback'], function (exports, _ember, _quizzesAddonAdaptersPlayerActivityFeedback, _quizzesAddonSerializersPlayerActivityFeedback) {

  /**
   * Service to support the capture activity feedback functionality
   *
   * @typedef {Object} ActivityFeedbackService
   */
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('activityFeedbackSerializer', _quizzesAddonSerializersPlayerActivityFeedback['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('activityFeedbackAdapter', _quizzesAddonAdaptersPlayerActivityFeedback['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {activityFeedbackAdapter} adapter
     */
    activityFeedbackAdapter: null,

    /**
     * @property {activityFeedbackSerializer} serializer
     */
    activityFeedbackSerializer: null,

    getLocalStorage: function getLocalStorage() {
      return window.localStorage;
    },

    /**
     * device_language_key
     */
    device_language_key: 'deviceLanguage',

    // -------------------------------------------------------------------------
    // Methods

    /**
     * get feedback category by user role
     * @param {Number} userCategoryId
     * @returns {Promise}
     */
    getFeedbackCategory: function getFeedbackCategory(userCategoryId) {
      var service = this;
      var language = service.getLocalStorage().getItem(service.device_language_key);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('activityFeedbackAdapter').getFeedbackCategories(userCategoryId, language).then(function (responseData) {
          var categoryContainer = service.get('activityFeedbackSerializer').normalizeFetchFeedbackCategories(responseData);
          resolve(categoryContainer);
        }, reject);
      });
    },

    /**
     * Gets the user feedback of a given content id and user id
     * @param {Number} contentId
     * @param {Number} userId
     * @returns {Promise}
     */
    fetchActivityFeedback: function fetchActivityFeedback(contentId, userId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('activityFeedbackAdapter').fetchActivityFeedback(contentId, userId).then(function (responseData) {
          resolve(service.get('activityFeedbackSerializer').normalizeFetchActivityFeedback(responseData));
        }, reject);
      });
    },

    /**
     * submit user feedback
     * @param {Object} feedbackData
     * @returns {Promise}
     */
    submitUserFeedback: function submitUserFeedback(feedbackData) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('activityFeedbackAdapter').submitUserFeedback(feedbackData).then(function (responseData) {
          resolve(responseData);
        }, reject);
      });
    }
  });
});