define('quizzes-addon/config/parse-event', ['exports'], function (exports) {
  var PARSE_EVENTS = {
    CLICK_FEEDBACK: 'click.feedback',
    START: 'click.start',
    CLICK_NARRATION: 'click.narration',
    CLICK_RELATED_CONTENT: 'play.related.content',
    CLICK_NEXT_RESOURCE: 'play.next.resource',
    CLICK_PREVIOUS_RESOURCE: 'play.previous.resource',
    SELECT_LIST_RESOURCE: 'select.list.resource',
    CLICK_USAGE_REPORT: 'clcik.usage.report',
    CLICK_STUDENT_PLAYER_STUDY_TIMER: 'click.student.player.study.timer',
    CLICK_STUDENT_PLAYER_STUDY_INSTRUCTION: 'click.student.player.study.instruction',
    CLICK_STUDENT_PLAYER_STUDY_FEEDBACK: 'click.student.player.study.feedback',
    CLICK_STUDY_PLAYER_RELATED_CONTENT: 'click.study.player.related.content',
    CLICK_STUDY_PLAYER_FULLSCREEN: 'click.study.player.fullscreen',
    CLICK_STUDY_PLAYER_QUESTION_INFO: 'click.study.player.question.info',
    CLICK_STUDY_HOME_WHATS_NEW: 'click.study.home.whats.new'
  };

  exports.PARSE_EVENTS = PARSE_EVENTS;
  var APP_ID = 'NavigatorWeb';

  exports.APP_ID = APP_ID;
  var APP_VERSION = '5.2.3';

  exports.APP_VERSION = APP_VERSION;
  var OS_LIST = [{ name: 'Windows Phone', value: 'Windows Phone', version: 'OS' }, { name: 'Windows', value: 'Win', version: 'NT' }, { name: 'iPhone', value: 'iPhone', version: 'OS' }, { name: 'iPad', value: 'iPad', version: 'OS' }, { name: 'Kindle', value: 'Silk', version: 'Silk' }, { name: 'Android', value: 'Android', version: 'Android' }, { name: 'PlayBook', value: 'PlayBook', version: 'OS' }, { name: 'BlackBerry', value: 'BlackBerry', version: '/' }, { name: 'Macintosh', value: 'Mac', version: 'OS X' }, { name: 'Linux', value: 'Linux', version: 'rv' }, { name: 'Palm', value: 'Palm', version: 'PalmOS' }];

  exports.OS_LIST = OS_LIST;
  var BROWSER_LIST = [{ name: 'Chrome', value: 'Chrome', version: 'Chrome' }, { name: 'Firefox', value: 'Firefox', version: 'Firefox' }, { name: 'Safari', value: 'Safari', version: 'Version' }, { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' }, { name: 'Opera', value: 'Opera', version: 'Opera' }, { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' }, { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }];
  exports.BROWSER_LIST = BROWSER_LIST;
});