define('quizzes-addon/components/reports/assessment/questions/qz-serp-identify-vowel', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  /**
   * SERP Encoding Assessment
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-serp-identify-vowel'],

    // -------------------------------------------------------------------------
    // Properties

    showCorrect: false,

    answers: _ember['default'].computed.alias('question.answer'),

    baseAnswers: _ember['default'].computed.alias('question.resource.baseAnswers'),

    // -----------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      var component = this;

      var baseAnswers = component.get('baseAnswers');
      baseAnswers.forEach(function (item, answerIndex) {
        var correctAnswer = item.correct_answer.map(function (cAns) {
          return JSON.parse(cAns);
        });
        var userAnswer = JSON.parse(component.get('answers')[answerIndex].value);
        var contentTag = component.$('.identify-vowel-edit-' + answerIndex);
        component.defaultHighlighter(contentTag, item.answer_text, answerIndex, correctAnswer, userAnswer);
      });
    },

    // ---------------------------------------------------------------------
    // Methods

    defaultHighlighter: function defaultHighlighter(contentTag, text, answerIndex, correctAnswer, userAnswer) {
      var component = this;
      var showCorrect = component.get('showCorrect');
      var crossedClass = function crossedClass(pIndex) {
        var answerText = correctAnswer.find(function (cAns) {
          return parseInt(cAns.crossPosition, 0) === pIndex;
        });
        if (answerText) {
          var classNames = 'selected';
          if (answerText.isShort) {
            classNames = 'short';
          }
          if (answerText.isCross) {
            classNames = 'crossed';
          }
          return classNames;
        }
      };
      var isVowels = function isVowels() {
        return '';
      };
      if (!showCorrect) {
        isVowels = function (letter, index) {
          var activeLetter = userAnswer.find(function (cAns) {
            return parseInt(cAns.crossPosition, 0) === index;
          });
          return activeLetter ? activeLetter.isCorrect ? 'selected' : 'selected wrong-selected' : '';
        };
        crossedClass = function (pIndex) {
          var crossItem = userAnswer.find(function (cItem) {
            return parseInt(cItem.crossPosition, 0) === pIndex && cItem.isCross;
          });
          var shortItem = userAnswer.find(function (cItem) {
            return parseInt(cItem.crossPosition, 0) === pIndex && cItem.isShort;
          });
          var classNames = '';
          if (crossItem) {
            var crtCross = correctAnswer.find(function (bItem) {
              return parseInt(bItem.crossPosition, 0) === pIndex;
            });
            classNames = crtCross ? 'crossed' : 'wrong crossed';
          }
          if (shortItem) {
            var crtCross = correctAnswer.find(function (bItem) {
              return parseInt(bItem.crossPosition, 0) === pIndex;
            });
            classNames = crtCross ? 'short' : 'wrong short';
          }
          return classNames;
        };
      }
      var splitText = [].concat(_toConsumableArray(text)).map(function (item, index) {
        return item !== ' ' ? '<b class="' + isVowels(item, index + 1) + ' ' + crossedClass(index + 1) + '" data-b-index=' + (index + 1) + '>' + item + '</b>' : item;
      }).join('');
      contentTag.html(splitText);
    }
  });
});