define('quizzes-addon/components/player/questions/qz-scientific-free-response', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion) {

  /**
   * True or false Question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-scientific-free-response'],

    isShow: false,

    // -------------------------------------------------------------------------

    // -------------------------------------------------------------------------
    // Events
    initInputEvents: (function () {
      var component = this;
      component.$('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });
      component.setAnswersEvents();
    }).on('didInsertElement'),

    // -------------------------------------------------------------------------
    // Properties
    /**
     * @param answers
     */
    answers: _ember['default'].computed('question', function () {
      var component = this;
      var answers = JSON.parse(JSON.stringify(component.get('question.answerDetails')));
      var hints = component.get('question.hint');

      answers.forEach(function (choice) {
        choice.hint = hints[choice.answer_category + '_explanation'];
      });

      return answers;
    }),
    // -------------------------------------------------------------------------
    // Methods
    /**
     * Notify input answers
     * @param {boolean} onLoad if this was called when loading the component
     */
    notifyInputAnswers: function notifyInputAnswers(onLoad) {
      var component = this,
          inputs = component.$('.free-response input[type=text]'),
          answers = inputs.map(function (index, input) {
        var answerDetails = component.get('answerDetails') || component.get('question.answerDetails');
        var answer = _ember['default'].$(input).val();
        var category = answerDetails[index].answer_category;
        return { value: _ember['default'].$.trim(category) + ':' + _ember['default'].$.trim(answer) };
      }).toArray();
      var answerCompleted = answers.join('').length > 0; //to check that at least 1 answer has text
      component.notifyAnswerChanged(answers);
      if (answerCompleted) {
        if (onLoad) {
          component.notifyAnswerLoaded(answers);
        } else {
          component.notifyAnswerCompleted(answers);
        }
      } else {
        component.notifyAnswerCleared(answers);
      }
    },
    /**
     * Set answers
     */
    setAnswersEvents: function setAnswersEvents() {
      var component = this;
      var inputs = component.$('.free-response');
      if (component.get('hasUserAnswer')) {
        component.notifyInputAnswers(true);
      }
      inputs.off('focusout');
      inputs.on('keyup', 'input[type=text]', function () {
        if (!component.get('isComprehension')) {
          component.notifyInputAnswers(false);
        }
      });
      if (component.get('isComprehension')) {
        inputs.on('focusout', 'input[type=text]', function () {
          component.notifyInputAnswers(false);
        });
      }
    },
    toggleAction: function toggleAction(isShow, category) {
      var component = this;
      component.set('category', category);
      component.set('isShow', !isShow);
    }
  });
});