define('quizzes-addon/mixins/endpoint-config', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    quizzesConfigurationService: _ember['default'].inject.service('quizzes/configuration'),

    getEndpointUrl: function getEndpointUrl() {
      var configuration = this.get('quizzesConfigurationService.configuration');
      return configuration.get('endpoint.url');
    },

    getEndpointProviderUrl: function getEndpointProviderUrl() {
      var configuration = this.get('quizzesConfigurationService.configuration');
      return configuration.get('endpoint.providerUrl');
    },

    getEndpointSecureUrl: function getEndpointSecureUrl() {
      var configuration = this.get('quizzesConfigurationService.configuration');
      return configuration.get('endpoint.secureUrl');
    },

    getRealTimeWebServiceUrl: function getRealTimeWebServiceUrl() {
      var configuration = this.get('quizzesConfigurationService.configuration');
      return configuration.get('realTime.webServiceUrl');
    },

    getRealTimeWebServiceUri: function getRealTimeWebServiceUri() {
      var configuration = this.get('quizzesConfigurationService.configuration');
      return configuration.get('realTime.webServiceUri');
    },

    getRealTimeWebSocketUrl: function getRealTimeWebSocketUrl() {
      var configuration = this.get('quizzesConfigurationService.configuration');
      return '' + configuration.get('realTime.webSocketUrl') + this.getRealTimeWebSocketUri();
    },

    getRealTimeWebSocketUri: function getRealTimeWebSocketUri() {
      var configuration = this.get('quizzesConfigurationService.configuration');
      return configuration.get('realTime.webSocketUri');
    },

    getFileMaxSizeInMB: function getFileMaxSizeInMB() {
      var configuration = this.get('quizzesConfigurationService.configuration');
      return configuration.get('FILE_UPLOAD.MAX_SIZE_IN_MB');
    }
  });
});