define('quizzes-addon/services/quizzes/api-sdk/context', ['exports', 'ember', 'quizzes-addon/serializers/context/context', 'quizzes-addon/adapters/context/context'], function (exports, _ember, _quizzesAddonSerializersContextContext, _quizzesAddonAdaptersContextContext) {
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('contextAdapter', _quizzesAddonAdaptersContextContext['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('contextSerializer', _quizzesAddonSerializersContextContext['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {ContextAdapter} adapter
     */
    contextAdapter: null,

    /**
     * @property {ContextSerializer} serializer
     */
    contextSerializer: null,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Creates a context
     * @param {Context} assignment
     * @returns {Promise}
     */
    createContext: function createContext(assignment) {
      var service = this;
      var serializedAssignment = service.get('contextSerializer').serializeContext(assignment);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').createContext(serializedAssignment).then(resolve, reject);
      });
    },

    /**
     * Gets  contexts by id assigned to the current student
     * @returns {Promise}
     */
    getAssignedContextById: function getAssignedContextById(contextId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').getAssignedContextById(contextId).then(function (response) {
          return service.get('contextSerializer').normalizeReadContext(response);
        }).then(resolve, reject);
      });
    },

    /**
     * Gets all the contexts assigned to the current student
     * @returns {Promise}
     */
    getContextsAssigned: function getContextsAssigned() {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').getContextsAssigned().then(function (response) {
          return service.get('contextSerializer').normalizeReadContexts(response);
        }).then(resolve, reject);
      });
    },

    /**
     * Gets all contexts created by the current teacher
     * @returns {Promise}
     */
    getContextsCreated: function getContextsCreated() {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').getContextsCreated().then(function (response) {
          return service.get('contextSerializer').normalizeReadContexts(response);
        }).then(resolve, reject);
      });
    },

    /**
     * Start Playing the new resource
     * @param {String} resourceId current resource
     * @param {String} contextId
     * @param {String} source component originating events
     * @returns {Promise}
     */
    startPlayResource: function startPlayResource(resourceId, contextId, eventContext) {
      var baseResource = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];

      var service = this;
      var serializedPreviousResult = {
        resourceId: resourceId
      };
      if (baseResource) {
        serializedPreviousResult.baseResourceId = baseResource.id;
      }
      var context = this.get('contextSerializer').serializeEventContext(eventContext);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').startPlayResource(resourceId, contextId, serializedPreviousResult, context).then(resolve, reject);
      });
    },

    /**
     * Stop Playing the  current resource.
     * @param {String} resourceId current resource
     * @param {String} contextId
     * @param {String} source component originating events
     * @returns {Promise}
     */
    stopPlayResource: function stopPlayResource(resourceId, contextId, previousResult, eventContext) {
      var service = this;
      var serializedPreviousResult = previousResult ? this.get('contextSerializer').serializeResourceResult(previousResult) : null;
      var context = this.get('contextSerializer').serializeEventContext(eventContext);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').stopPlayResource(resourceId, contextId, serializedPreviousResult, context).then(resolve, reject);
      });
    },

    /**
     * Pause  playing the current resource
     * @param {String} resourceId current resource
     * @param {String} contextId
     * @param {String} source component originating events
     * @returns {Promise}
     */
    pausePlayResource: function pausePlayResource(resourceId, contextId, previousResult, eventContext) {
      var service = this;
      var startTime = previousResult.startTime || 0;
      var stopTime = previousResult.stopTime || startTime;
      var serializedPreviousResult = {
        resourceId: resourceId,
        timeSpent: service.roundMilliseconds(stopTime - startTime)
      };
      var context = this.get('contextSerializer').serializeEventContext(eventContext);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').pausePlayResource(resourceId, contextId, serializedPreviousResult, context).then(resolve, reject);
      });
    },

    /**
     * Resume playing the current resource.
     * @param {String} resourceId current resource
     * @param {String} contextId
     * @param {String} source component originating events
     * @returns {Promise}
     */
    resumePlayResource: function resumePlayResource(resourceId, contextId, eventContext) {
      var service = this;
      var serializedPreviousResult = {
        resourceId: resourceId
      };
      var context = this.get('contextSerializer').serializeEventContext(eventContext);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').resumePlayResource(resourceId, contextId, serializedPreviousResult, context).then(resolve, reject);
      });
    },

    /**
     * Send event to notify the student started an assignment
     * @param {String} contextId
     * @param {String} source component originating events
     * @returns {Promise}
     */
    startContext: function startContext(contextId, eventContext) {
      var service = this;
      var context = this.get('contextSerializer').serializeEventContext(eventContext);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').sendStartContextEvent(contextId, context).then(function (response) {
          return service.get('contextSerializer').normalizeContextResult(response);
        }).then(resolve, reject);
      });
    },

    /**
     * Send event to notify the student submitted all questions in an assignment
     * @param {String} contextId
     * @param {String} source component originating events
     * @returns {Promise}
     */
    finishContext: function finishContext(contextId, eventContext) {
      var service = this;
      var context = this.get('contextSerializer').serializeEventContext(eventContext);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').sendFinishContextEvent(contextId, context).then(resolve, reject);
      });
    },

    /**
     * Update an assignment/context
     * @param {Context} assignment
     * @returns {Promise}
     */
    updateContext: function updateContext(assignment) {
      var service = this;
      var serializedAssignment = this.get('contextSerializer').serializeUpdateContext(assignment);
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.get('contextAdapter').updateContext(serializedAssignment, assignment.get('id')).then(resolve, reject);
      });
    },

    /**
     * Round milliseconds
     */
    roundMilliseconds: function roundMilliseconds(milliseconds) {
      return milliseconds - milliseconds % 1000;
    }
  });
});