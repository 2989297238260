define('quizzes-addon/components/taxonomy/gru-taxonomy-selector', ['exports', 'ember', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/models/taxonomy/taxonomy-tag'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig, _quizzesAddonModelsTaxonomyTaxonomyTag) {

  /**
   * Taxonomy selector component
   *
   * Component responsible for displaying/editing a category, subject and subject course values
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @requires service:taxonomy
     */
    taxonomyService: _ember['default'].inject.service('taxonomy'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['taxonomy', 'gru-taxonomy-selector'],

    // -------------------------------------------------------------------------
    // Events
    onInit: _ember['default'].on('init', function () {
      this.setupComponent();
    }),

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Set Category
       */
      setCategory: function setCategory(category) {
        var component = this;
        component.set('selectedSubject', null);
        component.set('internalCategory', category);
        component.loadSubjects(category);
        if (component.get('onCategorySelected')) {
          component.sendAction('onCategorySelected', category);
        }
      },
      /**
       * Set Subject
       */
      setSubject: function setSubject(subject) {
        var component = this;
        component.set('selectedSubject', subject);
        if (component.get('onSubjectSelected')) {
          component.sendAction('onSubjectSelected', subject);
        }
      },

      /**
       * Select a subject course
       */
      selectTaxonomy: function selectTaxonomy(taxonomy) {
        var component = this;
        component.set('selectedTaxonomy', taxonomy);
        if (component.get('onTaxonomySelected')) {
          component.sendAction('onTaxonomySelected', this.get('selectedTaxonomy'));
        }
      },

      /**
       * Remove a specific tag
       */
      removeTag: function removeTag(tag) {
        var component = this;
        component.removeTaxonomyTagData(tag.get('data.id'));
        if (component.get('onTaxonomySelected')) {
          component.sendAction('onTaxonomySelected', this.get('selectedTaxonomy'));
        }
      }
    },

    //
    // Methods
    /**
     * Removes a taxonomy tag data from taxonomy
     * @param id
     */
    removeTaxonomyTagData: function removeTaxonomyTagData(taxonomyId) {
      var taxonomy = this.get('selectedTaxonomy');
      var taxonomyTagData = taxonomy.findBy('id', taxonomyId);
      if (taxonomyTagData) {
        taxonomy.removeObject(taxonomyTagData);
      }
    },

    /**
     * Loads subjects by category
     */
    loadSubjects: function loadSubjects(category) {
      var component = this;
      component.get('taxonomyService').getSubjects(category).then(function (subjects) {
        component.set('subjects', subjects);
      });
    },

    setupComponent: function setupComponent() {
      var component = this;
      var subject = component.get('selectedSubject');
      var category = component.get('selectedCategory');
      if (category) {
        component.loadSubjects(category);
      }

      if (subject) {
        if (!subject.get('hasCourses')) {
          component.get('taxonomyService').getCourses(subject);
        }
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * i18n key for the subject dropdown label
     * @property {string}
     */
    subjectLabelKey: 'taxonomy.gru-taxonomy-selector.primary-subject-and-course',

    /**
     * @type {Ember.A} categories - List of course categories
     */
    categories: _quizzesAddonConfigQuizzesConfig.TAXONOMY_CATEGORIES,

    /**
     * @property {boolean}
     */
    showCategories: true,

    /**
     * Is the entity being edited or not?
     * @property {Boolean}
     */
    isEditing: null,

    /**
     * Indicates if it should only include subjects having standards
     * @poperty {boolean}
     */
    onlySubjectsWithStandards: false,

    /**
     * @property {TaxonomyTag[]} List of taxonomy tags
     */
    tags: _ember['default'].computed('selectedTaxonomy.[]', function () {
      return _quizzesAddonModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('selectedTaxonomy'), false);
    }),

    /**
     * @property {TaxonomyTag[]} List of taxonomy tags
     */
    editableTags: _ember['default'].computed('selectedTaxonomy.[]', function () {
      return _quizzesAddonModelsTaxonomyTaxonomyTag['default'].getTaxonomyTags(this.get('selectedTaxonomy'), false, true);
    }),

    /**
     * @property {string[]} taxonomy ids
     */
    selectedTaxonomyIds: _ember['default'].computed('selectedTaxonomy.[]', function () {
      return this.get('selectedTaxonomy').map(function (tagData) {
        return tagData.get('id');
      });
    }),

    /**
     * @type {Array} List of subjects
     */
    subjects: null,

    /**
     * Internal category used to store the category from UI when subject is not selected
     * @property {string}
     */
    internalCategory: null,

    /**
     * @type {String} the selected category
     */
    selectedCategory: _ember['default'].computed('selectedSubject.category', 'internalCategory', function () {
      return this.get('selectedSubject.category') || this.get('internalCategory');
    }),

    /**
     * the subject selected
     * @property {TaxonomyRoot}
     */
    selectedSubject: null,

    /**
     * the subject courses to present
     * @property {[]}
     */
    subjectCourses: _ember['default'].computed.alias('selectedSubject.courses'),

    /**
     * @property {TaxonomyTagData[]}
     */
    selectedTaxonomy: _ember['default'].A(),

    /**
     * Indicates if it should show subject courses
     * @property {boolean}
     */
    showCourses: false,

    /**
     * when a category is selected
     * @property {string}
     */
    onCategorySelected: null,

    /**
     * when a subject is selected
     * @property {string}
     */
    onSubjectSelected: null,

    /**
     * when a taxonomy is selected
     * @property {string}
     */
    onTaxonomySelected: null,

    // -------------------------------------------------------------------------
    // Observers

    /**
     * Sets the corresponding lists of subjects and courses when the primary subject changes
     */
    onSelectedSubjectChanged: _ember['default'].observer('selectedSubject', function () {
      this.setupComponent();
    })
  });
});