define('quizzes-addon/services/quizzes/configuration', ['exports', 'ember', 'quizzes-addon/adapters/configuration', 'quizzes-addon/config/env/development', 'quizzes-addon/config/env/test', 'quizzes-addon/config/env/production'], function (exports, _ember, _quizzesAddonAdaptersConfiguration, _quizzesAddonConfigEnvDevelopment, _quizzesAddonConfigEnvTest, _quizzesAddonConfigEnvProduction) {

  var ConfigurationService = _ember['default'].Service.extend({
    configurationAdapter: null,

    configuration: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('configurationAdapter', _quizzesAddonAdaptersConfiguration['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    loadConfiguration: function loadConfiguration() {
      var service = this;
      var environment = _ember['default'].getOwner(this).resolveRegistration('config:environment').environment;
      var isProduction = environment === 'production';
      var isDevelopment = environment === 'development';
      var envConfiguration = isProduction ? _quizzesAddonConfigEnvProduction['default'] : isDevelopment ? _quizzesAddonConfigEnvDevelopment['default'] : _quizzesAddonConfigEnvTest['default'];

      var configuration = _ember['default'].Object.create(envConfiguration);
      configuration.set('properties', {});

      service.set('configuration', configuration);

      //setting the configuration to the global variable
      ConfigurationService.configuration = configuration;

      return _ember['default'].RSVP.resolve(configuration);
    },

    mergeConfiguration: function mergeConfiguration(configuration) {
      this.get('configuration').setProperties(configuration);
    },

    addProperties: function addProperties(properties) {
      this.set('configuration.properties', properties);
    },

    /**
     * Sets the token property
     * @param {string} token
     */
    setToken: function setToken(token) {
      var service = this;
      service.set('configuration.properties.token', token);
    },

    /**
     * Sets the cdn url
     * @param {string} url
     */
    setCdnUrl: function setCdnUrl(url) {
      var service = this;
      service.set('configuration.properties.cdnURL', url);
    }
  });

  ConfigurationService.reopenClass({
    /**
     * Application configuration properties
     */
    configuration: null
  });

  exports['default'] = ConfigurationService;
});