define('quizzes-addon/routes/player', ['exports', 'ember', 'quizzes-addon/models/result/context', 'quizzes-addon/models/context/event-context', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonModelsResultContext, _quizzesAddonModelsContextEventContext, _quizzesAddonConfigQuizzesConfig) {

  /**
   * @typedef { Ember.Route } PlayerRoute
   *
   * @module
   * @augments ember/Route
   */
  exports['default'] = _ember['default'].Route.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    /**
     * @type {AttemptService} attemptService
     * @property {Ember.Service} Service to send context related events
     */
    quizzesAttemptService: _ember['default'].inject.service('quizzes/attempt'),

    /**
     * @type {CollectionService} collectionService
     * @property {Ember.Service} Service to retrieve a collection
     */
    quizzesCollectionService: _ember['default'].inject.service('quizzes/collection'),

    /**
     * @type {ContextService} contextService
     * @property {Ember.Service} Service to send context related events
     */
    quizzesContextService: _ember['default'].inject.service('quizzes/context'),

    /**
     * @property {Service} Configuration service
     */
    quizzesConfigurationService: _ember['default'].inject.service('quizzes/configuration'),

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Action triggered when click the course map.
       */
      onRedirectCourseMap: function onRedirectCourseMap() {
        // Empty, it does nothing by default
      },

      /**
       * Action triggered when  click the back to collection.
       */
      onBackToCollection: function onBackToCollection() {
        // Empty, it does nothing by default
      },

      /**
       * Action triggered when the user hits remix on the content player
       */
      onRemixCollection: function onRemixCollection() {
        // Empty, it does nothing by default
      },

      /**
       * When the submission is complete
       */
      onFinish: function onFinish() {
        var controller = this.get('controller');
        var reportURL = controller.get('reportURL');
        if (reportURL) {
          var url = reportURL.replace('{context-id}', controller.get('contextResult.contextId'));
          window.location.href = url;
        } else {
          this.transitionTo('reports.student-context', controller.get('contextResult.contextId'));
        }
      }
    },

    // -------------------------------------------------------------------------
    // Methods

    /**
     * @param {{ contextId: string }} params
     */
    model: function model(params) {
      return this.quizzesModel(params);
    },

    /**
     * @param {{ contextId: string }} params
     */
    quizzesModel: function quizzesModel(params) {
      var route = this;
      var resourceId = params.resourceId;
      var contextId = params.contextId;
      var source = params.source;
      var sourceId = params.sourceId;
      var sourceUrl = params.sourceUrl;
      var tenantId = params.tenantId;
      var partnerId = params.partnerId;
      var collectionSubType = params.collectionSubType;
      var pathId = params.pathId;
      var pathType = params.pathType;
      var notCheckAttempts = params.notCheckAttempts;
      var isStudyPlayer = params.isStudyPlayer;
      var isIframeMode = params.isIframeMode;
      var studentTimespentData = params.studentTimespentData;
      var ctxPathId = params.ctxPathId;
      var ctxPathType = params.ctxPathType;

      var type = params.type || route.get('quizzesConfigurationService.configuration.properties.type');
      var reportURL = params.routeURL || route.get('quizzesConfigurationService.configuration.properties.reportURL');
      var profileId = params.profileId || route.get('quizzesConfigurationService.configuration.properties.profileId');
      var role = params.role || route.get('quizzesConfigurationService.configuration.properties.role') || 'student';
      var isTeacher = role === 'teacher';
      var isAnonymous = profileId === 'anonymous';
      var isLTIResourceLinkLaunch = params.isLTIResourceLinkLaunch === 'true';
      var isDeepLink = params.isDeepLink === 'true';
      var classData = params.classData;
      //Keept this for 2 release and remove this with 4.4.4
      console.log('pathType', pathType); //eslint-disable-line
      var pathTypeEvtCtx = pathType === '' ? null : pathType;
      console.log('pathTypeEvtCtx', pathTypeEvtCtx); //eslint-disable-line
      pathType = pathTypeEvtCtx;
      var eventContext = _quizzesAddonModelsContextEventContext['default'].create({
        collectionSubType: collectionSubType,
        pathId: pathId,
        partnerId: partnerId,
        sourceId: sourceId,
        source: source,
        sourceUrl: sourceUrl,
        tenantId: tenantId,
        pathType: pathType,
        ctxPathId: ctxPathId,
        ctxPathType: ctxPathType
      });
      var model = {
        isAnonymous: isAnonymous,
        pathId: pathId,
        reportURL: reportURL,
        resourceId: resourceId,
        role: role,
        eventContext: eventContext,
        notCheckAttempts: notCheckAttempts,
        isStudyPlayer: isStudyPlayer,
        isIframeMode: isIframeMode,
        studentTimespentData: studentTimespentData,
        isLTIResourceLinkLaunch: isLTIResourceLinkLaunch,
        isDeepLink: isDeepLink,
        classData: classData
      };
      if (isAnonymous || isTeacher || !isStudyPlayer) {
        return route.get('quizzesContextService').startContext(contextId, eventContext).then(function (contextResult) {
          return _ember['default'].RSVP.hash(Object.assign(model, {
            contextResult: contextResult,
            collection: route.get('quizzesCollectionService').readCollection(contextResult.collectionId, type)
          }));
        });
      } else {
        return route.get('quizzesContextService').getAssignedContextById(contextId).then(function (context) {
          return !context ? null : route.get('quizzesCollectionService').readCollection(context.collectionId, type).then(function (collection) {
            return !collection ? null : route.get('quizzesAttemptService').getAttemptIds(contextId, profileId).then(function (attempts) {
              return _ember['default'].RSVP.hash(Object.assign(model, {
                attempts: attempts,
                collection: collection,
                context: context,
                startContextFunction: function startContextFunction() {
                  return route.startContext(context.id, eventContext);
                }
              }));
            });
          });
        });
      }
    },

    setupController: function setupController(controller, model) {
      window.parent.postMessage(_quizzesAddonConfigQuizzesConfig.PLAYER_EVENT_MESSAGE.GRU_LOADING_COMPLETED, '*');
      var collection = model.collection;
      var isAnonymous = model.isAnonymous;
      var isTeacher = model.role === 'teacher';
      var notCheckAttempts = model.notCheckAttempts;
      var isStudyPlayer = model.isStudyPlayer;
      var isIframeMode = model.isIframeMode;
      var lessonList = model.unit && model.unit.children ? model.unit.children : [];
      var unitList = model.course && model.course.children ? model.course.children : [];
      var contextResult = _quizzesAddonModelsResultContext['default'].create();
      if (model.resourceId) {
        contextResult.set('currentResourceId', model.resourceId);
      }
      if (isAnonymous || isTeacher || !isStudyPlayer) {
        contextResult = model.contextResult;
        contextResult.merge(collection);
        controller.set('role', model.role);
      } else {
        var context = model.context;
        if (model.course && model.course.id) {
          context.set('courseId', model.course.id);
        }
        context.set('attempts', model.attempts.length);
        contextResult.set('context', context);
        contextResult.set('collection', collection);
        controller.set('isAnonymous', isAnonymous);
        controller.set('role', model.role);
        controller.set('startContextFunction', model.startContextFunction);
      }
      controller.set('contextResult', contextResult);
      controller.set('reportURL', model.reportURL);
      controller.set('eventContext', model.eventContext);
      controller.set('notCheckAttempts', notCheckAttempts);
      controller.set('showConfirmation', false);
      controller.set('suggestedResources', model.suggestedResources);
      controller.set('pathType', model.eventContext.pathType);
      controller.set('ctxPathType', model.eventContext.ctxPathType);
      controller.set('isStudyPlayer', isStudyPlayer);
      controller.set('isIframeMode', isIframeMode);
      controller.set('lesson', model.lesson);
      controller.set('lessonList', lessonList);
      controller.set('unitList', unitList);
      controller.set('studentTimespentData', model.studentTimespentData);
      controller.set('isLTIResourceLinkLaunch', model.isLTIResourceLinkLaunch);
      controller.set('isDeepLink', model.isDeepLink);
      controller.set('class', model.classData);
    },

    /**
     * @param {string} contextId
     * Starts context to show the player
     */
    startContext: function startContext(contextId, eventContext) {
      var route = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        route.get('quizzesContextService').startContext(contextId, eventContext).then(resolve, reject);
      });
    }
  });
});