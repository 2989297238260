define('quizzes-addon/components/player/questions/qz-hs-text', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion) {
  /**
   * Hot Spot Text
   *
   * Component responsible for controlling the logic and appearance of a hot spot
   * text question inside of the {@link player/qz-question-viewer.js}
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments Ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies
    i18n: _ember['default'].inject.service(),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-hs-text'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    /**
     * Remove click event on answers
     */
    removeSubscriptions: _ember['default'].on('willDestroyElement', function () {
      this.$('li.answer').off('click');
    }),

    /**
     * Initialize answers when the user has previous answers or not
     */
    setupInstanceProperties: _ember['default'].on('init', function () {
      var component = this;
      component.setAnswers();
    }),

    /**
     * Set answers and set click events on every answer to selected and unselected answers
     */
    setupSubscriptions: _ember['default'].on('didInsertElement', function () {
      var component = this;
      var readOnly = component.get('readOnly');

      component.setUserAnswer();

      if (!readOnly) {
        if (component.get('userAnswer')) {
          component.notify(true);
        }
        this.$('li.answer').on('click', function () {
          var $this = $(this);
          var answerId = $this.data('id');

          var selected = component.get('selectedAnswers');
          var answer = selected.findBy('value', answerId);

          $this.toggleClass('selected');

          if (!answer) {
            selected.push({ value: answerId });
          } else {
            var idx = selected.indexOf(answer);
            selected.splice(idx, 1);
          }

          component.notify(false);
        });
      }
    }),

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @typedef answers
     * @property {String} value - answer value
     * @property {String} content - markup string containing the answer text
     */
    answers: _ember['default'].computed.map('question.answers', function (answer) {
      return {
        value: answer.get('value'),
        text: answer.get('text')
      };
    }),

    /**
     * @property {String} instructions - Question instructions
     */
    instructions: _ember['default'].computed(function () {
      return this.get('i18n').t('qz-hs-text.instructions');
    }),

    /**
     * @property {Array} selectedAnswers - Array of ids for each one of the answers selected by the user
     */
    selectedAnswers: _ember['default'].A([]),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Notifies answer events
     * @param {boolean} onLoad if this was called when loading the component
     */
    notify: function notify(onLoad) {
      var component = this;
      var selected = component.get('selectedAnswers');
      var cleared = !selected.length;

      component.notifyAnswerChanged(selected);
      if (cleared) {
        component.notifyAnswerCleared(selected);
      } else {
        if (onLoad) {
          component.notifyAnswerLoaded(selected);
        } else {
          component.notifyAnswerCompleted(selected);
        }
      }
    },

    /**
     * Set answers
     */
    setAnswers: function setAnswers() {
      var userAnswer = this.get('userAnswer');
      this.set('selectedAnswers', userAnswer || []);
    },

    /**
     * Set the user answer
     */
    setUserAnswer: function setUserAnswer() {
      if (this.get('hasUserAnswer')) {
        var userAnswer = this.get('userAnswer');
        userAnswer.forEach(function (answer) {
          var selector = 'li.answer[data-id=\'' + answer.value + '\']';
          var $answer = _ember['default'].$(selector);
          $answer.toggleClass('selected');
        });
      }
    }
  });
});