define('quizzes-addon/components/editor/assessment/qz-rubric-creation', ['exports', 'ember', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['editor', 'assessment', 'qz-rubric-creation'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /***
       * Select tab option
       */
      selectOption: function selectOption(type) {
        this.set('showFromWeb', type === 'fromWeb');
        this.set('showFromComputer', type === 'fromComputer');
      },
      /***
       * Select file
       */
      selectFile: function selectFile(file) {
        //TODO
        this.set('rubric.file', file);
      },
      /***
       * Add URL
       */
      addURL: function addURL(url) {
        if (this.get('showFromWeb')) {
          var resource = this.get('resource');
          resource.set('url', url);
        }
      }
    },

    // -------------------------------------------------------------------------
    // Properties
    /*** Indicates when then show from web option is visible
     * * @property {boolean}
     * */
    resource: _ember['default'].Object.create({}),
    /**
     * Indicates when then show from web option is visible
     * @property {boolean}
     */
    showFromWeb: true,

    /**
     * Indicates when show from computer is visible
     * @property {boolean}
     */
    showFromComputer: false,

    /**
     * @type {String} list of all valid extension per quizzes/config/config#UPLOAD_TYPES
     */
    validExtensions: _ember['default'].computed(function () {
      var extensions = _quizzesAddonConfigQuizzesConfig.UPLOADABLE_TYPES.map(function (typeObject) {
        return typeObject.validExtensions;
      });
      return extensions.join(' ');
    })
  });
});