define('quizzes-addon/serializers/taxonomy/taxonomy', ['exports', 'ember', 'quizzes-addon/models/taxonomy/taxonomy-root', 'quizzes-addon/models/taxonomy/taxonomy-item', 'quizzes-addon/models/taxonomy/taxonomy-tag-data', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonModelsTaxonomyTaxonomyRoot, _quizzesAddonModelsTaxonomyTaxonomyItem, _quizzesAddonModelsTaxonomyTaxonomyTagData, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Serializer for Taxonomy endpoints
   *
   * @typedef {Object} ProfileSerializer
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * Normalize the Fetch Taxonomy Subjects endpoint's response
     *
     * @param payload is the endpoint response in JSON format
     * @returns {Subject[]} an array of subjects
     */
    normalizeFetchSubjects: function normalizeFetchSubjects(payload) {
      var result = [];
      var serializer = this;
      var subjects = payload.subjects;
      if (_ember['default'].isArray(subjects)) {
        result = subjects.map(function (subject) {
          return serializer.normalizeSubject(subject);
        });
      }
      return result;
    },

    normalizeSubject: function normalizeSubject(subjectPayload) {
      var serializer = this;
      return _quizzesAddonModelsTaxonomyTaxonomyRoot['default'].create(_ember['default'].getOwner(serializer).ownerInjection(), {
        id: subjectPayload.id,
        frameworkId: subjectPayload.standard_framework_id,
        title: subjectPayload.title,
        subjectTitle: subjectPayload.title,
        code: subjectPayload.code,
        frameworks: serializer.normalizeFrameworks(subjectPayload.frameworks, subjectPayload.title)
      });
    },

    normalizeFrameworks: function normalizeFrameworks(frameworksPayload, parentTitle) {
      var frameworks = [];
      var serializer = this;
      if (frameworksPayload && _ember['default'].isArray(frameworksPayload)) {
        frameworks = frameworksPayload.map(function (framework) {
          return serializer.normalizeFramework(framework, parentTitle);
        });
      }
      return frameworks;
    },

    normalizeFramework: function normalizeFramework(subjectPayload, parentTitle) {
      var serializer = this;
      return _quizzesAddonModelsTaxonomyTaxonomyRoot['default'].create(_ember['default'].getOwner(serializer).ownerInjection(), {
        id: subjectPayload.taxonomy_subject_id,
        frameworkId: subjectPayload.standard_framework_id,
        title: subjectPayload.title,
        subjectTitle: '' + parentTitle
      });
    },

    /**
     * Normalize the Fetch Taxonomy Courses endpoint's response
     *
     * @param payload is the endpoint response in JSON format
     * @returns {Course[]} an array of courses
     */
    normalizeFetchCourses: function normalizeFetchCourses(payload) {
      var result = [];
      var serializer = this;
      var courses = payload.courses;
      if (_ember['default'].isArray(courses)) {
        result = courses.map(function (course) {
          return serializer.normalizeCourse(course);
        });
      }
      return result;
    },

    normalizeCourse: function normalizeCourse(coursePayload) {
      var serializer = this;
      return _quizzesAddonModelsTaxonomyTaxonomyItem['default'].create(_ember['default'].getOwner(serializer).ownerInjection(), {
        id: coursePayload.id,
        code: coursePayload.code,
        title: coursePayload.title
      });
    },

    /**
     * Normalize the Fetch Taxonomy Domains endpoint's response
     *
     * @param payload is the endpoint response in JSON format
     * @returns {Course[]} an array of domains
     */
    normalizeFetchDomains: function normalizeFetchDomains(payload) {
      var result = [];
      var serializer = this;
      var domains = payload.domains;
      if (_ember['default'].isArray(domains)) {
        result = domains.map(function (domain) {
          return serializer.normalizeDomain(domain);
        });
      }
      return result;
    },

    normalizeDomain: function normalizeDomain(domainPayload) {
      return _quizzesAddonModelsTaxonomyTaxonomyItem['default'].create({
        id: domainPayload.id,
        code: domainPayload.code,
        title: domainPayload.title
      });
    },

    /**
     * Normalize the Fetch Taxonomy Codes endpoint's response
     *
     * @param payload is the endpoint response in JSON format
     * @returns {Object[]} an array of objects, each one with code information.
     */
    normalizeFetchCodes: function normalizeFetchCodes(payload) {
      var result = [];
      var serializer = this;
      var codes = payload.codes;
      if (_ember['default'].isArray(codes)) {
        result = codes.map(function (code) {
          return serializer.normalizeCode(code);
        });
      }
      return result;
    },

    normalizeCode: function normalizeCode(codePayload) {
      return {
        id: codePayload.id,
        code: codePayload.code,
        title: codePayload.title,
        parentTaxonomyCodeId: codePayload.parent_taxonomy_code_id,
        codeType: codePayload.code_type
      };
    },

    /**
     * Serialize a TaxonomyTagData object into a JSON representation required by a core element (course|unit|collection|assessment|resource|question)
     *
     * @param taxonomyData the taxonomyData object
     * @returns {Object} a JSON Object
     */
    serializeTaxonomy: function serializeTaxonomy(taxonomyData) {
      var taxonomyResult = null;
      if (taxonomyData && _ember['default'].isArray(taxonomyData) && taxonomyData.length > 0) {
        taxonomyResult = {};
        taxonomyData.forEach(function (taxonomy) {
          var taxonomyKey = taxonomy.get('id');
          taxonomyResult[taxonomyKey] = {
            code: taxonomy.get('code'),
            title: taxonomy.get('title'),
            parent_title: taxonomy.get('parentTitle'),
            description: taxonomy.get('description'),
            framework_code: taxonomy.get('frameworkCode')
          };
        });
      }
      return taxonomyResult;
    },

    /**
     * Normalize the core element taxonomy data into a TaxonomyTagData object
     *
     * @param taxonomyArray - array of taxonomy objects
     * @param {string} level
     * @returns {TaxonomyTagData[]} a TaxonomyTagData array
     */
    normalizeTaxonomyArray: function normalizeTaxonomyArray(taxonomyArray, level) {
      var taxonomyData = [];

      if (taxonomyArray && taxonomyArray.length) {
        taxonomyArray.forEach(function (taxonomyObject) {
          var isMicroStandard = _quizzesAddonModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(taxonomyObject.internalCode);

          taxonomyData.push(_quizzesAddonModelsTaxonomyTaxonomyTagData['default'].create({
            id: taxonomyObject.internalCode,
            code: taxonomyObject.code,
            title: taxonomyObject.title,
            parentTitle: taxonomyObject.parentTitle,
            frameworkCode: taxonomyObject.frameworkCode,
            taxonomyLevel: level ? level : isMicroStandard ? _quizzesAddonConfigQuizzesConfig.TAXONOMY_LEVELS.MICRO : _quizzesAddonConfigQuizzesConfig.TAXONOMY_LEVELS.STANDARD
          }));
        });
      }
      return _ember['default'].A(taxonomyData);
    },

    /**
     * Normalize the core element taxonomy data into a TaxonomyTagData object
     *
     * @param taxonomyObject - object of taxonomy objects
     * @param level taxonomy level
     * @returns {TaxonomyTagData[]} a TaxonomyTagData array
     */
    normalizeTaxonomyObject: function normalizeTaxonomyObject(taxonomyObject, level) {
      var taxonomyData = [];
      if (taxonomyObject) {
        for (var key in taxonomyObject) {
          if (taxonomyObject.hasOwnProperty(key)) {
            var taxonomy = taxonomyObject[key];
            var isMicroStandard = level ? false : _quizzesAddonModelsTaxonomyTaxonomyTagData['default'].isMicroStandardId(key);
            taxonomyData.push(_quizzesAddonModelsTaxonomyTaxonomyTagData['default'].create({
              id: key,
              code: taxonomy.code,
              title: taxonomy.title,
              parentTitle: taxonomy.parent_title,
              description: taxonomy.description ? taxonomy.description : '',
              frameworkCode: taxonomy.framework_code,
              taxonomyId: key,
              taxonomyLevel: level ? level : isMicroStandard ? _quizzesAddonConfigQuizzesConfig.TAXONOMY_LEVELS.MICRO : _quizzesAddonConfigQuizzesConfig.TAXONOMY_LEVELS.STANDARD
            }));
          }
        }
      }
      return _ember['default'].A(taxonomyData);
    },

    /**
     * Serialize a TaxonomyTagData object into a JSON representation only for Resource Player Events
     *
     * @param taxonomyData the taxonomyData object
     * @returns {Object} a JSON Object
     */
    serializeTaxonomyForEvents: function serializeTaxonomyForEvents(taxonomyData) {
      var taxonomyResult = null;
      if (taxonomyData && _ember['default'].isArray(taxonomyData) && taxonomyData.length > 0) {
        taxonomyResult = {};
        taxonomyData.forEach(function (taxonomy) {
          var taxonomyKey = taxonomy.get('id');
          taxonomyResult[taxonomyKey] = taxonomy.get('code');
        });
      }
      return taxonomyResult;
    },

    /**
     * @function normalizeCrosswalkCompetency
     */
    normalizeCrosswalkCompetency: function normalizeCrosswalkCompetency(payload) {
      var crosswalkCompetencies = _ember['default'].A(payload.frameworkCrossWalkComp);
      return crosswalkCompetencies.map(function (competency) {
        return _ember['default'].Object.create({
          sourceDisplayCode: competency.source_display_code,
          sourceTaxonomyCodeId: competency.source_taxonomy_code_id,
          targetDisplayCode: competency.target_display_code,
          targetFrameworkId: competency.target_framework_id,
          targetTaxonomyCodeId: competency.target_taxonomy_code_id
        });
      });
    }
  });
});