define('quizzes-addon/components/gru-assign-student-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['gru-assign-student-modal'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Close Modal
       */
      closeModal: function closeModal() {
        this.triggerAction({ action: 'closeModal' });
      },
      /**
       * Update Assignment
       */
      updateAssignment: function updateAssignment(assignment) {
        this.get('model').callback.success(assignment);
        this.triggerAction({ action: 'closeModal' });
      }
    },
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Model object
     */
    model: null
  });
});