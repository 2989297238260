define('quizzes-addon/helpers/format-time', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {
  exports.formatTime = formatTime;

  /**
   * Format a timestamp
   */

  function formatTime(value /*, hash*/) {
    var time = value[0];
    return (0, _quizzesAddonUtilsUtils.formatTime)(time);
  }

  exports['default'] = _ember['default'].Helper.helper(formatTime);
});