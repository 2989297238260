define('quizzes-addon/components/reports/assessment/questions/qz-hot-text-highlight', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question', 'quizzes-addon/utils/question/hot-text-highlight'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion, _quizzesAddonUtilsQuestionHotTextHighlight) {

  /**
   * Hot Text Highlight
   *
   * Component responsible for controlling the logic and appearance of an Hot Text Highlight
   * question inside of the assessment report.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-hot-text-highlight'],

    // -------------------------------------------------------------------------
    // Properties
    items: _ember['default'].computed('question', function () {
      var component = this;
      var question = component.get('question');
      var showCorrect = component.get('showCorrect');
      var correctAnswers = question.get('question.correctAnswer');
      var userAnswers = showCorrect ? correctAnswers : component.get('userAnswer');
      var items = _quizzesAddonUtilsQuestionHotTextHighlight['default'].getItems(question.get('question'));
      items.forEach(function (item) {
        var removeCommaFromText = item.get('text').trim().replace(/\n/g, '');
        var value = removeCommaFromText + ',' + item.get('index');
        var selected = !!userAnswers.findBy('value', value);
        item.set('selected', selected);
        item.set('correct', !selected || !!correctAnswers.findBy('value', value));
      });
      return items;
    })

    // -------------------------------------------------------------------------
    // Methods
  });
});