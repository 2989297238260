define('quizzes-addon/components/editor/assessment/qz-fixed-footer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-fixed-footer'],

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {[]} actions List of action buttons to show in the footer
     */
    footerActions: []
  });
});