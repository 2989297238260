define('quizzes-addon/models/district', ['exports', 'ember'], function (exports, _ember) {

  /**
   * District model
   *
   * @typedef {Object} District
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {string}
     */
    id: null,

    /**
     * @property {string}
     */
    name: null,

    /**
     * @property {string}
     */
    code: null
  });
});