define('quizzes-addon/helpers/set-if-equal', ['exports', 'ember'], function (exports, _ember) {
  exports.setIfEqual = setIfEqual;

  /**
   * Return a string if two values are the same
   *
   * @param {String[]} value
   * value[0] - string to return if two values are the same
   * value[1] - first value to compare
   * value[2] - second value to compare
   *
   * @return {String}
   */

  function setIfEqual(value /*, options */) {
    var classToAdd = value[0];
    var valueA = value[1];
    var valueB = value[2];
    var result = '';

    if (valueA === valueB) {
      result = classToAdd;
    }
    return result;
  }

  exports['default'] = _ember['default'].Helper.helper(setIfEqual);
});