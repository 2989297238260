define('quizzes-addon/components/player/questions/qz-serp-pick-n-choose', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP decoding question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    session: _ember['default'].inject.service('session'),

    /**
     * @type {MediaService} mediaService
     * @property {Ember.Service} Service to work with media
     */
    mediaService: _ember['default'].inject.service('quizzes/api-sdk/media'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-pick-n-choose'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var question = this.get('question');
      var baseAnswers = [];
      var userAnswer = [];
      var ansersList = this.get('question.answers');
      var userAnswerList = this.get('userAnswer');
      question.baseAnswers.forEach(function (answer, index) {
        baseAnswers.push({
          answer_text: answer.answer_text,
          isCorrect: answer.is_correct === '1',
          id: ansersList[index] && ansersList[index].value ? ansersList[index].value : null
        });
      });
      if (userAnswerList && userAnswerList.length) {
        userAnswerList.forEach(function (item) {
          var answerData = baseAnswers.findBy('id', item.value);
          userAnswer.push(answerData);
        });
      }

      this.injectDecoding(baseAnswers, userAnswer);
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    // -------------------------------------------------------------------------
    // Properties

    userId: _ember['default'].computed.alias('session.userId'),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    injectDecoding: function injectDecoding(baseAnswers, userAnswer) {
      var component = this;
      var user = {
        userId: this.get('userId')
      };
      var content = {
        contentId: this.get('question.id'),
        contentTitle: this.get('question.title'),
        answer_type: 'pick-n-choose',
        answers: baseAnswers,
        userAnswer: userAnswer
      };
      window.serp.languageDecode().select('#serp-pick-n-choose-tool').dataIn(user, null, content).pickNChoose().render().listener(function (eventData) {
        var userAnswers = eventData.userAnswers;
        var results = userAnswers.filter(function (item) {
          return item.isCorrect;
        });
        results = results.map(function (item) {
          return {
            value: item.id
          };
        });
        if (userAnswers.length) {
          component.notifyAnswerCompleted(results);
        }
      });
    }
  });
});