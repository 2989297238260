define('quizzes-addon/components/player/resources/qz-youtube-resource', ['exports', 'ember', 'quizzes-addon/components/player/resources/qz-resource'], function (exports, _ember, _quizzesAddonComponentsPlayerResourcesQzResource) {

  /**
   * Youtube resource component
   *
   * Component responsible for controlling the logic and appearance of the youtube resource type
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-viewer.js
   * @augments Ember/Component
   **/

  exports['default'] = _quizzesAddonComponentsPlayerResourcesQzResource['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-youtube-resource'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    aspectRatio: {
      width: 16,
      height: 9
    },

    videoCounts: 0,

    /**
     * @property {string}Begin playing the video at the given number of seconds from the start of the video
     */
    start: _ember['default'].computed('resource.displayGuide.start_time', function () {
      var component = this;
      var playerMetadata = component.get('resource.playerMetadata');
      if (playerMetadata && playerMetadata.length) {
        var videoCounts = component.get('videoCounts');
        var startTime = playerMetadata[videoCounts].start_time;
        var seconds = moment.duration(startTime).asSeconds();
        return seconds;
      } else {
        return null;
      }
    }),

    /**
     * @property {string}The time, measured in seconds from the start of the video, when the player should stop playing the video
     */
    stop: _ember['default'].computed('resource.displayGuide.end_time', function () {
      var component = this;
      var playerMetadata = component.get('resource.playerMetadata');
      if (playerMetadata && playerMetadata.length) {
        var videoCounts = component.get('videoCounts');
        var stopTime = playerMetadata[videoCounts].end_time;
        var seconds = moment.duration(stopTime).asSeconds();
        return seconds;
      } else {
        return null;
      }
    }),

    /**
     * @property {string} full resource youtube url
     */
    youtubeUrl: _ember['default'].computed('resource.body', function () {
      var Env = _ember['default'].getOwner(this).resolveRegistration('config:environment');
      var url = this.get('resource.body');
      var youtubeId = this.getYoutubeIdFromUrl(url);
      var player = Env.player.youtubePlayerUrl;
      var start = this.get('start');
      var stop = this.get('stop');
      return '' + player + youtubeId + '?start=' + start + '&end=' + stop + '&rel=0&version=3&enablejsapi=1';
    }),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Convert the time in this format 00:00:00 to seconds
     */
    convertToSeconds: function convertToSeconds(time) {
      var sections = time.split(':');
      return parseInt(sections[0] * 3600) + parseInt(sections[1] * 60) + parseInt(sections[2]);
    },

    /**
     * Retrieves the youtube id from a url
     * @param url
     * @returns {*}
     */
    getYoutubeIdFromUrl: function getYoutubeIdFromUrl(url) {
      var regexp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      var match = url.match(regexp);
      if (match && match[2].length === 11) {
        return match[2];
      }
    },

    init: function init() {
      var controller = this;
      controller._super.apply(controller, arguments);
      controller.loadScript();
    },

    loadScript: function loadScript() {
      var controller = this;
      //eslint-disable-next-line
      if (typeof YT == 'undefined' || typeof YT.Player == 'undefined') {
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubePlayerAPIReady = function () {
          //eslint-disable-next-line
          new YT.Player('player', {
            events: {
              onStateChange: function onStateChange(evt) {
                //eslint-disable-next-line
                if (evt.data === YT.PlayerState.ENDED) {
                  controller.loadNextVideo();
                }
              }
            }
          });
        };
      } else {
        //eslint-disable-next-line
        new YT.Player('player', {
          events: {
            onStateChange: function onStateChange(evt) {
              //eslint-disable-next-line
              if (evt.data === YT.PlayerState.ENDED) {
                controller.loadNextVideo();
              }
            }
          }
        });
      }
    },

    loadNextVideo: function loadNextVideo() {
      var controller = this;
      var videoCount = controller.get('videoCounts');
      controller.set('videoCounts', videoCount + 1);
      var videoCounts = controller.get('videoCounts');
      var playerMetadata = controller.get('resource.playerMetadata');
      if (playerMetadata && playerMetadata.length && playerMetadata[videoCounts]) {
        var Env = _ember['default'].getOwner(this).resolveRegistration('config:environment');
        var url = controller.get('resource.body');
        var youtubeId = controller.getYoutubeIdFromUrl(url);
        var player = Env.player.youtubePlayerUrl;

        var startTime = playerMetadata[videoCounts].start_time;
        var endTime = playerMetadata[videoCounts].end_time;
        var start = moment.duration(startTime).asSeconds();
        var _stop = moment.duration(endTime).asSeconds();
        var videoUrl = '' + player + youtubeId + '?start=' + start + '&end=' + _stop + '&rel=0&version=3&autoplay=1&enablejsapi=1';
        controller.set('youtubeUrl', videoUrl);
        controller.loadScript();
      }
    }
  });
});