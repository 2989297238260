define('quizzes-addon/components/gru-bubbles', ['exports', 'ember'], function (exports, _ember) {
  /**
   * Bubble component
   *
   * Component responsible for show  the question bubbles under the assessment report
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['gru-bubbles'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Select a option in the bubble list
       * @function actions:selectBubbleOption
       * @param {Ember.Object} option i.e { label: string, status: string, value: Object, selected: boolean }
       */
      selectBubbleOption: function selectBubbleOption(option) {
        var component = this;
        component.clearSelection();
        option.set('selected', true);
        component.sendAction('onBubbleSelect', option);
      }
    },
    // -------------------------------------------------------------------------
    // Properties
    /**
     * List of options to show in bubbles
     *
     * @property {Ember.Object[]} { label: string, status: string, value: Object, selected: boolean }
      */
    bubbleOptions: null,

    // -------------------------------------------------------------------------
    // Methods
    /**
     * Clears current selection
     */
    clearSelection: function clearSelection() {
      var options = this.get('bubbleOptions');
      options.forEach(function (option) {
        option.set('selected', false);
      });
    }
  });
});