define('quizzes-addon/components/player/questions/qz-serp-classic', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP classic
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    session: _ember['default'].inject.service('session'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-classic'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var question = this.get('question');
      var baseAnswers = question.baseAnswers;
      var answersObj = [];
      baseAnswers.forEach(function (item) {
        if (item.correct_answer && item.correct_answer.length) {
          answersObj.push({
            answer_text: item.correct_answer[0],
            text_image: item.text_image || null,
            additional_letters: item.additional_letters ? item.additional_letters.map(function (letter) {
              return letter.text;
            }) : []
          });
        }
      });
      this.injectDecoding(answersObj);
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    // -------------------------------------------------------------------------
    // Properties

    userId: _ember['default'].computed.alias('session.userId'),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    injectDecoding: function injectDecoding(baseAnswers) {
      var component = this;
      var user = {
        userId: this.get('userId')
      };
      var accessibilitySettings = JSON.parse(window.localStorage.getItem('accessibility_settings'));
      var userAnswer = this.get('userAnswer');
      var content = {
        contentId: this.get('question.id'),
        contentTitle: this.get('question.title'),
        answers: baseAnswers,
        isHighContrast: accessibilitySettings && accessibilitySettings.is_high_contrast_enabled ? accessibilitySettings.is_high_contrast_enabled : false,
        userAnswer: userAnswer
      };
      window.serp.languageDecode().select('#serp-classic-player-container').dataIn(user, null, content).classic().render().listener(function (eventData) {
        var userAnswers = [];
        if (eventData.userAnswers.length) {
          eventData.userAnswers.forEach(function (item) {
            userAnswers.push({
              value: '[' + item + ']'
            });
          });
          component.notifyAnswerCompleted(userAnswers);
        }
      });
    }
  });
});