define('quizzes-addon/components/player/questions/qz-serp-encoding', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP encoding question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    session: _ember['default'].inject.service('session'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-encoding'],

    // -----------------------------------------------------------------------
    // Properties

    userAnswer: [],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var question = this.get('question');
      question.baseAnswers.map(function (baseAnswer) {
        baseAnswer.audio_file_url = baseAnswer.answer_audio_filename;
      });
      this.injectEncoding(question.baseAnswers);
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    // -------------------------------------------------------------------------
    // Properties

    userId: _ember['default'].computed.alias('session.userId'),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    injectEncoding: function injectEncoding(baseAnswers) {
      var _this = this;

      var component = this;
      var userAnswer = component.get('userAnswer') ? component.get('userAnswer') : [];
      var user = {
        userId: this.get('userId')
      };
      var accessibilitySettings = JSON.parse(window.localStorage.getItem('accessibility_settings'));
      var content = {
        contentId: this.get('question.id'),
        contentTitle: this.get('question.title'),
        answers: baseAnswers,
        userAnswer: userAnswer,
        isHighContrast: accessibilitySettings && accessibilitySettings.is_high_contrast_enabled ? accessibilitySettings.is_high_contrast_enabled : false
      };
      window.serp.languageDecode().select('#serp-encoding-answer-container').dataIn(user, null, content).encoding().render().listener(function (eventData) {
        return _this.callbackFn(eventData, component, baseAnswers);
      });
    },

    callbackFn: function callbackFn(eventData, component, baseAnswers) {
      if (component === undefined) component = this;

      var answers = eventData.answers.map(function (answer) {
        return {
          value: answer.answerText
        };
      });

      if (baseAnswers.length === answers.length) {
        component.notifyAnswerCompleted(answers);
      }
    }
  });
});