define('quizzes-addon/services/quizzes/api-sdk/profile', ['exports', 'ember', 'quizzes-addon/serializers/profile/profile', 'quizzes-addon/adapters/profile/profile', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonSerializersProfileProfile, _quizzesAddonAdaptersProfileProfile, _quizzesAddonUtilsUtils) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('profileAdapter', _quizzesAddonAdaptersProfileProfile['default'].create(_ember['default'].getOwner(this).ownerInjection()));
      this.set('profileSerializer', _quizzesAddonSerializersProfileProfile['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {ProfileAdapter} adapter
     */
    profileAdapter: null,

    /**
     * @property {ProfileSerializer} serializer
     */
    profileSerializer: null,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Reads a profile by id
     * @param {String[]} profileIds
     * @param {Number} chunkSize number of profiles to read at once
     * @returns {Promise}
     */
    readProfiles: function readProfiles(profileIds) {
      var chunkSize = arguments.length <= 1 || arguments[1] === undefined ? 50 : arguments[1];

      var service = this;
      // the profiles endpoint only accepts 50 ids at the same time
      var chunks = (0, _quizzesAddonUtilsUtils.arrayIntoChunks)(profileIds, chunkSize).map(function (ids) {
        return service.get('profileAdapter').readProfiles(ids).then(function (response) {
          return service.get('profileSerializer').normalizeProfiles(response);
        });
      });
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        return _ember['default'].RSVP.all(chunks).then(function (responses) {
          return Object.assign.apply(Object, [{}].concat(_toConsumableArray(responses)));
        }).then(resolve, reject);
      });
    },

    /**
     * Gets the user Profile information of a given user id
     *
     * @returns {Promise}
     */
    readUserProfile: function readUserProfile(profileId) {
      var service = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        service.readProfiles([profileId]).then(function (profiles) {
          resolve(profiles[profileId]);
        }, reject);
      });
    }
  });
});