define('quizzes-addon/controllers/reports/context', ['exports', 'ember', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/mixins/endpoint-config', 'quizzes-addon/models/result/report-data-event'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig, _quizzesAddonMixinsEndpointConfig, _quizzesAddonModelsResultReportDataEvent) {

  /**
   *
   * Controller for collection/assessment report
   *
   * Controls the gathering and merging of context events
   * for a collection/assessment
   *
   * @module
   * @augments ember/Route
   */
  exports['default'] = _ember['default'].Controller.extend(_quizzesAddonMixinsEndpointConfig['default'], {
    queryParams: ['anonymous'],

    /**
     * @type {ContextService} contextService
     * @property {Ember.Service} Service to send context related events
     */
    quizzesContextService: _ember['default'].inject.service('quizzes/context'),

    /**
     * @requires service:i18n
     */
    i18n: _ember['default'].inject.service(),

    /**
     * @requires service:notifications
     */
    quizzesNotifications: _ember['default'].inject.service('quizzes/notifications'),

    /**
     * @type {ProfileService} profileService
     * @property {Ember.Service} Service to send profile related events
     */
    quizzesProfileService: _ember['default'].inject.service('quizzes/profile'),

    /**
     * @type {ClassService} classService
     * @property {Ember.Service} Service to send class related events
     */
    quizzesClassService: _ember['default'].inject.service('quizzes/class'),

    /**
     * @type {AttemptService} attemptService
     * @property {Ember.Service} Service to send context related events
     */
    quizzesAttemptService: _ember['default'].inject.service('quizzes/attempt'),

    /**
     * Layout of the page / grid / list
     */
    layoutView: true,

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Navigate to the previous page
       */
      goBack: function goBack() {
        this.send('navigateBack');
      },
      /**
       * Close current anonymous window
       */
      closeWindow: function closeWindow() {
        window.close();
      },

      changeView: function changeView(layout) {
        var thumbnails = layout === _quizzesAddonConfigQuizzesConfig.VIEW_LAYOUT_PICKER_OPTIONS.LIST;
        this.set('layoutView', !thumbnails);
      },
      /**
       * Launch report with anonymous codes
       */
      launchAnonymous: function launchAnonymous() {
        var url = window.location.href;
        var separator = !url.includes('?') ? '?' : '&';
        window.open('' + url + separator + 'anonymous=true', 'realTimeAnonymous', 'width=' + window.screen.width + ', height=' + window.screen.height + ', left=0, top=0, scrollbars=1', true);
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {boolean}
     */
    anonymous: false,

    /**
     * @property {TeacherContext} reportData object with the student events for
     * the current assessment
     */
    reportData: null,

    /**
     * @property {boolean} is a notification regarding the connection currently
     * being displayed
     */
    isNotificationDisplayed: false,

    /**
     * @property {boolean} isRealTime
     */
    isRealTime: true,

    /**
     * @property {boolean} showAttempts
     */
    showAttempts: false,

    /**
     * @property { Object } webSocketClient - web socket client for getting live
     * data from the Real Time server
     */
    webSocketClient: null,

    /**
     * Max Number of attempts  to reconnect web scoket
     * @property {Number}
     */
    maxNumberOfRetry: 20,

    /**
     * Number of attempts tried to reconnect web scoket
     * @property {Number}
     */
    numberOfRetry: 0,

    /**
     * It has the list params in object
     * @property {Object}
     */
    modelParams: null,

    /**
     * It has the collection
     * @property {Collection}
     */
    collection: null,

    /**
     * scheduler properties to reload the data from server in order to avoid the data loss.
     * @property {Object}
     */
    reportReloadScheduler: null,

    /**
     * Wait time to reload the report data
     * @property {Object}
     */
    waitTimeToReloadReportData: 20000,

    /**
     * It has the class
     * @property {class}
     */
    'class': null,

    // -------------------------------------------------------------------------
    // Observers

    /**
     * Observe when the 'reportData' object has been set by the route.
     * At this point, it is possible to proceed with the creation of the
     * websocket to communicate with the real time server and safely merge
     * any initialization data from the real time server as well
     */
    reportDataLoaded: _ember['default'].observer('reportData', function () {
      var reportData = this.get('reportData');
      var contextId = reportData.get('contextId');
      if (reportData) {
        this.connectWithWebSocket(contextId, reportData);
      }
    }),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Create web socket connection
     */
    connectWithWebSocket: function connectWithWebSocket(contextId, reportData) {
      var controller = this;

      // Create a new web socket connection
      var url = this.getRealTimeWebSocketUrl();
      var socket = new SockJS(url);
      var webSocketClient = Stomp.over(socket);
      webSocketClient.heartbeat.outgoing = _quizzesAddonConfigQuizzesConfig.REAL_TIME_CLIENT.OUTGOING_HEARTBEAT;
      webSocketClient.heartbeat.incoming = _quizzesAddonConfigQuizzesConfig.REAL_TIME_CLIENT.INCOMING_HEARTBEAT;

      controller.set('webSocketClient', webSocketClient);
      webSocketClient.connect({}, function () {
        // Clear a failed connection notification, if there was one
        controller.set('numberOfRetry', 0);
        // A web socket connection was made to the RT server. Before subscribing
        // for live messages, a request will be made to fetch any initialization data
        // from the RT server (to avoid overriding data from live messages with init data)
        var channel = contextId;

        // Subscribe to listen for live messages
        webSocketClient.subscribe('/topic/' + channel, function (message) {
          var eventMessage = JSON.parse(message.body);
          reportData.parseEvent(eventMessage);
          var profilePromise = _ember['default'].RSVP.resolve();
          var profileId = eventMessage.profileId;
          var profileData = reportData.reportEvents.findBy('profileId', profileId);
          var isProfileNameExists = profileData.get('lastFirstName');
          if (eventMessage.eventName === _quizzesAddonConfigQuizzesConfig.CONTEXT_EVENT_TYPES.START && !isProfileNameExists) {
            profilePromise = controller.get('quizzesProfileService').readProfiles([profileId]);
          }
          profilePromise.then(function (profiles) {
            var profile = profiles ? profiles[profileId] : null;
            if (profile) {
              reportData.updatedProfileName(profileId, profile);
              reportData.setCollection(controller.get('collection'));
            }
          });
          if (controller.get('reportReloadScheduler')) {
            _ember['default'].run.cancel(controller.get('reportReloadScheduler'));
          }
          var waitTimeToReloadReportData = controller.get('waitTimeToReloadReportData');
          var reportReloadScheduler = _ember['default'].run.later(controller, function () {
            controller.loadReportData(controller, false);
          }, waitTimeToReloadReportData);
          controller.set('reportReloadScheduler', reportReloadScheduler);
        });
      }, function (error) {
        _ember['default'].Logger.error(error);
        var numberOfRetry = controller.get('numberOfRetry');
        var maxNumberOfRetry = controller.get('maxNumberOfRetry');
        if (numberOfRetry <= maxNumberOfRetry) {
          controller.loadReportData(controller, true);
        }
      });
    },

    /**
     * Show a connection lost notification
     */
    showNotification: function showNotification() {
      var isDisplayed = this.get('isNotificationDisplayed');

      if (!isDisplayed) {
        var notifications = this.get('quizzesNotifications');
        var message = this.get('i18n').t('common.warnings.on-air-connection-lost').string;

        // Use custom options for the notification
        notifications.setOptions({
          closeButton: false,
          newestOnTop: true,
          progressBar: false,
          positionClass: 'toast-top-full-width',
          preventDuplicates: false,
          showDuration: 300,
          hideDuration: 1000,
          timeOut: '0',
          extendedTimeOut: '0',
          showEasing: 'swing',
          hideEasing: 'linear',
          showMethod: 'fadeIn',
          hideMethod: 'fadeOut'
        });
        notifications.warning(message);
        this.set('isNotificationDisplayed', true);
      }
    },

    /**
     * Remove all notifications
     */
    clearNotification: function clearNotification() {
      this.get('quizzesNotifications').clear();
      this.set('isNotificationDisplayed', false);
    },

    loadReportData: function loadReportData(controller, isTryToReconnect) {
      var _this = this;

      var params = controller.get('modelParams');
      var contextId = params.contextId;
      var classId = params.classId;
      controller.get('quizzesClassService').readClassMembers(classId).then(function (data) {
        var students = data.members;
        controller.get('quizzesAttemptService').getReportData(contextId).then(function (reportData) {
          /* Setting avatarUrl for students which have event data */
          students.filter(function (student) {
            var reportDataFilteredByProfilePresent = reportData.get('reportEvents').findBy('profileId', student.id);
            return reportDataFilteredByProfilePresent;
          }).forEach(function (student) {
            var rptDataWthStudsData = reportData.get('reportEvents').findBy('profileId', student.id);

            if (rptDataWthStudsData) {
              rptDataWthStudsData.avatarUrl = student.get('avatarUrl');
            }
          });
          students.filter(function (student) {
            return !reportData.get('reportEvents').findBy('profileId', student.id);
          }).forEach(function (student) {
            reportData.get('reportEvents').push(_quizzesAddonModelsResultReportDataEvent['default'].create(_ember['default'].getOwner(_this).ownerInjection(), {
              profileId: student.get('id'),
              profileName: student.get('fullName'),
              lastFirstName: student.get('lastFirstName'),
              avatarUrl: student.get('avatarUrl'),
              isAttemptStarted: false,
              isAttemptFinished: false
            }));
          });
          return reportData;
        }).then(function (reportData) {
          _ember['default'].RSVP.hash({
            reportData: reportData,
            profiles: controller.get('quizzesProfileService').readProfiles(reportData.get('reportEvents').map(function (_ref) {
              var profileId = _ref.profileId;
              return profileId;
            }))
          }).then(function (_ref2) {
            var reportData = _ref2.reportData;
            var profiles = _ref2.profiles;

            reportData.get('reportEvents').forEach(function (reportEvent) {
              var profile = profiles[reportEvent.get('profileId')];
              reportEvent.setProfileProperties(profile);
            });
            reportData.setCollection(controller.get('collection'));

            if (isTryToReconnect) {
              controller.set('reportData', reportData);
            } else {
              controller.set('reportData.reportEvents', reportData.get('reportEvents'));
            }
          });
        });
      });
    }
  });
});