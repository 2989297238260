define('quizzes-addon/models/editor/assessment/category', ['exports', 'ember', 'quizzes-addon/models/editor/assessment/level'], function (exports, _ember, _quizzesAddonModelsEditorAssessmentLevel) {

  /**
   * Category model
   * typedef {Object} Category
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     *Init the scoring levels default on each category
     */
    initLevels: function initLevels() {
      this.set('scoringLevels', _ember['default'].A([_quizzesAddonModelsEditorAssessmentLevel['default'].create({
        id: 'exemplary'
      }), _quizzesAddonModelsEditorAssessmentLevel['default'].create({
        id: 'proficient'
      }), _quizzesAddonModelsEditorAssessmentLevel['default'].create({
        id: 'basic'
      }), _quizzesAddonModelsEditorAssessmentLevel['default'].create({
        id: 'below-basic'
      })]));
      return this;
    },
    /**
     * @property {string} Category Title
     */
    title: '',
    /**
     * @property {string} Feedback guidance
     */
    feedbackGuidance: '',
    /**
     * @property {Boolean} Required Feedback
     */
    requiredFeedback: false,
    /**
     * @property {Array[Levels]} scoringLevels
     */
    scoringLevels: _ember['default'].A([]),
    /**
     * Return a copy of the category
     *
     * @function
     * @return {Category}
     */
    copy: function copy() {
      var properties = this.getProperties(this.modelProperties());
      // Copy array values
      properties.scoringLevels = this.get('scoringLevels').map(function (level) {
        return level.copy();
      });
      return this.get('constructor').create(properties);
    },

    /**
     * Copy a list of property values from another model to override the current ones
     *
     * @function
     * @param {Category} model
     * @param {String[]} propertyList
     * @return {null}
     */
    merge: function merge(model) {
      var propertyList = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

      var properties = model.getProperties(propertyList);
      this.setProperties(properties);
    },
    /**
     * Return a list of properties
     *
     * @function
     * @return {Array}
     */
    modelProperties: function modelProperties() {
      var properties = [];
      var enumerableKeys = Object.keys(this);
      for (var i = 0; i < enumerableKeys.length; i++) {
        var key = enumerableKeys[i];
        var value = _ember['default'].typeOf(this.get(key));
        if (value === 'string' || value === 'number' || value === 'boolean') {
          properties.push(key);
        }
      }
      return properties;
    }
  });
});