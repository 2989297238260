define('quizzes-addon/components/player/questions/qz-serp-say-out-loud', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP decoding question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    session: _ember['default'].inject.service('session'),

    /**
     * @type {MediaService} mediaService
     * @property {Ember.Service} Service to work with media
     */
    mediaService: _ember['default'].inject.service('quizzes/api-sdk/media'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-say-out-loud'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var question = this.get('question');
      this.injectDecoding(question.baseAnswers, this.get('userAnswer'));
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    // -------------------------------------------------------------------------
    // Properties

    userId: _ember['default'].computed.alias('session.userId'),

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    injectDecoding: function injectDecoding(baseAnswers, userAnswer) {
      var component = this;
      var user = {
        userId: this.get('userId')
      };
      var accessibilitySettings = JSON.parse(window.localStorage.getItem('accessibility_settings'));

      var userAnswerList = [];
      if (userAnswer) {
        userAnswer.map(function (userAnswers) {
          var dataList = {
            audio: {
              url: userAnswers.value
            }
          };
          userAnswerList.pushObject(dataList);
        });
      }

      var content = {
        contentId: this.get('question.id'),
        contentTitle: this.get('question.title'),
        answer_type: 'say_out_loud',
        answers: baseAnswers,
        isHighContrast: accessibilitySettings && accessibilitySettings.is_high_contrast_enabled ? accessibilitySettings.is_high_contrast_enabled : false,
        userAnswer: userAnswerList
      };
      window.serp.languageDecode().select('#serp-say-out-loud-answer-container').dataIn(user, null, content).decoding().render().listener(function (eventData) {
        component.handleDecodingSubmission(eventData);
      });
    },

    handleDecodingSubmission: function handleDecodingSubmission(eventData) {
      var component = this;
      if (eventData.decoding_answers && eventData.decoding_answers.length) {
        (function () {
          $('.panel-body .say-out-loud').find('.loader-icons').show();
          component.set('isAudioUploaded', false);
          var answers = [];
          var audioPromises = eventData.decoding_answers.map(function (answer) {
            return new _ember['default'].RSVP.Promise(function (resolve) {
              component.get('mediaService').uploadContentFile(answer.audio.blob, true).then(function (filename) {
                if (filename) {
                  answers.push({
                    value: filename,
                    text: answer.audio.text
                  });
                  resolve(filename);
                } else {
                  component.set('isAudioUploaded', true);
                  component.$('.panel-body .say-out-loud').find('.audio_error').show();
                  return;
                }
              });
            });
          });
          if (!component.set('isAudioUploaded')) {
            Promise.all(audioPromises).then(function () {
              var result = [];
              if (answers && answers.length) {
                eventData.decoding_answers.map(function (data) {
                  var sayoutloudValue = answers.findBy('text', data.audio.text);
                  result.push({
                    value: sayoutloudValue.value
                  });
                });
                $('.panel-body .say-out-loud').find('.loader-icons').hide();
                $('.panel-body .say-out-loud').find('.audio-uploaded').show();
                $('.panel-body .say-out-loud').find('.confirm-text').addClass('audio-saved');
                component.notifyAnswerCompleted(result);
              }
            });
          }
        })();
      }
    }
  });
});