define('quizzes-addon/components/reports/assessment/questions/qz-reorder', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * Single choice
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-reorder'],
    showCorrect: false,

    // -------------------------------------------------------------------------
    // Properties
    /**
     * Return the drag and drop answers to show on the component, if is show correct: return the list
     * whit the correct answers, if not return the answers with the order as the user answered and if is correct or not.
     */
    answers: _ember['default'].computed('question', 'userAnswer', 'showCorrect', 'question.answers.@each.text', 'question.answers.@each.value', function () {
      var component = this;
      var question = component.get('question');
      var userAnswers = component.get('userAnswer') || [];
      var correctAnswers = question.get('question.correctAnswer');

      var answers = question.get('question.answers');

      var userAnswersWithText = userAnswers.map(function (userAns) {
        var userAnsText = answers.findBy('value', userAns.value).text;
        return {
          value: userAns.value,
          userAnsText: userAnsText
        };
      });

      return answers.map(function (answer, inx) {
        var userAnswer = userAnswers.findBy('value', answer.value) || {};
        var correctAnswer = correctAnswers.findBy('value', userAnswer.value);
        var correct = correctAnswer && correctAnswers.indexOf(correctAnswer) === userAnswers.indexOf(userAnswer);
        return {
          showCorrect: component.get('showCorrect'),
          selectedOrderText: userAnswersWithText && userAnswersWithText.length > 0 && userAnswersWithText[inx].userAnsText,
          selectedOrder: userAnswers.indexOf(userAnswer) + 1,
          text: answer.get('text'),
          correct: correct
        };
      });
    })
  });
});