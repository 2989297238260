define('quizzes-addon/components/player/questions/qz-scientific-fill-in-the-blank', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Fill in the blank
   *
   * Component responsible for controlling the logic and appearance of a fill in the blank
   * question inside of the {@link player/qz-question-viewer.js}
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments Ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-scientific-fill-in-the-blank'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events
    initInputEvents: (function () {
      var component = this;
      component.$('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });
      component.setAnswersEvents();
    }).on('didInsertElement'),

    // -------------------------------------------------------------------------
    // Properties
    /**
     * Replace '[]' to an input, but not []{
     * @param question.body
     */
    answers: _ember['default'].computed('question', function () {
      var component = this;
      var answers = component.get('question.answerDetails');
      var hints = component.get('question.hint');
      var readOnly = component.get('readOnly');
      var disabled = readOnly ? 'disabled' : '';

      // matches [] but not []{, which indicates a malformed sqrt
      var answerData = _ember['default'].A([]);
      answers.map(function (choice) {
        var input = '<input type=\'text\' value=\'\' data=' + choice.answer_category + ' ' + disabled + '/>';
        var answerText = choice.answer_text.replace(new RegExp(_quizzesAddonConfigQuizzesConfig.FIB_REGEX.source, 'g'), input);
        var hint = hints[choice.answer_category + '_explanation'];
        var answerObj = _ember['default'].Object.create({
          answer_category: choice.answer_category,
          answer_text: answerText,
          hint: hint
        });
        answerData.pushObject(answerObj);
      });
      return answerData;
    }),
    // -------------------------------------------------------------------------
    // Methods
    /**
     * Notify input answers
     * @param {boolean} onLoad if this was called when loading the component
     */
    notifyInputAnswers: function notifyInputAnswers(onLoad) {
      var component = this,
          inputs = component.$('.fib-answers input[type=text]'),
          answers = inputs.map(function (index, input) {
        var answer = _ember['default'].$(input).val();
        var category = _ember['default'].$(input).attr('data');
        return { value: _ember['default'].$.trim(category) + ':' + _ember['default'].$.trim(answer) };
      }).toArray();

      var answerCompleted = answers.join('').length > 0; //to check that at least 1 answer has text
      component.notifyAnswerChanged(answers);
      if (answerCompleted) {
        if (onLoad) {
          component.notifyAnswerLoaded(answers);
        } else {
          component.notifyAnswerCompleted(answers);
        }
      } else {
        component.notifyAnswerCleared(answers);
      }
    },

    /**
     * Set answers
     */
    setAnswersEvents: function setAnswersEvents() {
      var component = this;
      var inputs = component.$('.fib-answers');
      if (component.get('hasUserAnswer')) {
        component.notifyInputAnswers(true);
      }
      inputs.off('focusout');
      inputs.on('keyup', 'input[type=text]', function () {
        if (!component.get('isComprehension')) {
          component.notifyInputAnswers(false);
        }
      });
      if (component.get('isComprehension')) {
        inputs.on('focusout', 'input[type=text]', function () {
          component.notifyInputAnswers(false);
        });
      }
    },
    toggleAction: function toggleAction(item) {
      var flag = item.get('ishintShow');
      item.set('ishintShow', !flag);
    }
  });
});