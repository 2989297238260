define('quizzes-addon/helpers/minus-one', ['exports', 'ember'], function (exports, _ember) {
  exports.minusOne = minusOne;

  /**
   * Minus one
   */

  function minusOne(value /*, options*/) {
    return +value - 1;
  }

  exports['default'] = _ember['default'].Helper.helper(minusOne);
});