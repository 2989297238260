define('quizzes-addon/components/player/qz-player-header', ['exports', 'ember', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Default Player header
   *
   * Component responsible for showing an informative header for the  player.
   * It may embed other components for interacting with the player.
   *
   * @module
   * @see controllers/player.js
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @type {ContextService} contextService
     * @property {Ember.Service} Service to send context related events
     */
    contextService: _ember['default'].inject.service('quizzes/context'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-player-header'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Action triggered when the user closes the content player
       */
      closePlayer: function closePlayer() {
        var component = this;
        var isIframeMode = component.get('isIframeMode');
        var isEventFromRGO = component.get('isEventFromRGO');
        if (window.lastPlayedResource) {
          var context = window.lastPlayedResource;
          context.resourceResult.set('stopTime', new Date().getTime());
          component.get('contextService').pausePlayResource(context.resourceId, context.contextId, context.resourceResult, context.eventContext).then(function () {
            component.postMessage(isIframeMode, isEventFromRGO);
          })['catch'](function () {
            component.postMessage(isIframeMode, isEventFromRGO);
          });
          window.lastPlayedResource = null;
        } else {
          component.postMessage(isIframeMode, isEventFromRGO);
        }
      },

      /**
       * Action triggered to remix the collection
       * @param content
       */
      remixCollection: function remixCollection() {
        this.sendAction('onRemixCollection');
      }
    },

    // -------------------------------------------------------------------------
    // Events

    didRender: function didRender() {
      var component = this;
      component.$('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {collection} collection - The current Collection
     */
    collection: null,

    /**
     * @property {isEventFromRGO}
     * To determine whether the player event was triggered from the RGO app
     */
    isEventFromRGO: _ember['default'].computed('source', function () {
      var component = this;
      var source = component.get('source');
      return source === _quizzesAddonConfigQuizzesConfig.PLAYER_EVENT_SOURCE.RGO;
    }),

    // -------------------------------------------------------------------------
    // Methods

    postMessage: function postMessage(isIframeMode, isEventFromRGO) {
      var component = this;
      if (isIframeMode) {
        window.parent.postMessage(_quizzesAddonConfigQuizzesConfig.PLAYER_EVENT_MESSAGE.GRU_PUllUP_CLOSE, '*');
      } else if (isEventFromRGO) {
        window.close();
      } else {
        component.sendAction('onClosePlayer');
      }
    }
  });
});