define('quizzes-addon/components/reports/assessment/questions/qz-serp-decoding-assessment', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * SERP Encoding Assessment
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-serp-decoding-assessment'],

    // -------------------------------------------------------------------------
    // Properties

    showCorrect: false,

    answers: _ember['default'].computed.alias('question.answer'),

    baseAnswers: _ember['default'].computed.alias('question.resource.baseAnswers'),

    exemplars: _ember['default'].computed('question.resource.hint', function () {
      return this.get('question.resource.hint') ? this.get('question.resource.hint.exemplar_docs') : _ember['default'].A([]);
    }),
    /**
     * @property {Boolean} isPause
     */
    isPause: false,
    /*
     * Hold the audio details
     */
    audioRecorder: null,
    actions: {
      onPlayAudio: function onPlayAudio(container, url, index) {
        var component = this;
        var _audio = component.get('audioRecorder');
        if (!_audio || component.get('answerIndex') !== index) {
          _audio = new Audio(url);
          component.set('answerIndex', index);
        }
        component.set('audioRecorder', _audio);
        _audio.play();
        component.set('isPause', true);
        _audio.ontimeupdate = function () {
          component.$('.answer-container .' + container + ' .audio-progress .progress-filling').css('width', _audio.currentTime / _audio.duration * 100 + '%');
        };
        _audio.addEventListener('ended', function () {
          component.set('isPause', false);
        });
      },
      //Action triggered when pause audio
      onPauseAudio: function onPauseAudio() {
        var component = this;
        var audio = component.get('audioRecorder');
        audio.pause();
        component.set('isPause', false);
      }
    }
  });
});