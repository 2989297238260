define('quizzes-addon/components/reports/assessment/questions/qz-fib', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Fill in the blank
   *
   * Component responsible for controlling the logic and appearance of a fill in the blank
   * question inside of the assessment report.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-fib'],

    // -------------------------------------------------------------------------
    // Properties
    /**
     * Return an array with every sentence and user answer, and indicate if is correct or incorrect
     * @return {Array}
     */
    answer: _ember['default'].computed('question', 'anonymous', 'userAnswer', 'question.answers.@each.value', function () {
      var component = this;
      var question = component.get('question');
      var questionText = question.get('question.body');
      var questionTextParts = questionText.split(_quizzesAddonConfigQuizzesConfig.FIB_REGEX);
      var userAnswers = component.get('userAnswer');
      var anonymous = component.get('anonymous');
      var correctAnswers = question.get('question.correctAnswer');

      if (component.get('showCorrect')) {
        userAnswers = question.get('question.correctAnswer');
      }

      correctAnswers = correctAnswers.map(function (correctAnswer) {
        correctAnswer.normalizedValue = correctAnswer.value.trim().toLowerCase();
        return correctAnswer;
      });

      var answers = userAnswers.map(function (answer, index) {
        var userAnswer = answer.value.trim().toLowerCase();
        var correctAnsObj = correctAnswers[index];
        var correctAnswer = correctAnsObj ? correctAnsObj.normalizedValue : null;

        var correct = false;
        if (!question.skipped && question.score === 100) {
          correct = true;
        } else {
          correct = correctAnswer && correctAnswer === userAnswer;
        }

        var elementClass = anonymous ? 'anonymous' : correct ? 'correct' : 'incorrect';
        return {
          text: answer.value,
          'class': 'answer ' + elementClass
        };
      });

      var sentences = questionTextParts.map(function (questionTextPart) {
        return {
          text: questionTextPart,
          'class': 'sentence'
        };
      });
      sentences = userAnswers && userAnswers.length ? sentences : [];

      return this.mergeArrays(sentences, answers);
    }),

    // -------------------------------------------------------------------------
    // Methods
    /**
     * Merge sentences and answers arrays
     * @return {Array}
     */
    mergeArrays: function mergeArrays(sentences, answers) {
      var mergeArrays = _ember['default'].A();
      answers.forEach(function (item, index) {
        mergeArrays.pushObject(sentences.get(index));
        mergeArrays.pushObject(item);
      });
      mergeArrays.pushObject(sentences[sentences.length - 1]);
      return mergeArrays;
    }
  });
});