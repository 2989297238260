define('quizzes-addon/components/reports/class-assessment/qz-question-performance', ['exports', 'ember', 'quizzes-addon/utils/question/question', 'quizzes-addon/config/quizzes-config', 'quizzes-addon/utils/math'], function (exports, _ember, _quizzesAddonUtilsQuestionQuestion, _quizzesAddonConfigQuizzesConfig, _quizzesAddonUtilsMath) {

  /**
   * Question Performance Component
   *
   * Component responsible for displaying the question performance information for several students
   *
   * @module
   * @augments Ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'class-assessment', 'qz-question-performance'],

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Indicates if the report is displayed in anonymous mode
     * @property {boolean} anonymous
     */
    anonymous: null,

    /**
     * Indicates if is anonymous and show the performance Results
     * @property {boolean} anonymousAndShowResult
     */
    anonymousAndNotShowResult: _ember['default'].computed('anonymous', 'showResult', function () {
      return this.get('anonymous') && !this.get('showResult');
    }),

    /**
     * Indicates if is anonymous and show the performance Results
     * @property {boolean} anonymousAndShowResult
     */
    anonymousAndShowResult: _ember['default'].computed.and('anonymous', 'showResult'),

    studentsIds: _ember['default'].computed.alias('reportData.studentIds'),
    /**
     * Convenience structure to display the answers distribution
     * @property {*} answer distributions
     */
    answersData: _ember['default'].computed('questionResults.@each.updated', function () {
      var component = this;
      var reportData = component.get('reportData');
      var question = component.get('question');
      var questionUtil = _quizzesAddonUtilsQuestionQuestion['default'].create({ question: question });
      var distribution = questionUtil.distribution(this.get('questionResults'));

      var answersData = _ember['default'].A([]);
      distribution.forEach(function (answerDistribution) {
        var userAnswer = answerDistribution.get('answer');
        var students = reportData.getStudentsByQuestionAndAnswer(question, userAnswer);
        var correct = answerDistribution.get('correct');
        var percentage = answerDistribution ? answerDistribution.get('percentage') : 0;
        var result = answerDistribution.get('result');
        var isOpenEndedQuestion = answerDistribution.get('isOpenEndedQuestion');
        result.set('resource', question);
        answersData.addObject(_ember['default'].Object.create({
          correct: correct,
          userAnswer: userAnswer,
          percentage: percentage,
          students: students,
          result: result,
          isOpenEndedQuestion: isOpenEndedQuestion,
          charData: _ember['default'].A([_ember['default'].Object.create({
            color: correct ? _quizzesAddonConfigQuizzesConfig.CORRECT_COLOR : _quizzesAddonConfigQuizzesConfig.INCORRECT_COLOR,
            percentage: percentage
          })])
        }));
      });

      return answersData;
    }),

    /**
     * @property {Resource} question
     */
    question: null,

    /**
     * Returns a convenient structure to display the x-bar-chart
     */

    questionsData: _ember['default'].computed('question', 'reportData.reportEvents.@each.updated', function () {
      var studentsIds = this.get('studentsIds');
      var totalStudents = studentsIds.length;
      var questionId = this.get('question.id');
      var reportEvents = this.get('reportData.reportEvents');
      var questions = {
        id: questionId,
        correct: 0,
        incorrect: 0,
        openEnded: 0,
        total: totalStudents,
        title: ''
      };

      reportEvents.forEach(function (student) {
        var resourceResults = student.get('resourceResults').filter(function (result) {
          return result.resourceId === questionId;
        });
        resourceResults.forEach(function (questionResult) {
          questions.title = questionResult.resource ? questionResult.resource.get('title') : '';
          if (questionResult.get('started')) {
            if (questionResult.get('isOpenEnded')) {
              questions.openEnded += 1;
            } else {
              if (questionResult.correct) {
                delete questionResult.correct;
              }
              if (questionResult.incorrect) {
                delete questionResult.incorrect;
              }

              questions.correct += _ember['default'].get(questionResult, 'correct') ? 1 : 0;
              questions.incorrect += _ember['default'].get(questionResult, 'incorrect') ? 1 : 0;
            }
          } else if (questionResult.get('resource').isResource && questionResult.get('skipped') === false) {
            questions.correct += 1;
          }
        });
      });
      return questions;
    }),

    questionBarChartData: _ember['default'].computed('questionsData.@each.correct', 'questionsData.@each.incorrect', function () {
      var questionsData = this.get('questionsData');
      var correctColor = _quizzesAddonConfigQuizzesConfig.GRADING_SCALE[_quizzesAddonConfigQuizzesConfig.GRADING_SCALE.length - 1].COLOR;
      var failColor = _quizzesAddonConfigQuizzesConfig.GRADING_SCALE[0].COLOR;
      var dataObj = questionsData;

      var processedData = {
        id: dataObj.id,
        title: dataObj.title,
        questionsData: [{
          color: failColor,
          percentage: (0, _quizzesAddonUtilsMath.roundFloat)(dataObj.incorrect / dataObj.total * 100, 1)
        }, {
          color: correctColor,
          percentage: (0, _quizzesAddonUtilsMath.roundFloat)(dataObj.correct / dataObj.total * 100, 1)
        }, {
          color: _quizzesAddonConfigQuizzesConfig.OPEN_ENDED_COLOR,
          percentage: (0, _quizzesAddonUtilsMath.roundFloat)(dataObj.openEnded / dataObj.total * 100, 1)
        }],
        completed: dataObj.correct + dataObj.incorrect + dataObj.openEnded,
        total: dataObj.total
      };

      return processedData;
    }),

    /**
     * Question results for this question, all students
     *
     * @property {QuestionResult[]}
     */
    questionResults: _ember['default'].computed('question', 'reportData.reportEvents.@each.updated', function () {
      var reportData = this.get('reportData');
      return reportData.getResultsByQuestion(this.get('question.id'));
    }),

    /**
     * @prop { ReportData } reportData - Representation of the data to show in the reports as a 3D matrix
     * Any changes on the content feed will cause the report data to update
     */
    reportData: null,

    /**
     * Indicates when the report is display in anonymous mode if show all performance results
     * @property {boolean} showResult
     */
    showResult: null,

    /**
     * @property {User[]} students
     */
    students: null,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * willDestroyElement event
     */
    willDestroyElement: function willDestroyElement() {
      var component = this;
      component.set('showResult', false);
      component.set('anonymous', false);
    }
  });
});