define('quizzes-addon/helpers/grade-color', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {
  exports.gradeColor = gradeColor;

  /**
   * Get the color for the grade bracket that a score falls under per the app's grading scale (@see /app/config/config.js#GRADING_SCALE)
   *
   * @example
   *
   * Based on a score value, the DIV element may be styled differently
   * <div class="grade" style="background-color: {{gradeColor score}}">
   *  ...
   * </div>
   * @see /app/templates/components/reports/assessment/qz-summary.hbs#L1
   *
   * @param value - score within the grading scale
   * @returns {String} - hex color string
   */

  function gradeColor(value) {
    if (value[1]) {
      var color = value[0] || 'NA';
      return _ember['default'].String.htmlSafe(value[1] + ': ' + (0, _quizzesAddonUtilsUtils.getGradeColor)(color));
    } else {
      return _ember['default'].String.htmlSafe((0, _quizzesAddonUtilsUtils.getGradeColor)(value[0]));
    }
  }

  exports['default'] = _ember['default'].Helper.helper(gradeColor);
});