define('quizzes-addon/components/player/qz-evidence', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @type {MediaService} mediaService
     * @property {Ember.Service} Service to work with media
     */
    mediaService: _ember['default'].inject.service('quizzes/api-sdk/media'),

    /**
     * @requires service:session
     */
    session: _ember['default'].inject.service('session'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-evidence'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      //GRU-file-picker-events action
      selectFile: function selectFile(file) {
        var component = this;
        if (file) {
          component.set('isLoading', true);
          component.get('mediaService').uploadContentFile(file).then(function (filename) {
            var originalFileName = _ember['default'].Object.create({
              fileName: filename,
              originalFileName: file.name
            });
            var objList = component.get('uploadedFiles');
            objList.pushObject(originalFileName);
            component.addEvidence();
          });
        }
      },

      removeSelectedFile: function removeSelectedFile(file) {
        if (file) {
          var objList = this.get('uploadedFiles');
          objList.removeObject(file);
          this.addEvidence();
        }
      },

      onShowPullUp: function onShowPullUp(file) {
        this.set('activeFile', file);
        this.set('isShowFilePullUp', true);
      },

      onClosePullup: function onClosePullup() {
        this.set('activeFile', null);
        this.set('isShowFilePullUp', false);
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    isComprehension: false,

    uploadedFiles: _ember['default'].A(),

    filePickerErrors: _ember['default'].A(),

    isShowFilePullUp: false,

    activeFile: _ember['default'].A(),

    isLoading: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('uploadedFiles', _ember['default'].A());
      var $fileInput = this.$('input[type="file"]');
      if ($fileInput) {
        $fileInput.attr('title', 'uploadFile');
      }
      var questionResult = this.get('questionResult');
      if (questionResult && questionResult.evidence && questionResult.evidence.length) {
        questionResult.evidence.forEach(function (item) {
          var originalFileName = _ember['default'].Object.create({
            fileName: item.fileName,
            originalFileName: item.originalFileName
          });
          var objList = _this.get('uploadedFiles');
          objList.pushObject(originalFileName);
        });
      }
      this.addEvidence();
    },

    // -------------------------------------------------------------------------
    // Methods

    addEvidence: function addEvidence() {
      var component = this;
      var objList = this.get('uploadedFiles');
      var questionResult = this.get('questionResult');
      var evidenceList = _ember['default'].A([]);
      if (objList && objList.length) {
        objList.map(function (evidence) {
          var cdnURL = component.get('session.cdnUrls.content');
          var stringWithURL = evidence.fileName;
          var removeURL = stringWithURL.replace(cdnURL, '');
          if (!stringWithURL.includes(cdnURL)) {
            evidence.fileName = cdnURL + evidence.fileName;
          }
          var originalFileName = {
            fileName: removeURL,
            originalFileName: evidence.originalFileName
          };
          evidenceList.pushObject(originalFileName);
        });
        questionResult.set('evidence', evidenceList);
      }
      component.set('isLoading', false);
    }
  });
});