define('quizzes-addon/components/player/resources/qz-image-resource', ['exports', 'quizzes-addon/components/player/resources/qz-resource'], function (exports, _quizzesAddonComponentsPlayerResourcesQzResource) {

  /**
   * Image resource component
   *
   * Component responsible for controlling the logic and appearance of the image resource type
   *
   * @module
   * @see controllers/player.js
   * @see components/player/qz-viewer.js
   * @augments Ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerResourcesQzResource['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-image-resource']

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods
  });
});