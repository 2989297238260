define('quizzes-addon/helpers/answer-letter', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {
  exports.answerLetter = answerLetter;

  /**
   * Get the answer letter
   */

  function answerLetter(value) {
    return (0, _quizzesAddonUtilsUtils.getLetter)(parseInt(value[0]));
  }

  exports['default'] = _ember['default'].Helper.helper(answerLetter);
});