define('quizzes-addon/adapters/configuration', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Adapter to support the Lookup API 3.0 integration
   *
   * @typedef {Object} LookupAdapter
   */
  exports['default'] = _ember['default'].Object.extend({
    namespace: 'config',
    /**
     * Gets custom configuration
     * @returns {Promise.<[]>}
     */
    loadConfiguration: function loadConfiguration(key) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/' + key + '.json';
      var options = {
        type: 'GET',
        cache: false
      };
      return _ember['default'].RSVP.hashSettled({
        configuration: _ember['default'].$.ajax(url, options)
      }).then(function (hash) {
        return hash.configuration.value;
      });
    }
  });
});