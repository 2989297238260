define('quizzes-addon/components/reports/assessment/questions/qz-single-choice', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * Single choice
   *
   * Component responsible for show the single choice answer, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-single-choice'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Convenient structure to render options
     * @property {[]}
     */
    answers: _ember['default'].computed('question', 'userAnswer', 'question.answers.@each.text', 'question.answers.@each.value', function () {
      var component = this;
      var question = component.get('question');
      var userAnswer = component.get('userAnswer') ? component.get('userAnswer')[0] : null;

      var userAnswerCorrect = question.get('correct');

      if (typeof question.get('correct') === 'string') {
        userAnswerCorrect = question.get('correct') === 'correct';
      }

      if (component.get('showCorrect')) {
        userAnswer = question.get('question.correctAnswer.firstObject');
        userAnswerCorrect = true;
      }
      var answerValue = userAnswer ? userAnswer.value : null;
      var answers = question.get('question.answers');
      return answers.map(function (answer) {
        return {
          text: answer.get('text'),
          selected: answer.get('value') === answerValue,
          correct: userAnswerCorrect
        };
      });
    }),

    /**
     * @property {boolean} isChooseOne Help to handle selection for choose on question
     */
    isChooseOne: false

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods
  });
});