define('quizzes-addon/components/assignment/qz-assign-students', ['exports', 'ember', 'quizzes-addon/models/context/context'], function (exports, _ember, _quizzesAddonModelsContextContext) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    /**
     * @property {Service} I18N service
     */
    i18n: _ember['default'].inject.service(),
    /**
     * @property {Service} context service
     */
    contextService: _ember['default'].inject.service('quizzes/context'),

    /**
     * @type {ConfigurationService} Service to retrieve configuration information
     */
    configurationService: _ember['default'].inject.service('configuration'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['qz-assign-students'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /***
       * Assign students
       */
      createContext: function createContext() {
        var component = this;
        component.get('contextService').createContext(component.get('assignment')).then(function () {
          component.set('assignment', _quizzesAddonModelsContextContext['default'].create(_ember['default'].getOwner(component).ownerInjection(), {
            title: component.get('collection.title')
          }));
        });
      },
      /***
       * Create context as anonymous
       */
      createContextAnonymous: function createContextAnonymous() {
        var component = this;
        var assignment = component.get('assignment');
        assignment.set('classId', null);
        var collectionType = assignment.get('isCollection') ? 'collection' : 'assessment';
        component.get('contextService').createContext(assignment).then(function (_ref) {
          var id = _ref.id;

          var configurationService = component.get('configurationService');
          configurationService.addProperties({
            type: collectionType,
            profileId: component.get('configurationService.configuration.properties.profileId'),
            token: component.get('configurationService.configuration.properties.token'),
            reportURL: 'student-report-embedded.html?context-id={context-id}&type=' + collectionType
          });
          component.get('router').transitionTo('player', id);
        });
      }
    },

    // -------------------------------------------------------------------------
    // Events

    init: function init() {
      this._super.apply(this, arguments);
      var context = _quizzesAddonModelsContextContext['default'].create({});
      this.set('assignment', context);
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Assignment
     */
    assignment: null
  });
});