define('quizzes-addon/routes/assignments', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      isTeacher: {}
    },
    // -------------------------------------------------------------------------
    // Dependencies

    quizzesConfigurationService: _ember['default'].inject.service('quizzes/configuration'),

    quizzesContextService: _ember['default'].inject.service('quizzes/context'),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Get model for the controller
     */
    model: function model(params) {
      var profileId = params.profileId;
      var assignments = undefined;
      var studentList = this.get('quizzesConfigurationService.configuration.properties.students');
      var playerURL = this.get('quizzesConfigurationService.configuration.properties.playerURL');
      var realTimeURL = this.get('quizzesConfigurationService.configuration.properties.realTimeURL');

      var isTeacher = params.isTeacher === 'true';

      assignments = isTeacher ? this.get('quizzesContextService').getContextsCreated() : this.get('quizzesContextService').getContextsAssigned();

      return _ember['default'].RSVP.hash({
        profileId: profileId,
        isTeacher: isTeacher,
        assignments: assignments,
        studentList: studentList,
        playerURL: playerURL,
        realTimeURL: realTimeURL
      });
    },

    /**
     * Set all controller properties from the model
     * @param controller
     * @param model
     */
    setupController: function setupController(controller, model) {
      controller.set('profileId', model.profileId);
      controller.set('isTeacher', model.isTeacher);
      controller.set('assignments', model.assignments);
      controller.set('studentList', model.studentList);
      controller.set('playerURL', model.playerURL);
      controller.set('realTimeURL', model.realTimeURL);
    }
  });
});