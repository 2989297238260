define('quizzes-addon/helpers/round-number', ['exports', 'ember', 'quizzes-addon/utils/math'], function (exports, _ember, _quizzesAddonUtilsMath) {
  exports.roundNumber = roundNumber;

  /**
   * Return a rounded number
   */

  function roundNumber(value) {
    return (0, _quizzesAddonUtilsMath.roundFloat)(value[0]);
  }

  exports['default'] = _ember['default'].Helper.helper(roundNumber);
});