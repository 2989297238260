define('quizzes-addon/components/reports/assessment/questions/qz-serp-vowel-teams', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * SERP Encoding Assessment
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-vowel-teams'],

    // -------------------------------------------------------------------------
    // Properties

    showCorrect: false,

    answers: _ember['default'].computed.alias('question.answer'),

    baseAnswers: _ember['default'].computed.alias('question.resource.baseAnswers'),

    // -----------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      var _this = this;

      var baseAnswers = this.get('showCorrect') ? this.get('baseAnswers') : this.get('answers');
      baseAnswers.forEach(function (item, answerIndex) {
        item = _this.get('showCorrect') ? item.correct_answer : JSON.parse(item.value);
        if (item.length) {
          item.forEach(function (cItem) {
            cItem = !_this.get('showCorrect') ? cItem : JSON.parse(cItem);
            _this.hightLightDefaultWord(cItem, answerIndex, false, cItem.position);
          });
        }
      });
    },

    // ---------------------------------------------------------------------
    // Methods

    hightLightDefaultWord: function hightLightDefaultWord(text, answerIndex, editMode) {
      var component = this;
      var innerHTML = '';
      var html = '';
      var start = text.start;
      var end = text.end;
      var fulltext = text.text;
      var findPosition = function findPosition(index, itemName) {
        var findItem = text[itemName] ? text[itemName] : [];
        return findItem.indexOf(index) !== -1;
      };
      var sptText = fulltext.split('').map(function (item, index) {
        return '<b class=\'' + (findPosition(index, 'macronPositions') ? 'macron ' : '') + ' ' + (findPosition(index, 'crossPositions') ? 'cross ' : '') + '\'>' + item + '</b>';
      }).join('');

      if (editMode) {
        html = '<span class="serp-hl-text-span">' + fulltext + '<i class="material-icons">clear</i></span>';
      } else {
        html = '<span class="serp-hl-text-span">' + sptText + '</span>';
      }
      String.prototype.replaceBetween = function (start, end, what) {
        return this.substring(0, start) + what + this.substring(end);
      };
      component.$('.vowel-team-edit-' + answerIndex)[0].childNodes.forEach(function (childNode) {
        if (childNode.data && childNode.data.substring(start, end) === fulltext) {
          innerHTML = innerHTML + childNode.data.replaceBetween(start, end, $.trim(html));
        } else if (childNode.data) {
          innerHTML = innerHTML + childNode.data;
        } else {
          innerHTML = innerHTML + childNode.outerHTML;
        }
      });
      component.$('.vowel-team-edit-' + answerIndex).html(innerHTML);
    }
  });
});