define('quizzes-addon/components/player/questions/qz-true-false', ['exports', 'ember', 'quizzes-addon/components/player/questions/qz-question', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _quizzesAddonUtilsUtils) {

  /**
   * True or false Question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-true-false'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * When the user changes the answer choice selection
       * @param {number} answerId
       * @param {boolean} onLoad if this was called when loading the component
       */
      selectAnswerChoice: function selectAnswerChoice(answerId, onLoad) {
        var component = this;
        var answer = [{
          value: answerId
        }];
        component.notifyAnswerChanged(answer);
        if (onLoad) {
          component.notifyAnswerLoaded(answer);
        } else {
          component.notifyAnswerCompleted(answer);
        }
      }
    },

    // -------------------------------------------------------------------------
    // Events

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('hasUserAnswer')) {
        this.actions.selectAnswerChoice.call(this, this.get('userAnswer.firstObject.value'), true);
      }
    },

    // -------------------------------------------------------------------------
    // Properties
    useGroup: (function () {
      return this.get('userAnswer.firstObject.value') || (0, _quizzesAddonUtilsUtils.generateUUID)();
    }).property(),
    /**
     * Returns the answer value of first answer option
     */
    firstAnswerId: _ember['default'].computed('question.answers', function () {
      var answers = this.get('question.answers');
      if (answers && answers.length) {
        return answers[0].value;
      } else {
        return null;
      }
    }),

    /**
     * Returns the first answer object
     */
    firstAnswerObject: _ember['default'].computed('question.answers', function () {
      var answers = this.get('question.answers');
      return answers[0];
    }),

    /**
     * Returns the value of second answer option
     */
    secondAnswerId: _ember['default'].computed('question.answers', function () {
      var answers = this.get('question.answers');
      if (answers && answers.length) {
        return answers[1].value;
      } else {
        return null;
      }
    }),

    /**
     * Returns the second answer object
     */
    secondAnswerObject: _ember['default'].computed('question.answers', function () {
      var answers = this.get('question.answers');
      return answers[1];
    })

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods
  });
});