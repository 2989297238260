define('quizzes-addon/components/player/questions/qz-serp-silent-reading', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP decoding question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    session: _ember['default'].inject.service('session'),

    /**
     * @type {MediaService} mediaService
     * @property {Ember.Service} Service to work with media
     */
    mediaService: _ember['default'].inject.service('quizzes/api-sdk/media'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-silent-reading'],

    // -------------------------------------------------------------------------
    // Properties

    userId: _ember['default'].computed.alias('session.userId'),

    questions: _ember['default'].computed('question', function () {
      return this.get('question.answerDetails');
    }),

    totalReadingTime: 0,

    intervalObject: null,

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('intervalObject', this.readingTimer());
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    didDestoryElement: function didDestoryElement() {
      clearInterval(this.get('intervalObject'));
    },

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      onConfirm: function onConfirm() {
        var _this = this;

        clearInterval(this.get('intervalObject'));
        var answers = this.get('questions').map(function () {
          return { value: _this.get('totalReadingTime') };
        });
        this.notifyAnswerCompleted(answers);
      }
    },

    // -------------------------------------------------------------------------
    // Methods

    readingTimer: function readingTimer() {
      var component = this;
      return setInterval(function () {
        component.incrementProperty('totalReadingTime', 1000);
      }, 1000);
    }
  });
});