define('quizzes-addon/components/reports/assessment/questions/qz-hs-image', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Hot spot image
   *
   * Component responsible for show the hot spot image, which option is selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Dependencies
    /**
     * @property {Service} Configuration service
     */
    configurationService: _ember['default'].inject.service('quizzes/configuration'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-hs-image'],

    // -------------------------------------------------------------------------
    // Properties
    /**
     * Return the hot spot image answers array to show on the component, indicating if the user select the answer and
     * if is correct or not.
     */
    answers: _ember['default'].computed('question', 'anonymous', function () {
      var component = this;
      var question = component.get('question');
      var userAnswers = component.get('userAnswer');
      var correctAnswers = question.get('question.correctAnswer');
      var anonymous = component.get('anonymous');
      if (component.get('showCorrect')) {
        userAnswers = correctAnswers;
      }
      var answers = question.get('question.answers');

      return answers.map(function (answer) {
        var userAnswerCorrect = false;
        var selected = false;
        if (userAnswers.findBy('value', answer.value)) {
          userAnswerCorrect = correctAnswers.findBy('value', answer.value);
          selected = true;
        }

        var elementClass = anonymous ? 'anonymous' : userAnswerCorrect ? 'correct' : 'incorrect';

        var cdnURL = component.get('configurationService.configuration.properties.cdnURL');
        return {
          image: answer.get('text') ? cdnURL + answer.get('text') : _quizzesAddonConfigQuizzesConfig.DEFAULT_IMAGES.QUESTION_PLACEHOLDER_IMAGE,
          selected: selected,
          'class': elementClass
        };
      });
    })
  });
});