define('quizzes-addon/components/reports/assessment/questions/qz-scientific-fill-in-the-blank', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion, _quizzesAddonConfigQuizzesConfig) {

  /**
   * Fill in the blank
   *
   * Component responsible for controlling the logic and appearance of a fill in the blank
   * question inside of the assessment report.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-scientific-fill-in-the-blank'],

    // -------------------------------------------------------------------------
    // Properties
    userAnswers: _ember['default'].computed('userAnswer', function () {
      var component = this;
      var userAnswers = _ember['default'].A([]);
      var userAnswer = component.get('userAnswer');
      userAnswer.map(function (answer) {
        var answerValue = answer.value.split(':');
        userAnswers.pushObject({
          category: answerValue[0],
          value: answerValue[1]
        });
      });
      return userAnswers;
    }),
    /**
     * Return an array with every sentence and user answer, and indicate if is correct or incorrect
     * @return {Array}
     */
    answer: _ember['default'].computed('question', 'anonymous', 'userAnswers', 'question.answers.@each.value', function () {
      var component = this;
      var question = component.get('question');
      var userAnswers = component.get('userAnswers');
      var answerDetails = question.get('question.answerDetails');
      var answerData = answerDetails.map(function (answerDetail) {
        var questionTextParts = answerDetail.answer_text.split(_quizzesAddonConfigQuizzesConfig.FIB_REGEX);
        var userAnswerValue = userAnswers.filterBy('category', answerDetail.answer_category);
        var answers = userAnswerValue.map(function (answer) {
          return {
            text: answer.value !== '' ? answer.value : ' ',
            'class': 'answer'
          };
        });

        var sentences = questionTextParts.map(function (questionTextPart) {
          return {
            text: questionTextPart,
            'class': 'sentence'
          };
        });
        sentences = userAnswerValue && userAnswerValue.length ? sentences : [];

        return component.mergeArrays(sentences, answers, answerDetail.answer_category);
      });
      return answerData;
    }),

    // -------------------------------------------------------------------------
    // Methods
    /**
     * Merge sentences and answers arrays
     * @return {Array}
     */
    mergeArrays: function mergeArrays(sentences, answers, category) {
      var mergeArrays = _ember['default'].A();
      answers.forEach(function (item, index) {
        mergeArrays.pushObject(sentences.get(index));
        mergeArrays.pushObject(item);
      });
      mergeArrays.pushObject(sentences[sentences.length - 1]);
      var answerByCategory = _ember['default'].Object.create({
        category: category,
        value: mergeArrays
      });
      return answerByCategory;
    }
  });
});