define('quizzes-addon/models/collection/collection', ['exports', 'ember', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonConfigQuizzesConfig) {

  /**
   * @typedef {Object} Collection
   */

  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {number} Attempts allowed
     */
    attempts: _ember['default'].computed.alias('settings.attempts'),

    /**
     * @property {string} Collection avatar
     */
    avatarUrl: null,

    /**
     * @property {boolean} Bidirectional
     */
    bidirectional: _ember['default'].computed.alias('settings.bidirectional'),

    /**
     * @property {string}
     */
    id: null,

    /**
     * @property {boolean} hasResources
     */
    hasResources: _ember['default'].computed.bool('resources.length'),

    content: _ember['default'].A([]),

    /**
     * @property {boolean} Return true if the collection is an assessment
     */
    isAssessment: _ember['default'].computed.not('isCollection'),

    /**
     * @property {boolean} Returns true if the collection type is collection
     */
    isCollection: null,

    /**
     * @property {boolean} Returns true if the assessment has immediate feedback setting
     */
    immediateFeedback: _ember['default'].computed('showFeedback', function () {
      return this.get('showFeedback') === _quizzesAddonConfigQuizzesConfig.ASSESSMENT_SHOW_VALUES.IMMEDIATE;
    }),
    /**
     * @property {Profile} Collection owner profile
     */
    owner: null,

    /**
     * @property {[]}
     */
    questions: [],

    /**
     * @property {number} Total of resources in the collection
     */
    resourceCount: _ember['default'].computed.readOnly('resources.length'),

    /**
     * @property {Array} List of resources associated to the collection
     */
    resources: _ember['default'].A(),

    /**
     * @property {Array} List of resources associated to the collection
     */
    resourcesSorted: _ember['default'].computed('resources', function () {
      var resources = this.get('resources').sortBy('sequence');
      resources.forEach(function (resource, index) {
        return resource.set('sequence', index + 1);
      });
      return resources;
    }),

    /**
     * @property {Object}
     */
    settings: null,

    /**
     * @property {string} Indicate if show answer key setting
     */
    showFeedback: _ember['default'].computed.alias('settings.showFeedback'),

    /**
     * @property {boolean} Indicate if show answer key setting
     */
    showKey: _ember['default'].computed.alias('settings.showKey'),

    /**
     * @property {string} Collection's title
     */
    title: null,

    /**
     * Gets the next resource based on the resource provided
     * @param {Resource} resource
     * @returns {Resource|undefined} next resource
     */
    nextResource: function nextResource(resource) {
      var next = undefined;
      if (this.get('hasResources')) {
        var resources = this.get('resourcesSorted'),
            index = resources.indexOf(resource);
        next = resources.objectAt(index + 1);
      }
      return next;
    },

    /**
     * Gets the previous resource based on the resource provided
     * @param {Resource} resource
     * @returns {Resource|undefined} previous resource
     */
    prevResource: function prevResource(resource) {
      var next = undefined;
      if (this.get('hasResources')) {
        var resources = this.get('resourcesSorted'),
            index = resources.indexOf(resource);
        next = resources.objectAt(index - 1);
      }
      return next;
    },

    /**
     * Gets the resource by id
     * @param {string }resourceId
     * @returns {Resource|undefined}
     */
    getResourceById: function getResourceById(resourceId) {
      var resource = undefined;
      if (this.get('hasResources')) {
        var resources = this.get('resources').filterBy('id', resourceId);
        if (resources.length) {
          resource = resources[0];
        }
      }
      return resource;
    },

    /**
     * Returns true if it's the last resource of the collection
     * @param {Resource}resource
     * @returns {Resource|undefined}
     */
    isLastResource: function isLastResource(resource) {
      var resources = this.get('resources');
      var index = resources.indexOf(resource);
      var collectionLength = this.get('resourceCount');
      return index + 1 === collectionLength;
    }
  });
});