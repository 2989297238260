define('quizzes-addon/components/player/qz-submit-confirmation', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-submit-confirmation'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      confirm: function confirm() {
        this.sendAction('onConfirmFinish');
      },
      cancel: function cancel() {
        this.sendAction('onCancelFinish');
      }
    }
  });
});