define('quizzes-addon/components/reports/class-assessment/qz-student-performance-box', ['exports', 'ember', 'quizzes-addon/utils/utils', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonUtilsUtils, _quizzesAddonConfigQuizzesConfig) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'class-assessment', 'qz-student-performance-box'],

    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * When the user clicks at the box
       */
      selectStudent: function selectStudent() {
        var component = this;
        component.get('onSelectStudent')(component.get('student.profileId'));
        _ember['default'].Logger.debug('Clicking at student: ' + component.get('student.profileId'));
      },

      /**
       * @function actions:selectQuestion
       * @param {Number} questionId
       */
      selectQuestion: function selectQuestion(questionId) {
        if (questionId) {
          this.get('onSelectQuestion')(questionId);
        }
      }
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Indicates if the report is displayed in anonymous mode
     * @property {boolean} anonymous
     */
    anonymous: false,

    /**
     * @property {Function} onSelectQuestion - Event handler called when a question in a column is selected
     */
    onSelectQuestion: null,

    /**
     * It returns an object representing the status for each question
     * @property {[]} questions
     */
    questions: _ember['default'].computed('reportData.@each.answer', function () {
      var component = this;
      var reportData = component.get('reportData');
      return reportData.map(function (item) {
        return component.getQuestionStatus(item);
      });
    }),

    /**
     * Array containing the QuestionResult or empty object based on the student responses
     * empty object for not started questions
     * @property {QuestionResult[]} reportData
     */
    reportData: null,

    /**
     * @property {number} user assessment score
     */
    score: _ember['default'].computed.alias('student.averageScore'),

    /**
     * @property {String} startedStyle style safe string for started
     */
    startedStyle: _ember['default'].computed('score', 'student.totalAnswered', function () {
      return this.get('student.totalAnswered') ? _ember['default'].String.htmlSafe('background-color: ' + (0, _quizzesAddonUtilsUtils.getGradeColor)(this.get('score'))) : '';
    }),

    /**
     * @property {User} student
     */
    student: null,

    /**
     * Get students avatar url if present,
     * if not returns the default profile img
     */
    studentAvatarUrl: _ember['default'].computed('student.avatarUrl', function () {
      var imageUrl = undefined;
      if (this.get('student.avatarUrl') && this.get('student.avatarUrl') !== 'undefined') {
        imageUrl = this.get('student.avatarUrl');
      } else {
        var appRootPath = this.get('configurationService.configuration.appRootPath') ? this.get('configurationService.configuration.appRootPath') : '/';
        imageUrl = appRootPath + _quizzesAddonConfigQuizzesConfig.DEFAULT_IMAGES.USER_PROFILE;
      }

      return imageUrl;
    }),

    /**
     * returns split of lastFirstName of student
     */
    studentLastFirstName: _ember['default'].computed('student.lastFirstName', function () {
      var studentNameArray = undefined,
          studentLastName = '',
          studentFirstName = '',
          studentLastFirstNameObject = undefined;
      if (this.get('student.lastFirstName') && this.get('student.lastFirstName') !== 'undefined') {
        studentNameArray = this.get('student.lastFirstName').split(',');
        if (studentNameArray.length === 1) {
          studentLastName = studentNameArray[0];
          studentFirstName = '';
        } else if (studentNameArray.length > 1) {
          studentNameArray.map(function (curVal, curIndex) {
            if (curIndex === 0) {
              studentLastName = curVal;
            } else {
              studentFirstName = studentFirstName + curVal;
            }
          });
        } else {
          studentLastName = '';
          studentFirstName = '';
        }
        studentLastFirstNameObject = {
          studentLastName: studentLastName,
          studentFirstName: studentFirstName
        };
      }

      return studentLastFirstNameObject;
    }),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Gets the question status
     * @param {QuestionResult} questionResult
     */
    getQuestionStatus: function getQuestionStatus(questionResult) {
      var status = 'not-started',
          questionId = questionResult.get('resourceId'),
          title = undefined;
      if (questionResult.get('isResource') === true && questionResult.get('skipped') === false) {
        status = 'started';
        questionId = questionResult.get('questionId');
      } else {
        if (questionResult.get('started')) {
          //if it has been started
          status = questionResult.get('attemptStatus');
          questionId = questionResult.get('questionId');
        }
      }
      title = this.getResourceTitle(questionId);

      return _ember['default'].Object.create({
        status: status,
        savedTime: (0, _quizzesAddonUtilsUtils.roundTimeToGreatestValue)(questionResult.savedTime),
        id: questionId,
        title: title
      });
    },

    getResourceTitle: function getResourceTitle(questionId) {
      return this.get('student.collection.resources') && this.get('student.collection.resources').findBy('id', questionId) ? this.get('student.collection.resources').findBy('id', questionId).title : '';
    }
  });
});