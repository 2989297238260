define('quizzes-addon/adapters/context/context', ['exports', 'quizzes-addon/mixins/token', 'quizzes-addon/adapters/application'], function (exports, _quizzesAddonMixinsToken, _quizzesAddonAdaptersApplication) {
  exports['default'] = _quizzesAddonAdaptersApplication['default'].extend(_quizzesAddonMixinsToken['default'], {
    /**
     * @property {Object} namespace base url for context endpoints
     */
    namespace: '/quizzes/api/v1/contexts',

    /**
     * Creates a context
     * @param {Object} assignment
     * @returns {Promise}
     */
    createContext: function createContext(assignment) {
      var namespace = this.get('namespace');
      var url = '' + namespace;
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        data: JSON.stringify(assignment),
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Get context by id assigned to the current student
     * @returns {Promise}
     */
    getAssignedContextById: function getAssignedContextById(contextId) {
      var namespace = this.get('namespace');
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        headers: this.get('headers')
      };

      var url = namespace + '/' + contextId + '/assigned';
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Gets all the contexts assigned to the current student
     * @returns {Promise}
     */
    getContextsAssigned: function getContextsAssigned() {
      var namespace = this.get('namespace');
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        headers: this.get('headers')
      };
      var url = namespace + '/assigned';
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Gets all contexts created by the current teacher
     * @returns {Promise}
     */
    getContextsCreated: function getContextsCreated() {
      var namespace = this.get('namespace');
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        headers: this.get('headers')
      };
      var url = namespace + '/created';
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Start playing new resource based on the context.
     * @param {String} resourceId current resource
     * @param {String} contextId
     * @param {Object} previousResource resource to save
     * @returns {Promise}
     */
    startPlayResource: function startPlayResource(resourceId, contextId, prevResource, eventContext) {
      var namespace = this.get('namespace');
      var previousResource = prevResource || {};
      var data = { eventContext: eventContext, previousResource: previousResource };
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        processData: false,
        data: JSON.stringify(data),
        headers: this.get('headers')
      };
      var resourceIdParam = resourceId || previousResource.resourceId;
      var url = namespace + '/' + contextId + '/onResource/' + resourceIdParam + '/start';
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Stop playing  resource based on the context.
     * @param {String} resourceId current resource
     * @param {String} contextId
     * @param {Object} previousResource resource to save
     * @returns {Promise}
     */
    stopPlayResource: function stopPlayResource(resourceId, contextId, prevResource, eventContext) {
      var namespace = this.get('namespace');
      var previousResource = prevResource || {};
      var data = { eventContext: eventContext, previousResource: previousResource };
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        processData: false,
        data: JSON.stringify(data),
        headers: this.get('headers')
      };
      var resourceIdParam = resourceId || previousResource.resourceId;
      var url = namespace + '/' + contextId + '/onResource/' + resourceIdParam + '/stop';
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Pause playing  resource based on the context.
     * @param {String} resourceId current resource
     * @param {String} contextId
     * @param {Object} previousResource resource to save
     * @returns {Promise}
     */
    pausePlayResource: function pausePlayResource(resourceId, contextId, prevResource, eventContext) {
      var namespace = this.get('namespace');
      var previousResource = prevResource || {};
      var data = { eventContext: eventContext, previousResource: previousResource };
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        processData: false,
        data: JSON.stringify(data),
        headers: this.get('headers')
      };
      var resourceIdParam = resourceId || previousResource.resourceId;
      var url = namespace + '/' + contextId + '/onResource/' + resourceIdParam + '/pause';
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Resume playing  resource based on the context.
     * @param {String} resourceId current resource
     * @param {String} contextId
     * @param {Object} previousResource resource to save
     * @returns {Promise}
     */
    resumePlayResource: function resumePlayResource(resourceId, contextId, prevResource, eventContext) {
      var namespace = this.get('namespace');
      var previousResource = prevResource || {};
      var data = { eventContext: eventContext, previousResource: previousResource };
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        processData: false,
        data: JSON.stringify(data),
        headers: this.get('headers')
      };
      var resourceIdParam = resourceId || previousResource.resourceId;
      var url = namespace + '/' + contextId + '/onResource/' + resourceIdParam + '/resume';
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Send event to notify the student submitted all questions in an assignment
     * @param {String} contextId
     * @returns {Promise}
     */
    sendFinishContextEvent: function sendFinishContextEvent(contextId, eventContext) {
      var namespace = this.get('namespace');
      var url = namespace + '/' + contextId + '/finish';
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        processData: false,
        data: JSON.stringify(eventContext),
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Send event to notify the student started an assignment
     * @param {String} contextId
     * @returns {Promise}
     */
    sendStartContextEvent: function sendStartContextEvent(contextId, eventContext) {
      var namespace = this.get('namespace');
      var url = namespace + '/' + contextId + '/start';
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        data: JSON.stringify(eventContext),
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Update an assignment/context
     * @param {Object} data
     * @param {String} contextId
     * @returns {Promise}
     */
    updateContext: function updateContext(data, contextId) {
      var namespace = this.get('namespace');
      var url = namespace + '/' + contextId;
      var options = {
        type: 'PUT',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        processData: false,
        data: JSON.stringify(data),
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    }
  });
});