define('quizzes-addon/validators/reserved-words', ['exports', 'ember-cp-validations/validators/base', 'quizzes-addon/config/quizzes-config', 'ember'], function (exports, _emberCpValidationsValidatorsBase, _quizzesAddonConfigQuizzesConfig, _ember) {
  exports['default'] = _emberCpValidationsValidatorsBase['default'].extend({
    i18n: _ember['default'].inject.service(),

    validate: function validate(value) {
      if (value) {
        var reservedWord = _quizzesAddonConfigQuizzesConfig.RESERVED_WORDS.find(function (item) {
          return item === value;
        });
        return reservedWord ? this.get('i18n').t('sign-up.error-username-taken').string : true;
      } else {
        return true;
      }
    }
  });
});