define('quizzes-addon/components/qz-modal', ['exports', 'ember', 'quizzes-addon/mixins/modal'], function (exports, _ember, _quizzesAddonMixinsModal) {

  /**
   * Modal window
   *
   * Modal window to dynamically display any component
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsModal['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    /** @type {String[]} */
    classNames: ['qz-modal', 'fade', 'modal'],

    /** @type {String[]} */
    classNameBindings: ['component-class'],

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      var self = this;

      // Add event listener
      this.$().on('hidden.bs.modal', function () {
        // Force the ember run loop to run right away
        _ember['default'].run(function () {
          // The property 'is-visible' controls the visibility of a particular modal instance
          // However, all modals in the app must be controlled simultaneously. That is what
          // the modal mixin is for: to keep all modal instances in sync; therefore,
          // the value of 'modal.isVisible' is set instead of the property 'is-visible'.
          self.set('modal.isVisible', false);
        });
      });

      this.controlVisibility();
    },

    willDestroyElement: function willDestroyElement() {
      this.$().off('hidden.bs.modal');
    },

    // -------------------------------------------------------------------------
    // Properties

    /**
     * Specific component class(es). Multiple classes are separated with spaces.
     * @type {?String}
     */
    'component-class': _ember['default'].computed.alias('modal.component-class'),

    /**
     * Component data
     * @type {*}
     * @private
     */
    'component-model': _ember['default'].computed.alias('modal.model'),

    /**
     * Name of the component to display
     * @type {String}
     * @private
     */
    'component-name': _ember['default'].computed.alias('modal.name'),

    /**
     * Modal style
     * @type {String}
     */
    style: _ember['default'].computed('modal.width', function () {
      var width = this.get('modal.width');
      if (window.outerHeight < 768) {
        width = '93%';
      }
      var style = 'width: ' + width,
          height = this.get('modal.height'),
          overflow = this.get('modal.overflow');
      style = height ? style + ';height: ' + height : style;
      style = overflow ? style + ';overflow: ' + overflow : style;
      return _ember['default'].String.htmlSafe(style);
    }),

    height: _ember['default'].computed('modal.height', function () {
      var height = this.get('modal.height');
      return height ? _ember['default'].String.htmlSafe(';height: ' + height) : height;
    }),
    /**
     * Name of the channel this modal component will be listening to.
     * A global modal component will not have a channel value.
     * @type {String}
     */
    channel: null,

    /**
     * Whether the modal window should be visible or not
     * @type {Boolean}
     * @private
     */
    'is-visible': (function () {
      var activeChannel = this.get('modal.activeChannel');
      var isVisible = this.get('modal.isVisible');
      var modalChannel = this.get('channel');

      if (!activeChannel) {
        // Addressing the global modal
        return !modalChannel ? isVisible : false;
      } else {
        return activeChannel === modalChannel ? isVisible : false;
      }
    }).property('modal.isVisible', 'modal.activeChannel', 'channel'),

    // -------------------------------------------------------------------------
    // Observers

    /**
     * Display the modal based on its state
     * @type {String}
     */
    controlVisibility: (function () {
      if (this.get('is-visible')) {
        this.$().modal('show');
      } else {
        this.$().modal('hide');
      }
    }).observes('is-visible'),

    actions: {
      closeModal: function closeModal() {
        this.set('modal.isVisible', false);
      }
    }
  });
});