define('quizzes-addon/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    // -------------------------------------------------------------------------
    // Attributes

    queryParams: ['themeId'],

    /**
     * @property {string} application theme
     */
    themeId: null,

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {GruTheme} application theme
     */
    theme: null
  });
});