define('quizzes-addon/components/player/qz-image-modal', ['exports', 'ember', 'quizzes-addon/mixins/modal'], function (exports, _ember, _quizzesAddonMixinsModal) {
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsModal['default'], {
    // -------------------------------------------------------------------------
    // Dependencies

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['qz-image-modal'],

    thumbnail: _ember['default'].computed.alias('model.thumbnail'),

    zoomImg: 1,
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      zoomPlus: function zoomPlus() {
        var zoomImg = this.get('zoomImg');
        zoomImg = zoomImg - 0.1;
        _ember['default'].set(this, 'zoomImg', zoomImg);
      },
      zoomMinus: function zoomMinus() {
        var zoomImg = this.get('zoomImg');
        zoomImg = zoomImg + 0.1;
        _ember['default'].set(this, 'zoomImg', zoomImg);
      },
      closeModal: function closeModal() {
        this.set('modal.isVisible', false);
      }
    }
  });
});