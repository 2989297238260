define('quizzes-addon/components/player/questions/qz-serp-phrase-reading', ['exports', 'quizzes-addon/components/player/questions/qz-question', 'ember'], function (exports, _quizzesAddonComponentsPlayerQuestionsQzQuestion, _ember) {

  /**
   * SERP decoding question
   * Component responsible for controlling the logic and appearance of a true
   * or false question inside of the {@link player/qz-question-viewer.js}
   * @module
   * @see controllers/player.js
   * @see components/player/qz-question-viewer.js
   * @augments ember/Component
   */
  exports['default'] = _quizzesAddonComponentsPlayerQuestionsQzQuestion['default'].extend({
    // -------------------------------------------------------------------------
    // Dependencies

    session: _ember['default'].inject.service('session'),

    /**
     * @type {CollectionService} mediaService
     * @property {Ember.Service} Service to work with media
     */
    mediaService: _ember['default'].inject.service('quizzes/api-sdk/media'),

    // -------------------------------------------------------------------------
    // Attributes
    classNames: ['serp-phrase-reading'],

    // -------------------------------------------------------------------------
    // Actions

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var question = this.get('question');
      this.injectDecoding(question.baseAnswers);
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    // -------------------------------------------------------------------------
    // Properties

    userId: _ember['default'].computed.alias('session.userId'),

    isAudioUploaded: false,

    // -------------------------------------------------------------------------
    // Observers

    // -------------------------------------------------------------------------
    // Methods

    injectDecoding: function injectDecoding(baseAnswers) {
      var component = this;
      var user = {
        userId: this.get('userId')
      };
      var content = {
        contentId: this.get('question.id'),
        contentTitle: this.get('question.title'),
        answers: baseAnswers,
        isPhraseCued: true
      };
      window.serp.languageDecode().select('#serp-phrase-container').dataIn(user, null, content).wordsPerMinute().render().listener(function (eventData) {
        component.handleDecodingSubmission(eventData);
      });
    },

    handleDecodingSubmission: function handleDecodingSubmission(eventData) {
      var component = this;
      component.set('isAudioUploaded', false);
      var decodingAns = $('.panel-body .decoding-answers');
      decodingAns.find('.loader-icons').show();
      var answers = [];
      var audioPromises = eventData.decoding_answers.map(function (answer) {
        return new Promise(function (resolve) {
          return component.uploadAudio(answer.audio.blob).then(function (fileUrl) {
            if (fileUrl) {
              answers.push({ value: fileUrl });
              return resolve();
            } else {
              component.set('isAudioUploaded', true);
              decodingAns.find('.audio-error').show();
              return;
            }
          });
        });
      });

      Promise.all(audioPromises).then(function () {
        if (answers && answers.length) {
          if (answers && answers.length) {
            decodingAns.find('.audio-uploaded').show();
            decodingAns.find('.confirm-text').addClass('audio-saved');
            decodingAns.find('.loader-icons').hide();
            component.notifyAnswerCompleted(answers);
          }
        }
      });
    },

    uploadAudio: function uploadAudio(audioBlob) {
      return this.get('mediaService').uploadContentFile(audioBlob, true);
    }
  });
});