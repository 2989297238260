define('quizzes-addon/serializers/attempt/attempt', ['exports', 'ember', 'quizzes-addon/serializers/context/context', 'quizzes-addon/models/result/report-data', 'quizzes-addon/models/result/report-data-event', 'quizzes-addon/models/result/learning-target'], function (exports, _ember, _quizzesAddonSerializersContextContext, _quizzesAddonModelsResultReportData, _quizzesAddonModelsResultReportDataEvent, _quizzesAddonModelsResultLearningTarget) {
  exports['default'] = _ember['default'].Object.extend({
    /**
     * @property {ContextSerializer} contextSerializer
     */
    contextSerializer: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('contextSerializer', _quizzesAddonSerializersContextContext['default'].create(_ember['default'].getOwner(this).ownerInjection()));
    },

    /**
     * Normalizes a ReportData
     * @returns {ReportData}
     */
    normalizeReportData: function normalizeReportData(payload) {
      var serializer = this;
      return _quizzesAddonModelsResultReportData['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        contextId: payload.contextId,
        collectionId: payload.collectionId,
        reportEvents: serializer.normalizeReportDataEvents(payload.profileAttempts)
      });
    },

    /**
     * Normalizes a ReportDataEvent
     * @returns {ReportDataEvent}
     */
    normalizeReportDataEvent: function normalizeReportDataEvent(reportEvent) {
      var summary = reportEvent.eventSummary;
      var eventQuestion = reportEvent.events;
      var taxonomySummary = reportEvent.taxonomySummary;
      var reportDataEvent = _quizzesAddonModelsResultReportDataEvent['default'].create(_ember['default'].getOwner(this).ownerInjection(), {
        attemptId: reportEvent.attemptId,
        collectionId: reportEvent.collectionId,
        contextId: reportEvent.contextId,
        currentResourceId: reportEvent.currentResourceId,
        profileId: reportEvent.profileId,
        resourceResults: this.get('contextSerializer').normalizeResourceResults(reportEvent.events),
        isAttemptFinished: reportEvent.isComplete,
        submittedAt: reportEvent.updatedDate
      });
      if (summary) {
        reportDataEvent.setProperties({
          totalAnswered: summary.totalAnswered,
          totalCorrect: summary.totalCorrect,
          averageReaction: summary.averageReaction,
          averageScore: summary.averageScore,
          totalTimeSpent: summary.totalTimeSpent
        });
      }
      if (taxonomySummary) {
        var learningTargets = [];
        if (_ember['default'].isArray(taxonomySummary)) {
          learningTargets = taxonomySummary.map(function (standard) {
            //standard score  set -1 if all questions is skipped in taxonomySummary
            var skippedQuestioncount = 0;
            standard.resources.forEach(function (resourceId) {
              eventQuestion.forEach(function (event) {
                if (event.resourceId === resourceId && event.isSkipped === true) {
                  skippedQuestioncount = skippedQuestioncount + 1;
                }
              });
            });
            // minus one (-1) consider as not scored
            if (standard.resources.length === skippedQuestioncount) {
              standard.averageScore = -1;
            }
            return _quizzesAddonModelsResultLearningTarget['default'].create({
              id: standard.taxonomyId,
              mastery: standard.averageScore,
              relatedQuestions: standard.resources
            });
          });
        }
        reportDataEvent.set('mastery', learningTargets);
      }
      return reportDataEvent;
    },

    /**
     * Normalizes report data events
     * @returns {ReportDataEvent[]}
     */
    normalizeReportDataEvents: function normalizeReportDataEvents(payload) {
      var serializer = this;
      payload = payload || [];
      return payload.map(function (reportEvent) {
        return serializer.normalizeReportDataEvent(reportEvent);
      });
    },

    /**
     * Normalizes attempt ids to an array
     * @returns {String[]}
     */
    normalizeAttemptIds: function normalizeAttemptIds(payload) {
      return payload && payload.attempts ? payload.attempts : [];
    }
  });
});