define('quizzes-addon/serializers/player/activity-feedback', ['exports', 'ember'], function (exports, _ember) {
  /**
   * Serializer to support capture activity feedback functionality
   *
   * @typedef {Object} ActivityFeedbackSerializer
   */
  exports['default'] = _ember['default'].Object.extend({
    /**
     * Normalize the fetch feedback categories
     * @param payload is the endpoint response in JSON format
     * @returns {Category[]} an array of categoryLists
     */
    normalizeFetchFeedbackCategories: function normalizeFetchFeedbackCategories(payload) {
      var feedbackCategory = payload ? payload.feedback_categories : {};
      var contentCategory = _ember['default'].Object.create();

      var _loop = function (property) {
        contentCategory[property] = [];
        feedbackCategory[property].map(function (category) {
          var categoryObject = _ember['default'].Object.create({
            categoryId: category.id,
            categoryName: category.category_name,
            feedbackTypeId: category.feedback_type_id,
            maxScale: category.max_scale,
            rating: 0
          });
          contentCategory[property].push(categoryObject);
        });
      };

      for (var property in feedbackCategory) {
        _loop(property);
      }
      return contentCategory;
    },

    /**
     * Normalize the fetch user activity feedback
     * @param payload is the endpoint response in JSON format
     * @returns {UserActivityFeedback[]} an array of UserActivityFeedbacks
     */
    normalizeFetchActivityFeedback: function normalizeFetchActivityFeedback(payload) {
      var activityFeedback = payload ? payload.userActivityFeedbacks : [];
      var userActivityFeedbacks = [];
      if (activityFeedback.length) {
        activityFeedback.map(function (feedback) {
          userActivityFeedbacks.pushObject(_ember['default'].Object.create({
            categoryId: feedback.feedbackCategoryId,
            rating: feedback.userFeedbackQuantitative,
            qualitative: feedback.userFeedbackQualitative
          }));
        });
      }
      return userActivityFeedbacks;
    }
  });
});