define('quizzes-addon/models/taxonomy/taxonomy-tag', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Taxonomy Tag
   *
   * Model of a visual representation of a taxonomy tag
   *
   * @typedef {Object} TaxonomyTag
   */
  var TaxonomyTag = _ember['default'].Object.extend({
    /**
     * @property {boolean} isActive - Is the state of the tag active or not?
     */
    isActive: false,

    /**
     * @property {boolean} isReadonly - Is the tag read-only or does it accept changes
     * to its state
     */
    isReadonly: false,

    /**
     * @property {boolean} isRemovable - Can the tag be removed or not?
     */
    isRemovable: false,

    /**
     * @property {TaxonomyTagData} data - Data for the taxonomy tag
     */
    data: null
  });

  TaxonomyTag.reopenClass({
    /**
     * Gets the taxonomy tags
     * @param {TaxonomyTagData[]} taxonomy
     * @param editable
     * @returns {Array}
     */
    getTaxonomyTags: function getTaxonomyTags() {
      var taxonomy = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];
      var editable = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
      var removable = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

      return taxonomy.map(function (tagData) {
        return TaxonomyTag.create({
          isActive: false,
          isReadonly: !editable,
          isRemovable: removable,
          data: tagData
        });
      });
    }
  });

  exports['default'] = TaxonomyTag;
});