define('quizzes-addon/components/gru-two-tier-header-table', ['exports', 'ember', 'quizzes-addon/utils/utils'], function (exports, _ember, _quizzesAddonUtilsUtils) {

  /**
   * Two-tier header table
   *
   * Component responsible for displaying and filtering a set of data
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['gru-two-tier-header-table'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * @function actions:selectFirstTierColHeader
       * @param {string} headerId
       */
      selectFirstTierColHeader: function selectFirstTierColHeader(headerId) {
        this.get('onSelectFirstTierHeader')(headerId);
      },

      /**
       * @function actions:selectRowHeader
       * @param {string} headerId
       */
      selectRowHeader: function selectRowHeader(headerId) {
        this.get('onSelectRowHeader')(headerId);
      },

      /**
       * @function actions:updateSortCriteria
       * @param {number} firstTierIndex
       * @param {number} secondTierIndex
       */
      updateSortCriteria: function updateSortCriteria(firstTierIndex, secondTierIndex) {
        var sortCriteria = this.get('sortCriteria');
        var newSortCriteria = {
          firstTierIndex: firstTierIndex,
          secondTierIndex: secondTierIndex
        };

        if (sortCriteria.firstTierIndex === firstTierIndex && sortCriteria.secondTierIndex === secondTierIndex) {
          // Reverse the sort order if the same column has been selected
          newSortCriteria.order = sortCriteria.order * -1;
          this.set('sortCriteria', newSortCriteria);
        } else {
          newSortCriteria.order = this.get('defaultSortOrder');
          this.set('sortCriteria', newSortCriteria);
        }
      }
    },

    // -------------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.set('sortCriteria', this.initSortCriteria());
      });
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.updateColumnVisibility();
    },

    // -------------------------------------------------------------------------
    // Properties

    /*
     * @prop { Number } currentVisibleHeadersLen - Stores the current number of second tier headers that are visible
     */
    currentVisibleHeadersLen: 0,

    /**
     * @prop { Object[] } data - Array of objects with the information for all of the table rows
     * Objects are of the form:
     * {
     *    id: <row_id>,
     *    header: <row_label>,
     *    content: String[]
     * }
     * ... where 'content' will consist of values for each one of the
     * second tier headers
     */
    data: null,

    /*
     * @prop { Number } defaultSortOrder - Default sort order for values in columns (1 = ascending; -1 = descending)
     */
    defaultSortOrder: 1,

    /**
     * @prop { Object[] } firstTierHeaders - Array of objects to use as the first tier
     * headers for the table component
     *
     * Each object will consist of:
     * - label: visual representation of the header
     * - value: internal header identifier
     * They will be ordered from left to right in the table header as they appear in the array.
     */
    firstTierHeaders: null,

    /**
     * @prop { Function } onSelectFirstTierHeader - Event handler triggered when clicking on a first tier header
     */
    onSelectFirstTierHeader: null,

    /**
     * @prop { Function } onSelectRowHeader - Event handler triggered when clicking on a row header
     */
    onSelectRowHeader: null,

    /**
     * @prop { Object[] } secondTierHeaders - Second tier headers for the table component.
     * The same second tier headers will appear under each one of the first tier headers
     *
     * Each object will consist of:
     * - label: visual representation of the header
     * - value: internal header identifier
     * - visible: controls the visibility of the header
     */
    secondTierHeaders: null,

    /**
     * @prop { Number } secondTierHeadersVisible - Total number of second tier headers
     * that are visible
     */
    secondTierHeadersVisible: _ember['default'].computed('secondTierHeaders.@each.visible', function () {
      return this.get('secondTierHeaders').filterBy('visible', true).get('length');
    }),

    /**
     * @prop { Object } sortCriteria - Object with information on how the data should be sorted
     * - firstTierIndex: {number} - Index of the first tier header
     * - secondTierIndex: {number} - Index of the second tier header
     * - order: {number} - Ascending or descending order
     */
    sortCriteria: null,

    /**
     * @prop { Object[] } sortedData - Ordered representation of 'data'
     */
    sortedData: _ember['default'].computed('data.length', 'sortCriteria', function () {
      var _this = this;

      var sortCriteria = this.get('sortCriteria');
      var data = this.get('data');
      var sortedData = data;

      if (sortCriteria) {
        (function () {
          var secondTierHeaders = _this.get('secondTierHeaders');
          var firstTierIndex = sortCriteria.firstTierIndex;
          var secondTierIndex = sortCriteria.secondTierIndex;
          var sortColumn = sortCriteria.firstTierIndex * secondTierHeaders.length + secondTierIndex;
          var sortFunction = undefined;
          sortedData = _ember['default'].copy(data, true);

          if (firstTierIndex === -1 || secondTierIndex === -1) {
            // Sort alphabetically by row headers
            var rowHeadersHeader = _this.get('rowHeadersHeader');
            sortFunction = rowHeadersHeader.sortFunction || _quizzesAddonUtilsUtils.numberSort;
            sortedData.sort(function (a, b) {
              return sortFunction(a.header, b.header) * sortCriteria.order;
            });
          } else if (firstTierIndex >= 0) {
            sortFunction = secondTierHeaders[secondTierIndex].sortFunction || _quizzesAddonUtilsUtils.numberSort;
            sortedData.sort(function (a, b) {
              return sortFunction(a.content[sortColumn].value, b.content[sortColumn].value) * sortCriteria.order;
            });
          }
        })();
      }
      return sortedData;
    }),

    /**
     * @prop { Object? } rowHeadersHeader - Header for the row headers
     */
    rowHeadersHeader: null,

    // -------------------------------------------------------------------------
    // Observers

    /**
     * Update the visibility of the table columns based on the secondTierHeaders model
     *
     * @function
     * @returns {undefined}
     */
    updateColumnVisibility: _ember['default'].observer('secondTierHeaders.@each.visible', function () {
      var secondTierHeaders = this.get('secondTierHeaders');
      var secondTierHeadersLen = secondTierHeaders.length;
      var secondTierHeadersVisible = secondTierHeaders.filterBy('visible', true).length;
      var removeColumns = secondTierHeadersVisible < this.get('currentVisibleHeadersLen');
      var selectors = [];
      var cssSelector = undefined;

      secondTierHeaders.forEach(function (header, index) {
        if (removeColumns && !header.visible || !removeColumns && header.visible) {
          var offset = index - 1;
          var offsetStr = offset < 0 ? offset : '+' + offset;

          selectors.push('table tr.second-tier th.' + header.value);
          selectors.push('table tr.data td:nth-child(' + secondTierHeadersLen + 'n' + offsetStr + ')');
        }
      });
      cssSelector = selectors.join(',');

      if (removeColumns) {
        // There are less second tier headers visible now so the class 'hidden'
        // will be added to the second tier headers that are no longer visible.
        // Otherwise, if there are more second tier headers visible now, the
        // class 'hidden' will be removed from them.
        this.$(cssSelector).addClass('hidden');
      } else {
        this.$(cssSelector).removeClass('hidden');
      }

      this.set('currentVisibleHeadersLen', secondTierHeadersVisible);
    }),

    updateSortClasses: _ember['default'].observer('sortCriteria', function () {
      var sortCriteria = this.get('sortCriteria');
      var totalSecondTierHeaders = this.get('secondTierHeaders').length;
      var rowHeadersHeader = !!this.get('rowHeadersHeader');
      var headers = this.$('.second-tier th');

      var currentHeaderIndex = rowHeadersHeader + (sortCriteria.firstTierIndex * totalSecondTierHeaders + sortCriteria.secondTierIndex);

      headers.removeClass('ascending').removeClass('descending');

      if (currentHeaderIndex >= 0 && sortCriteria.order > 0) {
        headers.eq(currentHeaderIndex).addClass('ascending');
      } else {
        headers.eq(currentHeaderIndex).addClass('descending');
      }
    }),

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Initialize the table's sort criteria
     * @return {Object}
     */
    initSortCriteria: function initSortCriteria() {
      // No columns will be sorted by default
      return {
        firstTierIndex: -1,
        secondTierIndex: 0,
        order: this.get('defaultSortOrder')
      };
    }
  });
});