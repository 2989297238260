define('quizzes-addon/components/reports/assessment/qz-resources-xs', ['exports', 'ember'], function (exports, _ember) {

  /**
   *Resource-xs
   *
   * Component responsible for showing the resoruce details for xsmall devices.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'qz-resources-xs'],
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Resource to be displayed by the component
     *
     * @property {Ember.Object}
     */
    resource: null
  });
});