define('quizzes-addon/components/gru-assignments-table', ['exports', 'ember', 'quizzes-addon/mixins/modal', 'quizzes-addon/models/profile/profile'], function (exports, _ember, _quizzesAddonMixinsModal, _quizzesAddonModelsProfileProfile) {
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsModal['default'], {
    // -------------------------------------------------------------------------
    // Dependencies

    /**
     * @property {Service} I18N service
     */
    i18n: _ember['default'].inject.service(),

    /**
     * @property {Service} profile service
     */
    profileService: _ember['default'].inject.service('quizzes/profile'),

    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['gru-assignments-table'],

    // -------------------------------------------------------------------------
    // Actions

    actions: {
      /**
       * Open add student modal
       */
      addStudent: function addStudent(assignment) {
        var component = this;
        var students = [];
        var profilesList = assignment.get('assignees').map(function (profile) {
          return profile.id;
        });

        if (profilesList && component.get('studentList')) {
          students = component.get('studentList').map(function (student) {
            var studentObject = _quizzesAddonModelsProfileProfile['default'].create(student);
            var containsStudent = profilesList.includes(student.id);
            studentObject.set('isAssigned', containsStudent);
            studentObject.set('id', student.id);
            return studentObject;
          });
        }
        component.set('students', students);

        var model = {
          students: component.get('students'),
          assignment: assignment,
          width: '95%',
          callback: {
            success: function success(assignment) {
              return component.sendAction('onUpdateAssignment', assignment);
            }
          }
        };
        component.actions.showModal.call(component, 'gru-assign-student-modal', model, null, null, null, false);
      },
      /**
       * Open slide up actions
       */
      openActions: function openActions(assignment) {
        this.set('actualAssignment', assignment);
        this.set('showMenu', true);
      },

      /**
       * Redirect to real time
       */
      openRealTime: function openRealTime(assignment) {
        if (this.get('realTimeURL')) {
          var url = this.get('realTimeURL').replace('{context-id}', assignment.get('id'));
          window.location.href = url;
        } else {
          this.get('router').transitionTo('reports.context', assignment.get('id'));
        }
      },

      /**
       * Open player
       */
      openPlayer: function openPlayer(assignment) {
        if (this.get('playerURL')) {
          var url = this.get('playerURL').replace('{context-id}', assignment.get('id'));
          window.location.href = url;
        } else {
          this.get('router').transitionTo('player', assignment.get('id'));
        }
      },

      /***
       * Search assignment
       */
      selectAssignment: function selectAssignment(assignment) {
        this.selectAssignment(assignment);
        this.sendAction('onSelectAssignment', assignment);
      },

      /**
       * Sort assignment list
       */
      sortBy: function sortBy(criteria) {
        if (criteria === this.get('sortBy')) {
          this.set('reverseSort', !this.get('reverseSort'));
        } else {
          this.set('sortBy', criteria);
          this.set('reverseSort', false);
        }
      }
    },

    // -------------------------------------------------------------------------
    // Properties
    /**
     * @property {Context} selected assignment
     */
    actualAssignment: null,

    /**
     * @property {Boolean} Indicate if is a teacher view
     * @see gru-assignments-list and assignments.js route
     */
    isTeacher: false,

    /**
     * Return menu mobile options
     */
    optionsMobile: _ember['default'].computed('actualAssignment', 'isTeacher', function () {
      var options = undefined;
      if (this.get('isTeacher')) {
        options = this.teacherOptions(this.get('actualAssignment'));
      } else {
        //TODO STUDENT VIEW OPTIONS
      }
      return options;
    }),

    /**
     * @property {Array} Students list
     */
    students: [],

    /**
     * @property {Array} Total student list
     */
    studentList: [],

    /**
     *  @property {Boolean} show actions menu
     */
    showMenu: false,

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Set assignment as selected
     */
    selectAssignment: function selectAssignment(assignment) {
      this.unSelectAssignment();
      assignment.set('selected', true);
    },
    /**
     * Return the teacher options for mobile menu
     */
    teacherOptions: function teacherOptions(assignment) {
      return _ember['default'].A([_ember['default'].Object.create({
        option: 'assign',
        action: 'onAssign',
        object: assignment
      }), _ember['default'].Object.create({
        option: 'preview',
        action: 'onPreview',
        object: assignment
      })]);
    },

    /**
     * Unselected assignment
     */
    unSelectAssignment: function unSelectAssignment() {
      var selectedAssignment = this.get('assignments').findBy('selected', true);
      if (selectedAssignment) {
        selectedAssignment.set('selected', false);
      }
    }
  });
});