define('quizzes-addon/components/reports/qz-student-report', ['exports', 'ember', 'quizzes-addon/models/result/context', 'quizzes-addon/config/quizzes-config'], function (exports, _ember, _quizzesAddonModelsResultContext, _quizzesAddonConfigQuizzesConfig) {
  exports['default'] = _ember['default'].Component.extend({
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'qz-student-report'],

    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {ReportDataEvent} attemptData all data for the current/last attempt
     */
    attemptData: null,

    /**
     * @property {ContextResult} contextResult data normalized for report
     */
    contextResult: _ember['default'].computed('attemptData', function () {
      return _quizzesAddonModelsResultContext['default'].create({
        reportEvent: this.get('attemptData'),
        averageReaction: _ember['default'].computed.alias('reportEvent.averageReaction'),
        correctPercentage: _ember['default'].computed.alias('reportEvent.averageScore'),
        correctAnswers: _ember['default'].computed.alias('reportEvent.totalCorrect'),
        currentResourceId: _ember['default'].computed.alias('reportEvent.currentResourceId'),
        totalTimeSpent: _ember['default'].computed.alias('reportEvent.totalTimeSpent'),
        totalAttempts: 1,
        selectedAttempt: 1,
        submittedAt: _ember['default'].computed.alias('reportEvent.submittedAt'),
        resourceResults: _ember['default'].computed.alias('reportEvent.resourceResults'),
        collection: this.get('collection'),
        isRealTime: this.get('isRealTime'),
        showAttempts: this.get('showAttempts'),
        mastery: this.get('attemptData.mastery'),
        children: this.get('collectionObj') ? this.get('collectionObj.children') : _ember['default'].A([])
      });
    }),

    /**
     * @property {Collection} collection
     */
    collection: _ember['default'].computed.alias('attemptData.collection'),

    /**
     * @property {boolean} areAnswersHidden - Should answer results be hidden?
     */
    areAnswersHidden: _ember['default'].computed('collection.isAssessment', 'collection.showFeedback', function () {
      return this.get('collection.isAssessment') && this.get('collection.showFeedback') === _quizzesAddonConfigQuizzesConfig.ASSESSMENT_SHOW_VALUES.NEVER;
    }),

    /**
     * @property {boolean} isRealTime
     */
    isRealTime: true,

    /**
     * @property {boolean} showAttempts
     */
    showAttempts: false,

    /**
     * @property {Number} totalAttempts
     */
    totalAttempts: 0,

    sourceType: null
  });
});