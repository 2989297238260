define('quizzes-addon/adapters/context/resource/resource', ['exports', 'quizzes-addon/mixins/token', 'quizzes-addon/adapters/application'], function (exports, _quizzesAddonMixinsToken, _quizzesAddonAdaptersApplication) {
  exports['default'] = _quizzesAddonAdaptersApplication['default'].extend(_quizzesAddonMixinsToken['default'], {
    // -------------------------------------------------------------------------
    // Properties

    /**
     * @property {string} namespace Nile End-point URI
     */
    namespace: '/api/nucleus/v1/resources',

    /**
     * @property {string} namespaceQuizzes End-point URI
     */
    namespaceQuizzes: '/quizzes/api/v1/resources',

    // -------------------------------------------------------------------------
    // Methods

    /**
     * Finish resource event
     *
     * @param {string} resourceId
     * @param {string} resourceEventData
     * @param {string} eventContext
     * @returns {Promise}
     */
    sendFinishResource: function sendFinishResource(resourceId, resourceEventData, eventContext) {
      var adapter = this;
      var namespace = adapter.get('namespaceQuizzes');
      var url = namespace + '/' + resourceId + '/finish';
      var options = {
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers'),
        data: JSON.stringify({ resourceEventData: resourceEventData, eventContext: eventContext })
      };
      return this.sendAjaxRequest(url, options);
    },

    /**
     * Reads a resource by id
     *
     * @param {string} resourceId
     * @returns {Promise}
     */
    readResource: function readResource(resourceId) {
      var adapter = this;
      var namespace = adapter.get('namespace');
      var url = namespace + '/' + resourceId;
      var options = {
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        headers: this.get('headers')
      };
      return this.sendAjaxRequest(url, options);
    }
  });
});