define('quizzes-addon/components/reports/assessment/questions/qz-serp-syllables-division', ['exports', 'ember', 'quizzes-addon/mixins/reports/assessment/questions/question'], function (exports, _ember, _quizzesAddonMixinsReportsAssessmentQuestionsQuestion) {

  /**
   * SERP Encoding Assessment
   *
   * Component responsible for show the reorder, what option are selected
   * and the correct option.
   *
   * @module
   * @augments ember/Component
   */
  exports['default'] = _ember['default'].Component.extend(_quizzesAddonMixinsReportsAssessmentQuestionsQuestion['default'], {
    // -------------------------------------------------------------------------
    // Attributes

    classNames: ['reports', 'assessment', 'questions', 'qz-syllables-division'],

    // -------------------------------------------------------------------------
    // Properties

    showCorrect: false,

    answers: _ember['default'].computed.alias('question.answer'),

    baseAnswers: _ember['default'].computed.alias('question.resource.baseAnswers'),

    // -----------------------------------------------------------------------
    // Events

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      var component = this;
      var baseAnswers = this.get('baseAnswers');
      baseAnswers.forEach(function (item, answerIndex) {
        component.$('.syllables-division-edit-' + answerIndex).html(component.wrapLetters(item.answer_text));
        var userAnswer = _this2.get('answers') && _this2.get('answers').get(answerIndex) ? _this2.get('answers').get(answerIndex) : null;
        var answerItem = _this2.get('showCorrect') ? item.correct_answer : userAnswer ? JSON.parse(userAnswer.value) : [];
        if (answerItem.length) {
          answerItem.forEach(function (cItem) {
            cItem = !_this2.get('showCorrect') ? cItem : JSON.parse(cItem);
            component.hightLightDefaultWord(cItem, answerIndex);
          });
        }
      });
    },

    // ---------------------------------------------------------------------
    // Methods
    hightLightDefaultWord: function hightLightDefaultWord(text, answerIndex) {
      var component = this;
      var start = text.start;
      var end = text.end;
      var parentEl = component.$('.syllables-division-edit-' + answerIndex);
      parentEl.find('b[data-index=' + start + '], b[data-index =' + end + ']').wrapAll('<span class="serp-hl-text-span"></span>');
      if (text.selectedIndex.length) {
        text.selectedIndex.forEach(function (sIndex) {
          parentEl.find('b[data-index=' + sIndex + ']').addClass('selected');
        });
      }
      component.arrowLine(parentEl);
    },

    wrapLetters: function wrapLetters(value) {
      var text = '';
      if (value && value.length) {
        for (var i = 0; i < value.length; i++) {
          text += '<b data-index=' + i + '>' + value[i] + '</b>';
        }
      }
      return text;
    },
    arrowLine: function arrowLine(_this) {
      $(_this).find('span').removeClass('left-line');
      $(_this).find('span').each(function (index, el) {
        if ($(el).children('b').length <= 1) {
          if ($(el).prev('span')[0]) {
            $(el).addClass('left-line');
          }
        }
      });
    }
  });
});